import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { EventEmmiterService } from './../../../services/event-emmiter.service';
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss','./mq-testimonial.component.scss']
})
export class TestimonialComponent {

  @Input() pageData : any;

  ngAfterViewInit() : void{
    if(this.gettingResponse === 'slick initialized'){
      this.cdRef.detectChanges(); 
    }
  }
  @ViewChild('slickModal') slickModal:any =  SlickCarouselComponent;
  strtButton : boolean = false;
  endButton : boolean = false;
  gettingResponse = '';
  panelOpenState : boolean = false;
  scrollingValue : any;
  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":true,
    "arrows":false,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  mobileslideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":false,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":true,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  constructor(
    private cdRef: ChangeDetectorRef,
    private emitterService : EventEmmiterService
  ) {}

  loader : boolean = false;
  sectionLoading : boolean = false;

  ngOnInit(): void {
    this.testimonials = this.pageData;
    // this.getElements();
    // this.templatesService.updateElementsData.subscribe((res:any)=>{
    //   this.testimonials = res.testimonials;
    // });
    this.emitterService.loadingSections.subscribe(
      (res) =>{
        if(res.lastComponent === 'Home-process-component'){
          let a = document.getElementById('testimonial_bg_image_image');
          a?.scrollIntoView({behavior:'smooth'})
          this.sectionLoading = true;
          setTimeout(() => {
            this.sectionLoading = false;
            this.emitterService.activateComponent.emit({nextComponent:'Contactus-section',loading:false})
          }, 5000);
        }
        // //console.log(res)
      }
    )
    this.emitterService.componentLoader.subscribe(
      (res) => {
        if(res.name === 'Home_Testomnial'){
          this.loader = res.loading
          this.testimonials = this.pageData;
        }
        else{
          this.loader = false;
        }
      }
    )
  }
  // getElements(){
  //   this.templatesService.updateElements.subscribe(
  //     (res:any) => {
  //       document.querySelectorAll('*[id]').forEach(element => {
  //         if(res.elem === element.id){
  //           let highlightingElement = document.getElementById(element.id);
  //           if(!highlightingElement) return;
  //           highlightingElement.style.border = '2px solid #F79824 !important';
  //           let sliderNumber ;
  //           if(element.id.split('_').includes('first')){
  //             sliderNumber = 0
  //           }
  //           else if(element.id.split('_').includes('second')){
  //             sliderNumber = 1
  //           }
  //           else if(element.id.split('_').includes('third')){
  //             sliderNumber = 2
  //           }
  //           this.slickModal.slickGoTo(sliderNumber);
  //           this.slickModal.slickPause();
  //         }
  //         else{
  //           let highlightingElement = document.getElementById(element.id);
  //           if(!highlightingElement) return;
  //           highlightingElement.style.border = 'none'
  //         }
  //       });
  //     }
  //   )
  // }
  slickInit(e:any) {
    this.gettingResponse = 'slick initialized';
    e.slick.$nextArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.endButton = true
      }
      else{
        this.endButton = false
      }
    });
    e.slick.$prevArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.strtButton = true
      }
      else{
        this.strtButton = false
      }
    });
  }

  // breakpoint(e:any) {
  //     //console.log('breakpoint');
  // } 

  afterChange(e:any) {
    e.slick.$nextArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.endButton = true
      }
      else{
        this.endButton = false
      }
    });
    e.slick.$prevArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.strtButton = true
      }
      else{
        this.strtButton = false
      }
    });
  }
  testimonials : any;

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }
  stop(){
    // this.slickModal.autoplay = false;
    // this.slickModal.config.autoplay = false;
    //console.log(this.slickModal);
    this.slickModal.slickPause();
    // this.slickModal.unslick();
  }
}
