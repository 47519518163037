<!-- <section class="main_section">
    <section class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </section>
    <section class="body_section">
        <div class="field">
            <mat-form-field appearance="outline" class="sfull_width">
                <mat-select [(ngModel)]="selectingValue">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let item of testingArray" [value]="item.value">{{item.name | titlecase}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="sfull_width" appearance="outline">
                <input placeholder="Enter name" matInput/>
            </mat-form-field>
        </div>
        <div class="submit_btn" (click)="submit()">
            <button>Submit</button>
        </div>
    </section>
</section> -->
  <!-- <section> -->
    <!-- <app-herobanner-stat-image [templateData]="data"></app-herobanner-stat-image> -->
    <!-- <app-process-spark-image-side></app-process-spark-image-side> -->
    <!-- <app-aboutus-with-background-image></app-aboutus-with-background-image> -->
    <!-- <app-preview-aboutus></app-preview-aboutus> -->
    <!-- <preview-app-aboutus-with-background-image></preview-app-aboutus-with-background-image> -->
    <!-- <app-map-left-address></app-map-left-address> -->
    <!-- <app-map-with-right-address></app-map-with-right-address> -->
    <!-- <app-preview-hover-horizontal-service-section></app-preview-hover-horizontal-service-section> -->
    <!-- <app-preview-servicesectioneditor></app-preview-servicesectioneditor> -->
    <!-- <app-preview-verticle-service-section></app-preview-verticle-service-section> -->
    <!-- <app-preview-aboutus></app-preview-aboutus>
    <preview-app-aboutus-with-background-image></preview-app-aboutus-with-background-image>
</section> -->

<!-- <section class="preview">
    <section *ngIf="data.category.name === 'Hero Banner'">
        <app-preview-herobanner-stat-image [templateData]="data"></app-preview-herobanner-stat-image>

 <section class="preview">
    <section *ngIf="data.category.name === 'Testimonial'">
        <app-preview-single-horizontal-testimonial [templateData]="data"></app-preview-single-horizontal-testimonial>
    </section>
</section> -->
<!-- <app-our-success-story></app-our-success-story> -->

 <!-- <section class="preview">
    <section>
       <app-preview-blog-cards-vertical-align [blogData]="blogData"></app-preview-blog-cards-vertical-align>
     </section> 
</section>   -->
<!-- <app-process-count-image></app-process-count-image>
<app-process-count-image-side></app-process-count-image-side> -->
<!-- <app-slider-faq-components></app-slider-faq-components> -->
<!-- <app-stats-text-partition></app-stats-text-partition> -->
<!-- <app-stats-with-text></app-stats-with-text>
<app-stats-with-side-image></app-stats-with-side-image>
<app-stats-text-wrap></app-stats-text-wrap>
<app-stats-text-side-partition></app-stats-text-side-partition> -->
<!-- <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align> -->
<!-- <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align> -->



<!-- strip checking purpose -->



<!-- <section>
    <div class="product">
    <img src="https://i.imgur.com/EHyR2nP.png" alt="The cover of Stubborn Attachments" />
    <div class="description">
    <h3>Stubborn Attachments</h3>
    <h5>$20.00</h5>
    </div>
    </div>
    <form action="/create-checkout-session" method="POST">
    <button type="submit" id="checkout-button" (click)="stripeChecking()">Checkout</button>
    </form>
</section> -->

<section>
    <app-contact-us1></app-contact-us1>
 </section>
<!-- <section class="preview">
    <section>
        <app-preview-contactus-text-inputs></app-preview-contactus-text-inputs>
    </section>
</section>  -->