<div class="main-section">
    <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
    <div class="stats-section">
        <div *ngFor="let item of subheading.value; let i = index" class="stats" >
            <div [ngStyle]="subheading.style?subheading.style:{}">{{item}}</div>
            <div [ngStyle]="subtext.style?subtext.style:{}">{{subtext.value[i]}}</div>
        </div>
    
    </div>
</div>
