import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from './../../../services/template.service';

@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss','./mq-service-section.component.scss']
})
export class ServiceSectionComponent {
  @Input() pageData:any;
  constructor(
    private _activatedRoute : ActivatedRoute,
    private templatesService:TemplateService,
    private emitterService : EventEmmiterService
  ){
  }

  navId : any;
  services:any;
  serviceText:string="";
  loader : boolean = false;
  sectionLoading : boolean = false;
  ngOnInit(){
    this.emitterService.loadingSections.subscribe(
      (res) =>{
        if(res.lastComponent === 'heroBanner'){
          let a = document.getElementById('service_section');
          a?.scrollIntoView({behavior:'smooth'})
          this.sectionLoading = true;
          setTimeout(() => {
            this.sectionLoading = false;
            this.emitterService.activateComponent.emit({nextComponent:'Home-process-component',loading:false})
          }, 5000);
        }
        // //console.log(res)
      }
    )
    this.emitterService.componentLoader.subscribe(
      (res)=>{
        // this.loader = res.loading;
        if(res.name === 'service'){
          this.loader = res.loading;
        }
        else{
          this.loader = false;
        }
        // //console.log(res.name)
      }
    )
  }

  
}
