import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";
import { TemplateService } from "src/app/services/template.service";
import { WebisiteService } from "../../../../webisite.service";

@Component({
  selector: 'app-preview-single-verticle-testimonial',
  templateUrl: './single-verticle-testimonial.component.html',
  styleUrls: ['./preview-single-verticle-testimonial.component.scss']
})
export class PreviewSingleVerticleTestimonialComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit() :void{
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        //console.log("value check",value);
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'single_vertical_testimonial'){
            this.templateData=conCompList;  
            this.dataAssign();
          }
          else{}
        })
      }
    });
    this.dataAssign(); 
    
  }

  data:any;

  // image = this.data[0];
  image:any;
  // heading = this.data[1];
  heading :any;
  // text = this.data[2];
  text : any;
  // profile_img = this.data[3];
  profile_img : any;
  // name = this.data[4];
  name: any
  // designation = this.data[5];
  designation : any;

  bgColor : any;

  dataAssign(){
    this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    let bName = this._dbService.getBusinessName();
    this.checkingStyles()
    if(this.valuesData != null) {
      this.data[0].imgUrl = this.valuesData.data[0].companyLogos[0];
      this.data[2].value = this.valuesData.data[0].text.replaceAll("{Company Name}",bName);
      this.data[3].imgUrl = this.valuesData.data[0].reviewerImage;
      this.data[4].value = this.valuesData.data[0].reviewedBy;
      this.data[5].value = this.valuesData.data[0].reviewerDesignation;
      this.data[6].value = this.valuesData.data[0].desc.replaceAll("{Company Name}",bName)
      // this.data[1].imgUrl = this.valuesData.data[0].companyLogos[0]
    }


    // this.image = this.valuesData.data[0].companyLogos[0];
    // this.text = this.valuesData.data[0].text;
    // this.profile_img = this.valuesData.data[0].reviewerImage;
    // this.name = this.valuesData.data[0].reviewedBy;
    // this.designation = this.valuesData.data[0].reviewerDesignation;
    // this.heading = this.data[1];

  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
