import { Component, Inject } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessInprogressComponent } from '../process-inprogress/process-inprogress.component';

@Component({
  selector: 'app-steps-to-follow',
  templateUrl: './steps-to-follow.component.html',
  styleUrls: ['./steps-to-follow.component.scss']
})
export class StepsToFollowComponent {

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<StepsToFollowComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ){}

  close(){
    this.dialogRef.close();
  }

  submitVerify(){
    this.dialogRef.close();
    let popup = this.templateService.openDialog(
      ProcessInprogressComponent,
      'auto',
      '540px',
      '80vw'
    )
  }

}
