import { Component, EventEmitter, HostListener, Input, Output,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { buttonShape, buttonStyle, combinationFontFamily } from 'src/app/constants/common-constant';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
enum SectionType {
  Normal,
  Location,
  NavBar,
  Seo,
  StyleSheet
}
@Component({
  selector: 'app-rightsidepanel',
  templateUrl: './rightsidepanel.component.html',
  styleUrls: ['./rightsidepanel.component.scss','./../../edit-website/edit-website.component.scss','./../../../seo/seo.component.scss']
})
export class RightsidepanelComponent {

  constructor(  private _activatedRoute : ActivatedRoute,
    private templatesService:TemplateService,
    private emitterService : EventEmmiterService,
    private imageUploadService:ComponentImageUploadService,
    private _dbService : DbService,
    ){
      this.getScreenSize();
  }
@Input() selectedComponent:any;
@Input() templateData:any;
@Output() editCollapse = new EventEmitter<boolean>();
businessDetails : any;
componentData:any=null;
businessId:any="";
sectionType:SectionType=SectionType.Normal;
templateId : any = '';
buttonStyles = buttonStyle;
buttonShape = buttonShape;
bShape: any = '';
bStyle: any = '';

screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  headingFontFamily;
  bodyFontFamily;
  btnColor;
  btnTextColor;
  fontFamilies = combinationFontFamily;
  selectingFontFamily;
ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  if(window.localStorage.getItem('bDetails') === null){
    this.templateId = this._dbService.getWebsiteId()?.split("+")[0];
  }
  else{
    this.businessDetails = JSON.parse(window.localStorage.getItem('bDetails') || '');
    this.businessId=this.businessDetails.id;
    this.getBusinessDetailsByBusinessId(); 
  }
  this.sectionType=SectionType.Seo;
  this.templatesService.editComponent.subscribe({
    next:(value:any)=>{ 
        //console.log("value",value);
        this.selectedComponent=value.selectedComponent;
        if(this.selectedComponent!==""){
          // this.pageSeoData=false;
          this.dataAssign(); 
          if(this.selectedComponent=="seo"){
            this.sectionType=SectionType.Seo;
          }
          else if(this.selectedComponent == "StyleSheet"){
            // this.pageSeoData=true;
           this.sectionType=SectionType.StyleSheet
           this.sendingColor=value.style.sendingColor,
          //  this.buttonShape=value.style.buttonShape,
           this.bShape=value.style.bShape,
           this.bStyle=value.style.bStyle,
           this.headingFontFamily=value.style.headingFontFamily,
           this.bodyFontFamily=value.style.bodyFontFamily;
           this.btnColor=value.style.btnColor;
           this.btnTextColor=value.style.btnTextColor;
           this.selectingFontFamily=value.style.selectingFontFamily;
          }
          else{
            this.sectionType=SectionType.Normal;
          }
        }     
    }
  })  
}


changeFontFamily() {
  this.headingFontFamily['font-family'] =
    this.selectingFontFamily.split(',')[0];
  this.bodyFontFamily['font-family'] =
    this.selectingFontFamily.split(',')[1];
}


gettingData: any;
  metaTitle = '';
  metaTitlelength = 0;
  metaDescription = '';
  metaDescriptionLength = 0;

  facebookTitle = '';
  facebookImage :any ;
  facebookTitleLength = 0;
  facebookDescription = '';
  facebookDescriptionLength = 0;

  twitterTitle = '';
  twitterTitleLength = 0;
  twitterDescription = '';
  twitterDescriptionLength = 0;

  twitterImage :any ;
  domainName:String="";
  sendingColor: any;
  componentsStyling: any = {
    bgColor1: String,
    bgColor2: String,
    buttonShape: String,
    buttonStyle: String,
    headingFontFamily: String,
    bodyFontFamily: String,
    txtHeadingColor: String,
    txtDescColor: String,
    txtNormalColor: String,
    btnColor: String,
    btnTextColor: String,
  };
  getBusinessDetailsByBusinessId(){
    this.templatesService.getBusinessDetailsById(this.businessId).subscribe(
      (res:any) => {
        //console.log(res.data);
        this.gettingData = res.data;
        this.metaTitle = this.gettingData.website.metaTitle;
        this.metaDescription = this.gettingData.website.metaDesc;
        this.facebookImage = this.gettingData.website.fbImage ? this.gettingData.website.fbImage : undefined;
        this.facebookTitle = this.gettingData.website.fbTitle;
        this.facebookDescription = this.gettingData.website.fbDesc;
        this.twitterImage = this.gettingData.website.twitterImage ? this.gettingData.website.twitterImage : undefined;
        this.twitterTitle = this.gettingData.website.twitterTitle;
        this.twitterDescription = this.gettingData.website.twitterDesc;
      },
      (err) => {
        this.templatesService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

data:any=[
    
  {value:"We Help Customers Build Their Dreams into Reality",field:"heading",type:'service-heading',style:{}},
   {
    value:[
      {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
      {value:'Design and Build Services',type:'text',field:'service1'},
      {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
     ],
     type:'array'
    },
    {
      value:[
       {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
       {value:'Design and Build Services',type:'text',field:'service1'},
       {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
      ],
      type:'array'
     },
     {
      value:[
       {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
       {value:'Design and Build Services',type:'text',field:'service1'},
       {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
      ],
      type:'array'
     },

]
dataAssign(){
  this.templateData.componentContentList.forEach((value:any) => {
    if(value.webComponentName==this.selectedComponent){
      this.componentData=value;
      this.data=value.data;
      //console.log(this.componentData.category.name);
      if(this.componentData.category.name === 'Top Nav Bar'){
        // this.selectedImageShape = ;
        this.imageShapes.forEach((elem) => {
          if(elem.value === this.componentData.data[1].imageType){
            this.selectedImageShape = elem.value
          }
        });
      }
      // this.selectedImageShape = this.componentData[0].data[1].imageType;
      if(this.componentData.category.name.toLowerCase().includes('location')) {
        this.sectionType = SectionType.Location;
      }
    }
  });
  //console.log(this.componentData);
  //console.log(this.data);
}

ContentChanged(){
  // this.templateData.componentContentList.forEach((value:any) => {
  //   if(value.webComponentName==this.selectedComponent){
  //     this.componentData=value;
  //     value.data=this.data;
  //   }
  // });
  // this.templatesService.updateElements.emit(this.templateData);
}

imageIndexValue : any
addImage(i:any){
  //console.log('add image index',i);
  this.imageIndexValue = i;
  
  let elementId = document.getElementById('browse_img');
  if(!elementId) return;
  elementId.click();
  // document.getElementById('browse_img')?.click();
}

// closePage(){
//   this.dialogRef.close();
// }

file : any ;
profileImg : any = '';
imageUploadingLoader : boolean = false;
indexValue : any = '';
async updateBackgroundImage(ev:any,i:any){
  this.pageLoader = true;
//   this.profileImg = '';
//  //console.log('image index value',this.data[this.imageIndexValue]);
//   this.imageUploadingLoader = true;
  const file = ev.srcElement.files[0];
  // this.file = file;
  this.profileImg = await this.imageUploadService.uploadComponentsImages(file);
  this.profileImg = this.profileImg.Location;
  // //console.log(this.profileImg);
  this.data[this.imageIndexValue].imgUrl=this.profileImg;
  //console.log('deleted Index value',this.templateData, this.imageIndexValue);
  this.templateData.componentContentList.forEach((value:any,i) => {
    if(value.webComponentName==this.selectedComponent){
      this.componentData=value;
      value.data=this.data;
      this.indexValue = i;
    }
  });
  this.templatesService.updateElements.emit(this.templateData);
  this.pageLoader = false;
}

async updateArrayImage(ev:any,i:number,j:number){
  ////console.log(this.data[i][j]);
  this.pageLoader = true;
  const file = ev.srcElement.files[0];
  this.file = file;
  this.profileImg = await this.imageUploadService.uploadComponentsImages(this.file);
  this.profileImg = this.profileImg.Location;
  ////console.log(this.profileImg);
  this.data[this.imageIndexValue].value[j].imageUrl=this.profileImg;
  this.templateData.componentContentList.forEach((value:any) => {
    if(value.webComponentName==this.selectedComponent){
      this.componentData=value;
      value.data=this.data;
    }
  });
  this.templatesService.updateElements.emit(this.templateData);
  this.pageLoader = false;
}

pageLoader : boolean = false;
saveData(){
  // this.pageLoader = true;
  // let data : any;
  // if(window.localStorage.getItem('bDetails') === null){
  //   data = {
  //     id:this.templateId,
  //     components : this.templateData.componentContentList,
  //     businessName:null
  //   }
  // }
  // else{
  //   data = {
  //     id:this.businessDetails.templateId,
  //     components : this.templateData.componentContentList,
  //     businessName:this.businessDetails.name
  //   }
  // }
  // this.templatesService.updateTemplateData(data).subscribe(
  //   {
  //     next:(value)=>{
  //       this.templateData.componentContentList.forEach((value:any) => {
  //        value.editSection=false;
  //       });
  //       // this.editCollapse.emit(false);
  //       this.selectedComponent="";
  //       // this.pageSeoData=true;
  //       this.sectionType = SectionType.Seo;
  //       this.pageLoader = false;
  //     },
  //     error:(err)=>{
  //       ////console.log(err); 
  //       this.pageLoader = false;
  //     },
  //   }
  // )
  this.templatesService.editorSave.emit({templateData:this.templateData,templateId:this.businessDetails.templateId,businessName:this.businessDetails.name});
  this.sectionType = SectionType.Seo;
  // this.templatesService.intiateTemplateData(this.templateData).subscribe(
  //   {
  //     next:(value)=>{
  //       ////console.log(value);
  //     },
  //     error:(err)=>{
  //         ////console.log(err);
  //     },
  //   }
  // )
  ////console.log("template Data",this.templateData);
  
}

tooglebutton(i:any,j:any){
////console.log(this.data[i][j]);
this.templateData.componentContentList.forEach((value:any) => {
  if(value.webComponentName==this.selectedComponent){
    this.componentData=value;
    value.data=this.data;
  }
});
this.templatesService.updateElements.emit(this.templateData);
}


tooglebuttonUpper(i:number){
  this.data[i].status = !this.data[i].status;
  this.templateData.componentContentList.forEach((value:any) => {
    if(value.webComponentName==this.selectedComponent){
      this.componentData=value;
      value.data=this.data;
    }
  });
  this.templatesService.updateElements.emit(this.templateData);
}


removeImage(i:any) {
  this.imageUploadService.deleteImages([this.data[i].imgUrl]).then((value) => {
    // this.data[i].imageUrl = '';
    this.data[i].imgUrl="";
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.webComponentName==this.selectedComponent){
        this.componentData=value;
        value.data=this.data;
      }
    });
    this.templatesService.updateElements.emit(this.templateData);
    //console.log(this.templateData);
    
  })
  .catch((err) => {
    console.error(err);
  });
  
  // this.imageUploadService.deleteImages([this.data[i].imageUrl]);
}
removeArrayImages(i:any,j:any){
  //console.log(this.data[i].value[j],i,j);
  this.imageUploadService.deleteImages([this.data[i].value[j].imageUrl]).then((value) =>{
    this.data[i].value[j].imageUrl = '';
    this.templateData.componentContentList.forEach((comConList:any) => {
      if(comConList.webComponentName === this.selectedComponent){
        this.componentData = comConList;
        comConList.data = this.data;
      }
    });
    this.templatesService.updateElements.emit(this.templateData);
  })
}

addArrayImage(i:any,j:any){
  //console.log(i,j);
}

changeLocation() {
  //console.log('changeLocation');
}

bgColorChanged() {
  this.templatesService.updateElements.emit(this.templateData);
}
textheadingColor(){
  this.templatesService.updateElements.emit(this.templateData);
}
textDescriptionColor(){
  this.templatesService.updateElements.emit(this.templateData);
}
textNormalColor(){
  this.templatesService.updateElements.emit(this.templateData);
}

// pageSeoData:boolean=true;
get isSeo() {
  return this.sectionType === SectionType.Seo;
}
get isNormal() {
  return this.sectionType === SectionType.Normal;
}
get isLocation() {
  return this.sectionType === SectionType.Location;
}
get isStyleSheet() {
  return this.sectionType === SectionType.StyleSheet;
}

closePage(){}

imageShapes: any = [
  { 
    value:"ROUNDED",
    name:"Rounded"
  },
  {
    value:"RECTANGLE",
    name:"Rectangle"
  }
];
selectedImageShape : any = '';
changeImageShape(ev){
  // //console.log(ev.value);
  // //console.log(this.templateData);
  this.templateData.componentContentList[0].data[1].imageType = ev.value;
  this.templatesService.updateElements.emit(this.templateData);  
}

shapeChange(ev: any) {
  if (ev.value === 'ROUNDED') {
    this.globalStyles.btnBorderRadius = '10px';
  } else {
    this.globalStyles.btnBorderRadius = '0px';
  }
}
globalStyles = {
  primaryColor: '#5599d5',
  buttonColor: '#fbd09a',
  btnBorderRadius: '0px',
  // textFamily:{
  //   'font-family':"'Lexend Deca', sans-serif"
  // }
  headingFontFamily: {
    'font-family': "'Lexend Deca', sans-serif",
  },
  bodyFontFamily: {
    'font-family': "'Lexend Deca', sans-serif",
  },
};


saveStyleData(){
  this.templateData.componentContentList.forEach(
    (comContList: any, i: number) => {
      comContList.bgColor = this.templateData.componentContentList[i].bgColor;
      comContList.buttonShape = this.bShape;
      comContList.buttonStyle = this.bStyle;
      comContList.headingFontFamily = this.selectingFontFamily.split(',')[0];
      comContList.bodyFontFamily = this.selectingFontFamily.split(',')[1];
      comContList.colors.btnColor = this.btnColor;
      comContList.colors.btnTextColor = this.btnTextColor;
    }
  );
  this.templatesService.updateElements.emit(this.templateData);
  this.saveData();
}
}

