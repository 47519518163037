<section
  *ngIf="!loader && !sectionLoading"
  class="main_section"
  id="service_section"
>
  <div class="service-title-text">
    <div
      class="title-text"
      id="service_text"
      [ngStyle]="
        navId === 'service_text' ? { border: '2px solid #F79824' } : {}
      "
    >
      {{ pageData?.serviceText }}
    </div>
  </div>

  <div class="image-card">
    <ng-container *ngFor="let item of pageData?.service">
      <div class="card-section">
        <div
          class="image-service"
          [id]="item?.serviceImages?.id"
          [ngStyle]="
            navId === item?.serviceImages?.id
              ? { border: '2px solid #F79824' }
              : {}
          "
        >
          <img
            [src]="item?.serviceImages?.img"
            alt="service-section"
            loading="lazy"
          />
        </div>
        <div class="card-contentsection">
          <div
            class="card-text-title"
            [id]="item?.serviceTitle?.id"
            [ngStyle]="
              navId === item?.serviceTitle?.id
                ? { border: '2px solid #F79824' }
                : {}
            "
          >
            {{ item?.serviceTitle?.name }}
          </div>
          <div
            class="description"
            [id]="item?.serviceDes?.id"
            [ngStyle]="
              navId === item?.serviceDes?.id
                ? { border: '2px solid #F79824' }
                : {}
            "
          >
            {{ item?.serviceDes?.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
<section *ngIf="loader" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</section>
<section *ngIf="sectionLoading" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <div class="text">Generating Best Services For you</div>
</section>
