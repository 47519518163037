import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router : Router,
    private _dbService : DbService,
    private templateService:TemplateService,
  ) {
    this.getScreenSize();
    window.localStorage.clear();
  }

  scrHeight : any;
  scrWidth : any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // //console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    if(this._dbService.getBusinessId() != null){
      this.router.navigate([`/admin/website/preview`]);
    }
    else{}
  }

  go(){
    // this.router.navigate(['/introduction'])
    this.router.navigate([`/business-type`]);
  }

}
