import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-process-number-text',
  templateUrl: './process-number-text.component.html',
  styleUrls: ['./process-number-text.component.scss' , './mq-process-number-text.component.scss']
})
export class ProcessNumberTextComponent {

  
  @Input() templateData:any;
  valuesData : any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'process_section_number_text'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData: any = {
  //   'componentCategory': "process",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Process-2",
  //   "data" : [
  //     {
  //       "value":"How it works",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size": "40px", "font-weight": 600, "line-height":"normal"},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color": "#868585", "font-size":"18px", "padding-top":"10px"},
  //       "textType":"description"
  //     },
  //     {
  //       "value":"1",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color":"black","color":"white", "padding":"8px", "width":"15%", "font-size":"20px", "border-radius": "50%", "text-align":"center"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Sign up for creating your first online store with ease.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"23px", "padding-top":"5%","font-weight":400,"line-height":"normal","font-family":"emoji"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"2",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color":"black","color":"white", "padding":"8px", "width":"15%", "font-size":"20px", "border-radius": "50%", "text-align":"center"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Add your products to your store and customize.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"23px", "padding-top":"5%","font-weight":400,"line-height":"normal","font-family":"emoji"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"3",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color":"black","color":"white", "padding":"8px", "width":"15%", "font-size":"20px", "border-radius": "50%", "text-align":"center"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Sell and earn as much as you can. Grow fast.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"23px", "padding-top":"5%","font-weight":400,"line-height":"normal","font-family":"emoji"},
  //       "textType":"text"
  //     },
  //   ]
  // }

  // data = this.templateData.data
  // howItWorks = this.data[0]
  // howItWorksDescription = this.data[1]
  // firstStep = this.data[2]
  // firstStepDescription = this.data[3]
  // secondStep = this.data[4]
  // secondStepDescription = this.data[5]
  // thirdStep = this.data[6]
  // thirdStepDescription = this.data[7];


  data : any;

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title;
      this.data[1].value = this.valuesData.desc;
      // this.data[2].imgUrl = this.valuesData.data[0].img;
      this.data[3].value = this.valuesData.data[0].desc;
      // this.data[4].imgUrl = this.valuesData.data[1].img;
      this.data[5].value = this.valuesData.data[1].desc;
      // this.data[6].imgUrl = this.valuesData.data[2].img;
      this.data[7].value = this.valuesData.data[2].desc;
      // this.valuesData = this.templateData.values;
      // this.data[0].value = this.valuesData.title.replaceAll("[Company Name]", bName);
      // this.data[1].value = this.valuesData.features[0];
      // this.data[2].value = this.valuesData.features[1];
      // this.data[3].value = this.valuesData.features[2];
      // this.data[7].imgUrl = this.valuesData.imageUrl;
      // this.data[5].value = this.valuesData.button1Text;
      // // this.data[4].value = this.valuesData.counter1Count;
      // // this.data[5].value = this.valuesData.counter1Text;
      // // this.data[6].value = this.valuesData.counter2Count;
      // // this.data[7].value = this.valuesData.counter2Text;
      // // this.data[8].imgUrl = this.valuesData.imageUrl;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
