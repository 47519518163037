import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-list-master-blogs',
  templateUrl: './list-master-blogs.component.html',
  styleUrls: ['./list-master-blogs.component.scss']
})
export class ListMasterBlogsComponent {
  search: string = "";
  constructor(
    private blogService: BlogServiceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.showApiData();
  }


  Blogs = [
    { name: 'All', value: 'ALL', status: true },
    { name: 'Drafts', value: 'SAVE', status: false },
    { name: 'Scheduled', value: 'SCHEDULED', status: false },
    { name: 'Published', value: 'PUBLISH', status: false },
  ]

  blogData: string = 'ALL';
  getBlogList(value: any) {
    if (value === this.blogData) return;
    this.Blogs.forEach((element) => {
      if (element.value !== value)
        element.status = false;
      else element.status = true

    })
    this.blogData = value;
    this.showApiData();

  }
  jsonData: any = "";
  emptyloader: boolean = false;
  showApiData() {
    this.emptyloader = true;
    this.blogService.getMasterBlog().subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.jsonData = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.jsonData = [];
        }
      })
    // const edjsHTML = require("editorjs-html");
    // const edjsParser = edjsHTML();
    // const html = edjsParser.parse(res.data[0]);
    // var htmlTable="";
    // var dataCheck="";
    // let a= document.getElementById("add_to_me");
    // let b=document.getElementById("data_show");
    // if(!b) return;
    // if(!a) return;
    // html.forEach((data:any) => {
    //   dataCheck+=data;
    // });
    //console.log("dataCheck",dataCheck);

    // b.innerHTML=dataCheck;
    // res.data[0].blocks.forEach((a:any) => {
    //   if(a.type === 'table'){
    //     htmlTable+="<table>"
    //     for(var i=0;i<a.data.content.length;i++){
    //       htmlTable+="<tr>"
    //       htmlTable+="<td>"+ a.data.content[i]+"</td>";
    //       htmlTable+="</tr>"
    //     }
    //     htmlTable+="</table>"
    //   }
    // });
    // a.innerHTML=htmlTable;
    // this.barTransy?.reinit();
  }


  // search api integration 

  searchText: any;
  _searchBlog() {
    if (this.searchText.length > 0) this.searchBlog();
    else if (this.searchText === '') this.showApiData();
  }

  searchBlog() {

  }

  clickCards(blogId: any) {
    //console.log(cards);
    this.router.navigate(['/admin/website/blogs/master/view-blog'], { queryParams: { blogId: blogId } })
  }


  createBlog() {
    this.router.navigate(["/master/create-blog"]);
  }

  // deleteBlog(position:any,ev:any){
  //   ev.stopPropagation();
  //   let deleteDepartmentModel = this.blogService.openDeleteDialog(
  //     DeleteBlogComponent,
  //     'auto',
  //     '600px',
  //     { blogId:position.id },
  //     '97vw'
  //   );
  //   deleteDepartmentModel.afterClosed().subscribe((data) => {
  //     if(data.ended=="success"){
  //       this.showApiData();
  //     }
  //   });
  // }

  editBlog(blog: any) {
    // ev.stopPropagation();
    //console.log("blogs",blog);
    this.blogService.setBlogData(blog);
    this.router.navigate(["/master/create-blog"], { queryParams: { edit: true } });
  }

  // blogsloaded=true;
}
