<section class="main_section">
    <div class="rowContainer">
        <div class="left-rowContainer">
            <div class="left-side-rowContainer">
                <div>
                    <img src="{{data[0].imgUrl}}" alt="" [ngStyle]="data[0].style?data[0].style:{}"
                        class="footer-img" />
                </div>
                <div [ngStyle]="data[1].style?data[1].style:{}">
                    {{data[1].value | titlecase}}
                </div>
            </div>
            <div class="left-side-horizontal-section">
                <div class="left-side-tab" [ngStyle]="data[2].style?data[2].style:{}">
                    {{data[2].value | titlecase}}
                </div>
                <div class="left-side-tab" [ngStyle]="data[3].style?data[3].style:{}">
                    {{data[3].value | titlecase}}
                </div>
                <div class="left-side-tab" [ngStyle]="data[4].style?data[4].style:{}">
                    {{data[4].value | titlecase}}
                </div>
                <div class="left-side-tab" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value | titlecase}}
                </div>
                <div class="left-side-tab" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value | titlecase}}
                </div>
                <div class="left-side-tab" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value | titlecase}}
                </div>
            </div>
        </div>
        <div class="right-rowContainer">
            <div [ngStyle]="data[8].style?data[8].style:{}">
                {{data[8].value | titlecase}}
            </div>
            <div class="right-side-input-fields">
                <div class="input-field">
                    <mat-form-field appearance="outline">
                        <input matInput placeholder="Enter your email">
                    </mat-form-field>
                </div>
                <div>
                    <button [ngStyle]="data[9].style?data[9].style:{}" class="first-btn">
                        {{data[9].value}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="footer-bottom-section">
        <div class="left-side-footer-section">
            <div [ngStyle]="data[10].style?data[10].style:{}">
                {{data[10].value}}
            </div>
        </div>
        <div class="right-side-footer-section">
            <div [ngStyle]="data[11].style?data[11].style:{}">{{data[11].value | titlecase}}</div>
            <div [ngStyle]="data[12].style?data[12].style:{}">{{data[12].value | titlecase}}</div>
            <div [ngStyle]="data[13].style?data[13].style:{}">{{data[13].value | titlecase}}</div>
        </div>
    </div>
</section>