import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from "./../../../../../../../services/template.service";
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-simple-hero-banner',
  templateUrl: './simple-hero-banner.component.html',
  styleUrls: ['./simple-hero-banner.component.scss', './mq-simple-hero-banner.component.scss']
})
export class SimpleHeroBannerComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}


  ngOnInit(): void {

    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'simple_hero_banner'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    this.dataAssign();
  }
  // templateData: any = {
  //   "componentCategory": "hero banner",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner5Component",
  //   "data" : [
  //     {
  //       "value":"Manage your freelancer business with this suit",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-family": "Arial, Helvetica, sans-serif"}
  //     },
  //     {
  //       "value":"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color": "#737778"}
  //     },
  //     {
  //       "value":"Input text here",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Get Started",
  //       "type":"button",
  //       "field":"button",
  //       "style":{"background-color": "#033E4E", "color": "white"}
  //     },
  //     {
  //       "imgUrl": "https://m.media-amazon.com/images/I/71fjJhZPCpL._SL1500_.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     }
  //   ]
  // }
  // data = this.templateData.data
  data : any;
  // heading = this.data[0]
  heading : any;
  // description = this.data[1]
  description : any;
  // inputText = this.data[2]
  // getStartedButton = this.data[3]
  // rightContainerImage = this.data[4]
  rightContainerImage : any;

  bgColor : any;

  dataAssign(){
    this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.valuesData != null) {
      var bName = this._dbService.getBusinessName();
      let cityName = this._dbService.getLocality();
      this.data[0].value = this.valuesData.title.replaceAll("{Company Name}", bName);
      this.data[0].value = this.valuesData.title.replaceAll("{City Name}", cityName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{Company Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{City Name}", cityName);
      this.data[4].imgUrl = this.valuesData.imageUrl;
      this.data[3].value = this.valuesData.button1Text;
    }

    if(this.data!=null){
      this.data[1].value = this.data[1].value.replaceAll("{City Name}", this._dbService.getLocality());   
    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.rightContainerImage = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // //console.log(this.templateData);
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
  redirectUrl(){
    window.open(`${this.data[3].link}`,'_blank');
  }
}
