<section class="main_section">
    <!-- <div class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </div> -->
    <div class="body_section">
        <div class="our_icon">
            <img src="./../../../../assets/images/logo.svg" alt="">
        </div>
        <div class="section_title">
            Reset Password
        </div>
        <div class="hr-line"></div>
        <div class="field business-field-section">
            <div class="label">
                Password
            </div>
            <form [formGroup]="pwd">
                <mat-form-field class="sfull-width" appearance="outline">
                    <input placeholder="Enter password" matInput [(ngModel)]="password"
                    [type]="passwordHide ? 'password' : 'text'"
                    formControlName="password"/>
                    <mat-icon matSuffix (click)="passwordHide = !passwordHide" style="cursor:pointer" class="eye-icon">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="field business-field-section">
            <div class="label">Confirm Password</div>
            <form [formGroup]="cnfirmPwd">
                <mat-form-field class="sfull-width" appearance="outline">
                    <input placeholder="Confirm password" matInput [(ngModel)]="confirmPassword"
                    [type]="cnfirmPasswordHide ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    />
                    <mat-icon matSuffix (click)="cnfirmPasswordHide = !cnfirmPasswordHide" style="cursor:pointer" class="eye-icon">{{cnfirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="password_validation" *ngIf="confirmPasswordBoolean">
            * Passsword and Confirm Password didn't match.
        </div>
        <div class="submit_btn">
            <button (click)="submit()">Submit</button>
        </div>
    </div>
</section>