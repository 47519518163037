import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-herobanner-stat-image-reverse',
  templateUrl: './herobanner-stat-image-reverse.component.html',
  styleUrls: ['./herobanner-stat-image-reverse.component.scss', './mq-herobanner-stat-image-reverse.component.scss']
})
export class HerobannerStatImageReverseComponent {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}
  
  ngOnInit(): void {
    // //console.log('double stats hero banner',this.templateData);

    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'double_stats_hero_banner_reverse'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData: any = 
  // {
  //   "componentCategory": "hero banner",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner8Component",
  //   "data": [
  //     {
  //       "value":"SaaS growth with weekly tips",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Clarity gives you the blocks & components you need to create a truly professional website, landing page or admin panel for your SaaS.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Enter email to get started",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Get Started Free",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     },
  //     {
  //       "value":"5,910+",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },     {
  //       "value":"Customers are using & it's growing everyday",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"240+",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"UI blocks that helps you to build rapid website",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color": "#48494A"}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/mobile.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     }
  //   ]
  // }
  

  data:any;
  // heading = this.data[0]
  // description = this.data[1]
  // inputText = this.data[2]
  // buttonText = this.data[3]
  // users = this.data[4]
  // usersData = this.data[5]
  // blocks = this.data[6]
  // blocksContent = this.data[7]
  // image = this.data[8]



  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      let cityName = this._dbService.getLocality();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title.replaceAll("{Company Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{Company Name}", bName);
      this.data[0].value = this.valuesData.title.replaceAll("{City Name}",cityName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{City Name}",cityName);
      this.data[3].value = this.valuesData.button1Text;
      this.data[4].value = this.valuesData.counter1Count;
      this.data[5].value = this.valuesData.counter1Text;
      this.data[6].value = this.valuesData.counter2Count;
      this.data[7].value = this.valuesData.counter2Text;
      this.data[8].imgUrl = this.valuesData.imageUrl;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
  redirectUrl(){
    window.open(`${this.data[3].link}`,'_blank');
  }
}
