<div class="main-section" [ngStyle]="{'background-color': bgColor}">
    <div class="left">
        <div class="quote-details">
        <img [ngStyle]="data[0].style?data[0].style:{}" src="./../../../../../../../../assets/images/testimonials/quote-left.svg" class="quote-img">
        <div [ngStyle]="data[1].style?data[1].style:{}" class="main-heading">{{data[1].value | titlecase}}</div>
    </div>
        <div [ngStyle]="data[2].style?data[2].style:{}" class="heading">{{data[2].value | titlecase}}</div>
        <div [ngStyle]="data[7].style ? data[7].style : {}" class="subtext">{{data[7].value | titlecase}}</div>
        <!-- <div [ngStyle]="link.style?link.style:{}">{{link?.value}}</div> -->
    </div>
    <div class="right">
        <img [ngStyle]="data[4].style?data[4].style:{}"[src]="data[4].imgUrl" alt="" class="person-img">
        <div class="person-details">
            <div>
            <div [ngStyle]="data[5].style?data[5].style:{}" class="person-name">{{data[5].value | titlecase}}</div>
            <div [ngStyle]="data[6].style?data[6].style:{}" class="person-type">{{data[6].value | titlecase}}</div>
            </div>
        </div>
    </div>
</div>