import { Component } from '@angular/core';

@Component({
  selector: 'app-construction-footer',
  templateUrl: './construction-footer.component.html',
  styleUrls: ['./construction-footer.component.scss']
})
export class ConstructionFooterComponent {

  constructor(){}

  ngOnInit(){

  }

  footer = {
    logo :{
      imageText : 'Lets Mobility',
      textStyles:{
        'font-size':'24px',
        'font-weight':'bold',
        'font-family':"'Lexend Deca', sans-serif",
        'color':'white'
      }
    },
    shortNote:{
      text:'Constructing world best',
      textStyles:{
        'font-size':'16px',
        'font-weight':'normal',
        'font-family':"'Lexend Deca', sans-serif",
        'color':'white'
      }
    },
    className : 'normal'
  }
}
