<section class="main_section">
    <div class="map map_section" id="map">
        <div class="location_address">
            <div class="loc_box">
                <div class="title">{{data[0].value | titlecase}}</div>
                <div class="location">{{data[1].value | titlecase}},&nbsp;{{data[2].value | titlecase}}</div>
                <div class="mobile">
                    <div class="m_icon">
                        <img src="./../../../../../../../../assets/images/common/mobile.svg" alt="mobile">
                    </div>
                    <div class="m_text">
                        {{data[3].value}}
                    </div>
                </div>
                <div class="mail">
                    <div class="m_icon">
                        <img src="./../../../../../../../../assets/images/common/mail.svg" alt="mail">
                    </div>
                    <div class="m_text">
                        {{data[4].value}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>