<section class="main_section" id="service_section" [ngStyle]="{'background-color': bgColor}">
    <div class="service-title-text">
        <div class="title-text" [ngStyle]="data[0].style ? data[0].style : {}">
        {{data[0].value | titlecase}}
        </div> 
     </div>
     
     <div class="image-card">
        <div class="card-section">
            <div class="image-service">
                <img [src]="data[1].value[0].imageUrl" alt="service-section" loading="lazy">
            </div>
            <div class="card-contentsection">
                <div class="card-text-title" [ngStyle]="data[1].value[1].style ? data[1].value[1].style : {}">
                    {{data[1].value[1].value}}
                </div>
                <div class="description" [ngStyle]="data[1].value[2].style ? data[1].value[2].style : {}">
                    {{data[1].value[2].value}}
                </div>
            </div>
        </div>
        <div class="card_section">
            <div class="image-service">
                <img [src]="data[2].value[0].imageUrl" alt="service-section" loading="lazy">
            </div>
            <div class="card-contentsection" >
                <div class="card-text-title" [ngStyle]="data[2].value[1].style ? data[2].value[1].style : {}">
                    {{data[2].value[1].value}}
                </div>
                <div class="description" [ngStyle]="data[2].value[2].style ? data[2].value[2].style : {}">
                    {{data[2].value[2].value}}
                </div>
            </div>
        </div>
         <!-- <ng-container *ngFor="let item of value">
                <div class="card-section">
                    <div class="image-service">
                        <img [src]="item.serviceImages.img" alt="service-section" loading="lazy">
                    </div>
                    <div class="card-contentsection">
                        <div class="card-text-title" >
                            {{item.serviceTitle.value}}
                        </div>
                        <div class="description">
                            {{item.serviceDes.value}}
                        </div>
                    </div>
                  
                </div>
         </ng-container> -->
     </div>     
</section>
<section *ngIf="loader" class="loader">
    <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div>
</section>
<section *ngIf="sectionLoading" class="loader">
    <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div>
    <div class="text">
        Generating Best Services For you
    </div>
</section>