<!-- <section class="auth-main">
    <div class="center-placed">
      <div class="named-logo">
        <img
          src="./../../../../assets/images/common/named-logo.svg"
          alt="named-logo"
        />
      </div>
  
      <div class="main-auth-sec" *ngIf="businessForm === 'businessDetails'">
        <div class="welcome-heading">Create Business</div>
        <div class="heading-subtext">
          Add Business details to create business account
        </div>
        <div class="in-form-field">
          <div class="label-text-auth">Business Name</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="text"
                name="first"
                [(ngModel)]="businessDetails.name"
                placeholder="Business Name"
              />
              <button
                *ngIf="businessDetails.name"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.name = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
          <div class="label-text-auth">Phone Number</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="number"
                name="first"
                [(ngModel)]="businessDetails.contact.mobile"
                placeholder="Phone Number"
                maxLength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <button
                *ngIf="businessDetails.contact.mobile"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.contact.mobile = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
          <div class="label-text-auth">Email</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="text"
                name="first"
                [(ngModel)]="businessDetails.contact.email"
                placeholder="Email Id"
              />
              <button
                *ngIf="businessDetails.contact.email"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.contact.email = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
        </div>
  
        <mat-checkbox class="checked-box" [(ngModel)]="businessDetails.checked"
          >By continue, business agreeing to
          <b class="terms">Terms and Privacy policy.</b></mat-checkbox
        >
        <div class="submit-btn" (click)="continue()">Continue</div>
      </div>
  

      <div class="main-auth-sec" *ngIf="businessForm === 'addressDetails'">
        <div class="welcome-heading"><span *ngIf="businessForm !== 'businessDetails'" (click)="backtoCreate()"><img src="./../../../../../assets/images/common/arrow-down.svg" /></span>Almost Done</div>
        <div class="heading-subtext">Add business type and business location</div>
  
        <div class="in-form-field">
          <div class="label-text-auth">Business Type</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="text"
                name="first"
                [(ngModel)]="businessDetails.category"
                placeholder="Business Name"
              />
              <button
                *ngIf="businessDetails.category"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.category = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
          <div class="label-text-auth">City</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="text"
                name="first"
                [(ngModel)]="businessDetails.address.city"
                placeholder="City"
              />
              <button
                *ngIf="businessDetails.address.city"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.address.city = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
          <div class="label-text-auth">Address</div>
          <form>
            <mat-form-field class="material-form-width" appearance="outline">
              <input
                matInput
                type="text"
                name="first"
                [(ngModel)]="businessDetails.address.address"
                placeholder="address"
              />
              <button
                *ngIf="businessDetails.address.address"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="businessDetails.address.address = ''"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
        </div>
  
        <div class="submit-btn" (click)="createBusiness()" *ngIf="edit === 'false'">Create Business</div>
        <div class="submit-btn" (click)="updateBusinessDetails()" *ngIf="edit === 'true'">Update Business</div>
      </div>
    </div>
  </section> -->
  

   <!-- <div class="signup-section"> -->
    <!-- <div class="header-website">
        <div class="close-image">
          <mat-icon>keyboard_arrow_left</mat-icon>
            <img src="./../../../../assets/images/logo.svg"/>
        </div>
    </div> -->
    <!-- <hr class="break-line"> -->
    <!-- <div class="signin-section" *ngIf="currentUrl === 'login'">
        <div class="signin-feild">
            <div class="main-auth-sec" *ngIf="businessForm === 'loginDetails'">
                <div class="welcome-heading">Sign In</div>
                <div class="heading-subtext">
                 Log In with your details
                  </div>
                  <div class="in-form-field">
                    <div class="label-text-sauth">User Name</div>
                    <form>  
                        <mat-form-field class="material-form-width" appearance="outline">
                          <input
                            matInput
                            type="text"
                            name="first"
                            [(ngModel)]="businessDetails.name"
                            placeholder="User Name"
                          />
                          <button
                            *ngIf="businessDetails.name"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="businessDetails.name = ''"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </form>
                      <div class="label-text-auth">Password</div>
                    <form>
                        <mat-form-field class="material-form-width" appearance="outline">
                          <input
                            matInput
                            type="text"
                            name="first"
                            [(ngModel)]="businessDetails.name"
                            placeholder="Password"
                          />
                          <button
                            *ngIf="businessDetails.name"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="businessDetails.name = ''"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </form>
                      <div class="sign_in_btn">
                        <button (click)="signIn()">Sign In</button>
                      </div>
                      <div class="heading-subtext">
                        Account Doesn't exist,Please signUp <span (click)="signUpCheck()">Here</span>
                         </div>
                  </div>
            </div>
            <div class="main-auth-sec" *ngIf="businessForm === 'businessDetails'">
            <div class="welcome-heading">Create Business</div>
            <div class="heading-subtext">
              Add Business details to create business account
            </div>
            <div class="in-form-field">
              <div class="label-text-auth">Business Name</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="businessDetails.name"
                    placeholder="Business Name"
                  />
                  <button
                    *ngIf="businessDetails.name"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.name = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
              <div class="label-text-auth">Phone Number</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="number"
                    name="first"
                    [(ngModel)]="businessDetails.contact.mobile"
                    placeholder="Phone Number"
                    maxLength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <button
                    *ngIf="businessDetails.contact.mobile"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.contact.mobile = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
              <div class="label-text-auth">Email</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="businessDetails.contact.email"
                    placeholder="Email Id"
                  />
                  <button
                    *ngIf="businessDetails.contact.email"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.contact.email = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
              <mat-checkbox class="checked-box" [(ngModel)]="businessDetails.checked"
          >By continue, business agreeing to
          <b class="terms">Terms and Privacy policy.</b></mat-checkbox
        >
        <div class="submit-btn" (click)="continue()">Continue</div>
            </div>
        </div>
        <div class="main-auth-sec" *ngIf="businessForm === 'addressDetails'">
            <div class="welcome-heading"><span *ngIf="businessForm !== 'businessDetails'" (click)="backtoCreate()"><img src="./../../../../../assets/images/common/arrow-down.svg" /></span>Almost Done</div>
            <div class="heading-subtext">Add business type and business location</div>
      
            <div class="in-form-field">
              <div class="label-text-auth">Business Type</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="businessDetails.category"
                    placeholder="Business Name"
                  />
                  <button
                    *ngIf="businessDetails.category"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.category = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
              <div class="label-text-auth">City</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="businessDetails.address.city"
                    placeholder="City"
                  />
                  <button
                    *ngIf="businessDetails.address.city"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.address.city = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
              <div class="label-text-auth">Address</div>
              <form>
                <mat-form-field class="material-form-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="businessDetails.address.address"
                    placeholder="address"
                  />
                  <button
                    *ngIf="businessDetails.address.address"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="businessDetails.address.address = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </form>
            </div>
      
            <div class="submit-btn" (click)="createBusiness()" *ngIf="edit === 'false'">Create Business</div>
            <div class="submit-btn" (click)="updateBusinessDetails()" *ngIf="edit === 'true'">Update Business</div>
          </div>
          <div>
            <button (click)="loginWithGoogle()">Login with Google</button>
          </div>
        </div>
        <div class="partionline"></div>
        <div class="features-included">
                <div class="features-heading">
                    Features included
                </div>
                <div class="features-listing">
                    <div class="features-text">
                        Website editing tools
                    </div>
                    <div class="features-text">
                        Custom domain name
                    </div>
                    <div class="features-text">
                        Stock images and icons
                    </div>
                    <div class="features-text">
                        Website analytics
                    </div>
                    <div class="features-text">
                        SEO tools
                    </div>
                    <div class="features-text">
                        Website hosting
                    </div>
                </div>
        </div>
    </div> -->
    <div class="signup_section" *ngIf="currentUrl === 'signup'">
        <div class="signup_left_section">
          <div class="header-website">
            <div class="close-image" (click)="goBackToPreviousPage()">
              <mat-icon>keyboard_arrow_left</mat-icon>
                <img src="./../../../../assets/images/logo.svg"/>
            </div>
          </div>
          <div class="signup_body">
            <!-- <div class="company_logo">
              <img src="./../../../../assets/images/named-logo.svg" alt="logo">
            </div> -->
            <div class="section_title">Welcome! Lets build your business</div>
            <div class="fields">
              <div class="field">
                <div class="label">Full Name</div>
                <mat-form-field appearance="outline" class="taking_input">
                  <input placeholder="Enter Your Full Name" matInput [(ngModel)]="businessDetails.name"/>
                </mat-form-field>
              </div>
              <div class="field">
                <div class="label">Mobile Number</div>
                <div class="code_number">
                  <div class="country_code">
                    <mat-form-field class="taking_input" appearance="outline">
                      <mat-select [(ngModel)]="selectedCountry" [disabled]="disableDropdown">
                        <mat-option [value]="item" *ngFor="let item of countryCodes">
                          {{item.name}}({{item.dialingCode}})
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="mobile_number">
                    <mat-form-field class="taking_input" appearance="outline">
                      <input placeholder="Enter Your Mobile Number" matInput [(ngModel)]="businessDetails.contact.mobile"
                      type="number" maxLength="15" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="label">Email Address</div>
                <mat-form-field appearance="outline" class="taking_input">
                  <input matInput placeholder="Enter Email Address" [(ngModel)]="businessDetails.contact.email"/>
                </mat-form-field>
              </div>
              <div class="field">
                <div class="label">Password</div>
                <form [formGroup]="signup">
                  <mat-form-field class="taking_input" appearance="outline">
                    <input matInput placeholder="Enter Password" [type]="signupHide ? 'password' : 'text'" formControlName="password"
                    minlength="8" [(ngModel)]="businessDetails.pwd" minlength="8" maxlength="16"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}" (focus)="showPasswordChecker()" id="focus" (blur)="hide()"/>
                    <mat-icon matSuffix (click)="signupHide = !signupHide" style="cursor:pointer" class="eye-icon">{{signupHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </mat-form-field>
                  <!-- password checkers -->
                  <!-- <div *ngIf="showToolTip" class="password_checker">
                    <div class="normal_text" id="capital">First Letter Capital(UPPERCASE)</div>
                    <div class="normal_text" id="special_character">Must Include Special Character</div>
                    <div class="normal_text" id="number">Must Include Number</div>
                    <div class="normal_text" id="min_length">Minimum 8 characters</div>
                  </div> -->
                </form>
                <div class="password_length">
                  <div style="display:flex;justify-content: space-between;">
                    <div id="capital">
                      <li>First Letter Uppercase</li>
                    </div>
                    <div id="special_character">
                      <li>One Special Character</li>
                    </div>
                  </div>
                  <div style="display:flex;justify-content: space-between;">
                    <div id="number">
                      <li>Atleast One Numaric</li>
                    </div>
                    <div id="min_length">
                      <li>8 Characters Minimum</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="term_conditions">
                By clicking "Create an account" you agree to simpo.ai <span style="color:#0267C1;cursor: pointer;" (click)="privacyPolicy()">Privacy Policy</span> and <span style="color: #0267C1;cursor: pointer;" (click)="termsConditions()"> Terms of Service</span>.
            </div>
            <div class="continue_btn">
              <button (click)="createAnAccount()" [disabled]="businessDetails.name === ''
              || businessDetails.contact.email === '' || businessDetails.contact.mobile === '' 
              || businessDetails.pwd === '' || !upperCaseCheck || !specialCharacterCheck || !numberCheck || !minLengthCheck">
                <span *ngIf="!loader">Create an account</span>
                <span class="spinner" *ngIf="loader">
                  <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
              </button>
            </div>
            <!-- <div class="or_continue">
              <img src="./../../../../assets/images/common/or_continue.svg" alt="continue">
            </div>
            <div class="continue_google">
              <img src="./../../../../assets/images/common/continue_google.svg" alt="continue_google">
            </div> -->
            <div class="alrea_account">
              Already have an account? <span style="color:#0267C1;cursor: pointer;" (click)="redirectToSignIn()">Sign In</span>
            </div>
          </div>
        </div>
        <div class="signup_right_section">
          <img src="./../../../../assets/images/common/signup_right_full_image.svg" alt="signup_image">
        </div>
    </div>
    <div class="signup_section" *ngIf="currentUrl === 'login'">
      <div class="signup-left-section">
        <div class="header-website">
          <div class="close-image" (click)="goBackToPreviousPage()">
            <mat-icon>keyboard_arrow_left</mat-icon>
              <img src="./../../../../assets/images/logo.svg"/>
          </div>
        </div>
        <div class="login_body">
          <!-- <div class="company_logo">
            <img src="./../../../../assets/images/named-logo.svg" alt="logo">
          </div> -->
          <div class="section_title">Welcome back!</div>
          <div class="section_subtitle">Login to simpo.ai account</div>
          <div class="fields">
            <div class="field">
              <div class="label">Email Address</div>
              <mat-form-field appearance="outline" class="taking_input">
                <input matInput placeholder="Enter Email Address" [(ngModel)]="businessDetails.contact.email"/>
              </mat-form-field>
            </div>
            <div class="field">
              <div class="label">Password</div>
              <form [formGroup]="signin">
                <mat-form-field class="taking_input" appearance="outline">
                  <input matInput placeholder="Enter Password" [type]="signinHide ? 'password' : 'text'" formControlName="password"
                  minlength="8" [(ngModel)]="businessDetails.pwd" (keyup.enter)="login()"/>
                  <mat-icon matSuffix (click)="signinHide = !signinHide" class="eye-icon">{{signinHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
              </form>
              <div class="remember_forget">
                <div class="remeber">
                  <mat-checkbox class="example-margin">Remember me</mat-checkbox>
                </div>
                <div class="forgot_password" (click)="forgotPassword()">Forgot password</div>
              </div>
            </div>
          </div>
          <!-- <div class="term_conditions">
              By clicking "Log in" you agree to simpo.ai <span style="color:#0267C1;cursor: pointer;" (click)="privacyPolicy()">Privacy Policy</span> and <span style="color: #0267C1;cursor: pointer;" (click)="termsConditions()"> Terms of Service</span>.
          </div> -->
          <div class="continue_btn">
            <button (click)="login()">
              <span *ngIf="!loader">Login</span>
                <span class="spinner" *ngIf="loader">
                  <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
            </button>
          </div>
          <!-- <div class="continue">
            <img src="./../../../../assets/images/common/or_continue.svg" alt="continue">
          </div>
          <div class="google">
            <img src="./../../../../assets/images/common/continue_google.svg" alt="continue_google">
          </div> -->
          <div class="alrea_account">
            No account yet? <span style="color:#0267C1;cursor: pointer;" (click)="redirectToSignup()">Sign Up</span>
          </div>
        </div>
      </div>
      <div class="signin_right_section">
        <img src="./../../../../assets/images/common/login_image.svg" alt="signup_image">
      </div>
  </div>
   <!-- </div> -->