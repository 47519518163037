<section class="main_section" *ngIf="!pageLoader && websiteStatus != 'DISABLED'" id="main_section">
    <section class="options_section" *ngIf="screenWidth > 475">
        <div class="edit-icon" (click)="editWebsite()">
            <mat-icon>edit</mat-icon> &nbsp;Edit
        </div>
        <div class="domain_publish">
            <div class="domain_url">
                <div class="domain">{{this.websiteUrl}}</div>
                <div class="custom_domain" (click)="connectCustomDomain()">Connect custom domain</div>
            </div>
            <div class="published_not">
                &#x2022; {{websiteStatus | titlecase}}
            </div>
        </div>
        <div class="preview_monitors">
            <div class="mobile_desktop">
                <div class="monitor" (click)="changeScreen('monitor')">
                    <img src="./../../assets/images/preview_site/monitor.svg" alt="monitor_screen" *ngIf="!monitorScreen">
                    <img src="./../../assets/images/preview_site/monitor_active.svg" alt="active_monitor_screen" *ngIf="monitorScreen">
                </div>
                <div class="hr_line"></div>
                <div class="mobile" (click)="changeScreen('mobile')">
                    <img src="./../../assets/images/preview_site/mobile.svg" alt="mobile_screen" *ngIf="!mobileScreen">
                    <img src="./../../assets/images/preview_site/mobile_active.svg" alt="active_mobile_screen" *ngIf="mobileScreen">
                </div>
            </div>
            <div class="preview_icon" (click)="previewCurrentWebsite()">
                <mat-icon>remove_red_eye</mat-icon>
            </div>
        </div>
    </section>

    <section class="options_section_mobile" *ngIf="screenWidth < 475">
        <div class="domain_publish">
            <div class="domain_url">
                <div class="domain">{{this.websiteUrl}}</div>
            </div>
            <div class="published_not">
                &#x2022; {{websiteStatus | titlecase}}
            </div>
        </div>
    </section>

    <section class="website_preview_section" *ngIf="monitorScreen">
        <ng-container *ngFor="let item of templateData.componentsData;let i = index">
            <div class="single_website" *ngIf="item.componentCategoryName === 'Top Nav Bar'">
                <div *ngIf="item.componentName === 'nav_bar_simple_page_links'">
                    <app-simple-navbar-pagelinks [templateData]="templateData.componentContentList[i]"></app-simple-navbar-pagelinks>
                </div>
                <div *ngIf="item.componentName === 'nav_bar_simple_contact'">
                    <app-navbar-contact-btn [templateData]="templateData.componentContentList[i]"></app-navbar-contact-btn>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Hero Banner'">
                <div *ngIf="item.componentName === 'double_stats_hero_banner'">
                    <app-herobanner-stat-image [templateData]="templateData.componentContentList[i]"></app-herobanner-stat-image>
                </div>
                <div *ngIf="item.componentName === 'rating_review_hero_banner'">
                    <app-herobanner-rating-review-bgimg-image [templateData]="templateData.componentContentList[i]"></app-herobanner-rating-review-bgimg-image>
                </div>
                <div *ngIf="item.componentName === 'simple_hero_banner'">
                    <app-simple-hero-banner [templateData]="templateData.componentContentList[i]"></app-simple-hero-banner>
                </div>
                <div *ngIf="item.componentName === 'simple_button_image_hero_banner'">
                    <app-simple-herobanner-button-image [templateData]="templateData.componentContentList[i]"></app-simple-herobanner-button-image>
                </div>
                <div *ngIf="item.componentName === 'double_stats_hero_banner_reverse'">
                    <app-herobanner-stat-image-reverse [templateData]="templateData.componentContentList[i]"></app-herobanner-stat-image-reverse>
                </div>
                <div *ngIf="item.componentName === 'simple_hero_banner_rightside_background'">
                    <app-siple-herobaner-rightside-background [templateData]="templateData.componentContentList[i]"></app-siple-herobaner-rightside-background>
                </div>
                <div *ngIf="item.componentName === 'hero_banner_features_rightside_image'">
                    <app-herobanner-feature-rightside-image [templateData]="templateData.componentContentList[i]"></app-herobanner-feature-rightside-image>
                </div>
                <div *ngIf="item.componentName === 'hero_banner_with_review'">
                    <app-herobanner-with-review [templateData]="templateData.componentContentList[i]"></app-herobanner-with-review>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Location'" id="Location">
                <div *ngIf="item.componentName === 'map_with_left_side_address'">
                    <app-map-left-address [templateData]="templateData.componentContentList[i]"></app-map-left-address>
                </div>
                <div *ngIf="item.componentName === 'map_with_right_side_address'">
                    <app-map-with-right-address [templateData]="templateData.componentContentList[i]"></app-map-with-right-address>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Contact Us'" id="Contact Us">
                <div *ngIf="item.componentName === 'simple_contactus_right_heading'">
                    <app-contactus-text-inputs [templateData]="templateData.componentContentList[i]"></app-contactus-text-inputs>
                </div>
                <div *ngIf="item.componentName === 'simple_contact_form'">
                    <app-contact-us1 [templateData]="templateData.componentContentList[i]"></app-contact-us1>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Service Section'" id="Service Section">
                <div *ngIf="item.componentName === 'service_section_simple_horizontal'">
                    <app-servicesectioneditor [templateData]="templateData.componentContentList[i]"></app-servicesectioneditor>
                </div>
                <div *ngIf="item.componentName === 'service_section_simple_vertical'">
                    <app-verticle-service-section [templateData]="templateData.componentContentList[i]"></app-verticle-service-section>
                </div>
                <div *ngIf="item.componentName === 'hover_horizontal_service_section'">
                    <app-hover-horizontal-service-section [templateData]="templateData.componentContentList[i]"></app-hover-horizontal-service-section>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'About Us'">
                <div *ngIf="item.componentName === 'simple_about_us_section'">
                    <app-aboutus [templateData]="templateData.componentContentList[i]"></app-aboutus>
                </div>
                <div *ngIf="item.componentName === 'simple_about_us_section_background_image'">
                    <app-aboutus-with-background-image [templateData]="templateData.componentContentList[i]"></app-aboutus-with-background-image>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Testimonial'" id="Testimonial">
                <div *ngIf="item.componentName === 'testimonal_slickcarsouel'">
                    <app-testimonal-slickcarsouel [templateData]="templateData.componentContentList[i]"></app-testimonal-slickcarsouel>
                </div>
                <div *ngIf="item.componentName === 'double_item_testimonial'">
                    <app-single-page-double-testimomial [templateData]="templateData.componentContentList[i]"></app-single-page-double-testimomial>
                </div>
                <div *ngIf="item.componentName === 'single_horizontal_testimonial'">
                    <app-single-horizontal-testimonial [templateData]="templateData.componentContentList[i]"></app-single-horizontal-testimonial>
                </div>
                <div *ngIf="item.componentName === 'single_vertical_testimonial'">
                    <app-single-verticle-testimonial [templateData]="templateData.componentContentList[i]"></app-single-verticle-testimonial>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Process'">
                <div *ngIf="item.componentName === 'process_section_spark_image_side'">
                    <app-process-spark-image-side [templateData]="templateData.componentContentList[i]"></app-process-spark-image-side>
                </div>
                <div *ngIf="item.componentName === 'process_section_number_text'">
                    <app-process-number-text [templateData]="templateData.componentContentList[i]"></app-process-number-text>
                </div>
                <div *ngIf="item.componentName === 'process_count_image_side'">
                    <app-process-count-image-side [templateData]="templateData.componentContentList[i]"></app-process-count-image-side>
                </div>
                <div *ngIf="item.componentName === 'process_count_image'">
                    <app-process-count-image [templateData]="templateData.componentContentList[i]"></app-process-count-image>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'FAQ'" id="FAQ">
                <div *ngIf="item.componentName === 'horizontal_faq'">
                    <app-horizontal-faq [templateData]="templateData.componentContentList[i]"></app-horizontal-faq>
                </div>
                <div *ngIf="item.componentName === 'opened_faq'">
                    <app-opened-faq [templateData]="templateData.componentContentList[i]"></app-opened-faq>
                </div>
                <div *ngIf="item.componentName === 'faq_slider'">
                    <app-slider-faq-components [templateData]="templateData.componentContentList[i]"></app-slider-faq-components>
                </div>
            </div>
            <ng-container >
                <div class="single_website" *ngIf="item.componentCategoryName === 'Blog'" id="Blog">
                    <div *ngIf="item.componentName === 'Blog_Cards_Horizontal_Align'">
                        <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align>
                    </div>
                    <div *ngIf="item.componentName === 'Blog_Cards_Vertical_Align'">
                        <app-blog-cards-vertical-align [blogData]="blogData"></app-blog-cards-vertical-align>
                    </div>
                </div>
            </ng-container>
            <!-- <div class="single_website" *ngIf="item.componentName=='contact-Us1'">
                <app-contact-us1 [templateData]="templateData"></app-contact-us1>
                <div class="show_btns" >
                    <div class="containerelment">
                        <div class="feild-edit">
                            <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                <div class="regenerate-text">
                                    Regenerate 
                                </div>
                            </div>
                            <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                <div class="regenerate-text">
                                    Change Content 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentName=='contact-Us2'">
                <app-contact-us2 [templateData]="templateData"></app-contact-us2>
                <div class="show_btns" >
                    <div class="containerelment">
                        <div class="feild-edit">
                            <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                <div class="regenerate-text">
                                    Regenerate 
                                </div>
                            </div>
                            <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                <div class="regenerate-text">
                                    Change Content 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="single_website" *ngIf="item.componentCategoryName === 'Footer'">
                <div *ngIf="item.componentName === 'footer_simple_social'">
                    <app-footer1 [templateData]="templateData.componentContentList[i]"></app-footer1>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_social_pages'">
                    <app-simple-footer-social-links [templateData]="templateData.componentContentList[i]"></app-simple-footer-social-links>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_vertical'">
                    <app-simple-verticle-footer [templateData]="templateData.componentContentList[i]"></app-simple-verticle-footer>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_horizontal'">
                    <app-simple-footer-copyrights [templateData]="templateData.componentContentList[i]"></app-simple-footer-copyrights>
                </div>
            </div>
        </ng-container>
    </section>
    <section class="mobile_preview_section" *ngIf="mobileScreen" id="mobileScreen">
        <ng-container *ngFor="let item of templateData.componentsData;let i = index">
            <div class="single_website" *ngIf="item.componentCategoryName === 'Top Nav Bar'">
                <div *ngIf="item.componentName === 'nav_bar_simple_page_links'">
                    <app-preview-simple-navbar-pagelinks [templateData]="templateData.componentContentList[i]"></app-preview-simple-navbar-pagelinks>
                </div>
                <div *ngIf="item.componentName === 'nav_bar_simple_contact'">
                    <app-navbar-contact-btn [templateData]="templateData.componentContentList[i]"></app-navbar-contact-btn>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Hero Banner'">
                <div *ngIf="item.componentName === 'double_stats_hero_banner'">
                    <app-preview-herobanner-stat-image [templateData]="templateData.componentContentList[i]"></app-preview-herobanner-stat-image>
                </div>
                <div *ngIf="item.componentName === 'rating_review_hero_banner'">
                    <app-preview-herobanner-rating-review-bgimg-image [templateData]="templateData.componentContentList[i]"></app-preview-herobanner-rating-review-bgimg-image>
                </div>
                <div *ngIf="item.componentName === 'simple_hero_banner'">
                    <app-preview-simple-hero-banner [templateData]="templateData.componentContentList[i]"></app-preview-simple-hero-banner>
                </div>
                <div *ngIf="item.componentName === 'simple_button_image_hero_banner'">
                    <app-preview-simple-herobanner-button-image [templateData]="templateData.componentContentList[i]"></app-preview-simple-herobanner-button-image>
                </div>
                <div *ngIf="item.componentName === 'hero_banner_features_rightside_image'">
                    <app-preview-herobanner-feature-rightside-image [templateData]="templateData.componentContentList[i]"></app-preview-herobanner-feature-rightside-image>
                </div>
                <div *ngIf="item.componentName === 'hero_banner_with_review'">
                    <app-preview-herobanner-with-review [templateData]="templateData.componentContentList[i]"></app-preview-herobanner-with-review>
                </div>
                <div *ngIf="item.componentName === 'double_stats_hero_banner_reverse'">
                    <app-preview-herobanner-stat-image-reverse [templateData]="templateData.componentContentList[i]"></app-preview-herobanner-stat-image-reverse>
                </div>
                <div *ngIf="item.componentName === 'simple_hero_banner_rightside_background'">
                    <app-preview-siple-herobaner-rightside-background [templateData]="templateData.componentContentList[i]"></app-preview-siple-herobaner-rightside-background>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Service Section'" id="Service Section">
                <div *ngIf="item.componentName === 'service_section_simple_horizontal'">
                    <app-preview-servicesectioneditor [templateData]="templateData.componentContentList[i]"></app-preview-servicesectioneditor>
                </div>
                <div *ngIf="item.componentName === 'service_section_simple_vertical'">
                    <app-preview-verticle-service-section [templateData]="templateData.componentContentList[i]"></app-preview-verticle-service-section>
                </div>
                <div *ngIf="item.componentName === 'hover_horizontal_service_section'">
                    <app-preview-hover-horizontal-service-section [templateData]="templateData.componentContentList[i]"></app-preview-hover-horizontal-service-section>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName=='Testimonial'" id="Testimonial">
                <div *ngIf="item.componentName === 'testimonal_slickcarsouel'">
                    <app-preview-testimonal-slickcarsouel [templateData]="templateData.componentContentList[i]"></app-preview-testimonal-slickcarsouel>
                </div>
                <div *ngIf="item.componentName === 'double_item_testimonial'">
                    <app-preview-single-page-double-testimomial [templateData]="templateData.componentContentList[i]"></app-preview-single-page-double-testimomial>
                </div>
                <div *ngIf="item.componentName === 'single_horizontal_testimonial'">
                    <app-preview-single-horizontal-testimonial [templateData]="templateData.componentContentList[i]"></app-preview-single-horizontal-testimonial>
                </div>
                <div *ngIf="item.componentName === 'single_vertical_testimonial'">
                    <app-preview-single-verticle-testimonial [templateData]="templateData.componentContentList[i]"></app-preview-single-verticle-testimonial>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Location'" id="Location">
                <div *ngIf="item.componentName === 'map_with_left_side_address'">
                    <app-preview-map-left-address [templateData]="templateData.componentContentList[i]"></app-preview-map-left-address>
                </div>
                <div *ngIf="item.componentName === 'map_with_right_side_address'">
                    <app-preview-map-with-right-address [templateData]="templateData.componentContentList[i]"></app-preview-map-with-right-address>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Process'" id="Process">
                <div *ngIf="item.componentName === 'process_section_spark_image_side'">
                    <app-preview-process-spark-image-side [templateData]="templateData.componentContentList[i]"></app-preview-process-spark-image-side>
                </div>
                <div *ngIf="item.componentName === 'process_section_number_text'">
                    <app-preview-process-number-text [templateData]="templateData.componentContentList[i]"></app-preview-process-number-text>
                </div>
                <div *ngIf="item.componentName === 'process_count_image_side'">
                    <app-preview-process-count-image-side [templateData]="templateData.componentContentList[i]"></app-preview-process-count-image-side>
                </div>
                <div *ngIf="item.componentName === 'process_count_image'">
                    <app-preview-process-count-image [templateData]="templateData.componentContentList[i]"></app-preview-process-count-image>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'FAQ'" id="FAQ">
                <div *ngIf="item.componentName === 'horizontal_faq'">
                    <app-preview-horizontal-faq [templateData]="templateData.componentContentList[i]"></app-preview-horizontal-faq>
                </div>
                <div *ngIf="item.componentName === 'opened_faq'">
                    <app-preview-opened-faq [templateData]="templateData.componentContentList[i]"></app-preview-opened-faq>
                </div>
                <div *ngIf="item.componentName === 'faq_slider'">
                    <app-preview-slider-faq-components [templateData]="templateData.componentContentList[i]"></app-preview-slider-faq-components>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'About Us'" id="About Us">
                <div *ngIf="item.componentName === 'simple_about_us_section'">
                    <app-preview-aboutus [templateData]="templateData.componentContentList[i]"></app-preview-aboutus>
                </div>
                <div *ngIf="item.componentName === 'simple_about_us_section_background_image'">
                    <preview-app-aboutus-with-background-image [templateData]="templateData.componentContentList[i]"></preview-app-aboutus-with-background-image>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Contact Us'" id="Contact Us">
                <div *ngIf="item.componentName === 'simple_contactus_right_heading'">
                    <app-preview-contactus-text-inputs [templateData]="templateData.componentContentList[i]"></app-preview-contactus-text-inputs>
                </div>
                <div *ngIf="item.componentName === 'simple_contact_form'">
                    <app-preview-contact-us1 [templateData]="templateData.componentContentList[i]"></app-preview-contact-us1>
                </div>
            </div>
            <div class="single_website" *ngIf="item.componentCategoryName === 'Footer'">
                <div *ngIf="item.componentName === 'footer_simple_social'">
                    <app-preview-footer1 [templateData]="templateData.componentContentList[i]"></app-preview-footer1>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_social_pages'">
                    <app-preview-simple-footer-social-links [templateData]="templateData.componentContentList[i]"></app-preview-simple-footer-social-links>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_vertical'">
                    <app-preview-simple-verticle-footer [templateData]="templateData.componentContentList[i]"></app-preview-simple-verticle-footer>
                </div>
                <div *ngIf="item.componentName === 'footer_simple_horizontal'">
                    <app-preview-simple-footer-copyrights [templateData]="templateData.componentContentList[i]"></app-preview-simple-footer-copyrights>
                </div>
            </div>
        </ng-container>
    </section>
</section>
<section class="loader_section" *ngIf="pageLoader">
    <!-- <div class="loader_section"> -->
        <div class="api_loader">
            <div class="dots-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    <!-- </div> -->
</section>
<section class="main_section_disabled" *ngIf="websiteStatus === 'DISABLED'">
    Your website in DISABLED state
</section>