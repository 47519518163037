<section class="navProperties" *ngIf="screenWidth > 475" [ngStyle]="{'background-color': bgColor}">
    <div class="leftNav">
        <nav class="nav4">
            <div class="head">
                <img src="{{data[1].imgUrl}}" *ngIf="data[1].status === 'true' || data[1].status === true"
                [ngClass]="data[1].imageType === 'ROUNDED' ? 'rounded' : 'normal'">
                <span [ngStyle]="data[0].style?data[0].style:{}" *ngIf="data[0].status === 'true' || data[0].status === true">{{data[0].value | titlecase}}</span>
            </div>
        </nav>
    </div>
    <div class="navs">
        <span [ngStyle]="data[3].style?data[3].style:{}" *ngIf="data[3].value != '' && data[3].status"
            (click)="goTo(this.data[3].redirectingUrl)" class="normal-btn-text">{{data[3].value | titlecase}}</span>
        <span [ngStyle]="data[4].style?data[4].style:{}" *ngIf="data[4].value != '' && data[4].status"
            (click)="goTo(this.data[4].redirectingUrl)" class="normal-btn-text">{{data[4].value | titlecase}}</span>
        <span [ngStyle]="data[5].style?data[5].style:{}" *ngIf="data[5].value != '' && data[5].status"
            (click)="goTo(this.data[5].redirectingUrl)" class="normal-btn-text">{{data[5].value}}</span>
        <button [ngStyle]="data[6].style?data[6].style:{}" *ngIf="data[6].status" class="normal-btn"
        (click)="contactClicked()">Contact Now</button>
    </div>
</section>
<section class="navProperties" *ngIf="screenWidth < 475" [ngStyle]="{'background-color': bgColor}">
    <div class="leftNav">
        <nav class="nav4">
            <div class="head">
                <img src="{{data[1].imgUrl}}" *ngIf="data[1].status === 'true' || data[1].status === true" class="profile-img">
                <span [ngStyle]="data[0].style?data[0].style:{}" *ngIf="data[0].status === 'true' || data[0].status === true">{{data[0].value | titlecase}}</span>
            </div>
        </nav>
    </div>
    <div class="rightNav">
        <div class="navs">
            <!-- <span [ngStyle]="data[3].style?data[3].style:{}" *ngIf="data[3].value != '' && data[3].status"
                (click)="goTo(this.data[3].redirectingUrl)" class="normal-btn-text">{{data[3].value | titlecase}}</span>
            <span [ngStyle]="data[4].style?data[4].style:{}" *ngIf="data[4].value != '' && data[4].status"
                (click)="goTo(this.data[4].redirectingUrl)" class="normal-btn-text">{{data[4].value | titlecase}}</span>
            <span [ngStyle]="data[5].style?data[5].style:{}" *ngIf="data[5].value != '' && data[5].status"
                (click)="goTo(this.data[5].redirectingUrl)" class="normal-btn-text">{{data[5].value}}</span> -->
            <button [ngStyle]="data[6].style?data[6].style:{}" *ngIf="data[6].status" class="normal-btn"
            (click)="contactClicked()">Contact Now</button>
        </div>
        <div class="menu_open" (click)="openPopup()">
            <!-- <img src="./../../../../../../../../assets/images/common/menu_open.svg" alt="menu_open"> -->
            <div class="hr_line" [ngStyle]="{'border-bottom': borderBottom}"></div>
            <div class="hr_line" [ngStyle]="{'border-bottom': borderBottom}"></div>
            <div class="hr_line" [ngStyle]="{'border-bottom': borderBottom}"></div>
        </div>
        <div class="downPopup" *ngIf="openDownPopupUp">
            <div [ngStyle]="data[3].style?data[3].style:{}" *ngIf="data[3].value != '' && data[3].status"
                (click)="goTo(this.data[3].redirectingUrl)" class="normal-btn-text">{{data[3].value | titlecase}}</div>
            <div [ngStyle]="data[4].style?data[4].style:{}" *ngIf="data[4].value != '' && data[4].status"
                (click)="goTo(this.data[4].redirectingUrl)" class="normal-btn-text">{{data[4].value | titlecase}}</div>
            <div [ngStyle]="data[5].style?data[5].style:{}" *ngIf="data[5].value != '' && data[5].status"
                (click)="goTo(this.data[5].redirectingUrl)" class="normal-btn-text">{{data[5].value}}</div>
        </div>
    </div>
</section>

<!-- <div class="header-icon" *ngIf="screenWidth < 475">
                <img src="./../../../../../../../../assets/images/common/menu_open_black.svg" alt="menu.svg" />
            </div> -->