<section class="main_section">
    <section class="top_layer" *ngIf="!queryParams && !totalPageLoader">
        <div class="left_container" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            <div *ngIf="srcWidth<475">
                <img src="./../../../assets/images/common/rightArrow.svg" alt="rightArrow" class="rightArrowResposviness">
            </div>
            <img src="./../../assets/images/logo.svg" alt="" class="logo">
        </div>
        <div class="right_container" *ngIf="srcWidth>475">
            <div class="re-style" id="restyle_component">
                <button (click)="openRestyleComponent()">Restyle <img src="./../../assets/images/restyle.svg" alt="restyle"></button>
            </div>
            <div class="re-style">
                <button (click)="globalRegenrate()">Regenerate <img src="./../../assets/images/global-regeneration.svg" alt="global-regeneration"></button>
            </div>
            <div class="re-style">
                <button (click)="shareWebsite()">Share <img src="./../../assets/images/share.svg" alt="Share"></button>
            </div>
            <div class="publish">
                <button (click)="signUp()">Publish</button>
            </div>
            <!-- <div>
                <button (click)="updateGlobalColor()">update</button>
            </div> -->
        </div>
        <div class="right_container" *ngIf="srcWidth<475">
            <div class="re-style" id="restyle_component">
                <img src="./../../../assets/images/common/regenerateResposviness.svg" alt="regenerateResponsviness" (click)="globalRegenrate()">
            </div>
            <div class="re-style">
             <img src="./../../../assets/images/common/restyleResposviness.svg" alt="restyleResposviness" (click)="openRestyleComponent()">
            </div>
            <div class="re-style">
                <img src="./../../../assets/images/common/shareResposviness.svg" alt="shareResposviness" (click)="shareWebsite()">
               </div>
        </div>
    </section>
    <section class="top_layer" *ngIf="totalPageLoader">
        <div class="left_container" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            <img src="./../../assets/images/logo.svg" alt="" class="logo">
        </div>
        <div class="top_layer_loading">
            <div class="spin_loader"></div>
            <div class="loader_text">Building your website</div>
        </div>
    </section>
    <section >
    </section>
    <section class="bottom_layer" *ngIf="!totalPageLoader" [ngClass]="queryParams ? 'params_bottom_layer' : ''">
        <div class="editor-containersection" [ngClass]="queryParams ? 'params_edit_containersection' : ''">
            <!-- <ng-container>
                <div class="single_website">
                    <div #displayingComponents></div>
                    <div class="show_btns" >
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section">
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section">
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container> -->
            <ng-container *ngFor="let item of templateData.componentsData;let i = index">
                <div class="single_website" *ngIf="item.componentCategoryName === 'Top Nav Bar'" id="Top Nav Bar">
                    <div *ngIf="item.componentName === 'nav_bar_simple_page_links'">
                        <app-simple-navbar-pagelinks [templateData]="templateData.componentContentList[i]"></app-simple-navbar-pagelinks>
                    </div>
                    <div *ngIf="item.componentName === 'nav_bar_simple_contact'">
                        <app-navbar-contact-btn [templateData]="templateData.componentContentList[i]"></app-navbar-contact-btn>
                    </div>
                </div>
                <div class="single_website" *ngIf="item.componentCategoryName=='Hero Banner'" id="Hero Banner">
                    <div *ngIf="item.componentName === 'double_stats_hero_banner'">
                        <app-herobanner-stat-image [templateData]="templateData.componentContentList[i]" ></app-herobanner-stat-image>
                    </div>
                    <div *ngIf="item.componentName === 'rating_review_hero_banner'">
                        <app-herobanner-rating-review-bgimg-image [templateData]="templateData.componentContentList[i]" ></app-herobanner-rating-review-bgimg-image>
                    </div>
                    <div *ngIf="item.componentName === 'simple_hero_banner'">
                        <app-simple-hero-banner [templateData]="templateData.componentContentList[i]" ></app-simple-hero-banner>
                    </div>
                    <div *ngIf="item.componentName === 'simple_button_image_hero_banner'">
                        <app-simple-herobanner-button-image [templateData]="templateData.componentContentList[i]" ></app-simple-herobanner-button-image>
                    </div>
                    <div *ngIf="item.componentName === 'double_stats_hero_banner_reverse'">
                        <app-herobanner-stat-image-reverse [templateData]="templateData.componentContentList[i]" ></app-herobanner-stat-image-reverse>
                    </div>
                    <div *ngIf="item.componentName === 'simple_hero_banner_rightside_background'">
                        <app-siple-herobaner-rightside-background [templateData]="templateData.componentContentList[i]" ></app-siple-herobaner-rightside-background>
                    </div>
                    <div *ngIf="item.componentName === 'hero_banner_features_rightside_image'">
                        <app-herobanner-feature-rightside-image [templateData]="templateData.componentContentList[i]" ></app-herobanner-feature-rightside-image>
                    </div>
                    <div *ngIf="item.componentName === 'hero_banner_with_review'">
                        <app-herobanner-with-review [templateData]="templateData.componentContentList[i]" ></app-herobanner-with-review>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ajay" *ngIf="item.loaderDisplay">
                        loader
                    </div> -->
                </div>
                <!-- <div id="Service Section"> -->
                <div class="single_website" *ngIf="item.componentCategoryName=='Service Section'" id="Service Section">
                    <div *ngIf="item.componentName === 'service_section_simple_horizontal'">
                        <app-servicesectioneditor [templateData]="templateData.componentContentList[i]"></app-servicesectioneditor>
                    </div>
                    <div *ngIf="item.componentName === 'service_section_simple_vertical'">
                        <app-verticle-service-section [templateData]="templateData.componentContentList[i]"></app-verticle-service-section>
                    </div>
                    <div *ngIf="item.componentName === 'hover_horizontal_service_section'">
                        <app-hover-horizontal-service-section [templateData]="templateData.componentContentList[i]"></app-hover-horizontal-service-section>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single_website" *ngIf="item.componentCategoryName === 'Location'" id="Location">
                    <div *ngIf="item.componentName === 'map_with_left_side_address'">
                        <app-map-left-address [templateData]="templateData.componentContentList[i]"></app-map-left-address>
                    </div>
                    <div *ngIf="item.componentName === 'map_with_right_side_address'">
                        <app-map-with-right-address [templateData]="templateData.componentContentList[i]"></app-map-with-right-address>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="single_website" *ngIf="item.componentCategoryName=='Testimonial'" id="Testimonial">
                    <div *ngIf="item.componentName === 'testimonal_slickcarsouel'">
                        <app-testimonal-slickcarsouel [templateData]="templateData.componentContentList[i]"></app-testimonal-slickcarsouel>
                    </div>
                    <div *ngIf="item.componentName === 'double_item_testimonial'">
                        <app-single-page-double-testimomial [templateData]="templateData.componentContentList[i]"></app-single-page-double-testimomial>
                    </div>
                    <div *ngIf="item.componentName === 'single_horizontal_testimonial'">
                        <app-single-horizontal-testimonial [templateData]="templateData.componentContentList[i]"></app-single-horizontal-testimonial>
                    </div>
                    <div *ngIf="item.componentName === 'single_vertical_testimonial'">
                        <app-single-verticle-testimonial [templateData]="templateData.componentContentList[i]"></app-single-verticle-testimonial>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="single_website" *ngIf="item.componentCategoryName === 'Process'" id="Process">
                    <div *ngIf="item.componentName === 'process_section_spark_image_side'">
                        <app-process-spark-image-side [templateData]="templateData.componentContentList[i]"></app-process-spark-image-side>
                    </div>
                    <div *ngIf="item.componentName === 'process_section_number_text'">
                        <app-process-number-text [templateData]="templateData.componentContentList[i]"></app-process-number-text>
                    </div>
                    <div *ngIf="item.componentName === 'process_count_image_side'">
                        <app-process-count-image-side [templateData]="templateData.componentContentList[i]"></app-process-count-image-side>
                    </div>
                    <div *ngIf="item.componentName === 'process_count_image'">
                        <app-process-count-image [templateData]="templateData.componentContentList[i]"></app-process-count-image>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single_website" *ngIf="item.componentCategoryName === 'FAQ'" id="FAQ">
                    <div *ngIf="item.componentName === 'horizontal_faq'">
                        <app-horizontal-faq [templateData]="templateData.componentContentList[i]"></app-horizontal-faq>
                    </div>
                    <div *ngIf="item.componentName === 'opened_faq'">
                        <app-opened-faq [templateData]="templateData.componentContentList[i]"></app-opened-faq>
                    </div>
                    <div *ngIf="item.componentName === 'faq_slider'">
                        <app-slider-faq-components [templateData]="templateData.componentContentList[i]"></app-slider-faq-components>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single_website" *ngIf="item.componentCategoryName === 'About Us'" id="About Us">
                    <div *ngIf="item.componentName === 'simple_about_us_section'">
                        <app-aboutus [templateData]="templateData.componentContentList[i]"></app-aboutus>
                    </div>
                    <div *ngIf="item.componentName === 'simple_about_us_section_background_image'">
                        <app-aboutus-with-background-image [templateData]="templateData.componentContentList[i]"></app-aboutus-with-background-image>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="displayBlogs">
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Blog'" id="Blog">
                        <div *ngIf="item.componentName === 'Blog_Cards_Horizontal_Align'">
                            <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align>
                        </div>
                        <div *ngIf="item.componentName === 'Blog_Cards_Vertical_Align'">
                            <app-blog-cards-vertical-align [blogData]="blogData"></app-blog-cards-vertical-align>
                        </div>
                        <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <!-- <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                        </div> -->
                                        <div class="regenerate-text">
                                            Regenerate 
                                        </div>
                                    </div>
                                    <!-- <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="regenerate-text">
                                            Change Content 
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="single_website" *ngIf="item.componentCategoryName === 'Contact Us'" id="Contact Us">
                    <div *ngIf="item.componentName === 'simple_contactus_right_heading'">
                        <app-contactus-text-inputs [templateData]="templateData.componentContentList[i]"></app-contactus-text-inputs>
                    </div>
                    <div *ngIf="item.componentName === 'simple_contact_form'">
                        <app-contact-us1 [templateData]="templateData.componentContentList[i]"></app-contact-us1>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                                <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Change Content 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single_website" *ngIf="item.componentCategoryName === 'Footer'">
                    <div *ngIf="item.componentName === 'footer_simple_social'">
                        <app-footer1 [templateData]="templateData.componentContentList[i]"></app-footer1>
                    </div>
                    <div *ngIf="item.componentName === 'footer_simple_social_pages'">
                        <app-simple-footer-social-links [templateData]="templateData.componentContentList[i]"></app-simple-footer-social-links>
                    </div>
                    <div *ngIf="item.componentName === 'footer_simple_vertical'">
                        <app-simple-verticle-footer [templateData]="templateData.componentContentList[i]"></app-simple-verticle-footer>
                    </div>
                    <div *ngIf="item.componentName === 'footer_simple_horizontal'">
                        <app-simple-footer-copyrights [templateData]="templateData.componentContentList[i]"></app-simple-footer-copyrights>
                    </div>
                    <div class="show_btns" *ngIf="!queryParams && !loaderDisplay">
                        <div class="containerelment">
                            <div class="feild-edit">
                                <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                    <!-- <div class="edit image">
                                        <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                    </div> -->
                                    <div class="regenerate-text">
                                        Regenerate 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
            <!-- <div class="extra_section" *ngIf="!queryParams">
            </div> -->
            <div class="section-container" *ngIf="loaderDisplay">
                <div class="section-copnent-section">

                </div>
                <div class="loader-section">
                    <!-- <div  [ngClass]="scrollCount==componentNames.length-1?'loader-popup-increase':'loder-popup'"> -->
                    <div class="loder-popup" *ngIf="scrollCount!=componentNames.length-1">
                        <div class="loader_loading_text">
                            <div class="spin_loader"></div> 
                            <div class="loader_text">{{loaderText}}</div>
                        </div>
                        <!-- <div *ngIf="scrollCount==componentNames.length-1">
                            <div class="website-ready-text">
                                Your website is ready
                            </div>
                            <div class="website-seeit-button">
                                <button (click)="closeloader()">See It</button>
                            </div>
                        </div> -->
                    
                    </div>  
                </div>
            </div>
        </div>
    </section>
    <section class="loader_section" *ngIf="totalPageLoader">
        <div class="api_loader">
            <div class="dots-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    </section>
    <div class="signup-tab" *ngIf="!loaderDisplay && !totalPageLoader && !queryParams">
        <div class="signupsection">
          <div class="signUp-text" *ngIf="srcWidth>475">
              Edit and publish your website, get a custom domain, analytics and more
          </div>
          <div class="signUp-text" *ngIf="srcWidth<475">
            Want to edit and publish your business 
          </div>
          <div class="signUp-btn">
              <button (click)="signUp()">Sign up free</button>
          </div>
        </div>    
    </div>
</section>




<section *ngIf="restyleComponent" [ngClass]="srcWidth > 475 ? 'restyle_component' : 'mobile_restyle_component'">
    <div class="center_component">
        <div class="heading_style">
            <div class="heading_text">Restyle Website</div>
            <div class="cross_icon" (click)="cancelRestyleComponent()"><mat-icon>close</mat-icon></div>
        </div>
        <div class="hr-line"></div>
        <div class="button_styles">
            <div class="heading_text">Button Style</div>
            <div class="different_styles">
                <div class="style">
                    <div class="label_heading">Style</div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <mat-select [(ngModel)]="bStyle">
                            <!-- <mat-option [value]="bStyle">--Select--</mat-option> -->
                            <mat-option *ngFor="let item of buttonStyles" [value]="item?.value">
                                {{item?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="shape">
                    <div class="label_heading">Shape</div>
                    <mat-form-field appearance="outline" class="sfull-width">
                        <mat-select [(ngModel)]="bShape" (selectionChange)="shapeChange($event)">
                            <mat-option *ngFor="let item of buttonShape" [value]="item.value">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="button_styles">
            <div class="heading_text">Colors</div>
            <div class="different_styles">
                <div class="style">
                    <div class="label_heading">Brand</div>
                    <div class="color_picking">
                        <input type="color" [value]="componentsStyling.btnColor" [(ngModel)]="componentsStyling.btnColor"/>
                        <span class="color_value">{{componentsStyling.btnColor}}</span>
                    </div>
                </div>
                <div class="shape">
                    <div class="label_heading">Text Color</div>
                    <div class="color_picking">
                        <input type="color" [value]="componentsStyling.btnTextColor" [(ngModel)]="componentsStyling.btnTextColor"/>
                        <span class="color_value">{{componentsStyling.btnTextColor}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="button_styles">
            <div class="heading_text">Fonts</div>
            <div class="text_looks_like">
                <div class="text_showing" [ngStyle]="{'font-family':globalStyles.headingFontFamily['font-family']}">Heading</div>
                <div class="hint" [ngStyle]="{'font-family': globalStyles.bodyFontFamily['font-family']}">Body text will look like this.</div>
            </div>
            <div class="font_family">
                <div class="label_heading">Select a font pair</div>
                <mat-form-field appearance="outline" class="font-sfull-width">
                    <mat-select [(ngModel)]="selectingFontFamily" (ngModelChange)="changeFontFamily()">
                        <mat-option *ngFor="let item of fontFamilies" [value]="item?.value">
                            {{item?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="btns">
            <button class="cancel_btn" (click)="cancelRestyleComponent()">Cancel</button>
            <button class="restyle_website" (click)="saveRestyle()">Restyle website</button>
        </div>
    </div>
</section>

<!-- Global Regenerate -->
<section class="restyle_component" *ngIf="globalRegenerateComponent" [ngClass]="srcWidth > 475 ? 'restyle_component' : 'mobile_restyle_component'">
    <div class="regenerate_center_component">
        <div class="heading_style">
            <div class="heading_text">Regenerate Website</div>
            <div class="cross_icon" (click)="cancelRegenerate()"><mat-icon>close</mat-icon></div>
        </div>
        <div class="hr-line"></div>
        <div class="location_change">
            <div class="regenerate_location">
                <div class="label_heading">Location</div>
                <mat-form-field class="sfull-width input_card" appearance="outline">
                    <input type="text" placeholder="Search for location" matInput [formControl]="searchBox" [matAutocomplete]="auto2" 
                    [(ngModel)]="searchLocation" (keyup)="search()"/>
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option *ngFor="
                            let option of searchResults | async;
                            index as i
                        " [value]="option.displayName"
                        (click)="selectedLocations(option)"
                        >
                        {{ option.displayName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>  
        </div>
        <div class="location_change">
            <div class="regenerate_location">
                <div class="label_heading">Industry Type</div>
                <mat-form-field class="sfull-width domain-input input_card" appearance="outline">
                    <input type="text" placeholder="Enter business type" matInput [formControl]="myControl" [matAutocomplete]="auto1" 
                    [(ngModel)]="bType"/>
                    <mat-autocomplete #auto1="matAutocomplete">
                      <mat-option *ngFor="
                          let option of filteredOptions | async;
                          index as i
                        " [value]="option.name"
                        (click)="selectBusinessType(option)"
                        (keyup.enter)="selectBusinessType(option)"
                        >
                        {{ option.name}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="location_change">
            <div class="regenerate_location">
                <div class="label_heading">Name</div>
                <mat-form-field class="sfull-width input_card" appearance="outline">
                    <input matInput [(ngModel)]="bName"/>
                </mat-form-field>
            </div>
        </div>
        <div class="location_change">
            <div class="warning_text">
                <img src="./../../../assets/images/common/info.svg" alt="info">
                <div class="info_text">
                    Are you sure you want to regenerate the website? All content, images and styles will change.
                </div>
            </div>
        </div>
        <div class="regenerate_btns">
            <button class="cancel_btn" (click)="cancelRegenerate()">Cancel</button>
            <button class="restyle_website" (click)="saveGlobalRegenerate()"
            [disabled]="searchLocation === '' || bName === '' || bType === ''">Regenerate</button>
        </div>
    </div>
</section>

<section class="animation" *ngIf="scrollCount==componentNames.length-1 && loaderDisplay">
     <!-- Animation starts -->
     <div class="fireworks">
        <div class="firework">
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
        </div>
        <div class="firework" style="margin-top: -70px">
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
        </div>
        <div class="firework">
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
        </div>
        <div class="firework" style="margin-top: 70px">
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
        </div>
        <div class="firework">
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
        </div>
        <div class="firework" style="margin-top: -70px">
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
          <div class="explosion">
            <div class="spark silver"></div>
          </div>
        </div>
        <div class="firework">
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
          <div class="explosion">
            <div class="spark gold"></div>
          </div>
        </div>
    </div>
    <!-- Animation Ends -->

    <div class="loader-popup-increase">
        <div class="website-ready-text">
            <img src="./../../../assets/images/common/hurray_emoji.svg" alt="Hurray"> &nbsp;Hurray, AI Generated Your Website
        </div>
        <!-- <div class="website-seeit-button">
            <button (click)="closeloader()">See It</button>
        </div> -->
    </div>
</section>