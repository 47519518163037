import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmmiterService } from './../../../services/event-emmiter.service';

@Component({
  selector: 'app-process-section',
  templateUrl: './process-section.component.html',
  styleUrls: ['./process-section.component.scss','./mq-process-section.component.scss']
})
export class ProcessSectionComponent {

  @Input() pageData : any

  constructor(private _activatedRoute : ActivatedRoute,
    private emitterService : EventEmmiterService){}

    loader : boolean = false;
    sectionLoading : boolean = false;

  ngOnInit(): void {
    this.emitterService.loadingSections.subscribe(
      (res) =>{
        if(res.lastComponent === 'service'){
          let a = document.getElementById('process_section');
          a?.scrollIntoView({behavior:'smooth'})
          this.sectionLoading = true;
          setTimeout(() => {
            this.sectionLoading = false;
            this.emitterService.activateComponent.emit({nextComponent:'Home_Testomnial',loading:false})
          }, 5000);
        }
        // //console.log(res)
      }
    )
    this.emitterService.componentLoader.subscribe(
      (res)=>{
        // this.loader = res.loading;
        if(res.name === 'Home-process-component'){
          this.loader = res.loading;
        }
        else{
          this.loader = false;
        }
      }
    )
  }
  
processSection:any={
  processSectionText1:{
    text:'Our',
    textStyles :{
      'font-size':'24px',
      'font-weight':'600',
      'font-family':"'Lexend Deca', sans-serif",
      'color':'#444444',
      'font-style':'italic'
    }
  },
  processSectionText2:{
    text:'process',
    textStyles :{
      'font-size':'48px',
      'font-weight':'600',
      'font-family':"'Lexend Deca', sans-serif",
      'color':'#444444',
      'font-style':'normal',
      'line-height':'80px'
    }
  },
  processArray:[
    {
      serviceTitle: {
        name:'01',
        id:'process_card1_title',
        textStyles :{
          'font-size':'30px',
          'font-weight':'600',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'50px'
        }   
      },
      serviceText:{
        name:'Planning',
        id:'process_card1_text',
        textStyles :{
          'font-size':'28px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'45px'
        }
      },
      serviceDesc:{
        name:'Aenean commodo ligula eget dolor. Aeneane penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.',
        id:'process_card1_desc',
        textStyles :{
          'font-size':'14px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'22px'
        }
      }
    },
    {
      serviceTitle: {
        name:'02',
        id:'process_card2_title',
        textStyles :{
          'font-size':'30px',
          'font-weight':'600',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'50px'
        }   
      },
      serviceText:{
        name:'Planning',
        id:'process_card2_text',
        textStyles :{
          'font-size':'28px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'45px'
        }
      },
      serviceDesc:{
        name:'Aenean commodo ligula eget dolor. Aeneane penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.',
        id:'process_card2_desc',
        textStyles :{
          'font-size':'14px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'22px'
        }
      }
    },
    {
      serviceTitle: {
        name:'03',
        id:'process_card3_title',
        textStyles :{
          'font-size':'30px',
          'font-weight':'600',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'50px'
        }   
      },
      serviceText:{
        name:'Building',
        id:'process_card3_text',
        textStyles :{
          'font-size':'28px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'45px'
        }
      },
      serviceDesc:{
        name:'Aenean commodo ligula eget dolor. Aeneane penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.',
        id:'process_card3_desc',
        textStyles :{
          'font-size':'14px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'22px'
        }
      }
    },
    {
      serviceTitle:{
        name:'04',
        id:'process_card4_title',
        textStyles :{
          'font-size':'30px',
          'font-weight':'600',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'50px'
        }   
      },
      serviceText:{
        name:'Testing',
        id:'process_card4_text',
        textStyles :{
          'font-size':'28px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'45px'
        }
      },
      serviceDesc:{
        name:'Aenean commodo ligula eget dolor. Aeneane penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.',
        id:'process_card4_desc',
        textStyles :{
          'font-size':'14px',
          'font-weight':'400',
          'font-family':"'Lexend Deca', sans-serif",
          'color':'#444444',
          'font-style':'normal',
          'line-height':'22px'
        }
      }
    }
  ]
}

}
