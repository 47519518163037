import { Component } from '@angular/core';

@Component({
  selector: 'app-cta-action',
  templateUrl: './cta-action.component.html',
  styleUrls: ['./cta-action.component.scss','./mq-cta-action.component.scss']
})
export class CtaActionComponent {

}
