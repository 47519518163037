import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';
import { StepsToFollowComponent } from '../steps-to-follow/steps-to-follow.component';

@Component({
  selector: 'app-connect-existing-domain',
  templateUrl: './connect-existing-domain.component.html',
  styleUrls: ['./connect-existing-domain.component.scss']
})
export class ConnectExistingDomainComponent implements OnInit{

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<ConnectExistingDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ){}

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  linkDomain(){
    this.dialogRef.close();
    let popup = this.templateService.openDialog(
      StepsToFollowComponent,
      'auto',
      '540px',
      '80vw'
    )
  }

}
