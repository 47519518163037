<div class="main-section">
    <div class="headingContainer">
        <div class="subheading" [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
        <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="description" [ngStyle]="subtext.style?subtext.style:{}">{{subtext.value}}</div>
    </div>
    <div class="rowFlex">
        <div class="content">
            <div class="stat-section">
                <div class="stats">
                    <div class="statsHeading" [ngStyle]="stats1Heading.style?stats1Heading.style:{}">{{stats1Heading.value}}</div>
                    <div class="statsSubHeading" [ngStyle]="stats1Subheading.style?stats1Subheading.style:{}">{{stats1Subheading.value}}</div>
                    <div class="statsText" [ngStyle]="stats1Text.style?stats1Text.style:{}">{{stats1Text.value}}</div>
                </div>
                <div class="stats">
                    <div class="statsHeading" [ngStyle]="stats2Heading.style?stats2Heading.style:{}">{{stats2Heading.value}}</div>
                    <div class="statsSubHeading" [ngStyle]="stats2Subheading.style?stats2Subheading.style:{}">{{stats2Subheading.value}}</div>
                    <div class="statsText" [ngStyle]="stats2Text.style?stats2Text.style:{}">{{stats2Text.value}}</div>
                </div>
                <div class="stats">
                    <div class="statsHeading" [ngStyle]="stats3Heading.style?stats3Heading.style:{}">{{stats3Heading.value}}</div>
                    <div class="statsSubHeading" [ngStyle]="stats3Subheading.style?stats3Subheading.style:{}">{{stats3Subheading.value}}</div>
                    <div class="statsText" [ngStyle]="stats3Text.style?stats3Text.style:{}">{{stats3Text.value}}</div>
                </div>
                <div class="stats">
                    <div class="statsHeading" [ngStyle]="stats4Heading.style?stats4Heading.style:{}">{{stats4Heading.value}}</div>
                    <div class="statsSubHeading" [ngStyle]="stats4Subheading.style?stats4Subheading.style:{}">{{stats4Subheading.value}}</div>
                    <div class="statsText" [ngStyle]="stats4Text.style?stats4Text.style:{}">{{stats4Text.value}}</div>
                </div>
            </div>
        </div>
        <div class = "image-section imageContainer">
            <img class="imageProperties" [ngStyle]="image.style?image.style:{}" src="{{image.imgUrl}}" alt="">
        </div>
    </div> 
</div>
