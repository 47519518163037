import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { OurContactUsComponent } from '../components/our-contact-us/our-contact-us.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit{

  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router,
    private snackBar : MatSnackBar
  ){}

  allPlans : any = [];

  ngOnInit(): void {
    // this.getAllPlans();
    this.getAllCountries()
  }
  isAnually : boolean = true;
  twoButtons = [
    {
      name:'monthly',
      status:false,
      value:'MONTHLY'
    },
    {
      name:'yearly',
      status: true,
      value:'ANNUALLY'
    }
  ];

  testimonials = [
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_1.svg",
      review:"I couldn't believe how easy it was to build a website with Simpo.ai. The AI-powered builder made the process a breeze, and the",
      reviewerImage:"./../../../assets/images/pricing/reviewer_1.svg",
      reviwerName:"Sarah John"
    },
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_2.svg",
      review:"I couldn't believe how easy it was to build a website with Simpo.ai. The AI-powered builder made the process a breeze, and the",
      reviewerImage:"./../../../assets/images/pricing/reviewer_2.svg",
      reviwerName:"Sarah John"
    },
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_3.svg",
      review:"I couldn't believe how easy it was to build a website with Simpo.ai. The AI-powered builder made the process a breeze, and the",
      reviewerImage:"./../../../assets/images/pricing/reviewer_3.svg",
      reviwerName:"Sarah John"
    }
  ];
  faqQuestions = [
    {
      question:"Is there a free trial available?",
      answer:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    },
    {
      question:"Is there a free trial available?",
      answer:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    },
    {
      question:"Is there a free trial available?",
      answer:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    },
    {
      question:"Is there a free trial available?",
      answer:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    },
    {
      question:"Is there a free trial available?",
      answer:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    }
  ];
  expandedIndex = 0;
  

  allCountries : any = [];
  selectedCountry : any;
  getAllCountries(){
    this._paymentService.getAllCountries().subscribe(
      (res:any) => {
        res.data.forEach(country => {
          if(country.currency === 'INR'){
            this.selectedCountry = country
          }
        });
        this.allCountries = res.data;
        this.getAllPlans(this.selectedCountry.id)
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  getAllPlans(countryId){
    this._paymentService.getAllPlans(countryId).subscribe(
      (res:any) => {
        console.log(res.data);
        this.allPlans = res.data
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
    // this._paymentService.getAllPlans().subscribe(
    //   (res:any) => {
    //     console.log(res.data);
        
    //     this.allPlans = res.data;
    //   }
    // )
  }

  planChange(a:any){
    this.twoButtons.forEach(tb => {
      if(a === tb.value){
        tb.status = true;
      }
      else{
        tb.status = false;
      }
    });
    if(a === 'ANNUALLY'){
      this.isAnually = true;
    }
    else{
      this.isAnually = false;
    }
  }

  selectedPlan(id,price){
    console.log(id,this.isAnually);

    // let data ={
    //   bId:'1eddd077-efb5-6b04-9532-83e6506e4b48',
    //   subscriptionId:id,
    //   isMonthly:!this.isAnually
    // }
    // this._paymentService.subscribePlan(data).subscribe(
    //   (res:any) => {
    //     window.location.href = `${res.data.url}`
    //     this._dbService.setPaymentId(res.data.paymentId);
    //   },
    //   (err) => {
    //     console.log(err.error)
    //   }
    // )
    if(this._dbService.getBusinessId() === null){
      this._router.navigate(['/business-type']);
      this._dbService.setOrderId(`${id}`);
      this._dbService.setOrderPrice(`${price}`);
      this._dbService.setAnuallyOrNot(!this.isAnually);
    }
    else{
    }
  }

  generateWebsite(){
    this._router.navigate(['/business-type'])    
  }
  changeCountry(ev){
    this.getAllPlans(ev.value.id);    
  }


  contactUs(){
    this._paymentService.openDialog(
      OurContactUsComponent,
      'auto',
      '25%',
      {}
    )
  }
}
