import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-stats-with-side-image',
  templateUrl: './stats-with-side-image.component.html',
  styleUrls: ['./preview-stats-with-side-image.component.scss']
})
export class PreviewStatsWithSideImageComponent {
  
  templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Launch faster",
        type : 'text', field : 'text',
        style : {'color':'#A084DC','font-size':'14px','font-weight':700}
      },
      {
        value : "Build something great",
        type : 'text', field : 'text',
        style : {'font-weight':900}
      },
      {
        value : "We've done all the heavy lifting so you don't have to - get all the data you need to launch and grow your business faster.",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value : "4,000+",
        type : 'text', field : 'text',
        style : {'color':'#A084DC','font-weight':700}
      },
      {
        value:"600%",
        type:"text",
        field:"text",
        style:{'color':'#A084DC','font-weight':700}
      },
      {
        value:"10k",
        type:"text",
        field:"text",
        style:{'color':'#A084DC','font-weight':700}
      },
      {
        value:"200+",
        type:"text",
        field:"text",
        style:{'color':'#A084DC','font-weight':700}
      },
      {
        value : "Global customers",
        type : 'subtext', field : 'subtext',
        style : {'font-weight':700}
      },
      {
        value:"Return on investment",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value:"Global downloads",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value:"5-star reviews",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value : "We've helped over 4,000 amazing global companies.",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value:"Our customers have reported an average of -600% ROI.",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Our app has been downloaded over 10k times",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Were proud of our 5-star rating with over 200 reviews",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/stats/stats-image.jpg",
        display : true, type : 'image', field : 'image',
        style : {}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats1Component",
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  subheading = this.data[0];
  heading = this.data[1];
  subtext = this.data[2];
  stats1Heading = this.data[3];
  stats2Heading = this.data[4]
  stats3Heading = this.data[5]
  stats4Heading = this.data[6]
  stats1Subheading = this.data[7];
  stats2Subheading = this.data[8]
  stats3Subheading = this.data[9]
  stats4Subheading = this.data[10]
  stats1Text = this.data[11];
  stats2Text = this.data[12]
  stats3Text = this.data[13]
  stats4Text = this.data[14]
  image = this.data[15];

}
