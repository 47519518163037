<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value}}</div>
        <div class="description" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        <div class="buttons">
            <button class="freeProposalBtn" [ngStyle]="data[2].style?data[2].style:{}" (click)="redirectUrl()">{{data[2].value | titlecase}}</button>
            <!-- <button class="checkWorkBtn" [ngStyle]="checkWorkBtn.style?checkWorkBtn.style:{}">{{checkWorkBtn.value}}</button> -->
        </div>
    </div>
    <!-- <div class="rightContainer" [ngStyle]="{'background-color':templateData.colors.bgColor2}"></div> -->
</div>