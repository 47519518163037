
    <section class="our_section" 
    [ngStyle]="{'background-image': bgImage.imageUrl === '' ? 'url(./../../../../../../../../assets/images/components/our_success_bg_img.svg)' : 'url( '+ bgImage.imageUrl + ')'}">
        <div class="first_title">
            {{firstTitle.value}}
        </div>
        <div class="second_title" 
        [ngStyle]="secondTitle.style ? secondTitle.style : {}">
            {{secondTitle.value}}
        </div>
        <div class="yellow-line">
            <div class="line-container"></div>
        </div>
        <div class="sub_text" id="our_success_small_text"
        [ngStyle]="subText.style ? subText.style : {}">
            {{subText.value}}
        </div>
        <div class="progress_bar_section">
                <div class="single_bar" [ngStyle]="{'background-image': 'url('+card1[0].imageUrl+')'}">
                    <div class="count" >{{card1[1].value}}</div>
                    <div class="done_list">{{card1[2].value}}</div>
                </div>
                <div class="single_bar" [ngStyle]="{'background-image': 'url('+card2[0].imageUrl+')'}">
                    <div class="count">{{card2[1].value}}</div>
                    <div class="done_list">{{card2[2].value}}</div>
                </div>
                <div class="single_bar" [ngStyle]="{'background-image': 'url('+card3[0].imageUrl+')'}">
                    <div class="count">{{card3[1].value}}</div>
                    <div class="done_list">{{card3[2].value}}</div>
                </div>
        </div>
    </section>

