import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OutputData } from '@editorjs/editorjs';
import { BlogServiceService } from '../../blog-service.service';
import EditorJS from '@editorjs/editorjs';
const Delimiter = require('@editorjs/delimiter');
const InlineCode = require('@editorjs/inline-code');
const ColorPlugin = require('editorjs-text-color-plugin');
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';
import List from '@editorjs/list';
import Table from '@editorjs/table';
const Header = require('@editorjs/header');
import ImageTool from '@editorjs/image';
import { MetaTagsPopupComponent } from '../../meta-tags-popup/meta-tags-popup.component';
import { map, Observable, startWith } from 'rxjs';
import { DbService } from 'src/app/services/db-service/db.service';
import { FormControl } from '@angular/forms';
import { TemplateService } from 'src/app/services/template.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-master-blogs',
  templateUrl: './create-master-blogs.component.html',
  styleUrls: ['./create-master-blogs.component.scss']
})
export class CreateMasterBlogsComponent {
  @ViewChild('editor', { read: ElementRef, static: true }) editorElement:
  | ElementRef
  | undefined;
previousData: OutputData | undefined;
editor: EditorJS | undefined;
allTypes: any = [];
filteredOptions: Observable<string[]> | any;
bType = '';
myControl = new FormControl();
constructor(
  private blogService: BlogServiceService,
  private router: Router,
  private route: ActivatedRoute,
  private _dbService : DbService,
  private webService: TemplateService,
  private snackBar: MatSnackBar,
) {
  this.subscribeQueryPrams();
}
ngOnInit(): void {
  this.getAllTypes();
 }

edit: boolean = false;
subIndustryName:string="";
subscribeQueryPrams() {
  this.route.queryParams.subscribe((res: any) => {
    this.edit = res.edit;
    this.subCategoryId=this.blogService.getBlogData().subcategoryId;
    this.subIndustryName=this.blogService.getBlogData()?.subIndustryName;
    this.bType=this.subIndustryName;
    console.log("subInstryId",this.subCategoryId);
    
    if (this.edit == undefined) {
      this.edit = false;
    }
  });
}

ngAfterViewInit(): void {
  this.showApiData();
}

private intialzedEditor(): void {
  this.editor = new EditorJS({
    // minHeight:200,
    holder: this.editorElement?.nativeElement,
    tools: {
      delimiter: Delimiter,
      header: {
        class: Header,
        shortcut: 'CMD+SHIFT+H',
        inlineToolbar: ['link', 'bold', 'italic'],
      },
      Color: {
        class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
        config: {
          colorCollections: [
            '#EC7878',
            '#9C27B0',
            '#673AB7',
            '#3F51B5',
            '#0070FF',
            '#03A9F4',
            '#00BCD4',
            '#4CAF50',
            '#8BC34A',
            '#CDDC39',
            '#FFF',
          ],
          defaultColor: '#FF1300',
          type: 'text',
        },
      },
      Marker: {
        class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
        config: {
          defaultColor: '#FFBF00',
          type: 'marker',
        },
      },
      // Marker :{
      //   class : Marker,
      //   shortcut : 'CMD+SHIFT+M'
      // },
      list: {
        class: List,
        inlineToolbar: ['link', 'bold', 'italic'],
      },
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
        config: {
          data: {
            withBorder: false,
            stretched: false,
            withBackground: false,
          },
          uploader: {
            /**
             * Upload file to the server and return an uploaded image data
             * @param {File} file - file selected from the device or pasted by drag-n-drop
             * @return {Promise.<{success, file: {url}}>}
             */
            uploadByFile(file: any) {
              // your own uploading logic here
              const AWSService = AWS;
              const imageEnvCognito =
                environment.componentImageUploading.CredentialsProvider.CognitoIdentity.Default;
              const imageEnvUtility =
                environment.componentImageUploading.S3TransferUtility.Default;
              const region = imageEnvUtility.Region;
              const bucketName = imageEnvUtility.Bucket;
              const IdentityPoolId = imageEnvCognito.PoolId;
              // ? Configures the AWS service and initial authorization
              AWSService.config.update({
                region: region,
                credentials: new AWSService.CognitoIdentityCredentials({
                  IdentityPoolId: IdentityPoolId,
                }),
              });
              const s3 = new AWSService.S3({
                apiVersion: '2012-07-10',
                params: {
                  Bucket: bucketName,
                },
              });

              // const element = e.srcElement.files[0];
              // var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
              let imgPromise = new Promise((res, rej) => {
                if (file) {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    s3.upload(
                      {
                        Key:
                          'images/' +
                          Math.floor(100000 + Math.random() * 900000) +
                          'c' +
                          new Date().getTime() +
                          file.name,
                        Bucket: bucketName,
                        Body: file,
                        ACL: 'public-read',
                      },
                      // options,
                      function (err: any, data: any) {
                        if (err) {
                          rej(err);
                        } else {
                          res(data);
                        }
                      }
                    );
                  };
                }
              });

              return imgPromise
                .then((data: any) => {
                  // this.imageObject = data;
                  //console.log('awsResult', data);
                  return {
                    success: 1,
                    file: {
                      url: data.Location,
                      height: '500px',
                      // any other image data you want to store, such as width, height, color, extension, etc
                    },
                  };
                })
                .catch((err) => {
                  //console.log(err);
                  return err;
                });
            },
          },
        },
      },
      table: {
        class: Table,
        inlineToolbar: true,
        config: {
          rows: 2,
          cols: 3,
        },
      },
    },
    onChange: async (api, event) => {
      // method will be fired on every editor change
    },
    data: this.previousData,
    defaultBlock: 'header',
    // autofocus: true,
    placeholder: 'Beign writing your blog',
  });
}
data: any;
showEditorData() {
  this.editor
    ?.save()
    .then((data) => {
      this.data = data;

      console.log("data",this.data);
      (this.data.createdBy = window.localStorage.getItem('fname')),

        this.data.businessId = null;
        this.data.blogType="MASTER";
        this.data.subIndustryId=this.subCategoryId;
        this.data.subIndustryName=this.subCategoryName;

      (this.data.createdTimeStamp = new Date());
      if (this.data.blocks.length > 0) {
        let returnedTarget:any = {};
        //object null and empty check
        if (this.tagsData && Object.keys(this.tagsData).length !== 0) {
          returnedTarget = Object.assign(data, this.tagsData);
        }
        else {
          returnedTarget = data;
        }
        this.getBlocksTextLen(data.blocks);
        let readTime=Math.ceil(this.sumWords/200);
        console.log("readTime",readTime);
        returnedTarget.readTime=readTime;
        returnedTarget.wordCount=this.sumWords;
        returnedTarget.updatedTimeStamp=new Date();
        this.blogService.createMasterBlog(returnedTarget).subscribe(
          (res: any) => {
            window.location.reload();
            console.log("response",res);
            
            this.blogService.openSnack(
              'Blog created sucessfully',
              'Close'
            );
            this.router.navigate(['/admin/website/blogs']);
            this.sumWords=0;
            readTime=0;
          },
          (err) => {
            //console.log(err);
            this.sumWords=0;
            readTime=0;
          }
        );
      } else {
        this.blogService.openSnack(
          'Please write something to create Post',
          'Close'
        );
        
      }      
    })
    .catch((err) => {
      //console.log(err);
    });

}

couldBeCounted(block){
  return 'text' in block.data // it depends on tools you use
}
sumWords:number=0;
 getBlocksTextLen(blocks){
  return blocks
    .filter(this.couldBeCounted)
    .reduce((sum, block) => {
      let data=block.data.text
          data=data.replaceAll("<br>","");
          data=data.replaceAll("<b>","");
          data=data.replaceAll("</b>","");
          this.sumWords=this.sumWords+data.split(" ").length;
            console.log("sumWords",this.sumWords);
            
      return sum
    }, 0)
}
@ViewChild('barTransy') barTransy: ElementRef | undefined;

 
 


jsonData: any = '';
showApiData() {
  if (this.edit) {
    let dataSending = {
      time: 0,
      version: '',
      blocks: [],
    };
    dataSending.time = this.blogService.getBlogData().time;
    dataSending.version = this.blogService.getBlogData().version;
    dataSending.blocks = this.blogService.getBlogData().blocks;
    this.previousData = dataSending;
    this.subCategoryId=this.blogService.getBlogData().subcategoryId;

  } else {
    this.previousData = undefined;
  }
  this.intialzedEditor();
}

clickCards(cards: any) {
  //console.log(cards);
  this.router.navigate(['/view-blog'], { queryParams: { blogId: cards.id } });
}

back() {
  this.blogService.setBlogData({});
  this.router.navigate(['/admin/website/blogs/master/listing']);
}

editBlog() {
  let returnedTarget:any = {};
  this.editor
    ?.save()
    .then((data: any) => {
      data.id = this.blogService.getBlogData().id;
      data.createdBy = this.blogService.getBlogData().createdBy;
      data.createdTimeStamp = this.blogService.getBlogData().createdTimeStamp;
      data.createdBy = window.localStorage.getItem('fname'),
        data.blogType="MASTER";
        data.businessId = null;
        data.subIndustryId=this.subCategoryId;
        data.subIndustryName=this.subCategoryName;
      //object null and empty check
      if (this.tagsData && Object.keys(this.tagsData).length !== 0) {
        returnedTarget = Object.assign(data, this.tagsData);
      }
      else {
        returnedTarget = data;
      }
      this.getBlocksTextLen(data.blocks);
      let readTime=Math.ceil(this.sumWords/200);
      console.log("readTime",readTime);
      returnedTarget.readTime=readTime;
      returnedTarget.wordCount=this.sumWords;
      this.blogService.UpdateMasterBlog(returnedTarget).subscribe({
        next: (res: any) => {
          window.location.reload();
          this.blogService.openSnack(
            'Blog updated sucessfully',
            'Close'
          );
          this.router.navigate(['/user/dashboard/blogs/list-blog']);
          this.sumWords=0;
          readTime=0;
        },
        error: (err: any) => {
          //console.log('error', err);
          this.sumWords=0;
          readTime=0;
        },
      });
    })
    .catch((err) => {
      //console.log(err);
    });
}

countwords() {
  //console.log('words');
}

tagsData: any = {};

filterBtn(ev: any) {
  ev.stopPropagation();
  let blogModel = this.blogService.filterDialog(
    MetaTagsPopupComponent,
    'filter-dialog-container',
    '73vh',
    '300px',
    'relative',
    {
      data: {
        blogData: {
          keyWords: this.blogService.getBlogData()?.keyWords,
          description: this.blogService.getBlogData()?.description,
          author: this.blogService.getBlogData()?.author,
          postTitle: this.blogService.getBlogData()?.postTitle,
          blogMeta: {
            metaTitle: this.blogService.getBlogData()?.blogMeta?.metaTitle,
            metaDescription: this.blogService.getBlogData()?.blogMeta?.metaDescription
          },
          postSummary: this.blogService.getBlogData()?.postSummary,
          subIndustryId:this.subCategoryId,
          readTime:this.blogService.getBlogData()?.readTime||null
        }, typeRequest: "masterMetaTags",blogType:'master'
      }
    }
  );
  blogModel.afterClosed().subscribe((data: any) => {
    this.tagsData = data?.blogSetting;
    console.log("tagsData",this.tagsData);
    
  });
}
subCategoryId:any="";
subCategoryName:any="";
selectBusinessType(ev: any) {
  console.log(ev);
  this.subCategoryId=ev.subIndustryId;
  this.subCategoryName=ev.subIndustryName;
}

getAllTypes() {
  this.webService.mergedIndustrySubIndustry().subscribe(
    (res: any) => {
      console.log(res.data);
      this.filteredOptions = this.myControl
      // this.allTypes = res.data;
      res.data.forEach((data:any) => {
        this.allTypes.push({
          name:data.parent.name+' - '+data.name,
          industryId:data.parent.id,
          subIndustryId : data.id,
          industryName : data.parent.name,
          subIndustryName : data.name
        })
      });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    },
    (err) => {
      // //console.log(err)
      this.snackBar.open(`${err.error.message}`, 'Close');
    }
  );
}

private _filter(value: any) {
  const filterValue = value.toLowerCase();

  return this.allTypes.filter((option: any) =>
    option.name.toLowerCase().includes(filterValue)
  );
}




}
