import { Component, Input, OnInit } from "@angular/core";
import { TemplateService } from "./../../../../../../../services/template.service";
import { DbService } from 'src/app/services/db-service/db.service';
import { WebisiteService } from "../../../../webisite.service";

@Component({
  selector: 'app-herobanner-rating-review-bgimg-image',
  templateUrl: './herobanner-rating-review-bgimg-image.component.html',
  styleUrls: ['./herobanner-rating-review-bgimg-image.component.scss', './mq-herobanner-rating-review-bgimg-image.component.scss']
})
export class HerobannerRatingReviewBgimgImageComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){
    
  }

  ngOnInit(): void {
    //console.log('rating review hero banner',this.templateData);
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'rating_review_hero_banner'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    this.dataAssign();
  }


  // templateData: any = {
  //   "componentCategory": "hero banner",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner5Component",
  //   "data" : [
  //     {
  //       "value":"Connecting Devs with Employess",
  //       "type": "heading",
  //       "field":"text",
  //       "style":{"font-family": "Arial, Helvetica, sans-serif"}
  //     },
  //     {
  //       "value":"Amet minim mollit non desurant ullamco est sit aliqua dolor do amet sint. velit officia consequat",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color": "#A8A7A2", "font-family": "Arial, Helvetica, sans-serif", "font-style": "italic"}
  //     },
  //     {
  //       "value":"Trusted by 50k+ users.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"4.1/5 (14k Reviews)",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"https://www.push10.com/wp-content/uploads/geneva-gloval-website-hero-design.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "link":"",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"https://thumbs.dreamstime.com/b/increase-stats-economy-concept-hand-drawing-upward-red-arrow-blurry-city-background-multiexposure-153270493.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "link":"",
  //       "style":{}
  //     },
  //     {
  //       "value":"Find a Developer",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     }
  //   ]
  // }
  // data = this.templateData.data
  data : any;
  // heading = this.data[0]
  heading : any;
  // description = this.data[1]
  description : any;
  // downloadsData = this.data[2]
  downloadsData : any;
  // rating = this.data[3]
  rating : any;
  ratingCountText : any;
  // backgroundImage = this.data[4]
  backgroundImage : any;
  // rightContainerImage = this.data[5]
  rightContainerImage : any;
  // findDeveloperButton = this.data[6]
  bgColor:any;

  dataAssign(){
    this.data = this.templateData.data;  
    this.bgColor = this.templateData.bgColor;  
    this.checkingStyles();
    // this.heading = this.valuesData.title;
    // this.backgroundImage = this.valuesData.bgImageUrl;
    // this.description = this.valuesData.subTitle[0];
    // this.downloadsData = this.valuesData.reviewHeader;
    // this.rating = this.valuesData.rating;
    // this.ratingCountText = this.valuesData.reviewCountText;
    // this.rightContainerImage= this.valuesData.imageUrl;
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      let cityName = this._dbService.getLocality();
      this.bgColor = this.templateData.bgColor;
      this.valuesData = this.templateData.values;
      this.data[4].imgUrl = this.valuesData.bgImageUrl
      this.data[0].value = this.valuesData.title.replaceAll("{Company Name}", bName);
      this.data[0].value = this.valuesData.title.replaceAll("{City Name}", cityName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{Company Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{City Name}", cityName);
      this.data[2].value = this.valuesData.reviewHeader.replaceAll("{Company Name}", bName);
      this.data[2].value = this.valuesData.reviewHeader.replaceAll("{City Name}", cityName);
      this.data[3].value = this.valuesData.rating+this.valuesData.reviewCountText;
      this.data[5].imgUrl = this.valuesData.imageUrl;
      this.data[6].value = this.valuesData.button1Text;
    }
    else{

    }
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  typeText(data:any){
    // //console.log(this.templateData);
    // data.style = {
    //   "color":`${this.templateData.textColor}`,
    //   "font-family":this.templateData.fontFamily
    // }
    // //console.log(this.templateData.data);
    if(data.textType === 'heading'){
      data.style = {
        "color":this.templateData.colors.txtHeadingColor,
        "font-family":this.templateData.headingFontFamily,
      }
    }
    else if(data.textType === 'description'){
      data.style = {
        "color":this.templateData.colors.txtDescColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
    else if(data.textType === 'text'){
      data.style = {
        "color":this.templateData.colors.txtNormalColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":"none",
          "padding":"8px 15px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "padding":"8px 15px",
          "height":"44px",
          "font-size":"16px"
          // "border-radius":"none"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "height":"44px",
          "font-size":"16px",
          outline:`1px solid ${this.templateData.colors.btnColor}`
        }
      }
    }
  }

  redirectUrl(){
    window.open(`${this.data[6].link}`,'_blank');
  }
}
