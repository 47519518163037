<div class="mainSection" [ngStyle]="{'background-color': bgColor}">
    <div class="headingContainer">
        <div [ngStyle]="data[0].style?data[0].style:{}" class="heading">
            {{data[0].value | titlecase}}
        </div>
        <div [ngStyle]="data[1].style?data[1].style:{}" class="description">
            {{data[1].value}}
        </div>
    </div>
    <div class="rowFlexContainer">
        <div class="questions">
            <div [ngStyle]="data[2].style?data[2].style:{}" class="question">
                {{data[2].value}}
            </div>
            <div [ngStyle]="data[3].style?data[3].style:{}" class="answer">
                {{data[3].value}}
            </div>
            <!-- <div [ngStyle]="firstQuestionDescription2.style?firstQuestionDescription2.style:{}" class="answer">{{firstQuestionDescription2.value}}</div> -->
        </div>
        <div class="questions">
            <div [ngStyle]="data[5].style?data[5].style:{}" class="question">
                {{data[5].value}}
            </div>
            <div [ngStyle]="data[6].style?data[6].style:{}" class="answer">
                {{data[6].value}}
            </div>
            <!-- <div [ngStyle]="secondQuestionDescription2.style?secondQuestionDescription2.style:{}" class="answer">{{secondQuestionDescription2.value}}</div> -->
        </div>
        <div class="questions">
            <div [ngStyle]="data[8].style?data[8].style:{}" class="question">
                {{data[8].value}}
            </div>
            <div [ngStyle]="data[9].style?data[9].style:{}" class="answer">
                {{data[9].value}}</div>
            <!-- <div [ngStyle]="thirdQuestionDescription2.style?thirdQuestionDescription2.style:{}" class="answer">{{thirdQuestionDescription2.value}}</div> -->
        </div>
    </div>
</div>