<section class="main_section" *ngIf="!pageLoader">
    <div class="page_title_description">
        <div class="page_title">Site Settings</div>
        <div class="description">
            Manage your website's status and custom domain.
        </div>
    </div>
    <div class="web_status">
        <div class="heading_status">
            <div class="section_heading">Website Status</div>
            <div class="status" [ngClass]="
                websiteStatus === 'PRIVATE' ? 'status_red' : ''
                ||
                websiteStatus === 'PUBLIC' ? 'status' : ''
                ||
                websiteStatus === 'DISABLED' ? 'status_disabled' : ''
            ">
                &#x2022; {{websiteStatus | titlecase}}
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="status_array">
            <mat-radio-group aria-label="Select an option" class="status_array" [(ngModel)]="websiteStatus" (change)="changeWebsiteStatus($event)">
                <ng-container *ngFor="let item of webStatus">
                    <div class="single_status">
                        <mat-radio-button [value]="item.value" class="radio_btn" [checked]="item.status" [color]="color">
                            <div class="button_name">{{item?.name | titlecase}}</div>
                        </mat-radio-button>
                        <div class="message">{{item.message}}</div>
                    </div>
                </ng-container>
            </mat-radio-group>
        </div>
    </div>
    <!-- <div class="web_status">
        <div class="heading_status">
            <div class="section_heading">Search Indexing</div>
            <div class="status" [ngClass]="
                searchIndex === 'ENABLE' ? 'status' : ''
                ||
                searchIndex === 'DISABLE' ? 'status_disabled' : ''
            ">
                &#x2022; {{searchIndex | titlecase}}
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="sub_division">
            <div class="sub_heading">Website is currently not being indexed</div>
            <div class="sub_desc">Google, Bing and other search tools are not indexing your website</div>
        </div>
        <div class="status_array">
            <mat-radio-group aria-label="Select an option" class="status_array" [(ngModel)]="searchIndex">
                <ng-container *ngFor="let item of searchIndexing">
                    <div class="single_index">
                        <mat-radio-button [value]="item.value" class="radio_btn" [checked]="item.status" [color]="color">
                            <div class="button_name">{{item?.name | titlecase}}</div>
                        </mat-radio-button>
                        <div class="message">{{item.message}}</div>
                    </div>
                </ng-container>
            </mat-radio-group>
        </div>
    </div> -->
    <div class="web_status">
        <div class="heading_status">
            <div class="section_heading">
                Site Domain
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="sub_division">
            <div class="sub_heading">Site Link</div>
            <div class="sub_desc">Must be alphanumeric (A-Z, 0-9) with dashes between words.</div>
        </div>
        <div class="url_domain">
            <mat-form-field appearance="outline" class="url_input">
                <input matInput [(ngModel)]="domainUrl"  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"><button class="end_domain">.simpo.ai</button>
            </mat-form-field>
            <div class="https_url">
                https://{{domainUrl}}.simpo.ai
            </div>
        </div>
        <div class="update_btn">
            <button (click)="updateWebsiteUrl()">Update</button>
        </div>
        <div class="hr_line"></div>
        <div class="custome_domain_urls" id="custom_domain">
            <div class="cd_heading">Get a custom domain name!</div>
            <div class="cd_hint">
                <div class="domain_icon">
                    <img src="./../../../../../assets/images/common/domain.svg" alt="domain">
                </div>
                <div class="domain_hint">
                    Build your professional brand with a unique web address for free when you upgrade your plan!
                </div>
            </div>
            <div class="custom_domains">
                <!-- <ng-container>
                    <div class="single_domain">
                        <div class="check_img">
                            <img src="./../../../../../assets/images/common/check.svg" alt="check_mark">
                        </div>
                        <div class="domain_url">
                            smartsolutions.com
                        </div>
                        <div class="status_btn">
                            <div class="public_status">&#x2022; Public</div>
                            <div class="get_btn">
                                <button>Get</button>
                            </div>
                        </div>
                    </div>
                </ng-container> -->
                <!-- <div class="search_custom_domain">
                    <mat-form-field appearance="outline" class="search_domain">
                        <mat-icon class="search_Icon" matPrefix>search</mat-icon>
                        <input matInput placeholder="Seach for domain name here" [(ngModel)]="domainName"/>
                        <button class="search_btn" (click)="searchDomain()">Search</button>
                    </mat-form-field>
                    <button class="search_Domain_button" (click)="searchDomain()">Search Domain</button>
                </div> -->
                <div class="search_domain">
                    <button (click)="searchDomainthroughGodaddy()">Search Domain</button>
                </div>
            </div>
        </div>
        <div class="use_domain">
            <div class="cd_heading">Use existing domain</div>
            <div class="using_domain">Already have a domain with Google, GoDaddy, or somewhere else? Easily connect or transfer it to your site. Start by verifying your current domain</div>
            <div class="link_domain">
                <!-- <mat-form-field appearance="outline" class="url_input">
                    <button class="http">
                        https://
                    </button>
                    <input matInput placeholder="example.com" [(ngModel)]="linkingDomain"> -->
            <button class="linking_domain" (click)="linkDomain()">
                <span class="spinner" *ngIf="loader">
                    <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
                <span *ngIf="!loader">Link Domain</span>
            </button>
                <!-- </mat-form-field> -->
            </div>
        </div>
    </div>
</section>
<section class="loader_section" *ngIf="pageLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>