import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-delete-author',
  templateUrl: './delete-author.component.html',
  styleUrls: ['./delete-author.component.scss']
})
export class DeleteAuthorComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteAuthorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService : BlogServiceService
  ){

  }

  authorId : any ;
  ngOnInit() {
    this.authorId = this.data.data;
  }

  deleteAuthor(){
    this.blogService.deleteBlogAuthor(this.authorId).subscribe({
      next:(res:any)=>{
          this.blogService.openSnack("Tag Deleted sucessfully","Close");
          this.dialogRef.close('success');
      },
      error:(err)=>{
        this.blogService.openSnack(err.error.message,"close");
      }
    })
  }

  closeBtn(){
    this.dialogRef.close();
  }
}

