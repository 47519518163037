import { Component, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogServiceService } from '../blog-service.service';
import { DeleteBlogComponent } from '../delete-blog/delete-blog.component';
import { MetaTagsPopupComponent } from '../meta-tags-popup/meta-tags-popup.component';

@Component({
  selector: 'app-view-blogs',
  templateUrl: './view-blogs.component.html',
  styleUrls: ['./view-blogs.component.scss', './mq-view-blogs.component.scss']
})
export class ViewBlogsComponent {
  constructor(
    private blogService:BlogServiceService,
    private route:ActivatedRoute,
    private router:Router,
    private metaService: Meta,
    private titleService: Title,
  ) { 
    this.subscribeQueryParams();
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.showApiData();
    this.getScreenSize();
  }

  ngAfterViewInit() {
    // this.metaService.addTag({
    //   name: 'description',
    //   content: 'ajay'
    // });
    //console.log(this.jsonData.description);
  }
  blogId:any="";
  subscribeQueryParams(){
    this.route.queryParams.subscribe((params:any)=>{
        this.blogId=params.blogId;
        console.log(this.blogId);
        
    })
  }

  jsonData:any="";
  showApiData(){
    this.blogService.getBlogById(this.blogId).subscribe(
      (res:any)=>{
        this.jsonData=res.data;
        this.metaService.addTags([this.jsonData.blogMeta.metaDescription,this.jsonData.keyWords,this.jsonData.author]);
        this.titleService.setTitle(this.jsonData?.blogMeta.metaTitle)
        console.log("jsonData",this.jsonData);
      }
    )
    // this.barTransy?.reinit();
  }

  editBlog(blog: any) {
    // ev.stopPropagation();
    //console.log("blogs",blog);
    this.blogService.setBlogData(blog);
    this.router.navigate(["/create-blog"], { queryParams: { edit: true } });
  }


  back(){
    this.router.navigate(["/admin/website/blogs/listing"]);
  }

  filterBtn() {
    let blogModel = this.blogService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      '73vh',
      '300px',
      'relative',
      {
        data:{blogData:this.jsonData,typeRequest:"api",blogType:'master'}
      }
    );
     blogModel.afterClosed().subscribe((data:any) => {
      //console.log(data);
      
    });
  }

   deleteBlog(){
    let deleteDepartmentModel = this.blogService.openDeleteDialog(
      DeleteBlogComponent,
      'auto',
      '600px',
      { blogId:this.jsonData.id},
      '97vw'
    );
    deleteDepartmentModel.afterClosed().subscribe((data) => {
      if(data.ended=="success"){
        this.router.navigate(['/admin/website/blogs/listing']);
      }
    });
  }
}
