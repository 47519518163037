import { Component ,HostListener,OnInit } from '@angular/core';

@Component({
  selector: 'app-five-section-services',
  templateUrl: './five-section-services.component.html',
  styleUrls: ['./five-section-services.component.scss', './mq-five-section-services.component.scss']
})
export class FiveSectionServicesComponent implements OnInit {

  constructor(){
    this.getScreenSize();
  }

  ngOnInit(): void {
    
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data": [
      {
        "imgUrl": "./../../../../../../../../assets/images/service/service_container.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "value": "Explore our cutting-edge platform offerings, designed to revolutionize the way you do business.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 700, "font-size": "48px", "color": "#7F7F7F" }
      },
      {
        "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 400, "font-size": "18px", "color": "#7F7F7F" }
      },
      {
        "value": "Enterprise Reconciliation",
        "type": "Text",
        "field": "Text",
      },
      {
        "value": "Lessons on design that cover the most recent developments.",
        "type": "Text",
        "field": "Text",
      },
      {
        "value": "Learn more",
        "type": "Text",
        "field": "Text",
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/first_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/second_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/third_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/fourth_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/fifth_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },

    ]
  }

  data = this.templateData.data;
}
