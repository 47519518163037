<div class="pageSize">
    <div class="paddingForPage">
        <div class="title">
            Connect existing domain
            <mat-icon (click)="close()">close</mat-icon>
        </div>
        <div class="hr-line"></div>
        <div class="description">
            Enter domain you want to connect
        </div>
        <!-- <input class="inputBoxStyle" type="text" placeholder="Enter domain url"> -->
        <div class="form_field business-field-section">
            <mat-form-field class="sfull-width" appearance="outline">
                <input formControlName="websiteUrl" placeholder="https://beeos.com" matInput/>
            </mat-form-field>
        </div>
        <div class="btnRightAlign">
            <button class="buttonStyle" (click)="linkDomain()">Link Domain</button>
        </div>
    </div>
</div>