<div class="main-section">
    <div class="popup-profile-name">Are you sure</div>
    <div class="sub-text">You want to delete this Blog?</div>
    <div class="light-text">If you delete the blog it will you can’t recover it.</div>
    <div class="button-section">
        <div class="delete-btn">
            <button class="btn-name" (click)="deleteBlog()">Delete</button>
        </div>
        <div class="delete-btn">
            <button class="sec-btn" (click)="cancelBlog()">Cancel</button>
        </div>
    </div>
</div>