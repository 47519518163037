import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmmiterService } from './../../../services/event-emmiter.service';
import { TemplateService } from './../../../services/template.service';

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss','./mq-hero-banner.component.scss']
})
export class HeroBannerComponent {

  @Input() data : any;
  navId : any;
  bgImage = ''

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _templatesService:TemplateService,
    private emitterService : EventEmmiterService
  ){
    // this.getElementData()
  }

  loader : boolean = false;
  sectionLoading : boolean = false;

  ngOnInit(){
    this.sectionLoading = true;
    setTimeout(() => {
      this.sectionLoading = false;
      this.emitterService.activateComponent.emit({nextComponent:'service',loading:false})
    }, 5000);
    // this._templatesService.updateElementsData.subscribe((res:any)=>{
    //   this.data = res;
    //   //console.log(res)
    // });
    // //console.log(this.data)
    // //console.log(this.emitterService)
    this.emitterService.componentLoader.subscribe(
      (res)=>{
        // this.loader = res.loading;
        if(res.name === 'heroBanner'){
          this.loader = res.loading;
        }
        else{
          this.loader = false;
        }
      }
    )
  }

  getElementData(){
    this._templatesService.updateElements.subscribe(
      (res:any) => {
        document.querySelectorAll('*[id]').forEach((element) => {
          if(res.elem === element.id){
            let highlightingElement = document.getElementById(element.id);
            if(!highlightingElement) return;
            highlightingElement.style.border = "2px solid #F79824 !important"
          }
        })
      }
    )
  }

}
