<section class="main_section" *ngIf="!gettingLoader">
    <div class="top_section">
        <div class="section_title">
            Upgrade Plan
        </div>
        <div class="close_icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr-line"></div>
    <div class="botton_section">
        <div class="bottom_title">
            Upgrade your business plan to link domain
        </div>
        <div class="sub_title">
            Empower your business with the advanced features and tools offered by Simpo.ai, thoughtfully tailored to match your current growth phase.
        </div>
        <div class="buttons_bar">
            <button *ngFor="let item of twoButtons" [ngClass]="{'activate': item.status}" (click)="planChange(item.value)">{{item.name | titlecase}}
                    <span class="offers_tag" *ngIf="item.value === 'ANNUALLY'">
                        <img src="./../../../assets/images/common/offer_tag.svg" alt="offer_tag"> Get 2 months free
                    </span>
                </button>
        </div>
        <div class="offer_tag">
            <div class="offer_top_section">
                <div class="title_price">
                    <div class="plan_title">
                        {{upgradePlan.plan.name}}
                    </div>
                    <div class="plan_price">
                        <div class="html_entity" [innerHTML]="upgradePlan.prices.currencySymbol"></div>
                        <div class="pricing" *ngIf="isAnually">{{upgradePlan.prices.yearlyPrice}}/y</div>
                        <div class="pricing" *ngIf="!isAnually">{{upgradePlan.prices.monthlyPrice}}/m</div>
                    </div>
                </div>
                <div class="description">
                    {{upgradePlan.plan.desc}}
                </div>
            </div>
            <div class="offer_bottom_section">
                <ng-container *ngFor="let item of upgradePlan.plan.features">
                    <div class="single_feature">
                        <img src="./../../../../../../assets/images/check.svg" alt="check_image">
                        <div class="feature">
                            {{item.name}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="upgrade_btn">
                <button (click)="planUpgration(upgradePlan.plan.id)">
                    <span *ngIf="loader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!loader">Upgrade Plan</span>
                </button>
            </div>
        </div>
    </div>
</section>