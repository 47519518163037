<div class="total-section">
    <div class="total-box">
<div class="total-container">
<div class="heading-section">
    <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
</div>
</div>
<div class="middle-container">
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text1.style?text1.style:{}">{{text1.value}}</div>
    </div>
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text2.style?text2.style:{}">{{text2.value}}</div>
    </div>
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text3.style?text3.style:{}">{{text3.value}}</div>
    </div>
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text4.style?text4.style:{}">{{text4.value}}</div>
    </div>
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text5.style?text5.style:{}">{{text5.value}}</div>
    </div>
    <div>
        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        <div [ngStyle]="text6.style?text6.style:{}">{{text6.value}}</div>
    </div>

</div>
    </div>
</div> 


