<div class="body-styles" [ngStyle]="{'background-color': bgColor}">
    <div class="left_container">
        <!-- <div [ngStyle]="data[1].style ? data[1].style:{}" class="top_section">
            <div class="top_image">
                <img src="{{data[0].imgUrl}}" alt="">
            </div>
            <div>{{heading.value}}</div>
        </div> -->
        <div [ngStyle]="data[2].style ? data[2].style:{}" class="top_section_text">{{data[2].value | titlecase}}</div>
        <div class="short_note" [ngStyle]="data[6].style ? data[6].style : {}">
            {{data[6].value}}
        </div>
    </div>
    <div class="right_container">
        <div class="person-details">
            <div>
                <img src="{{data[3].imgUrl}}"alt=""  [ngStyle]="data[3].style ? data[3].style : {}" class="person-img">
            </div>
            <div [ngStyle]="data[4].style ? data[4].style :{}" class="person-name">{{data[4].value}}</div>
            <div [ngStyle]="data[5].style ? data[5].style :{}" class="person-type">{{data[5].value}}</div>
        </div>
    </div>
</div>
