<section class="main_section" [ngClass]="footer.className">
    <div class="left_container">
        <div class="business_name" [ngStyle]="footer.logo.textStyles">{{footer.logo.imageText}}</div>
        <div class="short_note" [ngStyle]="footer.shortNote.textStyles">{{footer.shortNote.text}}</div>
    </div>
    <div class="right_container">
        <!-- <div class="social_icons"> -->
            <ul class="social_icons">
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/instagram.svg" alt="instagram">
                </a></li>
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/twitter.svg" alt="twitter">
                </a></li>
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/facebook.svg" alt="facebook">
                </a></li>
            </ul>
        <!-- </div> -->
    </div>
</section>