<div class="total-section-container">
    <div class="header-section">
        <div class="header-part" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="subtext-part"[ngStyle]="subtext.style?subtext.style:{}">{{subtext.value}}</div>
        <div class="text" [ngStyle]="text1.style?text1.style:{}">{{ text1.value}}</div>
        <div class="button-section">
            <div class="first-button" [ngStyle]="button1.style?button1.style:{}">{{button1.value}}</div>
            <div class="second-button" [ngStyle]="button2.style?button2.style:{}">{{button2.value}}</div>
        </div>
    </div>
    <div class="middle-section-container">
        <div class="middle-section">
            <div class="member-section">
                <img src="{{text2.value[0].imgUrl}}" alt="" [ngStyle]="text2.value[0].style?text2.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text2.value[1].style?text2.value[1].style:{}">{{text2.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text2.value[2].style?text2.value[2].style:{}">{{text2.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text2.value[3].style?text2.value[3].style:{}">{{text2.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text3.value[0].imgUrl}}" alt="" [ngStyle]="text3.value[0].style?text3.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text3.value[1].style?text3.value[1].style:{}">{{text3.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text3.value[2].style?text3.value[2].style:{}">{{text3.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text3.value[3].style?text3.value[3].style:{}">{{text3.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text2.value[0].imgUrl}}" alt="" [ngStyle]="text2.value[0].style?text2.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text2.value[1].style?text2.value[1].style:{}">{{text2.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text2.value[2].style?text2.value[2].style:{}">{{text2.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text2.value[3].style?text2.value[3].style:{}">{{text2.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text3.value[0].imgUrl}}" alt="" [ngStyle]="text3.value[0].style?text3.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text3.value[1].style?text3.value[1].style:{}">{{text3.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text3.value[2].style?text3.value[2].style:{}">{{text3.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text3.value[3].style?text3.value[3].style:{}">{{text3.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text2.value[0].imgUrl}}" alt="" [ngStyle]="text2.value[0].style?text2.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text2.value[1].style?text2.value[1].style:{}">{{text2.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text2.value[2].style?text2.value[2].style:{}">{{text2.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text2.value[3].style?text2.value[3].style:{}">{{text2.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text3.value[0].imgUrl}}" alt="" [ngStyle]="text3.value[0].style?text3.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text3.value[1].style?text3.value[1].style:{}">{{text3.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text3.value[2].style?text3.value[2].style:{}">{{text3.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text3.value[3].style?text3.value[3].style:{}">{{text3.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text2.value[0].imgUrl}}" alt="" [ngStyle]="text2.value[0].style?text2.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text2.value[1].style?text2.value[1].style:{}">{{text2.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text2.value[2].style?text2.value[2].style:{}">{{text2.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text2.value[3].style?text2.value[3].style:{}">{{text2.value[3].value}}</div>
            </div>
            <div class="member-section">
                <img src="{{text3.value[0].imgUrl}}" alt="" [ngStyle]="text3.value[0].style?text3.value[0].style:{}" class="team-image">
                <div  class="heading" [ngStyle]="text3.value[1].style?text3.value[1].style:{}">{{text3.value[1].value}}</div>
                <div  class="designation"  [ngStyle]="text3.value[2].style?text3.value[2].style:{}">{{text3.value[2].value}}</div>
                <div  class="description"  [ngStyle]="text3.value[3].style?text3.value[3].style:{}">{{text3.value[3].value}}</div>
            </div>
        </div>
    </div>
</div>