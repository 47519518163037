<section class="main-section">
    <!-- header-section  -->
    <div class="main-header-section" *ngIf="screenWidth > 475">
        <!-- header-left-section  -->
        <div class="header-left-section">
            <div class="header-left-main-heading">
                Tags <span class="header-span">{{dataSource.length}} Tags</span>
            </div>
            <div class="header-left-sub-heading">
                View,create and manage your blog members
            </div>
        </div>
        <!-- header-right-section  -->
        <div class="header-right-section">
            <div class="add-business-section" (click)="addTag()">
                + Add Tag
            </div>
        </div>
    </div>
    <!-- search and filter section  -->
    <div class="search-filter-main-section" *ngIf="screenWidth > 475">
        <div class="search-bar">
            <mat-form-field class="sfull-width" appearance="outline">
                <input matInput placeholder="Search" [(ngModel)]=tagName (keyup)="_searchIndustry()" />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="filter-section">
            <div class="filter-icon">
                <img src="../../../../../../assets/images/master/filter_icon.svg" alt="filter_Svg" />
            </div>
            <div>Filters</div>
        </div>
    </div>

    <!-- table section  -->
    <ng-container *ngIf="!emptyloader; else editLoader">
        <ng-container *ngIf="screenWidth > 475">
            <ng-container *ngIf="dataSource.length > 0; else emptyscreen">
                <div class="table-section">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <!-- Position Column -->
                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                      selection.hasValue() && !isAllSelected()
                    " [aria-label]="checkboxLabel()" color="'#027A48'" class="">
                                </mat-checkbox>
                                Tag Name
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox (click)="
                              enquirycheck(element, element.active, $event)
                            " (change)="$event ? selection.toggle(element) : null"
                                    [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)"
                                    class="" color="'#027A48'">
                                </mat-checkbox>
                                {{element.tagName?element.tagName:'N/A'}}
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> No of Blogs </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.blogsCreatedCount?element.blogsCreatedCount:0}} </td>
                        </ng-container>

                        <!-- date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Created </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdTimeStamp | date: 'mediumDate'}}
                            </td>
                        </ng-container>


                        <!-- Action Column -->
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="action-icon">
                                    <div class="eye-icon">
                                        <img src="../../../../../../assets/images/master/Eye.svg" alt="eye-icon"
                                            (click)="viewTag(element.id)" />
                                    </div>
                                    <div class="edit-icon">
                                        <img src="../../../../../../assets/images/master/edit_icon.svg" alt="edit-icon"
                                            (click)="editTag(element)">
                                    </div>
                                    <div class="delete-icon">
                                        <img src="../../../../../../assets/images/master/delete_icon.svg"
                                            alt="delete_icon" (click)="deleteTag(element.id)">
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="screenWidth < 475">
            <div class="search-filter-resp">
                <div class="search-filter-left-resp">
                    <div class="search-img-resp" (click)="search()">
                        <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                            class="search-img">
                    </div>
                    <div class="search_input" *ngIf="searchbar">
                        <mat-form-field class="sfull-width" appearance="outline">
                            <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                                class="search-icon">
                            <input matInput placeholder="Search" [(ngModel)]=tagName (keyup)="_searchIndustry()" />
                            <img src="../../../../../../../../assets/images/close.svg" alt="close"
                                (click)="closesearch()">
                        </mat-form-field>
                    </div>
                    <div class="filter-section-resp">
                        <img src="../../../../../../../../assets/images/master/filter_icon.svg" alt=""
                            class="filter-img">
                    </div>
                </div>
                <div class="search-filter-right-resp">
                    <div class="add-business-section" (click)="addTag()">
                        + Add Tag
                    </div>
                </div>
            </div>
            <ng-container *ngIf="dataSource.length > 0; else emptyscreen">
                <div class="author-mobile-listing">
                    <div class="author-list-responsivess" *ngFor="let element of dataSource">
                        <div class="author-image-section">
                            <div class="left-side">
                                <div class="left-side-author-text">
                                    <div class="left-side-author-name">
                                        {{element.tagName | titlecase}}
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <div class="right-side-edit-button">
                                    <img src="./../../../../../../../../../assets/images/blogs/edit.svg" alt="edit_icon"
                                        (click)="editTag(element)" />
                                </div>
                                <div class="right-side-delete-button">
                                    <img src="./../../../../../../../../../assets/images/blogs/delete.svg"
                                        alt="delete_icon" (click)="deleteTag(element.id)" />
                                </div>
                            </div>
                        </div>
                        <div class="blog-author-section">
                            <div class="blog-left-side">
                                <div class="left-left-side">
                                    Blogs published :
                                </div>
                                <div class="left-right-side">
                                    {{element.blogsCreatedCount?element.blogsCreatedCount:'N/A'}}
                                </div>
                            </div>
                            <div class="blog-right-side">
                                <div class="right-left-side">
                                    Created on :
                                </div>
                                <div class="right-right-side">
                                    {{element.createdTimeStamp | date: 'mediumDate'}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #editLoader>
        <div class="img-section">
            <img src="./../../../../../../../../../assets/images/loader/beeos_loader.gif" alt="loader.svg" />
        </div>
    </ng-template>
    <ng-template #emptyscreen>
        <div class="empty-part">
            No Tag here!!
        </div>
    </ng-template>
</section>