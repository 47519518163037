import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from './../../../../services/template.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss','./mq-preview.component.scss']
})
export class PreviewComponent {

  componentNames:any=[];
  componentLoader : boolean = false;
  templateData:any={
    // "id": "1edb27b8-b73e-62c3-be4f-8127e4a93850",
    "componentContentList": [],
    componentsData:[],
    "businessId": null,
    "businessName": null,
    "primaryColor": null,
    "buttonColor": null,
    "textFamily": null
}

componentsStyling : any = {
  bgColor1 : String,
  bgColor2 : String,
  buttonShape: String,
  buttonStyle: String,
  fontFamily:String,
  txtHeadingColor:String,
  txtDescColor:String,
  txtNormalColor:String,
  btnColor:String,
  btnTextColor:String,
}


  constructor(
    private templateService : TemplateService,
    private router:Router,
    private responsive : BreakpointObserver,
    private route : ActivatedRoute,
    private _dbService : DbService
  ){this.getScreenSize()}

  monitorScreen : boolean = true;
  mobileScreen : boolean = false;

  heroBannerComponent : boolean = false;
  heroBanner_ComponentData : any;
  businessDetails : any;
  websiteStatus : any;
  pageLoader : boolean = false;

  ngOnInit(): void {
    // ////console.log(window.localStorage.getItem('webId'))
    // this.templateService.getTemplateDataByTemplateId(window.localStorage.getItem('webId')).subscribe(
    //   (res:any) => {
    //     ////console.log(res.data);
    //     res.data[0].componentContentList.forEach((element:any) => {
    //       if(element.componentName === "heroBanner"){
    //         this.heroBannerComponent = true;
    //         this.heroBanner_ComponentData = element.data[0];
    //       }
    //     })
    //   },
    //   (err) => {
    //     ////console.log(err)
    //   }
    // )
    // this.templateDataAssign();
    // ////console.log(window.localStorage.getItem('bDetails'));
    this.templateService.scrollToComponent.subscribe(
      (res:any)=>{
        if (res.goIndex) {
          let a = document.getElementById(
            this.templateData.componentContentList[res.goIndex].category.name
          );
          if (!a) return;
          a.scrollIntoView({ behavior: 'smooth' });
        } else {
          let a = document.getElementById('Contact Us');
          if (!a) return;
          a.scrollIntoView({ behavior: 'smooth' });
        }
      }
    )

    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }
    // this.businessDetails = {
    //   websiteUrl:'abcdef'
    // }
    ////console.log(this.businessDetails);
    this.getTemplateDataById();
    // this.getTemplateStructure();
    this.getblogsByBusinessId();
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  getTemplateStructure(){
    this.pageLoader = true;
    this.templateService.getTemplateStructure(this._dbService.getSubCategoryId()).subscribe(
      (res:any) => {
        window.localStorage.setItem('tempStructure',JSON.stringify(res.data.categories));
        res.data.categories.forEach((categories:any) => {
          this.componentNames.push(categories);
        });
        // //console.log('templateStructure',this.componentNames);
        
        // this.getComponentsData();
      },
      (err) => {
        // //console.log(err.error.message);
        this.templateService.openSnack(`${err.error.messsage}`,'Close')
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }
  // async getComponentsData(){
  //   await this.componentNames.forEach((compNames:any,i:any) => {
  //     this.templateService.getComponentsData(compNames.id,this._dbService.getSubCategoryId()).subscribe(
  //       (res:any) => {
  //         //console.log(res.data);
  //         this.componentNames.forEach((cmStructure:any,index:number) => {
  //           if(res.data.category.name === cmStructure.name){
  //             this.templateData.componentContentList.splice(index,0,res.data);
  //             this.templateData.componentsData.splice(index,0,{
  //               componentName: res.data.webComponentName,
  //               componentCategoryId: res.data.category.id,
  //               componentCategoryName : res.data.category.name,
  //               componentId : res.data.id,
  //               contentId : res.data.contentId,
  //               subIndustryId:this._dbService.getSubCategoryId()
  //             });
  //           }
  //         });
  //         // this.templateData.componentContentList.push(res.data);
  //         // this.pickComponentsDynamically(this.templateData,i);
  //         // //console.log('template Data',this.templateData);
          
  //       },
  //       (err) => {
  //         //console.log(err.error.message);
  //       }
  //     )
  //   })
  // }

  changeScreen(element:any){
    if(element === 'monitor'){
      this.monitorScreen = true;
      this.mobileScreen = false;
    }
    else{
      this.mobileScreen = true;
      this.monitorScreen = false;
      // ////console.log(document.body.clientWidth);
      // this.responsive.observe(Breakpoints.XSmall).subscribe(
      //   (res:any) =>{
      //     ////console.log(res);
      //   }
      // )
    }
  }


  editWebsite(){
    this.router.navigate(['/edit-website'])
  }


  //getting data based on buisiness id and templateid

  // async templateDataAssign(){
  //   this.componentNames.forEach((component:any) => {
  //       this.templateService.regenerateComponentCategory(component.componentCategory,'1eda9215-46e6-6984-a675-130cb2740cf4').subscribe(
  //         {
  //           next:(value:any)=>{
  //             this.templateData.componentContentList.push(value.data);
  //           },
  //           error:(err)=>{
  //             this.templateService.openSnack(`${component.componentCategory} Not Found`,"Close");
  //           },
  //         }
  //       )
  //   });
  // }

  sendingColor : any;
  bgColorCountValue = 1;

  getTemplateDataById(){
    this.pageLoader = true;
    this.templateService.getTemplateDataByTemplateId(this.businessDetails.templateId).subscribe(
      (res:any) => {
        this.sendingColor = res.data.components[0].colors;
        this.componentsStyling.buttonShape = res.data.components[0].buttonShape;
        this.componentsStyling.buttonStyle = res.data.components[0].buttonStyle;
        this.componentsStyling.fontFamily = res.data.components[0].fontFamily;
        this.componentsStyling.btnColor = res.data.components[0].colors.btnColor;
        this.componentsStyling.txtNormalColor = res.data.components[0].colors.txtNormalColor;
        //console.log(res.data)
        let a : number = 0;
        res.data.components.forEach((cmpList:any) => {
          res.data.colors = this.sendingColor;
          this.templateData.componentContentList.push(cmpList);
          this.templateData.componentsData.push({
            componentName: cmpList.webComponentName,
            componentCategoryId: cmpList.category.id,
            componentCategoryName : cmpList.category.name,
            componentId : cmpList.id,
            contentId : cmpList.contentId,
            subIndustryId:this._dbService.getSubCategoryId()
          });
        });
        // this.templatesService.updateElements.emit(this.templateData); 
        // a = a+1;
        // if(a === this.componentNames.length){
        //   this.initialTemplateData();
        // }
        //global stylings
        let data = this.templateData.componentContentList[1];
        // this.componentsStyling.bgColor = data.bgColor;
        if(this.bgColorCountValue%2) {
          res.data.bgColor = this.componentsStyling.bgColor1;
        } else {
          res.data.bgColor = this.componentsStyling.bgColor2;
        }
        res.data.buttonShape = this.componentsStyling.buttonShape;
        res.data.buttonStyle = this.componentsStyling.buttonStyle;
        res.data.fontFamily = this.componentsStyling.fontFamily;
        res.data.txtHeadingColor = this.componentsStyling.txtHeadingColor;
        res.data.txtDescColor = this.componentsStyling.txtDescColor;
        res.data.txtNormalColor = this.componentsStyling.txtNormalColor;
        res.data.btnColor = this.componentsStyling.btnColor;
        res.data.btnTextColor = this.componentsStyling.btnTextColor;
        this.templateService.updateElements.emit(this.templateData); 
        this.getBusinessDetailsByBusinessId();
      },
      (err) => {
        ////console.log(err.error.message);
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  websiteUrl : any = '';
  getBusinessDetailsByBusinessId(){
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res:any) => {
        // //console.log(res.data);
        this.websiteStatus = res.data.website.status;
        this.websiteUrl = res.data.website.websiteUrl;
        this.pageLoader = false;
        if(this.websiteStatus != 'DISABLED'){

        }
        else{
          let getId = document.getElementById('main_section');
          if(!getId) return;
          getId.insertAdjacentHTML('beforeend','<div class="disabled_text">Disabled</div>')
        }
      },
      (err) => {
        // //console.log(err.error.message);
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,'Close'
        )
      }
    )
  }


  connectCustomDomain(){
    this.router.navigate([`/admin/website/settings`]);
    this.templateService.scrollToComponent.emit({goTo:'custom_domain',component:'settings'})
  }
  previewCurrentWebsite(){
    window.open(this.websiteUrl,'_blank')?.focus();
  }

  blogData:any="";
  displayBlogs:boolean=false;
  getblogsByBusinessId() {
    this.templateService.getBlogsByBuisnessId(this.businessDetails.id,"ALL").subscribe
      ({
        next: (res: any) => {
          this.blogData = res.data;
          if(this.blogData.length>0){
            this.displayBlogs=true;
          }
          else{
            this.displayBlogs=false;
          }
          //console.log("displayBlogs",this.displayBlogs);
          
        },
        error: (err: any) => {
        }
      })

  }

}
