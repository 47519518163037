<nav class="navbar navbar-dark navbar-expand-lg fixed-top" [ngClass]="scrWidth > 475 ? 'navbar' : 'small_screen_nav'">
    <div class="logo_section">
        <a class="navbar-brand"
        [ngStyle]="header.logo.textStyles">{{header.logo.imageText.replace('[Company Name]', businessName) | titlecase}}</a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menuItems" aria-controls="menuItems" aria-expanded="false" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="menuItems">
        <div class="right_container">
            <ul class="navbar-nav">
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/instagram.svg" alt="instagram">
                </a></li>
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/twitter.svg" alt="twitter">
                </a></li>
                <li class="nav-item"><a  class="nav-link">
                    <img src="./../../../../assets/images/facebook.svg" alt="facebook">
                </a></li>
                <li class="nav-item">
                    <button [ngStyle]="header.contactBtn.textStyles">{{header.contactBtn.buttonText | titlecase}}</button>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- <nav class="navbar navbar-dark navbar-expand-md fixed-top" id="navbar2" style="visibility: hidden;">
    <div class="logo_section2">
        <a class="navbar-brand"
        [ngStyle]="header.logo.textStyles">{{header.logo.imageText| titlecase}}</a>
    </div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse right_section" id="navbar">
        <ul class="navbar-nav">
            <li class="nav-item"><a  class="nav-link">
                <img src="./../../../../assets/images/instagram.svg" alt="instagram">
            </a></li>
            <li class="nav-item"><a  class="nav-link">
                <img src="./../../../../assets/images/twitter.svg" alt="twitter">
            </a></li>
            <li class="nav-item"><a  class="nav-link">
                <img src="./../../../../assets/images/facebook.svg" alt="facebook">
            </a></li>
            <li class="nav-item">
                <button [ngStyle]="header.contactBtn.textStyles">{{header.contactBtn.buttonText | titlecase}}</button>
            </li>
        </ul>
    </div>
</nav> -->