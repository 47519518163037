<section class="main_section">
    <div class="user_name_description">
        <div class="user_name">Hey Harish </div>
        <div class="description">here’s what’s happening with your business</div>
    </div>
    <div class="days_count">
        <div class="last_days">
            <mat-form-field appearance="outline" class="days_dropdown">
                <mat-select [(ngModel)]="selectedDaysCount">
                  <mat-option [value]="item.daysCount"
                  *ngFor="let item of dropdownValues">{{item.displayValue}}</mat-option>
                  <!-- <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                  <mat-option value="option3">Option 3</mat-option> -->
                </mat-select>
            </mat-form-field>
        </div>
        <div class="day_count">
            <div class="count_box">{{selectedDaysCount}}</div>
        </div>
    </div>
    <div class="visitors_count">
        <ng-container *ngFor="let item of visitors">
            <div class="single_count_box">
                <div class="box_title">{{item.boxTitle}}</div>
                <div class="visitors_ratio">
                    <div class="visitors">{{item.count}}</div>
                    <div class="ratio_increased" *ngIf="item.increased">+{{item.increasedBy}}% <mat-icon>arrow_upward</mat-icon></div>
                    <div class="ratio_decreased" *ngIf="!item.increased">-{{item.increasedBy}}% <mat-icon>arrow_downward</mat-icon></div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="line_chart">
        <div class="visitors_chart">
            <div class="chart_top_layer">
                <div class="chart_title">Visits</div>
            </div>
            <div class="chart_bottom_layer">
                <p-chart type="line" [data]="linData" [options]="LineOptions" height="250"></p-chart>
            </div>
        </div>
    </div>
    <div class="bar_chart">
        <div class="visitors_chart">
            <div class="chart_top_layer">
                <div class="chart_title">Most visiting hour’s</div>
            </div>
            <div class="chart_bottom_layer">
                <p-chart type="bar" 
                [data]="barBasicData" 
                [options]="barBasicOptions" height="250"></p-chart>
            </div>
        </div>
    </div>
    <div class="pages_device">
        <div class="pages_views">
            <div class="heading_layer">
                <div class="pages_title">Pages</div>
                <div class="other_title">Views</div>
                <div class="other_title">Scrolls</div>
                <div class="other_title">Unique visits</div>
                <div class="other_title">Sessions</div>
            </div>
            <div class="hr_line"></div>
            <div class="pages_results">
                <ng-container *ngFor="let item of pagesViews">
                    <div class="bottom_layer">
                        <div class="pages_title">{{item.pageTitle | titlecase}}</div>
                        <div class="other_title">{{item.views}}</div>
                        <div class="other_title">{{item.scrolls}}</div>
                        <div class="other_title">{{item.uniqueVisitors}}</div>
                        <div class="other_title">{{item.sessions}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="device_views">
            <div class="heading_layer">
                <div class="divice_title">Device</div>
                <div class="other_title">Views</div>
            </div>
            <div class="hr_line"></div>
            <div class="page_results">
                <ng-container *ngFor="let item of deviceAnalytics">
                    <div class="bottom_layer">
                        <div class="divice_title">{{item.device | titlecase}}</div>
                        <div class="other_title">{{item.views}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>