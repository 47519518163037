import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { TemplateService } from './../../services/template.service';
import { MapsAPILoader } from '@agm/core';
import * as mapboxgl from 'mapbox-gl';
import { GeoJson } from 'src/app/constants/map';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/services/map.service';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { DbService } from 'src/app/services/db-service/db.service';

@Component({
  selector: 'app-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: [
    './business-type.component.scss',
    './mq-business-type.component.scss',
  ],
})
export class BusinessTypeComponent implements OnInit {
  myControl = new FormControl();
  searchBox = new FormControl();
  filteredOptions: Observable<string[]> | any;
  searchResults: Observable<string[]> | any;
  seeSuggestionsScreen: boolean = false;
  bType = '';
  subCategory = '';
  allTypes: any = [];
  allSubTypes: any = [];
  locations: any = [];
  indexValue: any = 0;
  // icon :any = {
  //   url: './../../../assets/images/map/map-pin.png',
  //   scaledSize: { height: 45, width: 30 },
  // };

  constructor(
    private router: Router,
    private webService: TemplateService,
    private snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private _mapservice: MapService,
    private _dbService: DbService
  ) {
    window.localStorage.setItem('prevUrl', this.router.url);
  }
  latitude: any;
  longitude: any;
  zoom: any;

  //default settings
  map: mapboxgl.Map | any;
  style = 'mapbox://styles/mapbox/light-v11?optimize=true';
  lat: number = 0;
  lng: number = 0;

  //mapbox data
  source: any;
  markers: any;
  showAddress = '';

  ngOnInit(): void {
    // ////console.log(navigator.geolocation.getCurrentPosition)
    this.gettingCurrentLocation();
    this.getAllTypes();
    this.getScreenSize();
  }

  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  gettingCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((positions) => {
        // ////console.log(positions.coords.latitude,positions.coords.longitude);
        this.latitude = positions.coords.latitude;
        this.longitude = positions.coords.longitude;
        // window.localStorage.setItem('lat',this.latitude);
        this._dbService.setLat(this.latitude);
        this._dbService.setLng(this.longitude);
        // window.localStorage.setItem('lng',this.longitude);
        setTimeout(() => {
          this.map.flyTo({
            center: [this.longitude, this.latitude],
          });
        }, 0);
        this.buildMap();
      });
    }
  }
  isAtStart = true;
  start = {
    center: [80, 36],
    zoom: 1,
    pitch: 0,
    bearing: 0,
  };
  end = {
    center: [8.11862, 46.58842],
    zoom: 12.5,
    bearing: 130,
    pitch: 75,
  };
  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 12,
      pitch: 0,
      bearing: 0,
      // center: [this.longitude, this.latitude],
      accessToken: environment.mapbox.accessToken,
    });
    const target = this.isAtStart ? this.end : this.start;
    this.isAtStart = !this.isAtStart;
    this.map.flyTo({
      ...target, // Fly to the selected target
      duration: 1200, // Animate over 12 seconds
      essential: true,
    });
    new mapboxgl.Marker({ color: '#0267c1' })
      .setLngLat([this.longitude, this.latitude])
      .addTo(this.map);
    // const geocoder = new MapboxGeocoder({
    //   accessToken: environment.mapbox.accessToken,
    //   mapboxgl: mapboxgl,
    //   zoom: 20, // Specify the zoom level for geocoding results
    // });
    // this.map.addControl(geocoder);

    //add marker on click
    this._mapservice.getAddress(this.latitude, this.longitude).subscribe(
      (res: any) => {
        ////console.log(res.features[0].text, res.features[1].text.split(' '));
        //console.log(res);
        this.showAddress = `${res.features[0].text}, ${res.features[1].text}`;
        // window.localStorage.setItem('locality',res.features[0].text);
        this._dbService.setLocality(res.features[0].text);
        this._dbService.setState(res.features[1].text);
        this._dbService.setShortCode(res.features[2].properties.short_code);
        // window.localStorage.setItem('state',res.features[1].text);
      },
      (err) => {
        ////console.log(err);
      }
    );
    // const geocoder = new MapboxGeocoder({
    //   accessToken:environment.mapbox.accessToken
    // });
  }

  flyTo(data: GeoJson) {
    this.map.flyTo({
      center: data.geometry.coordinates,
    });
  }

  getAllTypes() {
    this.webService.mergedIndustrySubIndustry().subscribe(
      (res: any) => {
        //console.log(res.data);
        this.filteredOptions = this.myControl;
        // this.allTypes = res.data;
        res.data.forEach((data: any) => {
          this.allTypes.push({
            name: data.parent.name + ' - ' + data.name,
            industryId: data.parent.id,
            subIndustryId: data.id,
            industryName: data.parent.name,
            subIndustryName: data.name,
          });
        });
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
      (err) => {
        // ////console.log(err)
        this.snackBar.open(`${err.error.message}`, 'Close');
      }
    );
  }
  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.allTypes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  x: any = 3;
  suggestions: any = [];
  seeSuggestions() {
    this.seeSuggestionsScreen = true;
    let n = this.allTypes.length;
    this.x = this.x % n;
    let first_x_elements: any = this.allTypes.slice(0, this.x);
    let remaining_elements: any = this.allTypes.slice(this.x, n);
    this.allTypes = [...remaining_elements, ...first_x_elements];
    this.allTypes.forEach((element: any, i: any) => {
      if (i < 3) {
        this.suggestions.push(element);
      }
    });
  }

  seeMore(ev: any) {
    this.suggestions = [];
    let n = this.allTypes.length;
    this.x = this.x % n;
    let first_x_elements = this.allTypes.slice(0, this.x);
    let remaining_elements = this.allTypes.slice(this.x, n);
    this.allTypes = [...remaining_elements, ...first_x_elements];
    this.allTypes.forEach((element: any, i: any) => {
      if (i < 3) {
        this.suggestions.push(element);
      }
    });
  }
  goBack() {
    this.seeSuggestionsScreen = false;
    this.suggestions = [];
  }
  goTobName() {
    this.router.navigate(['/business-name']);
    // window.localStorage.setItem('businessType', this.bType);
    // window.localStorage.setItem('subCategory',this.subCategory);
    // //console.log(this.bType)
  }

  subCategoryId:any="";
  selectBusinessType(ev: any) {
    //console.log(ev);
    this.bType = ev.name;
    //console.log("byType",this.bType);
    
    // window.localStorage.setItem('bcid',ev.industryId)
    this._dbService.setBusinessCategoryId(ev.industryId);
    // window.localStorage.setItem('busniessType',ev.industryName);
    this._dbService.setBusinessCategoryName(ev.industryName);
    // window.localStorage.setItem('subCategory',ev.subIndustryId);
   this._dbService.setSubCategoryId(ev.subIndustryId);
   this.subCategoryId= this._dbService.getSubCategoryId();
    this._dbService.setSubCategoryName(ev.subIndustryName);
    // window.localStorage.setItem('bcid', ev.id);
    // this.bType = ev.name;
    // this.webService.getSubIndustryTypes(ev.id).subscribe(
    //   (res: any) => {
    //     this.allSubTypes = res.data;
    //     //console.log(this.allSubTypes);
    //     // this.filteredOptions = this.myControl.valueChanges.pipe(
    //     //   startWith(''),
    //     //   map((value) => this._filter(value))
    //     // );
    //   },
    //   (err) => {
    //     // ////console.log(err)
    //     this.snackBar.open(`${err.error.message}`, 'Close');
    //   }
    // );
  }

 async checkConditions(){
    let checkEnable=false;
   await this.allTypes.forEach((element:any) => {
      if(this.bType==element.name){
        checkEnable=true;
      }
    });
    if(checkEnable){
      return false;
    }
    else{
      return true;
    }
  }

  //mapbox locations

  changeLocationbox: boolean = false;
  changeLocation() {
    this.changeLocationbox = !this.changeLocationbox;
  }
  close() {
    this.changeLocationbox = false;
  }
  searchLocation = '';
  selectedLocation: any;
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        res.features.forEach((location: any) => {
          locations.push({
            displayName:
              location?.place_name.split(', ')[0] +
              ',' +
              location?.context[0]?.text +
              ',' +
              location?.context[2]?.text,
            latlng: location?.center,
          });
        });
        this.locations = locations;
        this.searchResults = this.searchBox.valueChanges.pipe(
          startWith(''),
          map((value) => this._searchFilter(value))
        );
        // ////console.log(this.locations);
      },
      (err) => {
        ////console.log(err);
      }
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    this.selectedLocation = option;
  }

  saveLocation() {
    this.changeLocationbox = false;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    this.showAddress = splitedLocation[0] + ',' + splitedLocation[1];
    this.map.flyTo({
      center: [
        this.selectedLocation.latlng[0],
        this.selectedLocation.latlng[1],
      ],
    });
    new mapboxgl.Marker({ color: '#0267c1' })
      .setLngLat([
        this.selectedLocation.latlng[0],
        this.selectedLocation.latlng[1],
      ])
      .addTo(this.map);
    // window.localStorage.setItem('locality',splitedLocation[0]);
    this._dbService.setLocality(splitedLocation[0]);
    this._dbService.setState(splitedLocation[1]);
    // window.localStorage.setItem('state',splitedLocation[1]);
  }


  getVal(ev){
    // let a = document.querySelector('input');
    // //console.log(a?.value);
    // //console.log(ev.length)
    if(ev.length === 0){
      this.bType = '';
      this._dbService.setSubCategoryId(null);
      this._dbService.setBusinessCategoryName(null);
    }

  }
}
