import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-stats-text-side-partition',
  templateUrl: './stats-text-side-partition.component.html',
  styleUrls: ['./preview-stats-text-side-partition.component.scss']
})
export class PreviewStatsTextSidePartitionComponent {
  templateData : any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Numbers are telling our story",
        type : 'text', field : 'text',
        style : {'font-weight':900}
      },
      {
        value : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value : "2M+",
        type : 'text', field : 'text',
        style : {'font-weight':700}
      },
      {
        value:"46K+",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value:"99%",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value :"Tickets Delivered This Month",
        type : 'text', 
        field : 'text',
        style : {'color':'#959393'}
      },
      {
        value:"Active Customers Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Customer Satisfaction Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats4Component",
  }
  data = this.templateData.data;
  heading = this.data[0];
  subheading =  this.data[1];
  statsHeading1 = this.data[2];
  statsHeading2 = this.data[3]
  statsHeading3 = this.data[4]
  statsText1 = this.data[5];
  statsText2 = this.data[6]
  statsText3 = this.data[7]

}
