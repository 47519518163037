import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-stats-with-text',
  templateUrl: './stats-with-text.component.html',
  styleUrls: ['./preview-stats-with-text.component.scss']
})
export class PreviewStatsWithTextComponent {
  templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Unleash the full power of data",
        type : 'text', field: 'text',
        style : {'font-weight':900}
      },
      {
        value : "Everything you need to convert, engage, and retain more users",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value : "40+",
        type : 'text', field : 'text',
        style : {'color':'#A084DC','font-weight':700}
      },
      {
        value:"600%",
        type:"text",
        field:"text",
        style:{'color':'#A084DC','font-weight':700}
      },
      {
        value:"4k+",
        type:"text",
        field:"text",
        style:{'color':'#A084DC','font-weight':700}
      },
      {
        value : "Integrations",
        type : 'text', field : 'text',
        style : {'font-weight':600}
      },
      {
        value:"Return on Investment",
        type:"text",
        field:"text",
        style:{'font-weight':600}
      },
      {
        value:"Global customers",
        type:"text",
        field:"text",
        style:{'font-weight':600}
      },
      {
        imgUrl :"./../../../../../../../../assets/images/stats/bolt-icon.png",
        display : true, type :'image', field :'image',
        style : {}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats2Component",
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subheading = this.data[1];
  stats1Heading = this.data[2];
  stats2Heading = this.data[3]
  stats3Heading = this.data[4]
  stats1Text = this.data[5];
  stats2Text = this.data[6]
  stats3Text = this.data[7]
  image = this.data[8];

}
