import { EventEmitter, HostListener, Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebisiteService {
  constructor(
    private http: HttpClient,
  ) { 
    this.getScreenSize();
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  updateWebsiteStatus(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/status?businessId=${data.bId}&status=${data.status}`,data
    )
  }
  updateWebsiteUrl(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/url`,data
    )
  }

  updateSEOTagsFromSpecificWebsite(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/seo`,data
    )
  }

  linkExistingDomain(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/url/domain`,data
    )
  }



  //search domain from godaddy
  getAllDomains(data:any){
    return this.http.get(
      environment.baseUrl+`domain/suggest?text=${data}`
    )
  }

  //crm contact leads
  getContactLeads(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/contact?businessId=${businessId}&size=1000&page=0`
    )
  }

  typeText(data:any,templateData:any){
    // data.style = {
    //   "color":templateData.textColor,
    //   "font-family":templateData.fontFamily,
    // }
    // console.log(templateData);
    if(this.screenWidth > 475){
      if(data.textType === 'heading'){
        data.style = {
          "color":templateData.colors.txtHeadingColor,
          "font-family":templateData.headingFontFamily,
          "font-size":"28px",
          "font-weight":"bold",
          "line-height":"40px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'description'){
        data.style = {
          "color":templateData.colors.txtDescColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"16px",
          "font-weight":"500",
          "line-height":"26px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'text'){
        data.style = {
          "color":templateData.colors.txtNormalColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"18px",
          "font-weight":"700",
          "line-height":"28px",
          "letter-spacing":"0px"
        }
      }
      return data;
    }
    else{
      if(data.textType === 'heading'){
        data.style = {
          "color":templateData.colors.txtHeadingColor,
          "font-family":templateData.headingFontFamily,
          "font-size":"20px",
          "font-weight":"bold",
          "line-height":"40px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'description'){
        data.style = {
          "color":templateData.colors.txtDescColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"14px",
          "font-weight":"500",
          "line-height":"26px",
          "letter-spacing":"0px"
        }
      }
      else if(data.textType === 'text'){
        data.style = {
          "color":templateData.colors.txtNormalColor,
          "font-family":templateData.bodyFontFamily,
          "font-size":"16px",
          "font-weight":"700",
          "line-height":"28px",
          "letter-spacing":"0px"
        }
      }
      return data;
    }
  }


  typeButton(data:any,templateData:any){
    if(this.screenWidth > 475){
      if(templateData.buttonStyle === "SOLID"){
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":"none",
            "padding":"8px 15px",
            "border":"none",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
        else{
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 15px",
            "border":"none",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      else{
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border":`2px solid ${templateData.colors.btnColor}`,
            "padding":"8px 15px",
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
            // "border-radius":"none"
          }
        }
        else{
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 15px",
            "border":`2px solid ${templateData.colors.btnColor}`,
            "height":"44px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      // if(templateData.buttonStyle === "OUT_LINE"){
      //   data.style = {
      //     "background-color":"transparent",
      //     "color":templateData.colors.btnColor,
      //     "font-family":templateData.headingFontFamily,
      //     "border":`2px solid ${templateData.colors.btnColor}`,
      //     "padding":"8px 15px",
      //     "height":"44px",
      //     "font-size":"14px",
      //     "font-weight":"700",
      //     "letter-spacing":"0px"
      //     // "border-radius":"none"
      //   }
      // }
      return data;
    }
    else{
      if(templateData.buttonStyle === "SOLID"){
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":"none",
            "padding":"8px 14px",
            "border":"none",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
        else{
          data.style = {
            "background-color":templateData.colors.btnColor,
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 14px",
            "border":"none",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      else{
        if(templateData.buttonShape === "SHARP"){
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnTextColor,
            "font-family":templateData.headingFontFamily,
            "border":`2px solid ${templateData.colors.btnColor}`,
            "padding":"8px 14px",
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
            // "border-radius":"none"
          }
        }
        else{
          data.style = {
            "background-color":"transparent",
            "color":templateData.colors.btnColor,
            "font-family":templateData.headingFontFamily,
            "border-radius":`10px`,
            "padding":"8px 14px",
            "border":`2px solid ${templateData.colors.btnColor}`,
            "height":"36px",
            "font-size":"14px",
            "font-weight":"700",
            "letter-spacing":"0px"
          }
        }
      }
      if(templateData.buttonStyle === "OUT_LINE"){
        data.style = {
          "background-color":"transparent",
          "color":templateData.colors.btnColor,
          "font-family":templateData.headingFontFamily,
          "border":`2px solid ${templateData.colors.btnColor}`,
          "padding":"8px 14px",
          "height":"36px",
          "font-size":"14px",
          "font-weight":"700",
          "letter-spacing":"0px"
          // "border-radius":"none"
        }
      }
      return data;
    }
  }

  getCountries(pageNo:any,size:any,text:any){
    return this.http.get(
      environment.baseUrl+`admin/master/location/country?size=${size}&page=${pageNo}&text=${text}&active=true`
    )
  }
}
