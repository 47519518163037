import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss', './mq-analytics.component.scss']
})
export class AnalyticsComponent implements OnInit{

  selectedDaysCount = '';

  constructor(){
    this.selectedDaysCount = this.dropdownValues[0].daysCount;
  }

  dropdownValues = [
    {
      displayValue:'Last 15 days',
      daysCount : 'Jan 30, 2023 - Feb 13, 2023'
    },
    {
      displayValue:'Last 7 days',
      daysCount:'Jan 30, 2023 - Feb 05, 2023'
    }
  ]

  ngOnInit(): void {
  }

  visitors = [
    {
      boxTitle:'Unique Visitor',
      count:'3.4k',
      increased:true,
      increasedBy:'36'
    },
    {
      boxTitle:'Page Views',
      count:'8.4k',
      increased:false,
      increasedBy:'14'
    },
    {
      boxTitle:'Sessions',
      count:'3.7k',
      increased:true,
      increasedBy:'17'
    },
    {
      boxTitle:'Bounce Rate',
      count:'3.2',
      increased:true,
      increasedBy:'9.4'
    }
  ];

  pagesViews = [
    {
      pageTitle:'home',
      views:'10',
      scrolls:'7',
      uniqueVisitors:'6',
      sessions:'5'
    },
    {
      pageTitle:'about us',
      views:'10',
      scrolls:'7',
      uniqueVisitors:'6',
      sessions:'5'
    }
  ]
  deviceAnalytics = [
    {
      device:'Desktop',
      views:'10'
    },
    {
      device:'mobile',
      views:'10'
    }
  ]

  //graph chart
  linData = {
    labels: [
      "Feb 1",
      "Feb 2",
      "Feb 3",
      "Feb 4",
      "Feb 5",
      "Feb 6",
      "Feb 7",
      "Feb 8",
      "Feb 9",
      "Feb 10",
      "Feb 11",
      "Feb 12"
    ],
    datasets: [
      {
        label: "Regular Visits",
        data: [2,4,55,3,4,54,66,15,19,20,4,100],
        fill: false,
        backgroundColor: "#0267C1",
        borderColor:'#0267C1'
      },
      {
        label: "Unique Visitor's",
        data: [4,8,12,6,8,4,24,30,38,40,8,150],
        fill: false,
        backgroundColor: "#F79824",
        borderColor: '#F79824'
      }
    ]
  };

  LineOptions = {
    maintainAspectRatio: false,
    scales: {
      // yAxes: [
      //   {
      //     display: true,
      //     position: 'left',
      //     type: "linear",
      //       scaleLabel: {
      //           display: true,
      //           labelString: 'USD',
      //           beginAtZero: true,
      //       },
      //     //yAxisID: "id1"
      //     id: "id1" // incorrect property name.
      //   },
      //   {
      //     scaleLabel: {
      //               display: true,
      //               labelString: 'Commissions',
      //               beginAtZero: true,
      //           },
      //          //display: false,
      //          display: true, // Hopefully don't have to explain this one.
      //         //  type: "linear",
      //          position:"right",
      //          gridLines: {
      //              display: false
      //          },
      //          //yAxisID: "id2"
      //          id: "id2" // incorrect property name.
      //   }
      // ]
    }
  };


  barBasicData = {
    labels: ['04', '05', '06', '07', '08', '09', '10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
    datasets: [
        {
            backgroundColor: '#0267C1',
            label:'hourly Report',
            data: ['04', '05', '06', '07', '08', '09', '10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']
        },

    ]
};

barBasicOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#black'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#black'
            },
            grid: {
                color: 'rgba(255,255,255,0.2)'
            }
        },
        y: {
            ticks: {
                color: '#black'
            },
            grid: {
                color: 'rgba(255,255,255,0.2)'
            }
        }
    }
};
  

}
