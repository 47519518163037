<section class="main_section">
    <div class="main-heading" [ngStyle]="data[1].style?data[1].style:{}">
        {{data[1].value}}
    </div>
    <div class="sub-heading" [ngStyle]="data[2].style?data[2].style:{}">
        {{data[2].value}}
    </div>

    <div class="photos-section">
        <div class="left-side" *ngIf="screenWidth > 475">
            <div>
                <img src="{{data[0].imgUrl}}" alt="" [ngStyle]="data[0].style?data[0].style:{}" class="first-img" />
            </div>
            <div class="left-side-heading" [ngStyle]="data[3].style?data[3].style:{}">
                {{data[3].value}}
            </div>
            <div class="left-side-sub-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="learn-more-btn" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>

        <div class="left-photos-section" *ngIf="screenWidth < 475">
            <div>
                <img src="{{data[6].imgUrl}}" alt="" [ngStyle]="data[6].style?data[6].style:{}" class="first-img" />
            </div>
            <div class="left-side-heading" [ngStyle]="data[3].style?data[3].style:{}">
                {{data[3].value}}
            </div>
            <div class="left-side-sub-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="learn-more-btn" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="right-photos-section" *ngIf="screenWidth < 475">
            <div>
                <img src="{{data[7].imgUrl}}" alt="" [ngStyle]="data[7].style?data[7].style:{}" class="first-img" />
            </div>
            <div>
                <img src="{{data[8].imgUrl}}" alt="" [ngStyle]="data[8].style?data[8].style:{}" class="first-img" />
            </div>
            <div>
                <img src="{{data[9].imgUrl}}" alt="" [ngStyle]="data[9].style?data[9].style:{}" class="first-img" />
            </div>
            <div>
                <img src="{{data[10].imgUrl}}" alt="" [ngStyle]="data[10].style?data[10].style:{}" class="first-img" />
            </div>
        </div>
    </div>
</section>