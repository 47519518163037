<div class="bgContainer">
    <div class="rowContainer">
        <div class="leftSection">
            <span><img src="{{data[0].imgUrl}}" alt="" [ngStyle]="data[0].style?data[0].style:{}" class="footer-img"></span>
            <span [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value | titlecase}}</span>
        </div>
        <div class="middleSection" [ngStyle]="data[2].style?data[2].style:{}">{{data[2].value | titlecase}}</div>
        <!-- <div class="rightSection">
            <span [ngStyle]="termsConditions.style?termsConditions.style:{}">{{termsConditions.value}}</span>
            <span [ngStyle]="privacyPolicy.style?privacyPolicy.style:{}">{{privacyPolicy.value}}</span>
        </div> -->
    </div>
</div>