<nav
  class="navbar navbar-expand-lg navbar-light bg-light"
  [ngStyle]="
    data?.header?.position.fixedPosition ? { position: 'sticky', top: '0' } : {}
  "
>
  <div class="container-fluid">
    <a
      onclick="return false;"
      class="navbar-brand"
      *ngIf="data?.header?.logo.text"
      id="header_logo"
      [ngStyle]="navId === 'header_logo' ? data.header.logo.textStyles : {}"
      >{{ data?.header?.logo?.imageText }}</a
    >
    <a
      onclick="return false;"
      class="navbar-brand"
      *ngIf="data?.header?.logo.image"
      id="header_logo"
      [ngStyle]="navId === 'header_logo' ? { border: '2px solid #F79824' } : {}"
    >
      <img [src]="data?.header?.logo.imageUrl" alt="" id="logo_image" />
    </a>
    <a onclick="return false;" class="navbar-brand"></a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul
        class="navbar-nav me-auto mb-2 mb-lg-0"
        id="header_navigations"
        [ngStyle]="
          navId === 'header_navigations'
            ? data.header.navigations.textStyles
            : {}
        "
      >
        <ng-container *ngFor="let item of data.header?.navigations.tabs">
          <li class="nav-item" *ngIf="item.visible">
            <a
              onclick="return false;"
              class="nav-link active"
              aria-current="page"
              [href]="item.route"
              [id]="item.id"
              >{{ item.name }}</a
            >
          </li>
        </ng-container>

        <!-- <li class="nav-item">
          <a onclick="return false;"class="nav-link" href="#" id="text_2">Projects</a>
        </li>
        <li class="nav-item dropdown">
          <a onclick="return false;" class="nav-link dropdown-toggle" href="#" id="navbarDropdown text_3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Services
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">Service 1</a>
            <a class="dropdown-item" href="#">Service 2</a>
            <a class="dropdown-item" href="#">Service 3</a>
          </div>
        </li>
        <li class="nav-item">
          <a onclick="return false;"class="nav-link" id="text_4">Contact us</a>
        </li>
        <li class="nav-item">
          <a onclick="return false;"class="nav-link" id="text_5">Blogs</a>
        </li> -->
      </ul>
    </div>
    <form
      class="d-flex"
      id="header_searchBar"
      [ngStyle]="
        navId === 'header_contact' ? { border: '2px solid #F79824' } : {}
      "
      *ngIf="data.header?.contact.display"
    >
      <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"> -->
      <button
        class="btn"
        type="submit"
        id="btn_1"
        style="display: flex; width: 215px"
      >
        <mat-icon> phone</mat-icon> &nbsp;{{
          data.header.contact.contactDetails
        }}
      </button>
    </form>
    <div *ngIf="data?.header?.contactNow?.display" class="contactbtn-changes">
      <button
        type="button"
        class="contact-button"
        (click)="headerBtnEvent(data.header.contactBtn)"
      >
        {{ data.header.contactBtn.text }}
      </button>
    </div>
  </div>
</nav>
