<div class="bgContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="mainContainer">
        <div class="headContainer">
            <div [ngStyle]="data[0].style?data[0].style:{}" class="heading">
                {{data[0].value | titlecase}}
            </div>
            <!-- <div [ngStyle]="data[1].style?data[1].style:{}" class="description">
                {{data[1].value}}
            </div> -->
        </div>
        <div class="rowFlexContainer">
            <div class="contentStyle firstBox">
                <div>
                <img [ngStyle]="data[2].style?data[2].style:{}" src="https://dev-beeos.s3.amazonaws.com/prod-images/444397c1683718437398flash%201.png" alt="" class="firstBoxImage">
                </div>
                <div [ngStyle]="data[3].style?data[3].style:{}" class="imageDescription">{{data[3].value}}</div>
            </div>
            <div class="contentStyle secondBox">
                <div>
                <img [ngStyle]="data[4].style?data[4].style:{}" src="https://dev-beeos.s3.amazonaws.com/prod-images/444397c1683718437398flash%201.png" alt="" class="secondBoxImage">
                </div>
                <div [ngStyle]="data[5].style?data[5].style:{}" class="imageDescription">{{data[5].value}}</div>
            </div>
            <div class="contentStyle thirdBox">
                <div>
                <img [ngStyle]="data[6].style?data[6].style:{}" src="https://dev-beeos.s3.amazonaws.com/prod-images/444397c1683718437398flash%201.png" alt="" class="thirdBoxImage">
                </div>
                <div [ngStyle]="data[7].style?data[7].style:{}" class="imageDescription">{{data[7].value}}</div>
            </div>
            <!-- <div class="contentStyle thirdBox">
                <img [ngStyle]="data[8].style?data[6].style:{}" src="https://w7.pngwing.com/pngs/950/279/png-transparent-electricity-electric-power-electrical-energy-computer-icons-power-socket-angle-electronics-text-thumbnail.png" alt="">
                <div [ngStyle]="data[9].style?data[7].style:{}" class="imageDescription">{{data[9].value}}</div>
            </div> -->
            <!-- <div class="contentStyle fourthBox">
                <img [ngStyle]="image4.style?image4.style:{}" src="{{image4.imgUrl}}" alt="">
                <div [ngStyle]="image4Description.style?image4Description.style:{}" class="imageDescription">{{image4Description.value}}</div>
            </div> -->
        </div>
    </div>
</div>