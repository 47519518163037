<div class="header-section">
    <div class="left-header">
        <span><img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow"
                (click)="back()"></span><span class="back-text" (click)="back()">Blogs</span>
    </div>
    <div class="right-header">
        <button type="button" (click)="showEditorData()" *ngIf="edit==false;else editcheck">Publish</button>
        <ng-template #editcheck>
            <button type="button" (click)="editBlog()">Save</button>
        </ng-template>
    </div>
</div>
<div class="configuration-tab">
    <div class="setting-icon">
        <img src="./../../../../../assets/images/blogs/settings.svg" (click)="filterBtn($event)" />
    </div>
</div>
<!-- editorjs-block -->
<div #editor class="editorjs-block" (keyup)="wordCounter()" #text></div>