import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import EditorJS from '@editorjs/editorjs';
const Header = require('@editorjs/header');
import { BlogServiceService } from '../blog-service.service';
import ImageTool from '@editorjs/image';
import Marker from '@editorjs/marker';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import { table } from 'console';
import * as AWS from 'aws-sdk';
const Delimiter = require('@editorjs/delimiter');
const InlineCode = require('@editorjs/inline-code');
const ColorPlugin = require('editorjs-text-color-plugin');
import { OutputData } from '@editorjs/editorjs';
import { environment } from 'src/environments/environment';
import { MetaTagsPopupComponent } from '../meta-tags-popup/meta-tags-popup.component';

@Component({
  selector: 'app-create-blogs',
  templateUrl: './create-blogs.component.html',
  styleUrls: ['./create-blogs.component.scss']
})
export class CreateBlogsComponent {
  @ViewChild('editor', { read: ElementRef, static: true }) editorElement:
    | ElementRef
    | undefined;
  previousData: OutputData | undefined;
  @ViewChild("text") text: ElementRef | undefined;
  editor: EditorJS | undefined;
  constructor(
    private blogService: BlogServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.subscribeQueryPrams();
  }
  ngOnInit(): void { }

  edit: boolean = false;
  subscribeQueryPrams() {
    this.route.queryParams.subscribe((res: any) => {
      this.edit = res.edit;
      if (this.edit == undefined) {
        this.edit = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.showApiData();
  }

  private intialzedEditor(): void {
    this.editor = new EditorJS({
      // minHeight:200,
      holder: this.editorElement?.nativeElement,
      tools: {
        delimiter: Delimiter,
        header: {
          class: Header,
          shortcut: 'CMD+SHIFT+H',
          inlineToolbar: ['link', 'bold', 'italic'],
        },
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: [
              '#EC7878',
              '#9C27B0',
              '#673AB7',
              '#3F51B5',
              '#0070FF',
              '#03A9F4',
              '#00BCD4',
              '#4CAF50',
              '#8BC34A',
              '#CDDC39',
              '#FFF',
            ],
            defaultColor: '#FF1300',
            type: 'text',
          },
        },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
          },
        },
        // Marker :{
        //   class : Marker,
        //   shortcut : 'CMD+SHIFT+M'
        // },
        list: {
          class: List,
          inlineToolbar: ['link', 'bold', 'italic'],
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        image: {
          class: ImageTool,
          config: {
            data: {
              withBorder: false,
              stretched: false,
              withBackground: false,
            },
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              uploadByFile(file: any) {
                // your own uploading logic here
                const AWSService = AWS;
                const imageEnvCognito =
                  environment.componentImageUploading.CredentialsProvider.CognitoIdentity.Default;
                const imageEnvUtility =
                  environment.componentImageUploading.S3TransferUtility.Default;
                const region = imageEnvUtility.Region;
                const bucketName = imageEnvUtility.Bucket;
                const IdentityPoolId = imageEnvCognito.PoolId;
                // ? Configures the AWS service and initial authorization
                AWSService.config.update({
                  region: region,
                  credentials: new AWSService.CognitoIdentityCredentials({
                    IdentityPoolId: IdentityPoolId,
                  }),
                });
                const s3 = new AWSService.S3({
                  apiVersion: '2012-07-10',
                  params: {
                    Bucket: bucketName,
                  },
                });

                // const element = e.srcElement.files[0];
                // var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
                let imgPromise = new Promise((res, rej) => {
                  if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      s3.upload(
                        {
                          Key:
                            'images/' +
                            Math.floor(100000 + Math.random() * 900000) +
                            'c' +
                            new Date().getTime() +
                            file.name,
                          Bucket: bucketName,
                          Body: file,
                          ACL: 'public-read',
                        },
                        // options,
                        function (err: any, data: any) {
                          if (err) {
                            rej(err);
                          } else {
                            res(data);
                          }
                        }
                      );
                    };
                  }
                });

                return imgPromise
                  .then((data: any) => {
                    // this.imageObject = data;
                    //console.log('awsResult', data);
                    return {
                      success: 1,
                      file: {
                        url: data.Location,
                        height: '500px',
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    };
                  })
                  .catch((err) => {
                    //console.log(err);
                    return err;
                  });
              },
            },
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
      data: this.previousData,
      defaultBlock: 'header',
      // autofocus: true,
      placeholder: 'Begin writing your blog',
      onChange: (api, event) => {
        console.log('Now I know that Editor\'s content changed!', event)
      }
    });
  }
  data: any;
  showEditorData() {
    this.editor
      ?.save()
      .then((data) => {
        this.data = data;
        console.log(this.data);
        this.data.createdBy = window.localStorage.getItem('fname'),

          this.data.businessId = JSON.parse(window.localStorage.getItem('bDetails') || "").id;
          this.data.blogType="MASTER";
          // this.data.subcategoryId.push(window.localStorage.getItem(''))
        this.data.createdTimeStamp = new Date();
        if (this.data.blocks.length > 0) {
          let returnedTarget:any = {};
          //object null and empty check
          if (this.tagsData && Object.keys(this.tagsData).length !== 0) {
            returnedTarget = Object.assign(data, this.tagsData);
          }
          else {
            returnedTarget = data;
          }
          this.getBlocksTextLen(data.blocks);
          let readTime=Math.ceil(this.sumWords/200);
          console.log("readTime",readTime);
          returnedTarget.readTime=readTime;
          returnedTarget.wordCount=this.sumWords;
          returnedTarget.updatedTimeStamp=new Date();
          this.blogService.uploadBlog(returnedTarget).subscribe(
            (res: any) => {
              this.blogService.openSnack(
                'Blog created sucessfully',
                'Close'
              );
              this.router.navigate(['/admin/website/blogs/listing']);
              this.sumWords=0;
              readTime=0;
            },
            (err) => {
              //console.log(err);
              this.sumWords=0;
              readTime=0;
            }
          );
        } else {
          this.blogService.openSnack(
            'Please write something to create Post',
            'Close'
          );
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  @ViewChild('barTransy') barTransy: ElementRef | undefined;


  couldBeCounted(block){
    return 'text' in block.data // it depends on tools you use
  }
  sumWords:number=0;
   getBlocksTextLen(blocks){
    return blocks
      .filter(this.couldBeCounted)
      .reduce((sum, block) => {
        let data=block.data.text
            data=data.replaceAll("<br>","");
            data=data.replaceAll("<b>","");
            data=data.replaceAll("</b>","");
            this.sumWords=this.sumWords+data.split(" ").length;
              console.log("sumWords",this.sumWords);
              
        return sum
      }, 0)
  }



  jsonData: any = '';
  showApiData() {
    if (this.edit) {
      let dataSending = {
        time: 0,
        version: '',
        blocks: [],
      };
      dataSending.time = this.blogService.getBlogData().time;
      dataSending.version = this.blogService.getBlogData().version;
      dataSending.blocks = this.blogService.getBlogData().blocks;
      this.previousData = dataSending;
    } else {
      this.previousData = undefined;
    }
    this.intialzedEditor();
  }

  clickCards(cards: any) {
    //console.log(cards);
    this.router.navigate(['/view-blog'], { queryParams: { blogId: cards.id } });
  }

  back() {
    this.blogService.setBlogData({});
    this.router.navigate(['/admin/website/blogs/listing']);
  }

  editBlog() {
    let returnedTarget = {};
    this.editor
      ?.save()
      .then((data: any) => {
        let blogdata= this.blogService.getBlogData();
        blogdata.blocks=data.blocks;
        // data.id = this.blogService.getBlogData().id;
        // data.createdBy = this.blogService.getBlogData().createdBy;
        // data.createdTimeStamp = this.blogService.getBlogData().createdTimeStamp;
        blogdata.updatedTimeStamp=new Date();
        // data.businessId = JSON.parse(window.localStorage.getItem('bDetails') || "").id;
        //object null and empty check
        // if (this.tagsData && Object.keys(this.tagsData).length !== 0) {
        //   returnedTarget = Object.assign(data, this.tagsData);
        // }
        // else {
        //   returnedTarget = data;
        // }
        console.log('returnesTarget',blogdata);
        this.getBlocksTextLen(data.blocks);
          let readTime=Math.ceil(this.sumWords/200);
          console.log("readTime",readTime);
          blogdata.readTime=readTime;
          blogdata.wordCount=this.sumWords;
        
        this.blogService.updateBlog(blogdata).subscribe({
          next: (res: any) => {
            this.blogService.openSnack(
              'Blog updated sucessfully',
              'Close'
            );
            this.router.navigate(['/user/dashboard/blogs/list-blog']);
            this.sumWords=0;
            readTime=0;
          },
          error: (err: any) => {
            //console.log('error', err);
            this.sumWords=0;
            readTime=0;
          },
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  countwords() {
    //console.log('words');
  }

  words: any;
  wordCount: any;
  wordCounter(){
    // this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    // this.words = this.wordCount ? this.wordCount.length : 0;
    console.log("word count",this.wordCount);
  }

  tagsData: any = {};

  filterBtn(ev: any) {
    ev.stopPropagation();
    let blogModel = this.blogService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      '73vh',
      '300px',
      'relative',
      {
        data: {
          blogData: {
            keyWords: this.blogService.getBlogData()?.keyWords||null,
            description: this.blogService.getBlogData()?.description||null,
            author: this.blogService.getBlogData()?.author||null,
            postTitle: this.blogService.getBlogData()?.postTitle||null,
            blogMeta: {
              metaTitle: this.blogService.getBlogData()?.blogMeta?.metaTitle||null,
              metaDescription: this.blogService.getBlogData()?.blogMeta?.metaDescription||null
            },
            postSummary: this.blogService.getBlogData()?.postSummary||null,
            readTime:this.blogService.getBlogData()?.readTime||null
          }, typeRequest: "addtags",blogType:'business'
        }
      }
    );
    blogModel.afterClosed().subscribe((data: any) => {
      this.tagsData = data?.blogSetting;
      console.log(this.tagsData);  
    let blogData = this.blogService.getBlogData();
    blogData.keyWords=this.tagsData.keyWords;
    blogData.author=this.tagsData.author;
    blogData.postTitle=this.tagsData.postTitle;
    blogData.blogMeta=this.tagsData.blogMeta;
    blogData.postSummary=this.tagsData.postSummary;
    blogData.readTime=this.tagsData.readTime;
    this.blogService.setBlogData(blogData);
    console.log("blogData",blogData);
    
    });
  }
}
