import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-partner1',
  templateUrl: './partner1.component.html',
  styleUrls: ['./partner1.component.css']
})
export class Partner1Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }


  @Input() templateData:any={
    "componentCategory": "Partner",
    "ranking": 0,
    "businessCategoryId": "",
    "businessCategoryName": "All category",
    "data": [
      {
        value:"Popular brands use Landingfolio",
        field:"heading",
        type : "heading",
        style : {'font-size':'13px', 'font-weight':300, 'color':'gray'}
      },
      {
        value: "4.4/5 (14,590 Reviews)",
        field:"text",
        type:"text",
        style :{'font-size':'7px','font-weight':500}
      },

      {
        imgUrl :"./../../../../../../../../assets/images/partner/logo-img.png",
        display : true,
        type : 'image',
        field :'image',
        style :{'width':'70px','height':'35px', 'margin-right':'5px'}
      },
      {
        imgUrl :"./../../../../../../../../assets/images/partner/5-star-icon.png",
        display : true,
        type : 'image',
        field :'image',
        style :{'width':'25px','height':'25px', 'margin-right':'10px'}
      },
    ],
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "partner1component"
}
componentName = this.templateData.componentName;
data =this.templateData.data;
heading = this.data[0];
text1 = this.data[1];
icon1 = this.data[2];
icon2 = this.data[3];

dataAssign(){
  this.templateData.componentContentList.forEach((value:any) => {
    if(value.componentName=='app-partner1'){
      this.componentData=value;
      this.data=value.data;
    }
  });
  this.heading = this.data[0];
  this.text1 = this.data[1];
  this.icon1 = this.data[2];
  this.icon2 = this.data[3];
}

}
