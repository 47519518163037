import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-features-with-image',
  templateUrl: './features-with-image.component.html',
  styleUrls: ['./preview-features-with-image.component.scss']
})
export class PreviewFeaturesWithImageComponent {
  templateData: any = {
    "componentName": "PreviewMultipleFeaturesComponent",
    "data": [
      {
        "value": "Features",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "16px", "color": "#6941C6" }
      },
      {
        "value": "Cutting-edge features for advanced analytics",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "36px", "color": "#101828" }
      },
      {
        "value": "Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 400, "font-size": "20px", "color": "#475467" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/features/phone_desktop_image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "100%" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/features/main-Image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "48px" }
      },
      {
        "value": "Share team inboxes",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "20px", "color": "#101828" }
      },
      {
        "value": "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 400, "font-size": "16px", "color": "#475467" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/features/learn-more.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "35%","margin-top":"4%" }
      },





    ]
  }

  data = this.templateData.data;
}
