import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-footer1',
  templateUrl: './footer1.component.html',
  styleUrls: ['./footer1.component.scss' ,  './mq-footer1.component.scss']
})
export class Footer1Component {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;


  constructor(
    private _dbService : DbService,
    private templatesService:TemplateService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(){
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'footer_simple_social'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      });
    this.dataAssign();
  }
  businessName:any=window.localStorage.getItem('bname');
  // data = [{
  //   "logo" :{
  //     "imageText" : "[Company Name]",
  //     "textStyles":{
  //       "font-size":"24px",
  //       "font-weight":"bold",
  //       "font-family":"Lexend Deca, sans-serif",
  //       "color":"white"
  //     }
  //   },
  //   "shortNote":{
  //     "text":"Constructing world best",
  //     "textStyles":{
  //       "font-size":"16px",
  //       "font-weight":"normal",
  //       "font-family":"Lexend Deca, sans-serif",
  //       "color":"white"
  //     }
  //   },
  //   "className" : "normal"
  // }];
  data : any;


  footer :any;

  dataAssign(){
    // //console.log('footer templateData',this.templateData);
    // this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    // this.footer = this.data[0];
    this.checkingStyles();
    if(this.templateData.values != null){
      this.valuesData = this.templateData.values;
      var bName = this._dbService.getBusinessName();
      this.data[0].value = this.data[0].value.replaceAll("{Company Name}", bName);
      this.data[5].value = this.valuesData.copyRightText.replaceAll("{Company Name}", bName);
      // this.data[0].logo.imageText = this.valuesData.companyName;
      // this.data[0].shortNote.text = this.valuesData.companyDesc;
      // this.data[0].instagramUrl = this.valuesData.instagramUrl;
      // this.data[0].twitterUrl = this.valuesData.twitterUrl;
      // this.data[0].facebookUrl = this.valuesData.facebookUrl;
    }
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }
}
