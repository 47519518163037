import { Component } from '@angular/core';

@Component({
  selector: 'app-colored-herobanner-with-image',
  templateUrl: './colored-herobanner-with-image.component.html',
  styleUrls: ['./colored-herobanner-with-image.component.scss']
})
export class ColoredHerobannerWithImageComponent {

  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      {
        "imgUrl": "./../../../../../../../../assets/images/hero_banner/editor_icon.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "36px"}
      },
      {
        "value": "New feature",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "14px", "color":"#6941C6"}
      },
      {
        "value": "Check out the team dashboard",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "14px", "color":"#6941C6"}
      },
      {
        "value": "High-performing remote teams.The future of work.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "36px", "color":"#42307D"}
      },
      {
        "value": "Powerful, self-serve team engagement tools and analytics. Supercharge your managers & keep employees engaged from anywhere.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "20px", "color":"#6941C6"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/hero_banner/play-circle/svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "36px"}
      },
      {
        "value": "demo",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "18px", "background-color":"#6941C6"}
      },
      {
        "value": "contact-us",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "18px", "background-color":"#FFFFFF"}
      },


      

    ]
  }

  data = this.templateData.data;
}
