import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-teams-cards-profile-circle-images',
  templateUrl: './teams-cards-profile-circle-images.component.html',
  styleUrls: ['./preview-teams-cards-profile-circle-images.component.scss']
})
export class PreviewTeamsCardsProfileCircleImagesComponent {
  templateData: any = {
    "componentCategory": "Team",
    "ranking": 0,
    "businessCategoryId": "",
    "businessCategoryName": "All category",
    "data":
      [
        {
          "value": "We're hiring",
          "field": "heading",
          "type": "heading",
          "style": { 'font-size': '13px', 'font-weight': 600, 'color': 'rgb(160, 132, 220)' },
          "textType":"heading"
        },
        {
          "value": "Meet our team",
          "field": "subtext",
          "type": "subtext",
          "style": { 'font-size': '25px', 'font-weight': 600, },
          "textType":"subtext"
        },
        {
          "value": "Our philosophy is simple -- hire a team of diverse, passionate people and  foster a culture that empowers you to do best work",
          "field": "text",
          "type": "text",
          "style": { 'font-size': '15px', 'font-weight': 500, 'color': '#959393' },
          "textType":"text"
        },
        {
          "value": "About us",
          "field": "button", 
          "type": "button",
          "link": "", display: true,
          "style": { 'font-size': '15px', 'font-weight': 500, },
          "textType":"button"
        },
        {
          "value": "Open position",
          "field": "button", 
          "type": "button",
          "link": "", display: true,
          "style": { 'font-size': '14px', 'font-weight': 500, 'background-color': '#A084DC', 'color': 'white' },
          "textType":"button"
        },

        {
          value:
            [
              {
                "imgUrl": "../../../../../../../../assets/images/testimonials/profile-img.jpg",
                "display": true,
                "type": 'image',
                "field": 'image',
                "style": {  'margin': '12px 0px' },
                "textType":"image"
              },
              {
                "value": "Olivia Rhye",
                "type": "heading",
                "field": "Name",
                "style": { 'font-size': '15px', 'font-weight': 600,  'color': 'black' },
                "textType":"name"
              },

              {
                "value": "Founder & CEO",
                "type": "heading",
                "field": "Designation",
                "style": { 'font-size': '13px', 'font-weight': 500,  'margin-bottom': '18px' },
                "textType":"designation"
              },
              {
                "value": "Former co-founder of opendoor.early staff at spotify and clearbit.",
                "type": "text",
                "field": "Description",
                "style": { 'font-size': '12px', 'font-weight': 500, 'color': '#959393', 'margin-bottom': '5px' },
                "textType":"description"
              }
            ],
          type: 'array',
          field: '',
          style: {}
        },
        {
          value:
            [
              {
                "imgUrl": "../../../../../../../../assets/images/testimonials/profile-img.jpg",
                "display": true,
                "type": 'image',
                "field": 'image',
                "style": { 'margin': '12px 0px' },
                "textType":"image"
              },
              {
                "value": "Phoenix Baker",
                "type": "heading",
                "field": "Name",
                "style": { 'font-size': '15px', 'font-weight': 600,  'color': 'black' },
                "textType":"name"
              },
              {
                "value": "Engineering Manager",
                "type": "heading",
                "field": "Designation",
                "style": { 'font-size': '13px', 'font-weight': 500,  'margin-bottom': '18px' },
                "textType":"designation"
              },
              {
                "value": "Leading engineering team at Figma, Pitch and Protocol.",
                "type": "text",
                "field": "Description",
                "style": { 'font-size': '12px', 'font-weight': 500, 'color': '#959393', 'margin-bottom': '5px' },
                "textType":"description"
              }
            ],
          type: 'array',
          field: '',
          style: {}
        },

      ],
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "team4component"
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subtext = this.data[1];
  text1 = this.data[2];
  button1 = this.data[3];
  button2 = this.data[4];
  text2 = this.data[5];
  text3 = this.data[6];
}
