<div class="mainSection" [ngStyle]="{'background-color': bgColor}">
    <div class="headContainer">
        <div [ngStyle]="data[0].style?data[0].style:{}" class="heading">{{data[0].value | titlecase}}</div>
        <!-- <div class="description" [ngStyle]="description.style?description.style:{}">{{description.value}}</div> -->
    </div>
    <div class="servicesContainer">
        <div class="row">
            <div class="bankingContainer serviceContainerProperties" [ngStyle]="{'background-image': 'url('+data[9].imgUrl+')'}">
                <div class="column">
                    <div [ngStyle]="data[2].style?data[2].style:{}" class="serviceNameProperties">{{data[2].value | titlecase}}</div>
                    <div [ngStyle]="data[3].style?data[3].style:{}" class="exploreMore">{{data[3].value | titlecase}}</div>
                </div>
            </div>
            <div class="insuranceContainer serviceContainerProperties" [ngStyle]="{'background-image': 'url('+data[10].imgUrl+')'}">
                <div class="column">
                    <div [ngStyle]="data[4].style?data[4].style:{}" class="serviceNameProperties">{{data[4].value | titlecase}}</div>
                    <div [ngStyle]="data[12].style?data[12].style:{}" class="exploreMore" >{{data[12].value | titlecase}}</div>
                </div>
            </div>
            <div class="financialServiceContainer serviceContainerProperties" [ngStyle]="{'background-image': 'url('+data[11].imgUrl+')'}">
                <div class="column">
                    <div [ngStyle]="data[5].style?data[5].style:{}" class="serviceNameProperties">{{data[5].value | titlecase}}</div>
                    <div [ngStyle]="data[13].style?data[13].style:{}" class="exploreMore">{{data[13].value | titlecase}}</div>
                </div>
            </div>
            <!-- <div class="healthCareContainer serviceContainerProperties">
                <div class="column">
                    <div [ngStyle]="healthCare.style?healthCare.style:{}" class="serviceNameProperties">{{healthCare.value}}</div>
                    <div [ngStyle]="exploreMore.style?exploreMore.style:{}" class="exploreMore">{{exploreMore.value}}</div>
                </div>
            </div>
            <div class="edTechContainer serviceContainerProperties">
                <div class="column">
                    <div [ngStyle]="edTech.style?edTech.style:{}" class="serviceNameProperties">{{edTech.value}}</div>
                    <div [ngStyle]="exploreMore.style?exploreMore.style:{}" class="exploreMore">{{exploreMore.value}}</div>
                </div>
            </div>
            <div class="othersContainer serviceContainerProperties">
                <div class="column">
                    <div [ngStyle]="others.style?others.style:{}" class="serviceNameProperties">{{others.value}}</div>
                    <div [ngStyle]="exploreMore.style?exploreMore.style:{}" class="exploreMore">{{exploreMore.value}}</div>
                </div>
            </div> -->
        </div>
    </div>
</div>