import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { DbService } from 'src/app/services/db-service/db.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-testimonal-slickcarsouel',
  templateUrl: './testimonal-slickcarsouel.component.html',
  styleUrls: ['./testimonal-slickcarsouel.component.scss' ,  './mq-testimonal-slickcarsouel.component.scss']
})
export class TestimonalSlickcarsouelComponent {
  @Input() templateData:any;
  @Input() color:any;
  valuesData:any;
  componentData: any;
   constructor(
     private _activatedRoute : ActivatedRoute,
     private templatesService:TemplateService,
     private emitterService : EventEmmiterService,
     private cdRef: ChangeDetectorRef,
     private _dbService : DbService,
     private webisiteService:WebisiteService
   ){
   }
  ngAfterViewInit() : void{
    if(this.gettingResponse === 'slick initialized'){
      this.cdRef.detectChanges(); 
    }
  }
  @ViewChild('slickModal') slickModal:any =  SlickCarouselComponent;
  strtButton : boolean = false;
  endButton : boolean = false;
  gettingResponse = '';
  panelOpenState : boolean = false;
  scrollingValue : any;
  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":true,
    "arrows":false,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  mobileslideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":false,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":true,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  loader : boolean = false;
  sectionLoading : boolean = false;

  ngOnInit(): void {
    // this.emitterService.loadingSections.subscribe(
    //   (res) =>{
    //     if(res.lastComponent === 'Home-process-component'){
    //       let a = document.getElementById('testimonial_bg_image_image');
    //       a?.scrollIntoView({behavior:'smooth'})
    //     }
    //     // ////console.log(res)
    //   }
    // )
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'testimonal_slickcarsouel'){
            this.templateData=conCompList;  
            this.dataAssign(); 
          }
          else{}
        }) 
      }
    });
    this.dataAssign(); 
  }
  
  slickInit(e:any) {
    this.gettingResponse = 'slick initialized';
    e.slick.$nextArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.endButton = true
      }
      else{
        this.endButton = false
      }
    });
    e.slick.$prevArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.strtButton = true
      }
      else{
        this.strtButton = false
      }
    });
  }

 

  afterChange(e:any) {
    e.slick.$nextArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.endButton = true
      }
      else{
        this.endButton = false
      }
    });
    e.slick.$prevArrow[0].classList.forEach((element:any) => {
      if(element === 'slick-disabled' || element === 'slick-hidden'){
        this.strtButton = true
      }
      else{
        this.strtButton = false
      }
    });
  }
  testimonials : any;

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }
  stop(){
    // this.slickModal.autoplay = false;
    // this.slickModal.config.autoplay = false;
    ////console.log(this.slickModal);
    this.slickModal.slickPause();
    // this.slickModal.unslick();
  }

  data:any=[];

  backGroundImage:any;
  carsouel1:any;
  carsouel2:any;
  carsouel3:any;
  bgColor : any ;
  dataAssign(){
    // //console.log(this.templateData);
    // this.templateData.componentContentList.forEach((value:any) => {
    //   if(value.componentName=='testimonal-slickcarsouel'){
    //     this.componentData=value;
    //     this.data=value.data;
    //     this.valuesData=value.values;
    //   }
    // });
    // //console.log(this.data);
  // this.valuesData = this.templateData.values;
  this.data = this.templateData.data;
  this.bgColor = this.templateData.bgColor;
  let bName = this._dbService.getBusinessName();
  this.checkingStyles();
  if(this.templateData.values != null){
    this.valuesData = this.templateData.values;
    this.data[0].imgUrl = this.valuesData.bgImg;
    this.data[1].value[0].value = this.valuesData.data[0].text.replaceAll("{Company Name}",bName);
    this.data[1].value[1].value = this.valuesData.data[0].desc.replaceAll("{Company Name}",bName)
    this.data[1].value[2].value = this.valuesData.data[0].reviewedBy;
    this.data[1].value[3].value = this.valuesData.data[0].reviewerDesignation;
    this.data[1].value[4].value = this.valuesData.data[0].reviewerCompany;
    this.data[2].value[0].value = this.valuesData.data[1].text.replaceAll("{Company Name}",bName)
    this.data[2].value[1].value = this.valuesData.data[1].desc.replaceAll("{Company Name}",bName)
    this.data[2].value[2].value = this.valuesData.data[1].reviewedBy;
    this.data[2].value[3].value = this.valuesData.data[1].reviewerDesignation;
    this.data[2].value[4].value = this.valuesData.data[1].reviewerCompany;
    this.data[3].value[0].value = this.valuesData.data[2].text.replaceAll("{Company Name}",bName)
    this.data[3].value[1].value = this.valuesData.data[2].desc.replaceAll("{Company Name}",bName)
    this.data[3].value[2].value = this.valuesData.data[2].reviewedBy;
    this.data[3].value[3].value = this.valuesData.data[2].reviewerDesignation;
    this.data[3].value[4].value = this.valuesData.data[2].reviewerCompany;
  }
  else{

  }
  // this.backGroundImage=this.data[0];
  // this.carsouel1=this.data[1].value;
  // this.carsouel2=this.data[2].value;
  // this.carsouel3=this.data[3].value;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
