<!-- <div class="">
    <img class="bgContainer" src="{{backgroundImage}}" alt="">
</div> -->
<div class="MainContainer" [ngStyle]="{'background-color':bgColor}">
    <div class="leftContainer">
        <div class="headPart" [ngStyle]="data[0].style? data[0].style : {}">{{data[0].value | titlecase}}</div>
        <div class="descriptionPart" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        <div class="inputHolder" >
            <!-- <input type="text" placeholder="Try Java Developer, React etc.,"> -->
            <!-- <button  class="finddeveloper" [ngStyle]="findDeveloperButton.style?findDeveloperButton.style:{}" >{{findDeveloperButton.value}}</button> -->
            <button class="finddeveloper" [ngStyle]="data[6].style?data[6].style:{}" (click)="redirectUrl()">{{data[6].value | titlecase}}</button>
        </div>
        <!-- <mat-form-field class="inputBox" >
            <input matInput placeholder="Try Java Developer, React etc.," class="matInput">
            <button class="searchBtn" mat-button matSuffix>Find a Developer</button>
        </mat-form-field>  -->
        <div class="Downloads" [ngStyle]="data[2].style?data[2].style:{}">{{data[2].value | titlecase}}</div>
        <div class="row-flex">
            <div class="stars">
                <span class="material-icons"> star_rate </span>
                <span class="material-icons"> star_rate </span>
                <span class="material-icons"> star_rate </span>
                <span class="material-icons"> star_rate </span>
                <span class="material-icons"> star_rate </span>
            </div>
            <!-- <div class="rating" [ngStyle]="data[3].style?data[3].style:{}">{{data[3].value}}</div> -->
        </div>
    </div>
    <div class="rightContainer">
        <!-- <img class="rightImage" src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"> -->
        <div class="leftTopImage">
            <img class="imageProperties" src="{{data[5].imgUrl}}" alt="">
        </div>
        <div class="rightBottomImage">
            <img class="imageProperties" src="{{data[4].imgUrl}}" alt="">
        </div>
    </div>
</div>