import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-herobanner-with-review',
  templateUrl: './herobanner-with-review.component.html',
  styleUrls: ['./preview-herobanner-with-review.component.scss']
})
export class PreviewHerobannerWithReviewComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color: any;


  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        //console.log(value)
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'hero_banner_with_review'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  
  // templateData: any = {
  //   'componentCategory': "hero banner",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner4Component",
  //   "data": [
  //     {
  //       "value": "An editor that helps you write clean codes.",
  //       "type": 'heading',
  //       "field": 'text',
  //       "style":{'font-family': 'Arial, Helvetica, sans-serif', 'font-weight': 800}
  //     },
  //     {
  //       "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.",
  //       "type": 'text',
  //       "field": 'text',
  //       "style":{}
  //     },
  //     {
  //       "value": "Best code editor in market!",
  //       "type": "text",
  //       "field": "text",
  //       "style":{'font-weight': 700, 'font-family': 'Arial, Helvetica, sans-serif'}
  //     },
  //     {
  //       "value":"Consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu. aliquam nulla tincidunt gravida.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{' color': '#A8A7A2'}
  //     },
  //     {
  //       "value": "Denny Jones",
  //       "type":"text",
  //       "field":"text",
  //       "style":{'font-weight': 600}
  //     },
  //     {
  //       "value":"Try our free editor",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/profilepic.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "link":"",
  //       "style":{}
  //     },
  //     {
  //       "value":"Features",
  //       "type":"text",
  //       "field":'text',
  //       "style":{}
  //     },
  //     {
  //       "value":"RAREBLOCK",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     },
  //     {
  //       "value":"Try for Free",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/mobile.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "link":"",
  //       "style":{}
  //     },
  //     {
  //       "value":"Pricing",
  //       "type":"text",
  //       "field":'text',
  //       "style":{}
  //     },
  //     {
  //       "value":"Support",
  //       "type":"text",
  //       "field":'text',
  //       "style":{}
  //     },
  //   ]
  // }
  data:any;
  bgColor : any;
  // content = this.templateData.data
  // heading = this.content[0]
  // subtext = this.content[1]
  // feedbackHeading = this.content[2]
  // feedbackContent = this.content[3]
  // personName = this.content[4]
  // freeEditorButton = this.content[5]
  // image = this.content[6]
  // features = this.content[7]
  // leftNav = this.content[8]
  // navbarButton = this.content[9]
  // rightContainerImage = this.content[10]
  // pricing = this.content[11]
  // support = this.content[12]

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title.replaceAll("[Company Name]", bName);
      // this.data[0].value = this.valuesData.title.replaceAll("{City Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("[Company Name]", bName);
      // this.data[1].value = this.valuesData.subTitle.replaceAll("{City Name}", bName);
      // this.data[4].value = this.valuesData.counter1Count;
      // this.data[5].value = this.valuesData.counter1Text;
      // this.data[6].value = this.valuesData.counter2Count;
      // this.data[7].value = this.valuesData.counter2Text;
      // this.data[8].imgUrl = this.valuesData.imageUrl;
      this.data[2].value = this.valuesData.reviewHeader.replaceAll("[Company Name]", bName);
      this.data[3].value = this.valuesData.reviewText.replaceAll("[Company Name]", bName);
      this.data[4].value =  this.valuesData.reviewerName;
      this.data[10].imgUrl = this.valuesData.imageUrl;
      this.data[6].imgUrl = this.valuesData.reviewerImage;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }

  redirectUrl(){
    window.open(`${this.data[5].link}`,'_blank');
  }

}
