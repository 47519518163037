import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar
  ) { }

  setLat(lat:any){
    localStorage.setItem('lat',lat);
  }
  getLat(){
    // const lat = JSON.parse(localStorage.getItem('lat'));
    // if(lat){
    //   return lat
    // }
    // return null;
    return localStorage.getItem('lat')
  }


  setLng(lng:any){
    localStorage.setItem('lng',lng)
  }
  getLng(){
    return localStorage.getItem('lng');
  }

  setShortCode(shrtCode:any){
    localStorage.setItem('shortcode',shrtCode);
  }
  getShortCode(){
    return localStorage.getItem('shortcode')
  }

  setLocality(locality:any){
    localStorage.setItem('locality',locality);
  }
  getLocality(){
    return localStorage.getItem('locality');
  }

  setState(state:any){
    localStorage.setItem('state',state);
  }
  getState(){
    return localStorage.getItem('state');
  }

  setBusinessCategoryId(bcId:any){
    localStorage.setItem('bcid',bcId);
  }
  getBusinessCategoryId(){
    return localStorage.getItem('bcid');
  }

  setBusinessCategoryName(bType:any){
    localStorage.setItem('bcName',bType);
  }
  getBusinessCategoryName(){
    return localStorage.getItem('bcName')
  }

  setSubCategoryId(subCategoryId:any){
    localStorage.setItem('subCategoryId',subCategoryId)
  }
  getSubCategoryId(){
    return localStorage.getItem('subCategoryId')
  }
  setSubCategoryName(subCategoryName:any){
    localStorage.setItem('subCategoryName',subCategoryName)
  }
  getSubCategoryName(){
    return localStorage.getItem('subCategoryName')
  }

  setBusinessName(bName:any){
    localStorage.setItem('bname',bName)
  }
  getBusinessName(){
    return localStorage.getItem('bname')
  }

  setBusinessId(businessId:any){
    localStorage.setItem('businessId',businessId);
  }
  getBusinessId(){
    return localStorage.getItem('businessId');
  }
// staff Data
  setStaffId(staffId:any){
    localStorage.setItem('staffId',staffId);
  }
  getStaffId(){
    return localStorage.getItem('staffId');
  }

  setStaffName(staffName:any){
    localStorage.setItem('staffName',staffName);
  }
  getStaffName(){
    return localStorage.getItem('staffName');
  }
  
  setWebsiteId(webId:any){
    localStorage.setItem('templateId',webId)
  }
  getWebsiteId(){
    return localStorage.getItem('templateId');
  }

  //signup signin page

  setFname(fname:any){
    localStorage.setItem('fname',fname);
  }
  getFname(){
    return localStorage.getItem('fname')
  }

  //payment id storing

  setPaymentId(pId){
    localStorage.setItem('pId',pId);
  }
  getPaymentId(){
    return localStorage.getItem('pId');
  }

  setOrderId(orId){
    localStorage.setItem('orId',orId);
  }
  getOrderId(){
    return localStorage.getItem('orId');
  }

  setOrderPrice(orPrice){
    localStorage.setItem('orP',orPrice);
  }
  getOrderPrice(){
    return localStorage.getItem('orP');
  }

  setAnuallyOrNot(isAnually){
    localStorage.setItem('anually',isAnually);
  }
  getAnuallyOrNot(){
    return localStorage.getItem('anually');
  }
}
