import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsiteRoutingModule } from './website-routing.module';
import { PreviewComponent } from './preview/preview.component';
import { ConstantMaterialModule } from './../../../material_module/constant-material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Topnavbar2Component } from './../../../global-components/topnavbar/topnavbar2/topnavbar2.component';
import { AppModule } from './../../../app.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SeoComponent } from './seo/seo.component';
import { SettingsComponent } from './settings/settings.component';
import { EditWebsiteComponent } from './edit-website/edit-website/edit-website.component';
import { ServicesectioneditorComponent } from './edit-website/website-generationcomponent/service-sections/servicesectioneditor/servicesectioneditor.component';
import { RightsidepanelComponent } from './edit-website/website-generationcomponent/rightsidepanel/rightsidepanel.component';
import { HomeHeroBannerComponent } from './edit-website/website-generationcomponent/home-hero-banner/home-hero-banner.component';
import { AddComponentsectionComponent } from './edit-website/website-generationcomponent/add-componentsection/add-componentsection.component';
import { ContactUs1Component } from './edit-website/website-generationcomponent/contact-us/contact-us1/contact-us1.component';
import { ContactUs2Component } from './edit-website/website-generationcomponent/contact-us/contact-us2/contact-us2.component';
import { Footer1Component } from './edit-website/website-generationcomponent/footer/footer1/footer1.component';
import { TopNavbar1Component } from './edit-website/website-generationcomponent/navBar/top-navbar1/top-navbar1.component';
import { AboutusComponent } from './edit-website/website-generationcomponent/about-us/aboutus/aboutus.component';
import { OurSuccessStoryComponent } from './edit-website/website-generationcomponent/oursucess/our-success-story/our-success-story.component';
import { TestimonalSlickcarsouelComponent } from './edit-website/website-generationcomponent/testimonal/testimonal-slickcarsouel/testimonal-slickcarsouel.component';
import { Stats1Component } from './edit-website/website-generationcomponent/stats/stats1/stats1.component';
import { Stats2Component } from './edit-website/website-generationcomponent/stats/stats2/stats2.component';
import { Stats3Component } from './edit-website/website-generationcomponent/stats/stats3/stats3.component';
import { Stats4Component } from './edit-website/website-generationcomponent/stats/stats4/stats4.component';
import { Stats5Component } from './edit-website/website-generationcomponent/stats/stats5/stats5.component';
import { Partner1Component } from './edit-website/website-generationcomponent/partner/partner1/partner1.component';
import { Partner2Component } from './edit-website/website-generationcomponent/partner/partner2/partner2.component';
import { ContactUs3Component } from './edit-website/website-generationcomponent/contact-us/contact-us3/contact3.component';
import { ContactUs4Component } from './edit-website/website-generationcomponent/contact-us/contact-us4/contact4.component';
import { ImageGalleryComponent } from './edit-website/website-generationcomponent/image-gallery/image-gallery/image-gallery.component';
import { ListBlogsComponent } from './edit-website/website-generationcomponent/blogs/list-blogs/list-blogs.component';
import { CreateBlogsComponent } from './edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { ViewBlogsComponent } from './edit-website/website-generationcomponent/blogs/view-blogs/view-blogs.component';
import { MetaTagsPopupComponent } from './edit-website/website-generationcomponent/blogs/meta-tags-popup/meta-tags-popup.component';
import { DeleteBlogComponent } from './edit-website/website-generationcomponent/blogs/delete-blog/delete-blog.component';
import { SinglePageDoubleTestimomialComponent } from './edit-website/website-generationcomponent/testimonal/single-page-double-testimomial/single-page-double-testimomial.component';
import { SingleVerticleTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/single-verticle-testimonial/single-verticle-testimonial.component';
import { SingleHorizontalTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/single-horizontal-testimonial/single-horizontal-testimonial.component';
import { HerobannerStatImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-stat-image/herobanner-stat-image.component';
import { HerobannerRatingReviewBgimgImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-rating-review-bgimg-image/herobanner-rating-review-bgimg-image.component';
import { SimpleHeroBannerComponent } from './edit-website/website-generationcomponent/heroBanner/simple-hero-banner/simple-hero-banner.component';
import { SimpleHerobannerButtonImageComponent } from './edit-website/website-generationcomponent/heroBanner/simple-herobanner-button-image/simple-herobanner-button-image.component';
import { VerticleServiceSectionComponent } from './edit-website/website-generationcomponent/service-sections/verticle-service-section/verticle-service-section.component';
import { SimpleFooterSocialLinksComponent } from './edit-website/website-generationcomponent/footer/simple-footer-social-links/simple-footer-social-links.component';
import { SimpleFooterCopyrightsComponent } from './edit-website/website-generationcomponent/footer/simple-footer-copyrights/simple-footer-copyrights.component';
import { SimpleVerticleFooterComponent } from './edit-website/website-generationcomponent/footer/simple-verticle-footer/simple-verticle-footer.component';
import { SimpleNavbarPagelinksComponent } from './edit-website/website-generationcomponent/navBar/simple-navbar-pagelinks/simple-navbar-pagelinks.component';
import { NavbarContactBtnComponent } from './edit-website/website-generationcomponent/navBar/navbar-contact-btn/navbar-contact-btn.component';
import { BlogListingComponent } from './edit-website/website-generationcomponent/blogs/websiteComponents/blog-listing/blog-listing.component';
import { ListTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/list-tags/list-tags.component';
import { AddTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/add-tags/add-tags.component';
import { ViewTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/view-tags/view-tags.component';
import { DeleteTagsComponent } from './edit-website/website-generationcomponent/blogs/tagsComponents/delete-tags/delete-tags.component';
import { PreviewSimpleHeroBannerComponent } from './edit-website/website-generationcomponent/heroBanner/simple-hero-banner/preview-simple-hero-banner.component';
import { PreviewHerobannerStatImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-stat-image/preview-herobanner-stat-image.component';
import { HerobannerStatImageReverseComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-stat-image-reverse/herobanner-stat-image-reverse.component';
import { PreviewHerobannerRatingReviewBgimgImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-rating-review-bgimg-image/preview-herobanner-rating-review-bgimg-image.component';
import { PreviewSimpleHerobannerButtonImageComponent } from './edit-website/website-generationcomponent/heroBanner/simple-herobanner-button-image/preview-simple-herobanner-button-image.component';
import { PreviewServicesectioneditorComponent } from './edit-website/website-generationcomponent/service-sections/servicesectioneditor/preview-servicesectioneditor.component';
import { PreviewVerticleServiceSectionComponent } from './edit-website/website-generationcomponent/service-sections/verticle-service-section/preview-verticle-service-section.component';
import { PreviewSingleHorizontalTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/single-horizontal-testimonial/preview-single-horizontal-testmonial.component';
import { PreviewSinglePageDoubleTestimomialComponent } from './edit-website/website-generationcomponent/testimonal/single-page-double-testimomial/preview-single-page-double-testimomial.component';
import { PreviewSingleVerticleTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/single-verticle-testimonial/preview-single-verticle-testimonial.component';
import { PreviewTestimonalSlickcarsouelComponent } from './edit-website/website-generationcomponent/testimonal/testimonal-slickcarsouel/preview-testimonal-slickcarsouel.component';
import { PreviewFooter1Component } from './edit-website/website-generationcomponent/footer/footer1/preview-footer1.component';
import { ProcessCountImageComponent } from './edit-website/website-generationcomponent/process-section/process-count-image/process-count-image.component';
import { ProcessNumberTextComponent } from './edit-website/website-generationcomponent/process-section/process-number-text/process-number-text.component';
import { ProcessCountImageSideComponent } from './edit-website/website-generationcomponent/process-section/process-count-image-side/process-count-image-side.component';
import { ProcessSparkImageSideComponent } from './edit-website/website-generationcomponent/process-section/process-spark-image-side/process-spark-image-side.component';
import { TeamsCardsProfilesComponent } from './edit-website/website-generationcomponent/teams/teams-cards-profiles/teams-cards-profiles.component';
import { PreviewSimpleFooterCopyrightsComponent } from './edit-website/website-generationcomponent/footer/simple-footer-copyrights/preview-simple-footer-copyrights.components';
import { PreviewSimpleFooterSocialLinksComponent } from './edit-website/website-generationcomponent/footer/simple-footer-social-links/preview-simple-footer-social-links.component';
import { PreviewSimpleVerticleFooterComponent } from './edit-website/website-generationcomponent/footer/simple-verticle-footer/preview-simple-verticle-footer.component';
import { SipleHerobanerRightsideBackgroundComponent } from './edit-website/website-generationcomponent/heroBanner/siple-herobaner-rightside-background/siple-herobaner-rightside-background.component';
import { HerobannerFeatureRightsideImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-feature-rightside-image/herobanner-feature-rightside-image.component';
import { SliderFaqComponentsComponent } from './edit-website/website-generationcomponent/faq-components/slider-faq-components/slider-faq-components.component';
// import { HeroBannerComponent } from './../../../global-components/others/hero-banner/hero-banner.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { PreviewProcessCountImageSideComponent } from './edit-website/website-generationcomponent/process-section/process-count-image-side/preview-process-count-image-side.component';
import { PreviewProcessCountImageComponent } from './edit-website/website-generationcomponent/process-section/process-count-image/preview-process-count-image.component';
import { PreviewProcessNumberTextComponent } from './edit-website/website-generationcomponent/process-section/process-number-text/preview-process-number-text.component';
import { PreviewProcessSparkImageSideComponent } from './edit-website/website-generationcomponent/process-section/process-spark-image-side/preview-process-spark-image-side.component';
import { ListAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/list-author/list-author.component';
import { AddAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/add-author/add-author.component';
import { DeleteAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/delete-author/delete-author.component';
import { ViewAuthorComponent } from './edit-website/website-generationcomponent/blogs/authorscomponents/view-author/view-author.component';
import { HoverHorizontalServiceSectionComponent } from './edit-website/website-generationcomponent/service-sections/hover-horizontal-service-section/hover-horizontal-service-section.component';
import { HerobannerWithReviewComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-with-review/herobanner-with-review.component';
import { TeamsCardsProfileCircleImagesComponent } from './edit-website/website-generationcomponent/teams/teams-cards-profile-circle-images/teams-cards-profile-circle-images.component';
import { TeamProfileCircleImagesComponent } from './edit-website/website-generationcomponent/teams/team-profile-circle-images/team-profile-circle-images.component';
import { PreviewTeamProfileCircleImagesComponent } from './edit-website/website-generationcomponent/teams/team-profile-circle-images/preview-team-profile-circle-images.component';
import { PreviewTeamsCardsProfileCircleImagesComponent } from './edit-website/website-generationcomponent/teams/teams-cards-profile-circle-images/preview-teams-cards-profile-circle-images.component';
import { PreviewTeamsCardsProfilesComponent } from './edit-website/website-generationcomponent/teams/teams-cards-profiles/preview-teams-cards-profiles.components';
import { PreviewOurSuccessStoryComponent } from './edit-website/website-generationcomponent/oursucess/our-success-story/preview-our-success-story.component';
import { StatsWithSideImageComponent } from './edit-website/website-generationcomponent/stats/stats-with-side-image/stats-with-side-image.component';
import { StatsWithTextComponent } from './edit-website/website-generationcomponent/stats/stats-with-text/stats-with-text.component';
import { StatsTextPartitionComponent } from './edit-website/website-generationcomponent/stats/stats-text-partition/stats-text-partition.component';
import { StatsTextSidePartitionComponent } from './edit-website/website-generationcomponent/stats/stats-text-side-partition/stats-text-side-partition.component';
import { StatsTextWrapComponent } from './edit-website/website-generationcomponent/stats/stats-text-wrap/stats-text-wrap.component';
import { PreviewStatsTextPartitionComponent } from './edit-website/website-generationcomponent/stats/stats-text-partition/preview-stats-partition.component';
import { PreviewStatsWithSideImageComponent } from './edit-website/website-generationcomponent/stats/stats-with-side-image/preview-stats-with-side-image.component';
import { PreviewStatsWithTextComponent } from './edit-website/website-generationcomponent/stats/stats-with-text/preview-stats-with-text.component';
import { PreviewStatsTextWrapComponent } from './edit-website/website-generationcomponent/stats/stats-text-wrap/preview-stats-text-wrap.component';
import { PreviewStatsTextSidePartitionComponent } from './edit-website/website-generationcomponent/stats/stats-text-side-partition/preview-stats-text-side-partition.component';
import { CreateMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/create-master-blogs/create-master-blogs.component';
import { AboutusWithBackgroundImageComponent } from './edit-website/website-generationcomponent/about-us/aboutus-with-background-image/aboutus-with-background-image.component';
import { PreviewAboutusComponent } from './edit-website/website-generationcomponent/about-us/aboutus/preview-aboutus.component';
import { PreviewAboutusWithBackgroundImageComponent } from './edit-website/website-generationcomponent/about-us/aboutus-with-background-image/preview-aboutus-with-background-image.component';
import { PreviewSliderFaqComponentsComponent } from './edit-website/website-generationcomponent/faq-components/slider-faq-components/preview-slider-faq-components.component';
import { HorizontalFaqComponent } from './edit-website/website-generationcomponent/faq-components/horizontal-faq/horizontal-faq.component';
import { PreviewHorizontalFaqComponent } from './edit-website/website-generationcomponent/faq-components/horizontal-faq/preview-horizontal-faq.component';
import { OpenedFaqComponent } from './edit-website/website-generationcomponent/faq-components/opened-faq/opened-faq.component';
import { PreviewOpenedFaqComponent } from './edit-website/website-generationcomponent/faq-components/opened-faq/preview-opened-faq.component';
import { PreviewHerobannerFeatureRightsideImageComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-feature-rightside-image/preview-herobanner-feature-rightside-image.component';
import { PreviewHerobannerStatImageReverseComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-stat-image-reverse/preview-herobanner-stat-image-reverse.component';
import { PreviewHerobannerWithReviewComponent } from './edit-website/website-generationcomponent/heroBanner/herobanner-with-review/preview-herobanner-with-review.component';
import { PreviewSipleHerobanerRightsideBackgroundComponent } from './edit-website/website-generationcomponent/heroBanner/siple-herobaner-rightside-background/preview-siple-herobaner-rightside-background.component';
import { ListMasterBlogsComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/list-master-blogs/list-master-blogs.component';
import { BlogCardsVerticalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-vertical-align/blog-cards-vertical-align.component';
import { BlogLeftTextSideCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-left-text-side-cards/blog-left-text-side-cards.component';
import { BlogCardsHorizontalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-horizontal-align/blog-cards-horizontal-align.component';
import { BlogTagCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-tag-cards/blog-tag-cards.component';
import { PreviewBlogCardsVerticalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-vertical-align/preview-blog-cards-vertical-align.component';
import { PreviewBlogCardsHorizontalAlignComponent } from './edit-website/website-generationcomponent/blogs-components/blog-cards-horizontal-align/preview-blog-cards-horizontal-align.component';
import { PreviewBlogTagCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-tag-cards/preview-blog-tag-cards.component';
import { BlogTextCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-text-cards/blog-text-cards.component';
import { PreviewBlogTextCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-text-cards/preview-blog-text-cards.component';
import { PreviewBlogLeftTextSideCardsComponent } from './edit-website/website-generationcomponent/blogs-components/blog-left-text-side-cards/preview-blog-left-text-side-cards.component';
import { MapLeftAddressComponent } from './edit-website/website-generationcomponent/location/map-left-address/map-left-address.component';
import { MapWithRightAddressComponent } from './edit-website/website-generationcomponent/location/map-with-right-address/map-with-right-address.component';
import { PreviewMapLeftAddressComponent } from './edit-website/website-generationcomponent/location/map-left-address/preview-map-left-address.compnent';
import { PreviewMapWithRightAddressComponent } from './edit-website/website-generationcomponent/location/map-with-right-address/preview-map-with-right-address.component';
import { ViewMasterBlogComponent } from './edit-website/website-generationcomponent/blogs/masterBlogs/view-master-blog/view-master-blog.component';
import { ContactusTextInputsComponent } from './edit-website/website-generationcomponent/contact-us/contactus-text-inputs/contactus-text-inputs.component';
import { PreviewHoverHorizontalServiceSectionComponent } from './edit-website/website-generationcomponent/service-sections/hover-horizontal-service-section/preview-hover-horizontal-service-section.component';
import { PreviewContactUs1Component } from './edit-website/website-generationcomponent/contact-us/contact-us1/preivew-contact-us1.component';
import { PreviewContactusTextInputsComponent } from './edit-website/website-generationcomponent/contact-us/contactus-text-inputs/preview-contactus-text-inputs.component';
import { ContactLeadsComponent } from './contact-leads/contact-leads.component';
import { BlogPageComponent } from './edit-website/website-generationcomponent/blogs-components/blog-page/blog-page.component';
import { Footer4Component } from './edit-website/website-generationcomponent/footer/footer4/footer4.component';
import { ConnectExistingDomainComponent } from './popups/connect-existing-domain/connect-existing-domain.component';
import { StepsToFollowComponent } from './popups/steps-to-follow/steps-to-follow.component';
import { ProcessInprogressComponent } from './popups/process-inprogress/process-inprogress.component';
import { ListSearchedDomainsComponent } from './popups/list-searched-domains/list-searched-domains.component';
import { MqRightsidepanelComponent } from './edit-website/website-generationcomponent/rightsidepanel/mq-rightsidepanel.component';
import { FiveSectionServicesComponent } from './edit-website/website-generationcomponent/service-sections/five-section-services/five-section-services.component';
import { RatingReviewTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/rating-review-testimonial/rating-review-testimonial.component';
import { SimpleFaqWithMoreQuestionComponent } from './edit-website/website-generationcomponent/faq-components/simple-faq-with-more-question/simple-faq-with-more-question.component';
import { SimpleFooterGetInTouchComponent } from './edit-website/website-generationcomponent/footer/simple-footer-get-in-touch/simple-footer-get-in-touch.component';
import { PreviewSimpleFooterGetInTouchComponent } from './edit-website/website-generationcomponent/footer/simple-footer-get-in-touch/preview-simple-footer-get-in-touch';
import { PreviewFiveSectionServicesComponent } from './edit-website/website-generationcomponent/service-sections/five-section-services/preview-five-section-services.component';
import { PreviewSimpleFaqWithMoreQuestionComponent } from './edit-website/website-generationcomponent/faq-components/simple-faq-with-more-question/preview-simple-faq-with-more-question-component';
import { PreviewRatingReviewTestimonialComponent } from './edit-website/website-generationcomponent/testimonal/rating-review-testimonial/preview-rating-review-testimonial.component';
import { ColoredHerobannerWithImageComponent } from './edit-website/website-generationcomponent/heroBanner/colored-herobanner-with-image/colored-herobanner-with-image.component';
import { ListedCompaniesHorizontalComponent } from './edit-website/website-generationcomponent/clients/listed-companies-horizontal/listed-companies-horizontal.component';
import { MultipleFeaturesComponent } from './edit-website/website-generationcomponent/features/multiple-features/multiple-features.component';
import { FeaturesWithImageComponent } from './edit-website/website-generationcomponent/features/features-with-image/features-with-image.component';
import { MultipleStatsWithImageComponent } from './edit-website/website-generationcomponent/stats/multiple-stats-with-image/multiple-stats-with-image.component';
import { PreviewListedCompaniesHorizontalComponent } from './edit-website/website-generationcomponent/clients/listed-companies-horizontal/preview-listed-companies-horizontal.component';
import { PreviewMultipleStatsWithImageComponent } from './edit-website/website-generationcomponent/stats/multiple-stats-with-image/preview-multiple-stats-with-image.component';
import { PreviewMultipleFeaturesComponent } from './edit-website/website-generationcomponent/features/multiple-features/preview-multiple-features.component';
import { PreviewFeaturesWithImageComponent } from './edit-website/website-generationcomponent/features/features-with-image/preview-features-with-image.component';
import { PreviewSimpleNavbarPagelinksComponent } from './edit-website/website-generationcomponent/navBar/simple-navbar-pagelinks/preview-simple-navbar.pagelinks.component';
import { UpgradePlanComponent } from './popups/upgrade-plan/upgrade-plan.component';

@NgModule({
  declarations: [
    PreviewComponent,
    AnalyticsComponent,
    SeoComponent,
    SettingsComponent,
    EditWebsiteComponent,
    ServicesectioneditorComponent,
    RightsidepanelComponent,
    MqRightsidepanelComponent,
    HomeHeroBannerComponent,
    AddComponentsectionComponent,
    ContactUs1Component,
    ContactUs2Component,
    Footer1Component,
    TopNavbar1Component,
    ViewBlogsComponent,
    AboutusComponent,
    AboutusWithBackgroundImageComponent,
    OurSuccessStoryComponent,
    TestimonalSlickcarsouelComponent,
    Stats1Component,
    Stats2Component,
    Stats3Component,
    Stats4Component,
    Stats5Component,
    Partner1Component,
    Partner2Component,
    ContactUs3Component,
    ContactUs4Component,
    ImageGalleryComponent,
    ListBlogsComponent,
    CreateBlogsComponent,
    MetaTagsPopupComponent,
    DeleteBlogComponent,
    SinglePageDoubleTestimomialComponent,
    SingleVerticleTestimonialComponent,
    SingleHorizontalTestimonialComponent,
    HerobannerStatImageComponent,
    SipleHerobanerRightsideBackgroundComponent,
    HerobannerRatingReviewBgimgImageComponent,
    SimpleHeroBannerComponent,
    SimpleHerobannerButtonImageComponent,
    VerticleServiceSectionComponent,
    SimpleFooterSocialLinksComponent,
    SimpleFooterCopyrightsComponent,
    SimpleVerticleFooterComponent,
    SimpleNavbarPagelinksComponent,
    NavbarContactBtnComponent,
    BlogListingComponent,
    ListTagsComponent,
    AddTagsComponent,
    ViewTagsComponent,
    DeleteTagsComponent,
    ListAuthorComponent,
    AddAuthorComponent,
    DeleteAuthorComponent,
    ViewAuthorComponent,
    HoverHorizontalServiceSectionComponent,
    HerobannerWithReviewComponent,
    ProcessSparkImageSideComponent,
    HorizontalFaqComponent,
    OpenedFaqComponent,
    ListMasterBlogsComponent,
    BlogCardsVerticalAlignComponent,
    BlogTextCardsComponent,
    BlogCardsHorizontalAlignComponent,
    BlogTagCardsComponent,
    ContactusTextInputsComponent,
    BlogLeftTextSideCardsComponent,
    // HeroBannerComponent

    //preview files
    PreviewSimpleHeroBannerComponent,
    PreviewHerobannerStatImageComponent,
    HerobannerStatImageReverseComponent,
    PreviewHerobannerRatingReviewBgimgImageComponent,
    PreviewSimpleHerobannerButtonImageComponent,
    PreviewServicesectioneditorComponent,
    PreviewVerticleServiceSectionComponent,
    PreviewSingleHorizontalTestimonialComponent,
    PreviewSinglePageDoubleTestimomialComponent,
    PreviewSingleVerticleTestimonialComponent,
    PreviewTestimonalSlickcarsouelComponent,
    PreviewFooter1Component,
    PreviewListedCompaniesHorizontalComponent,
    ProcessCountImageComponent,
    ProcessNumberTextComponent,
    ProcessCountImageSideComponent,
    ProcessSparkImageSideComponent,
    TeamsCardsProfilesComponent,
    PreviewSimpleFooterCopyrightsComponent,
    PreviewSimpleFooterSocialLinksComponent,
    PreviewSimpleVerticleFooterComponent,
    HerobannerFeatureRightsideImageComponent,
    SliderFaqComponentsComponent,
    PreviewProcessCountImageComponent,
    PreviewProcessCountImageSideComponent,
    PreviewProcessSparkImageSideComponent,
    PreviewProcessNumberTextComponent,
    TeamsCardsProfileCircleImagesComponent,
    TeamProfileCircleImagesComponent,
    PreviewTeamProfileCircleImagesComponent,
    PreviewTeamsCardsProfileCircleImagesComponent,
    PreviewTeamsCardsProfilesComponent,
    PreviewOurSuccessStoryComponent,
    StatsWithSideImageComponent,
    StatsWithTextComponent,
    StatsTextPartitionComponent,
    StatsTextSidePartitionComponent,
    StatsTextWrapComponent,
    PreviewStatsTextPartitionComponent,
    PreviewStatsWithSideImageComponent,
    PreviewStatsWithTextComponent,
    PreviewStatsTextWrapComponent,
    PreviewStatsTextSidePartitionComponent,
    PreviewAboutusComponent,
    PreviewAboutusWithBackgroundImageComponent,
    CreateMasterBlogsComponent,
    PreviewSliderFaqComponentsComponent,
    PreviewHorizontalFaqComponent,
    PreviewOpenedFaqComponent,
    PreviewHerobannerFeatureRightsideImageComponent,
    PreviewHerobannerStatImageReverseComponent,
    PreviewHerobannerWithReviewComponent,
    PreviewSipleHerobanerRightsideBackgroundComponent,
    PreviewBlogCardsVerticalAlignComponent,
    PreviewBlogCardsHorizontalAlignComponent,
    PreviewBlogTagCardsComponent,
    PreviewBlogTextCardsComponent,
    PreviewBlogLeftTextSideCardsComponent,
    PreviewMapWithRightAddressComponent,
    PreviewMapLeftAddressComponent,
    PreviewHoverHorizontalServiceSectionComponent,
    PreviewContactUs1Component,
    PreviewContactusTextInputsComponent,
    PreviewSimpleFooterGetInTouchComponent,
    PreviewFiveSectionServicesComponent,
    PreviewSimpleFaqWithMoreQuestionComponent,
    PreviewRatingReviewTestimonialComponent,
    ListMasterBlogsComponent,
    MapLeftAddressComponent,
    MapWithRightAddressComponent,
    ViewMasterBlogComponent,
    ListMasterBlogsComponent,
    ViewMasterBlogComponent,
    ContactLeadsComponent,
    BlogPageComponent,
    Footer4Component,
    ConnectExistingDomainComponent,
    StepsToFollowComponent,
    ProcessInprogressComponent,
    ListSearchedDomainsComponent,
    FiveSectionServicesComponent,
    RatingReviewTestimonialComponent,
    SimpleFaqWithMoreQuestionComponent,
    SimpleFooterGetInTouchComponent,
    ColoredHerobannerWithImageComponent,
    ListedCompaniesHorizontalComponent,
    MultipleFeaturesComponent,
    FeaturesWithImageComponent,
    MultipleStatsWithImageComponent,
    PreviewMultipleStatsWithImageComponent,
    PreviewMultipleFeaturesComponent,
    PreviewFeaturesWithImageComponent,
    PreviewSimpleNavbarPagelinksComponent,
    UpgradePlanComponent,
    

  ],
  imports: [
    CommonModule,
    WebsiteRoutingModule,
    ConstantMaterialModule,
    FormsModule,
    MatExpansionModule
    // AppModule
  ],
  exports:[
    HomeHeroBannerComponent,
    TopNavbar1Component,
    AboutusComponent,
    OurSuccessStoryComponent,
    HerobannerWithReviewComponent,

    //testimonials
    TestimonalSlickcarsouelComponent,
    SinglePageDoubleTestimomialComponent,
    SingleVerticleTestimonialComponent,
    SingleHorizontalTestimonialComponent,
    RatingReviewTestimonialComponent,

    //hero banners
    HerobannerStatImageComponent,
    HerobannerRatingReviewBgimgImageComponent,
    SimpleHeroBannerComponent,
    SimpleHerobannerButtonImageComponent,
    HerobannerStatImageReverseComponent,
    SipleHerobanerRightsideBackgroundComponent,
    HerobannerFeatureRightsideImageComponent,
    ColoredHerobannerWithImageComponent,


    //service Section
    VerticleServiceSectionComponent,
    ServicesectioneditorComponent,
    HoverHorizontalServiceSectionComponent,
    SimpleFaqWithMoreQuestionComponent,
    FiveSectionServicesComponent,


    //client section
    ListedCompaniesHorizontalComponent,


    //footers

    SimpleFooterSocialLinksComponent,
    SimpleFooterCopyrightsComponent,
    SimpleVerticleFooterComponent,
    Footer1Component,
    Footer4Component,
    SimpleFooterGetInTouchComponent,

    // Feature section 
    FeaturesWithImageComponent,
    MultipleFeaturesComponent,


    //topnavbars
    SimpleNavbarPagelinksComponent,
    NavbarContactBtnComponent,

    //process section
    ProcessCountImageComponent,
    ProcessNumberTextComponent,
    ProcessCountImageSideComponent,
    ProcessSparkImageSideComponent,


    //teams
    TeamsCardsProfilesComponent,
    TeamsCardsProfileCircleImagesComponent,
    TeamProfileCircleImagesComponent,

    //faq components
    SliderFaqComponentsComponent,
    HorizontalFaqComponent,
    OpenedFaqComponent,
    SimpleFaqWithMoreQuestionComponent,

    //about us
    AboutusWithBackgroundImageComponent,
    

    //location
    MapLeftAddressComponent,
    MapWithRightAddressComponent,

    // blog cards section 
    BlogCardsVerticalAlignComponent,
    // BlogLeftTextSideCardsComponent,
    BlogTextCardsComponent,
    BlogCardsHorizontalAlignComponent,
    BlogTagCardsComponent,
    //contact us
    ContactusTextInputsComponent,
    ContactUs1Component,
    ContactUs2Component,
    ContactUs3Component,
    ContactUs4Component,


    StatsTextPartitionComponent,
    StatsTextSidePartitionComponent,
    StatsTextWrapComponent,
    StatsWithSideImageComponent,
    StatsWithTextComponent,
    MultipleStatsWithImageComponent,





    //preview files
    //hero banners
    PreviewSimpleHeroBannerComponent,
    PreviewHerobannerStatImageComponent,
    PreviewHerobannerRatingReviewBgimgImageComponent,
    PreviewSimpleHerobannerButtonImageComponent,
    PreviewHerobannerFeatureRightsideImageComponent,
    PreviewHerobannerStatImageReverseComponent,
    PreviewHerobannerWithReviewComponent,
    PreviewSipleHerobanerRightsideBackgroundComponent,


      // service sections
    PreviewServicesectioneditorComponent,
    PreviewVerticleServiceSectionComponent,
    PreviewHoverHorizontalServiceSectionComponent,
    PreviewFiveSectionServicesComponent,


    // client section 
    PreviewListedCompaniesHorizontalComponent,

    //testimonal
    PreviewSingleHorizontalTestimonialComponent,
    PreviewSinglePageDoubleTestimomialComponent,
    PreviewSingleVerticleTestimonialComponent,
    PreviewTestimonalSlickcarsouelComponent,
    PreviewRatingReviewTestimonialComponent,

    //footer
    PreviewFooter1Component,
    PreviewSimpleFooterCopyrightsComponent,
    PreviewSimpleFooterSocialLinksComponent,
    PreviewSimpleVerticleFooterComponent,
    PreviewSimpleFooterGetInTouchComponent,


    //process section preview screens
    PreviewProcessCountImageComponent,
    PreviewProcessCountImageSideComponent,
    PreviewProcessSparkImageSideComponent,
    PreviewProcessNumberTextComponent,
    
    //teams section preview screen
    PreviewTeamProfileCircleImagesComponent,
    PreviewTeamsCardsProfileCircleImagesComponent,
    PreviewTeamsCardsProfilesComponent,

    // stats preview screens
    PreviewOurSuccessStoryComponent,
    PreviewStatsTextPartitionComponent,
    PreviewStatsWithSideImageComponent,
    PreviewStatsWithTextComponent,
    PreviewStatsTextWrapComponent,
    PreviewStatsTextSidePartitionComponent,
    PreviewMultipleStatsWithImageComponent,
    
    //faq section preview files
    PreviewSliderFaqComponentsComponent,
    PreviewHorizontalFaqComponent,
    PreviewOpenedFaqComponent,
    PreviewSimpleFaqWithMoreQuestionComponent,
    
    //Aboutus
    PreviewAboutusComponent,
    PreviewAboutusWithBackgroundImageComponent,

    // features preview components 
    PreviewMultipleFeaturesComponent,
    PreviewFeaturesWithImageComponent,

    //blog components in website 
    PreviewBlogCardsVerticalAlignComponent,
    PreviewBlogCardsHorizontalAlignComponent,
    PreviewBlogTagCardsComponent,
    PreviewBlogTextCardsComponent,
    PreviewBlogLeftTextSideCardsComponent,


    //location
    PreviewMapLeftAddressComponent,
    PreviewMapWithRightAddressComponent,

    //contact us
    PreviewContactusTextInputsComponent,
    PreviewContactUs1Component,

    // navbar
    PreviewSimpleNavbarPagelinksComponent,
    
  ]
})
export class WebsiteModule { }