<!-- toplayer section -->
<section *ngIf="scrWidth > 475">
  <section class="top_layer" *ngIf="!totalPageLoader; else showText">
    <div class="left_container" (click)="goBackHome()">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <img src="./../../assets/images/logo.svg" alt="" class="logo" />
    </div>
    <div class="right_container" *ngIf="!queyWebCheck; else createOwn">
      <div class="re-style">
        <button (click)="openRestyleComponent()">
          Restyle <img src="./../../assets/images/restyle.svg" alt="restyle" />
        </button>
      </div>
      <div class="re-style">
        <button (click)="globalRegeneration()">
          Regenerate
          <img
            src="./../../assets/images/global-regeneration.svg"
            alt="global-regeneration"
          />
        </button>
      </div>
      <div class="share">
        <button (click)="shareWebsite()">
          Share <img src="./../../assets/images/share.svg" alt="Share" />
        </button>
      </div>
      <!-- <div>
                    <button (click)="updateGlobalColor()">update</button>
                </div> -->
    </div>
    <ng-template #createOwn>
      <div class="right_container">
        <button (click)="createYourOwn()" class="create-own">
          Create your Own
        </button>
      </div>
    </ng-template>
  </section>
</section>

<!-- bottom layer website section -->
<section
  *ngIf="!totalPageLoader"
  [ngClass]="scrWidth > 475 ? 'bottom_layer' : '.small_screen_bottom_layer'"
>
  <div>
    <app-topnavbar2></app-topnavbar2>
  </div>
  <div
    *ngIf="heroBannerComponent"
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
  >
    <app-hero-banner
      [data]="heroBanner_ComponentData"
      id="heroBanner"
    ></app-hero-banner>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('heroBanner')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
    id="aboutUs"
  >
    <app-about-us></app-about-us>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('aboutUs')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div
    *ngIf="serviceComponent"
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
    id="service"
    style="margin-top: 10px"
  >
    <app-service-section
      [pageData]="service_ComponentData"
    ></app-service-section>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('service')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
    *ngIf="process_component"
  >
    <app-process-section
      [pageData]="process_componentData"
    ></app-process-section>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('Home-process-component')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div
    *ngIf="testimonial_component"
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
    id="Home_Testomnial"
  >
    <app-testimonial [pageData]="testimonial_componentData"></app-testimonial>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('Home_Testomnial')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div
    [ngClass]="loader ? 'single_website:hover' : 'single_website'"
    *ngIf="contact_component"
  >
    <app-contact-us [pageData]="contact_componentData"></app-contact-us>
    <div class="show_btns" *ngIf="!queyWebCheck && scrWidth > 475">
      <button class="regenerate" (click)="regenrate('Contactus-section')">
        <img src="./../../assets/images/regenerate.svg" alt="regenerate" />
        Regenerate
      </button>
    </div>
  </div>
  <div class="footer">
    <app-construction-footer></app-construction-footer>
  </div>
</section>

<!-- signup section -->
<section *ngIf="scrWidth > 475">
  <div class="signup-tab" *ngIf="!totalPageLoader && !loader">
    <div class="signupsection">
      <div class="signUp-text">
        Edit and publish your website, get a custom domain, analytics and more
      </div>
      <div class="signUp-btn">
        <button (click)="signUp()">Sign up free</button>
      </div>
    </div>
  </div>
</section>

<!-- restyle component section -->
<section class="restyle_component" *ngIf="restyleComponent">
  <div class="center_component">
    <div class="heading_style">
      <div class="heading_text">Restyle Website</div>
      <div class="cross_icon"><mat-icon>close</mat-icon></div>
    </div>
    <div class="hr-line"></div>
    <div class="button_styles">
      <div class="heading_text">Buttom Style</div>
      <div class="different_styles">
        <div class="style">
          <div class="label_heading">Style</div>
          <mat-form-field appearance="outline" class="sfull-width">
            <mat-select [(ngModel)]="bStyle">
              <mat-option
                *ngFor="let item of buttonStyles"
                [value]="item?.value"
              >
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="shape">
          <div class="label_heading">Shape</div>
          <mat-form-field appearance="outline" class="sfull-width">
            <mat-select [(ngModel)]="bShape">
              <mat-option
                *ngFor="let item of buttonShape"
                [value]="item?.value"
              >
                {{ item?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="button_styles">
      <div class="heading_text">Colors</div>
      <div class="different_styles">
        <div class="style">
          <div class="label_heading">Brand</div>
          <div class="color_picking">
            <input
              type="color"
              value="#5599d5"
              [(ngModel)]="globalStyles.primaryColor"
            />
            <span class="color_value">{{ globalStyles.primaryColor }}</span>
          </div>
        </div>
        <div class="shape">
          <div class="label_heading">Buttons</div>
          <div class="color_picking">
            <input
              type="color"
              value="#fbd09a"
              [(ngModel)]="globalStyles.buttonColor"
            />
            <span class="color_value">{{ globalStyles.buttonColor }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button_styles">
      <div class="heading_text">Fonts</div>
      <div class="text_looks_like">
        <div
          class="text_showing"
          [ngStyle]="{ 'font-family': globalStyles.textFamily['font-family'] }"
        >
          Heading
        </div>
        <div class="hint">Body text will look like this.</div>
      </div>
      <div class="font_family">
        <div class="label_heading">Select a font pair</div>
        <mat-form-field appearance="outline" class="font-sfull-width">
          <mat-select
            [(ngModel)]="selectingFontFamily"
            (ngModelChange)="changeFontFamily()"
          >
            <mat-option *ngFor="let item of fontFamilies" [value]="item?.value">
              {{ item?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="btns">
      <button class="cancel_btn">Cancel</button>
      <button class="restyle_website">Restyle website</button>
    </div>
  </div>
</section>

<!-- total page loader section -->
<section class="loading" *ngIf="totalPageLoader">
  <!-- <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div> -->
  <div class="gif_img">
    <img src="./../../assets/images/loader/beeos_loader.gif" alt="loader" />
  </div>
  <div class="gethering_text">Building</div>
</section>

<!-- top layer loader section -->
<ng-template #showText>
  <div class="show_text">
    <div class="spinner-border text-info" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="gethering_text">Building Your Website</div>
  </div>
</ng-template>
