import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit{

  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router
  ){}

  planName : any = '';
  planAmount : any = '';
  validity : any = '';
  
  ngOnInit(): void {
    this.planName = window.localStorage.getItem('planName');
    this.planAmount = window.localStorage.getItem('planAmount');
    this.validity = window.localStorage.getItem('endDate');

    let paymentId = this._dbService.getPaymentId()
    this.sendingStatus(paymentId)
  }

  sendingStatus(pId){
    let data = {
      paymentId : pId,
      status:'SUCCESS'
    }
    this._paymentService.sendPaymentId(data).subscribe(
      (res:any) => {
        console.log(res.data)
        localStorage.removeItem('pId');
        localStorage.removeItem('planName');
        localStorage.removeItem('planAmount');
        localStorage.removeItem('endDate');
        this._router.navigate(['/admin/website/preview'])
      },
      (err) => {
        console.log(err)
      }
    )
  }

}
