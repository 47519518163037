import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";

@Component({
  selector: 'app-preview-simple-footer-copyrights',
  templateUrl: './simple-footer-copyrights.component.html',
  styleUrls: ['./preview-simple-footer-copyrights.component.scss']
})
export class PreviewSimpleFooterCopyrightsComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private _dbService : DbService
  ){}
  
  ngOnInit(): void {
    this.dataAssign();
  }

  
  // templateData: any = {
  //   "componentCategory": "navbar",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Footer2Component",
  //   "data" : [
  //     {
  //       "imgUrl": "https://png.pngtree.com/png-clipart/20190604/original/pngtree-creative-company-logo-png-image_1197025.jpg",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "30px","padding-right": "12px"}
  //     },
  //     {
  //       "value": "ClarityUI",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight": 500,"font-size": "23px"}
  //     },
  //     {
  //       "value": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu, ipsum nibh felis neque hendrerit.",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"color":"#868687"}
  //     },
  //     {
  //       "value": "Terms & Conditions",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Privacy Policy",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     }
  //   ]
  // }

  // data = this.templateData.data
  data:any;
  // logo = this.data[0]
  logo : any
  // heading = this.data[1]
  heading : any;
  // description = this.data[2]
  description : any;
  // termsConditions = this.data[3]
  // privacyPolicy = this.data[4]
  dataAssign(){
    this.data = this.templateData.data;
    // this.logo = this.valuesData.companyLogo;
    // this.heading = this.valuesData.companyName;
    // this.description = this.valuesData.companyDesc;
    this.checkingStyles()
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].imgUrl = this.valuesData.companyLogo;
      this.data[1].value = this.valuesData.companyName;
      this.data[2].value = this.valuesData.companyDesc.replace("{Company Name}", bName);
    }
    else{

    }
  }

  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    data.style = {
      "color":this.templateData.textColor,
      "font-family":this.templateData.fontFamily,
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
  }

}
