<section *ngIf="!pageLoader">
    <div class="right-container" *ngIf="screenWidth > 475">

        <ng-container #componentDataTemplate *ngIf="isNormal">
            <!-- <div>
                <mat-form-field style="width: 50px;" appearance="outline">
                    <input
                    matInput
                    type="color"
                    name="bgColor"
                    [(ngModel)]="componentData.bgColor"
                    (ngModelChange)="bgColorChanged()"
                    placeholder="text"
                    />  
                </mat-form-field>
            </div> -->
        <div class="first-layer">
            <div class="layer_title">
                {{componentData.category.name| titlecase}}
            </div>
            <div class="done_btn">
                <button (click)="saveData()">Save</button>
            </div>
          </div>
          <hr class="hr-line">
          <div class="section_bottom_layer">
            <div class="top_layer">
                <div class="colors_layer">
                    <div class="layer1">
                        <div class="colors-heading-text">Background Color</div>
                        <div class="color_pick business-field-section">
                                <!-- <mat-form-field appearance="outline"> -->
                                    <input
                                    type="color"
                                    name="bgColor"
                                    [(ngModel)]="componentData.bgColor"
                                    (ngModelChange)="bgColorChanged()"
                                    placeholder="text"
                                    />  
                                <!-- </mat-form-field> -->
                        </div>
                    </div>
                    <div class="layer2">
                        <div class="colors-heading-text">Text Heading Color</div>
                        <div class="color_pick business-field-section">
                            <!-- <mat-form-field appearance="outline"> -->
                                <input
                                type="color"
                                name="bgColor"
                                [(ngModel)]="componentData.colors.txtHeadingColor"
                                (ngModelChange)="textheadingColor()"
                                placeholder="text"
                                />  
                            <!-- </mat-form-field> -->
                        </div>
                    </div>
                </div>
                <div class="colors_layer">
                    <div class="layer1">
                        <div class="colors-heading-text">Text Description Color</div>
                        <div class="color_pick business-field-section">
                            <!-- <mat-form-field appearance="outline"> -->
                                <input
                                type="color"
                                name="bgColor"
                                [(ngModel)]="componentData.colors.txtDescColor"
                                (ngModelChange)="textDescriptionColor()"
                                placeholder="text"
                                />  
                            <!-- </mat-form-field> -->
                        </div>
                    </div>
                    <div class="layer2">
                        <div class="colors-heading-text">Text normal Color</div>
                        <div class="color_pick business-field-section">
                            <!-- <mat-form-field appearance="outline"> -->
                                <input
                                type="color"
                                name="bgColor"
                                [(ngModel)]="componentData.colors.txtNormalColor"
                                (ngModelChange)="textNormalColor()"
                                placeholder="text"
                                />  
                            <!-- </mat-form-field> -->
                        </div>
                    </div>
                </div>
              </div>
              <hr class="hr-line">
              <div class="content-start">
            <div *ngFor="let item of data;let i= index">
            <div *ngIf="item.type=='heading'">
                <div class="heading-text">
                    {{item.field}}
                </div>
                <div>
                    <mat-form-field class="material-form-width" appearance="outline">
                        <input
                        matInput
                        type="text"
                        name="first"
                        [(ngModel)]="item.value"
                        (ngModelChange)="ContentChanged()"
                        placeholder="text"
                        />  
                    </mat-form-field>
                </div>
            </div>
            
            <div *ngIf="item.type=='text'">
                <div *ngIf="componentData.category.name != 'Top Nav Bar'">
                    <div class="heading-text">
                        {{item.field}}
                    </div>
                    <div>
                          <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
                    </div>
                </div>
                <div *ngIf="componentData.category.name === 'Top Nav Bar'">
                    <div class="button-header">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div class="toogle-btn">
                            <mat-slide-toggle  [checked]="item.status" (click)="tooglebuttonUpper(i)"></mat-slide-toggle>
                        </div>
                    </div>
                    <div>
                        <!-- <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea> -->
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                            matInput
                            type="text"
                            name="first"
                            (ngModelChange)="ContentChanged()"
                            [(ngModel)]="item.value"
                            placeholder="Label"
                            />  
                        </mat-form-field>
                  </div>
                </div>
            </div>
            <div *ngIf="item.type=='button'">
                <div class="button-header">
                    <div class="heading-text">
                        {{item.field | titlecase}}
                    </div>
                    <div class="toogle-btn">
                        <mat-slide-toggle  [checked]="item.status" (click)="tooglebuttonUpper(i)"></mat-slide-toggle>
                    </div>
                </div>
                <div class="heading-text">
                Button Name
                </div>
                <div>
                    <mat-form-field class="material-form-width" appearance="outline">
                        <input
                        matInput
                        type="text"
                        name="first"
                        (ngModelChange)="ContentChanged()"
                        [(ngModel)]="item.value"
                        placeholder="Label"
                        />  
                    </mat-form-field>
                </div>
                <div class="heading-text">
                Link URL
                </div>
                <div>
                    <mat-form-field class="material-form-width" appearance="outline">
                        <input
                        matInput
                        type="text"
                        name="first"
                        (ngModelChange)="ContentChanged()"
                        [(ngModel)]="item.link"
                        placeholder="Link"
                        />  
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="item.type=='image'">
                <div *ngIf="componentData.category.name != 'Top Nav Bar' && componentData.category.name != 'Footer'">
                    <div class="heading-text">
                        {{item.field}}
                    </div>
                    <div class="Inside-div" *ngIf="item.imgUrl=='';else showImage" (click)="addImage(i)">
                        <div class="upper-div">
                                <div class="upload-icon">
                                    <img src="./../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                                </div>
                                <div class="sub-image-text">
                                <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                                </div>
                        </div> 
                        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                        class="browse-btn" id="browse_img"/>
                    </div>
                    <ng-template #showImage>
                        <div class="image_fill">
                            <!-- <img [src]="item.imageUrl" alt="about-us" *ngIf="item.imageUrl"> -->
                            <img [src]="item.imgUrl" alt="about-us">
                            <div class="show-reUpload">
                                <div class="updateImage" (click)="addImage(i)">
                                    Update Image
                                </div>
                                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                                class="browse-btn" id="browse_img"/>
                                <div class="remove-text" (click)="removeImage(i)">Remove</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="componentData.category.name === 'Top Nav Bar' || componentData.category.name === 'Footer'">
                    <div class="button-header">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div class="toogle-btn">
                            <mat-slide-toggle  [checked]="item.status" (click)="tooglebuttonUpper(i)"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="heading-text" *ngIf="componentData.category.name != 'Footer'">
                        Logo Shape
                    </div>
                    <div class="heading-text_images" *ngIf="componentData.category.name != 'Footer'">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedImageShape">
                            <mat-radio-button *ngFor="let item of imageShapes" [value]="item.value"
                            (change)="changeImageShape($event)">{{item.name}}</mat-radio-button>
                            <!-- <mat-radio-button value="RECTANGLE">Rectangle</mat-radio-button> -->
                        </mat-radio-group>
                    </div>
                    <div class="heading-text">
                        Logo
                    </div>
                    <div class="Inside-div" *ngIf="item.imgUrl=='';else showImage" (click)="addImage(i)">
                        <div class="upper-div">
                                <div class="upload-icon">
                                    <img src="./../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                                </div>
                                <div class="sub-image-text">
                                <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                                </div>
                        </div> 
                        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                        class="browse-btn" id="browse_img"/>
                    </div>
                    <ng-template #showImage>
                        <div class="image_fill">
                            <!-- <img [src]="item.imageUrl" alt="about-us" *ngIf="item.imageUrl"> -->
                            <img [src]="item.imgUrl" alt="about-us">
                            <div class="show-reUpload">
                                <div class="updateImage" (click)="addImage(i)">
                                    Update Image
                                </div>
                                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                                class="browse-btn" id="browse_img"/>
                                <div class="remove-text" (click)="removeImage(i)">Remove</div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div class="hr-line"></div>
            </div>
            <div *ngIf="item.type=='array'">
                <div *ngFor="let arrayData of item.value;let j=index">
                    <div *ngIf="arrayData.type=='heading'">
                        <div class="heading-text">
                            {{arrayData.field}}
                        </div>
                        <div>
                            <mat-form-field class="material-form-width" appearance="outline">
                                <input
                                  matInput
                                  type="text"
                                  name="first"
                                  [(ngModel)]="arrayData.value"
                                  (ngModelChange)="ContentChanged()"
                                  placeholder="text"
                                />  
                              </mat-form-field>
                        </div>
                        </div>
                        
                        <div *ngIf="arrayData.type=='text'">
                            <div class="heading-text">
                                {{arrayData.field}}
                            </div>
                            <div>
                                  <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="arrayData.value" (ngModelChange)="ContentChanged()"></textarea>
                            </div>
                            </div>
                        <div *ngIf="arrayData.type=='button'">
                        <div class="button-header">
                        <div class="heading-text">
                            {{arrayData.feild}}
                        </div>
                        <div class="toogle-btn">
                            <mat-slide-toggle  [checked]="arrayData.status" (click)="tooglebutton(i,j)"></mat-slide-toggle>
                        </div>
                        </div>
                        <div class="heading-text">
                        Label
                        </div>
                        <div>
                            <mat-form-field class="material-form-width" appearance="outline">
                                <input
                                  matInput
                                  type="text"
                                  name="first"
                                  (ngModelChange)="ContentChanged()"
                                  [(ngModel)]="arrayData.value"
                                  placeholder="Label"
                                />  
                              </mat-form-field>
                        </div>
                        <div class="heading-text">
                        Link
                        </div>
                        <div>
                            <mat-form-field class="material-form-width" appearance="outline">
                                <input
                                  matInput
                                  type="text"
                                  name="first"
                                  (ngModelChange)="ContentChanged()"
                                  [(ngModel)]="arrayData.link"
                                  placeholder="Link"
                                />  
                              </mat-form-field>
                        </div>
                        </div>
                        <div *ngIf="arrayData.type=='image'">
                            <div class="heading-text">
                                {{arrayData.field | titlecase}}
                            </div>
                        <div class="Inside-div" *ngIf="arrayData.imageUrl=='';else showImage" (click)="addImage(i)">
                            <div class="upper-div">
                                    <div class="upload-icon">
                                        <img src="./../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                                    </div>
                                    <div class="sub-image-text">
                                       <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                                    </div>
                            </div> 
                            <input  type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateArrayImage($event,i,j)"
                            class="browse-btn" id="browse_img"/>
                        </div>
                        <ng-template #showImage>
                            <div class="image_fill">
                                <img [src]="arrayData.imageUrl" alt="about-us">
                                <!-- <img [src]="../../../assets/images/editor/aboutus.svg" alt="about-us"> -->
                                <div class="show-reUpload">
                                    <div class="updateImage" (click)="addImage(i)">
                                        Update Image
                                    </div>
                                    <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateArrayImage($event,i,j)"
                            class="browse-btn" id="browse_img"/>
                                    <div class="remove-text" (click)="removeArrayImages(i,j)">Remove</div>
                                    </div>
                            </div>
                        </ng-template>
                        </div>
                </div>
            </div>
            </div>
            </div>
          </div>
        </ng-container>
        
        
    
        <ng-container *ngIf="isLocation">
            <div>
                <div class="first-layer">
                    <div class="layer_title">
                        Home
                    </div>
                    <div class="done_btn">
                        <button (click)="saveData()">Done</button>
                    </div>
                  </div>
                  <hr class="hr-line">
                  <div class="heading-text">
                    Location
                </div>
                <!-- <div class="optimise-text">
                    Set up the business Location.
                </div>
                <div>
                    <button (click)="changeLocation()">Change Location</button>
                </div> -->
                <ng-container *ngFor="let item of data;let i= index">
                    <div *ngIf="item.type=='text'">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div>
                            <!-- <mat-form-field appearance="outline" class="material-form-width">
                                <textarea matInput [(ngModel)]="item.value" style="box-sizing:content-box;"   (ngModelChange)="ContentChanged()"></textarea>
                              </mat-form-field> -->
                              <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
                        </div>
                    </div>
                    <div *ngIf="item.type=='number'">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div>
                            <!-- <mat-form-field appearance="outline" class="material-form-width">
                                <textarea matInput [(ngModel)]="item.value" style="box-sizing:content-box;"   (ngModelChange)="ContentChanged()"></textarea>
                              </mat-form-field> -->
                              <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
                        </div>
                    </div>
                </ng-container>
                </div>
        </ng-container>
    
        <ng-container *ngIf="isSeo">
            <div>
                <div class="first-layer">
                    <div class="layer_title">
                        Home
                    </div>
                    <div class="done_btn">
                        <button (click)="saveData()">Done</button>
                    </div>
                  </div>
                  <hr class="hr-line">
                  <div class="heading-text">
                    SEO
                </div>
                <div class="optimise-text">
                    Set up and optimize how your site appears in search engines.
                </div>
                <ng-container>
                    <div class="heading-text">
                        Meta Title
                    </div>
                    <div>
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                              matInput
                              type="text"
                              name="first"
                              [(ngModel)]="metaTitle"
                              (ngModelChange)="ContentChanged()"
                              placeholder="text"
                            />  
                          </mat-form-field>
                    </div>
                    <div class="heading-text">
                        Meta Description
                    </div>
                    <div>
                          <textarea name="" id="" cols="" rows=""  placeholder="Meta Description" class="text-area" [(ngModel)]="metaDescription"></textarea>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Search Engine Result Preview</div>
                        <div class="search_engine_box">
                            <div class="google_searchbox">
                                <div class="google_img">
                                    <img src="./../../../../../assets/images/common/google.svg" alt="google">
                                </div>
                                <mat-form-field class="google_search" appearance="outline">
                                    <input matInput [(ngModel)]="metaTitle" disabled/>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{metaTitle ? metaTitle : 'Meta Title'}}
                            </div>
                            <div class="meta_description">
                                {{metaDescription ? metaDescription : 'Meta Description'}}
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Facebook Card Preview</div>
                        <div class="search_engine_box">
                            <div class="facebook_imgUrl" *ngIf="facebookImage === undefined; else upImg">
                                Facebook
                            </div>
                            <ng-template #upImg>
                                <div class="fb_imgUrl">
                                    <img [src]="facebookImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{facebookTitle ? facebookTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{facebookDescription ? facebookDescription : 'Description'}}
                            </div>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Twitter Card Preview</div>
                        <div class="search_engine_box">
                            <div class="twitter_imgUrl" *ngIf="twitterImage === undefined; else twitterImg">
                                Twitter
                            </div>
                            <ng-template #twitterImg>
                                <div class="fb_imgUrl">
                                    <img [src]="twitterImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{twitterTitle ? twitterTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{twitterDescription ? twitterDescription : 'Description'}}
                            </div>
                        </div>
                    </div> -->
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="isStyleSheet">
            <div>
                <div class="first-layer">
                    <div class="layer_title">
                        Style
                    </div>
                    <div class="done_btn">
                        <button (click)="saveStyleData()">Save</button>
                    </div>
                  </div>
                  <hr class="hr-line"> 
            </div>
            <div class="style-sheet-section">
                <div class="heading-text">
                    Button Style
                </div>
                <div class="dexcription-sec">
                    Choose a button style
                </div>
            </div>
            <div>
                <div class="style-background-grey">
                    <div class="different_styles whitebackgroundInput">
                        <div class="style">
                            <div class="label_heading">Style</div>
                            <mat-form-field appearance="outline" class="sfull-width">
                                <mat-select [(ngModel)]="bStyle">
                                    <!-- <mat-option [value]="bStyle">--Select--</mat-option> -->
                                    <mat-option *ngFor="let item of buttonStyles" [value]="item?.value">
                                        {{item?.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="shape">
                            <div class="label_heading">Shape</div>
                            <mat-form-field appearance="outline" class="sfull-width">
                                <mat-select [(ngModel)]="bShape" (selectionChange)="shapeChange($event)">
                                    <mat-option *ngFor="let item of buttonShape" [value]="item.value">
                                      <span class="color-text-input">{{item.name}}</span>  
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="button_styles">
                    <div class="heading-text">Colors</div>
                    <div class="dexcription-sec">
                        Choose a color style
                    </div>
                    <div class="different_styles">
                        <div class="style">
                            <div class="label_heading">Brand</div>
                            <div class="color_picking">
                                <input type="color" [value]="btnColor" [(ngModel)]="btnColor"/>
                                <span class="color_value">{{btnColor}}</span>
                            </div>
                        </div>
                        <div class="shape">
                            <div class="label_heading">Text Color</div>
                            <div class="color_picking">
                                <input type="color" [value]="btnTextColor" [(ngModel)]="btnTextColor"/>
                                <span class="color_value">{{btnTextColor}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button_styles">
                    <div class="heading-text">Fonts</div>
                    <div class="text_looks_like">
                        <div class="text_showing" [ngStyle]="{'font-family':headingFontFamily['font-family']}">Heading</div>
                        <div class="hint" [ngStyle]="{'font-family':bodyFontFamily['font-family']}">Body text will look like this.</div>
                    </div>
                    <div class="font_family whitebackgroundInput">
                        <div class="label_heading">Select a font pair</div>
                        <mat-form-field appearance="outline" class="font-sfull-width">
                            <mat-select [(ngModel)]="selectingFontFamily" (ngModelChange)="changeFontFamily()">
                                <mat-option *ngFor="let item of fontFamilies" [value]="item?.value">
                                    {{item?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
          
              
        </ng-container>
        
    </div>
    <div class="mobile-right-container" *ngIf="screenWidth < 475">
    
        <ng-container #componentDataTemplate *ngIf="isNormal">
            <div class="first-layer">
                <div class="layer_title">
                    {{componentData.category.name| titlecase}}
                </div>
                <div class="done_btn">
                    <!-- <button (click)="saveData()">Save</button> -->
                    <img src="./../../../../../../../assets/images/close.svg" alt="close_svg" (click)="closePage()" />
                </div>
            </div>
            <hr class="hr-line">
          <div class="content-start">
        <div *ngFor="let item of data;let i= index">
        <div *ngIf="item.type=='heading'">
            <div class="heading-text">
                {{item.field}}
            </div>
            <div>
                <mat-form-field class="material-form-width" appearance="outline">
                    <input
                    matInput
                    type="text"
                    name="first"
                    [(ngModel)]="item.value"
                    (ngModelChange)="ContentChanged()"
                    placeholder="text"
                    />  
                </mat-form-field>
            </div>
        </div>
        
        <div *ngIf="item.type=='text'">
            <div class="heading-text">
                {{item.field}}
            </div>
            <div>
                <!-- <mat-form-field appearance="outline" class="material-form-width">
                    <textarea matInput [(ngModel)]="item.value" style="box-sizing:content-box;"   (ngModelChange)="ContentChanged()"></textarea>
                  </mat-form-field> -->
                  <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
            </div>
        </div>
        <div *ngIf="item.type=='button'">
            <div class="button-header">
            <div class="heading-text">
                {{item.field}}
            </div>
            <div class="toogle-btn">
                <mat-slide-toggle  [checked]="item.status" (click)="tooglebuttonUpper(i)"></mat-slide-toggle>
            </div>
            </div>
            <div class="heading-text">
            Label
            </div>
            <div>
                <mat-form-field class="material-form-width" appearance="outline">
                    <input
                    matInput
                    type="text"
                    name="first"
                    (ngModelChange)="ContentChanged()"
                    [(ngModel)]="item.value"
                    placeholder="Label"
                    />  
                </mat-form-field>
            </div>
            <div class="heading-text">
            Link
            </div>
            <div>
                <mat-form-field class="material-form-width" appearance="outline">
                    <input
                    matInput
                    type="text"
                    name="first"
                    (ngModelChange)="ContentChanged()"
                    [(ngModel)]="item.link"
                    placeholder="Link"
                    />  
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="item.type=='image'">
            <div class="heading-text">
                {{item.field}}
            </div>
            <div class="Inside-div" *ngIf="item.imgUrl=='';else showImage" (click)="addImage(i)">
                <div class="upper-div">
                        <div class="upload-icon">
                            <img src="./../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                        </div>
                        <div class="sub-image-text">
                        <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                        </div>
                </div> 
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                class="browse-btn" id="browse_img"/>
            </div>
            <ng-template #showImage>
                <div class="image_fill">
                    <!-- <img [src]="item.imageUrl" alt="about-us" *ngIf="item.imageUrl"> -->
                    <img [src]="item.imgUrl" alt="about-us">
                    <div class="show-reUpload">
                        <div class="updateImage" (click)="addImage(i)">
                            Update Image
                        </div>
                        <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateBackgroundImage($event,i)"
                        class="browse-btn" id="browse_img"/>
                        <div class="remove-text" (click)="removeImage(i)">Remove</div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div *ngIf="item.type=='array'">
            <div *ngFor="let arrayData of item.value;let j=index">
                <div *ngIf="arrayData.type=='heading'">
                    <div class="heading-text">
                        {{arrayData.field}}
                    </div>
                    <div>
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                              matInput
                              type="text"
                              name="first"
                              [(ngModel)]="arrayData.value"
                              (ngModelChange)="ContentChanged()"
                              placeholder="text"
                            />  
                          </mat-form-field>
                    </div>
                    </div>
                    
                    <div *ngIf="arrayData.type=='text'">
                        <div class="heading-text">
                            {{arrayData.field}}
                        </div>
                        <div>
                              <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="arrayData.value" (ngModelChange)="ContentChanged()"></textarea>
                        </div>
                        </div>
                    <div *ngIf="arrayData.type=='button'">
                    <div class="button-header">
                    <div class="heading-text">
                        {{arrayData.feild}}
                    </div>
                    <div class="toogle-btn">
                        <mat-slide-toggle  [checked]="arrayData.status" (click)="tooglebutton(i,j)"></mat-slide-toggle>
                    </div>
                    </div>
                    <div class="heading-text">
                    Label
                    </div>
                    <div>
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                              matInput
                              type="text"
                              name="first"
                              (ngModelChange)="ContentChanged()"
                              [(ngModel)]="arrayData.value"
                              placeholder="Label"
                            />  
                          </mat-form-field>
                    </div>
                    <div class="heading-text">
                    Link
                    </div>
                    <div>
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                              matInput
                              type="text"
                              name="first"
                              (ngModelChange)="ContentChanged()"
                              [(ngModel)]="arrayData.link"
                              placeholder="Link"
                            />  
                          </mat-form-field>
                    </div>
                    </div>
                    <div *ngIf="arrayData.type=='image'">
                        <div class="heading-text">
                            {{arrayData.field}}
                        </div>
                    <div class="Inside-div" *ngIf="arrayData.imageUrl=='';else showImage" (click)="addImage(i)">
                        <div class="upper-div">
                                <div class="upload-icon">
                                    <img src="./../../../assets/images/editor/uploadIcons.svg" alt="upload-icon">
                                </div>
                                <div class="sub-image-text">
                                   <span class="text-color">Click to upload</span> or drag and drop PNG or JPG (atleast. 32x32px)
                                </div>
                        </div> 
                        <input  type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateArrayImage($event,i,j)"
                        class="browse-btn" id="browse_img"/>
                    </div>
                    <ng-template #showImage>
                        <div class="image_fill">
                            <img [src]="arrayData.imageUrl" alt="about-us">
                            <!-- <img [src]="../../../assets/images/editor/aboutus.svg" alt="about-us"> -->
                            <div class="show-reUpload">
                                <div class="updateImage" (click)="addImage(i)">
                                    Update Image
                                </div>
                                <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" (change)="updateArrayImage($event,i,j)"
                        class="browse-btn" id="browse_img"/>
                                <div class="remove-text" (click)="removeArrayImages(i,j)">Remove</div>
                                </div>
                        </div>
                    </ng-template>
                    </div>
            </div>
        </div>
        </div>
        </div>
        <div class="button-section">
            <div class="cancel-btn">
                <button (click)="closePage()">Cancel</button>
            </div>
            <div class="save-btn">
                <button (click)="saveData()">Save</button>
            </div>
        </div>
        </ng-container>
        
        
    
        <ng-container *ngIf="isLocation">
            <div>
                <div class="first-layer">
                    <div class="layer_title">
                        Home
                    </div>
                    <div class="done_btn">
                        <!-- <button (click)="saveData()">Save</button> -->
                        <img src="./../../../../../../../assets/images/close.svg" alt="close_svg" (click)="closePage()" />
                    </div>
                  </div>
                  <hr class="hr-line">
                  <div class="heading-text">
                    Location
                </div>
                <!-- <div class="optimise-text">
                    Set up the business Location.
                </div>
                <div>
                    <button (click)="changeLocation()">Change Location</button>
                </div> -->
                <ng-container *ngFor="let item of data;let i= index">
                    <div *ngIf="item.type=='text'">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div>
                            <!-- <mat-form-field appearance="outline" class="material-form-width">
                                <textarea matInput [(ngModel)]="item.value" style="box-sizing:content-box;"   (ngModelChange)="ContentChanged()"></textarea>
                              </mat-form-field> -->
                              <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
                        </div>
                    </div>
                    <div *ngIf="item.type=='number'">
                        <div class="heading-text">
                            {{item.field}}
                        </div>
                        <div>
                            <!-- <mat-form-field appearance="outline" class="material-form-width">
                                <textarea matInput [(ngModel)]="item.value" style="box-sizing:content-box;"   (ngModelChange)="ContentChanged()"></textarea>
                              </mat-form-field> -->
                              <textarea name="" id="" cols="" rows=""  placeholder="Text" class="text-area" [(ngModel)]="item.value" (ngModelChange)="ContentChanged()"></textarea>
                        </div>
                    </div>
                </ng-container>
                </div>
        </ng-container>
    
        <!-- <ng-container *ngIf="isSeo">
            <div>
                <div class="first-layer">
                    <div class="layer_title">
                        Home
                    </div>
                    <div class="done_btn">
                        <button (click)="saveData()">Save</button>
                    </div>
                  </div>
                  <hr class="hr-line">
                  <div class="heading-text">
                    SEO
                </div>
                <div class="optimise-text">
                    Set up and optimize how your site appears in search engines.
                </div>
                <ng-container>
                    <div class="heading-text">
                        Meta Title
                    </div>
                    <div>
                        <mat-form-field class="material-form-width" appearance="outline">
                            <input
                              matInput
                              type="text"
                              name="first"
                              [(ngModel)]="metaTitle"
                              (ngModelChange)="ContentChanged()"
                              placeholder="text"
                            />  
                          </mat-form-field>
                    </div>
                    <div class="heading-text">
                        Meta Description
                    </div>
                    <div>
                          <textarea name="" id="" cols="" rows=""  placeholder="Meta Description" class="text-area" [(ngModel)]="metaDescription"></textarea>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Search Engine Result Preview</div>
                        <div class="search_engine_box">
                            <div class="google_searchbox">
                                <div class="google_img">
                                    <img src="./../../../../../assets/images/common/google.svg" alt="google">
                                </div>
                                <mat-form-field class="google_search" appearance="outline">
                                    <input matInput [(ngModel)]="metaTitle"/>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{metaTitle ? metaTitle : 'Meta Title'}}
                            </div>
                            <div class="meta_description">
                                {{metaDescription ? metaDescription : 'Meta Description'}}
                            </div>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Facebook Card Preview</div>
                        <div class="search_engine_box">
                            <div class="facebook_imgUrl" *ngIf="facebookImage === undefined; else upImg">
                                Facebook
                            </div>
                            <ng-template #upImg>
                                <div class="fb_imgUrl">
                                    <img [src]="facebookImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{facebookTitle ? facebookTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{facebookDescription ? facebookDescription : 'Description'}}
                            </div>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Twitter Card Preview</div>
                        <div class="search_engine_box">
                            <div class="twitter_imgUrl" *ngIf="twitterImage === undefined; else twitterImg">
                                Twitter
                            </div>
                            <ng-template #twitterImg>
                                <div class="fb_imgUrl">
                                    <img [src]="twitterImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{twitterTitle ? twitterTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{twitterDescription ? twitterDescription : 'Description'}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container> -->
    </div>
</section>
<section class="main_section" *ngIf="pageLoader">
    <div class="loader">
        <img src="./../../../../../../../assets/images/loader/beeos_loader.gif" alt="loader_loading">
    </div>
</section>