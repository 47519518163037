import { Component,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.scss' , './mq-signin-signup.component.scss']
})
export class SigninSignupComponent {
  currentUrl = '';
  constructor(
    private router: Router,
    private route : ActivatedRoute,
    private authService: AuthServiceService,
    private templateService:TemplateService,
    private _dbService : DbService,
    ) {      
    }
  // businessDetails: any = {
  //   businessName: '',
  //   phoneNumber: '',
  //   email: '',
  //   businessType: '',
  //   city: '',
  //   address: '',
  //   checked: true,
  // };
  updatingObject ={
    id:'',
    websiteUrl:''
  };
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  businessForm: any = 'loginDetails';
  edit : any;
  loader : boolean = false;

  countryCodes : any = [];
  selectedCountry : any;

  ngOnInit(){
    // this.route.params.subscribe(
    //   (params) => {
    //     if(params['edit']){
    //       this.edit = params['edit']
    //       //console.log(this.edit)
    //       this.templateService.getBusinessDetailsById(this.encriptionCryptoService.getValue('businessDetails')).subscribe({
    //         next:(res:any) => {
    //           // //console.log(res.data);
    //           this.businessDetails.name = res.data.business.name;
    //           this.businessDetails.category = res.data.business.category;
    //           this.businessDetails.contact.mobile = res.data.business.contact.mobile;
    //           this.businessDetails.contact.email = res.data.business.contact.email;
    //           this.businessDetails.address.city = res.data.business.address.city;
    //           this.businessDetails.address.address = res.data.business.address.address;
    //           this.businessDetails.address.country = res.data.business.address.country;
    //           this.businessDetails.displayId = res.data.business.displayId;
    //           this.updatingObject.id = res.data.business.id;
    //           this.updatingObject.websiteUrl = res.data.business.websiteUrl;
    //           this.businessDetails.logo = res.data.business.logo;
    //         },
    //         error:(err:any) => {
    //           //console.log(err);
    //         }
    //       })
    //     }
    //   }
    // )
    if(this._dbService.getBusinessId() != null){
      this.router.navigate([`/admin/website/preview`]);
    }
    else{}
    this.currentUrl = this.router.url.split('/')[1];
    //console.log(window.localStorage.getItem('bcid'));
    this.getAllCountries();
  }

  disableDropdown : boolean = false;
  getAllCountries(){
    this.authService.getAllCountries().subscribe(
      (res:any) => {
        this.countryCodes = res.data;
        if(this._dbService.getShortCode() != null){
          this.countryCodes.forEach((countries) => {
            if((countries.shortCode).toLowerCase() === this._dbService.getShortCode()){
              this.selectedCountry = countries;
              this.disableDropdown = true;
            }
          });
        }
        else{
          this.selectedCountry = this.countryCodes[0]
        }
      },
      (err) => {
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close',
        )
      }
    )
  }

  continue() {
    if(this.businessDetails.name==""||this.businessDetails.contact.mobile==""||this.businessDetails.contact.email==""){
    this.businessForm="businessDetails";
    this.templateService.openSnack("please fill all mandantory feilds","Ok");
    }
    else{
      if(this.emailRegex.test(this.businessDetails.contact.email)){
        this.businessForm = 'addressDetails';
      }
      else{
        this.templateService.openSnack('please provide valid mail','Ok');
      }
    }
  }
  createBusiness() {
    this.templateService.createBusiness(this.businessDetails).subscribe({
      next:(res:any)=> {
        this.templateService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/list']);
        // this.encriptionCryptoService.storeValue("businessDetails",res.data.id);
      },
      error:(err)=> {
       this.templateService.openSnack("Something went wrong","Close"); 
      },
    }
    )  
  }


  businessDetails={
    displayId: "",
    name: "",
    subDomain: "",
    contact: {
      name: "",
      mobile: "",
      countryCode: "",
      email: ""
    },
    address: {
      address: "",
      city: "",
      country: "91"
    },
    logo: "",
    category: "",
    createdTimeStamp: new Date(),
    status: "ACTIVE",
    checked: true,
    pwd : ""
  }

  backtoCreate(){
    this.businessForm = 'businessDetails';
  }

  updateBusinessDetails(){
    Object.assign(this.businessDetails,this.updatingObject);
    this.templateService.updateBusiness(this.businessDetails).subscribe({
      next:(res:any) => {
        // //console.log(res.data);
        this.templateService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/list']);
        // this.encriptionCryptoService.storeValue("businessDetails",res.data.id);
      },
      error:(err:any) => {
        // //console.log(err)
        this.templateService.openSnack("Something went wrong","Close"); 
      }
    })
  }

  // closeImage(){
  //   this.dialogRef.close();
  // }
  signUpCheck(){
    this.businessForm="businessDetails";
  }

  loginWithGoogle(){
      this.authService.GoogleAuth().then(
        (value:any)=>{
          //console.log(value);
          
        }
      );
  }
  signIn(){
    this.router.navigate(['/admin/website/preview']);
  }



  //signup
  signup: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  signupHide = true;
  get passwordInput() {
    return this.signup.get('password');
  }

  //signin
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3)]),
  });
  signinHide = true;
  get signinPasswordInput() {
    return this.signin.get('password');
  }

  redirectToSignup(){
    if(window.localStorage.getItem('bcid') != null){
      this.router.navigate(['/signup']);
    }
    else{
      this.router.navigate(['/business-type'])
    }
  }
  redirectToSignIn(){
    this.router.navigate(['/login'])
  }

  login(){
    let data = {
      userName:this.businessDetails.contact.email,
      pwd:this.businessDetails.pwd
    }
    this.loader = true;
    this.authService.login(data).subscribe(
      (res:any) => {
        console.log("response",res);  
        
        // window.localStorage.setItem('businessId',res.data.business[0].id);
        this._dbService.setBusinessId(res.data.business[0].id);
        this._dbService.setStaffId(res.data.id);
        this._dbService.setStaffName(res.data.name);
        // window.localStorage.setItem('fname',res.data.name);
        this._dbService.setFname(res.data.contact.name)
        // window.localStorage.setItem('bname',res.data.business[0].name);
        this._dbService.setBusinessName(res.data.business[0].name);
        this.getBusinessDetailsById(res.data.business[0].id);
      },
      (err) =>{
        //console.log(err.error.message);
        this.loader = false;
        this.templateService.openSnack(`${err.error.message}`,'Close')
      }
    )
    // this.router.navigate(['/admin/website/preview']);
    // window.localStorage.setItem('type','admin');
  }
  createAnAccount(){
    if(this.emailRegex.test(this.businessDetails.contact.email)){
      if(this.upperCaseCheck && this.numberCheck && this.specialCharacterCheck && this.minLengthCheck){
        let data = {
          businessName:this._dbService.getBusinessName(),
          contact:{
            mobile:this.businessDetails.contact.mobile,
            email:this.businessDetails.contact.email,
            countryCode:this.selectedCountry.dialingCode,
            name:this.businessDetails.name
          },
          address:{
            city:this._dbService.getLocality(),
            geolocation:{
              latitude:this._dbService.getLat(),
              longitude:this._dbService.getLng()
            }
          },
          industryName:this._dbService.getBusinessCategoryName(),
          industryId:this._dbService.getBusinessCategoryId(),
          subIndustryId:this._dbService.getSubCategoryId(),
          subIndustryName:this._dbService.getSubCategoryName(),
          name:this._dbService.getBusinessName(),
          pwd:this.businessDetails.pwd,
          templateId : this._dbService.getWebsiteId(),
          countryId:this.selectedCountry.id,
          countryCode:this.selectedCountry.shortCode
        }
        this.loader = true;
        // //console.log(data);
        this.authService.createAccount(data).subscribe(
          (res:any) => {
            // window.localStorage.setItem('businessId',res.data.business[0].id);
            this._dbService.setBusinessId(res.data.business[0].id)
            // window.localStorage.setItem('fname',res.data.name);
            this._dbService.setFname(res.data.contact.name);
            this.getBusinessDetailsById(res.data.business[0].id);
          },
          (err) => {
            this.templateService.openSnack(`${err.error.message}`,'Close')
            this.loader = false;
          }
        )
      }
      else{
        this.templateService.openSnack('Please check password length','Ok')
      }
    }
    else{
      this.templateService.openSnack('Please enter valid mail id','Ok')
    }
  }
  getBusinessDetailsById(bId:any){
    this.authService.getDetailsByBusinessId(bId).subscribe(
      (res:any) => {
        //console.log(res.data);
        window.localStorage.setItem('bDetails',JSON.stringify(res.data.business));
        this.router.navigate(['/admin/website/preview']);
        window.localStorage.setItem('type','admin');
        this.loader = false;
      },
      (err) => {
        //console.log(err.error.message);
        this.loader = false;
      }
    )
  }
  goBackToPreviousPage(){
    // this.router.navigate(['/generating-website']);
    // //console.log(window.localStorage.getItem('prevUrl'));
    if(this._dbService.getWebsiteId()){
      this.router.navigate([`${window.localStorage.getItem('prevUrl')}`])
    }
    else{
      this.router.navigate(['/'])
    }
    
  }
  privacyPolicy(){
    this.router.navigate(['/privacy-policy']);
  }
  termsConditions(){
    this.router.navigate(['/terms-conditions'])
  }

  showToolTip : boolean = false;
  top : any = '';
  upperCaseCheck : boolean = false;
  numberCheck : boolean = false;
  specialCharacterCheck : boolean = false;
  minLengthCheck : boolean = false;
  showPasswordChecker(){
    let a = document.getElementById('focus');
    if(!a) return;
    this.showToolTip = true;
    let numbersRegex = /[0-9]/g;
    let specialRegex = /[$&+,:;=?@#|'<>.-^*()%!]/;
    let upperCaseLettersRegex = /[A-Z]/g;
    a.onkeyup = ()=>{
      let inputValue = (<HTMLInputElement>document.getElementById('focus')).value;
      let captial = document.getElementById('capital');
      let special = document.getElementById('special_character');
      let number = document.getElementById('number');
      let min_length = document.getElementById('min_length');
      if(inputValue[0].match(upperCaseLettersRegex)){
        captial?.classList.add('success_text')
        this.upperCaseCheck = true;
      }
      else{
        captial?.classList.remove('success_text');
        this.upperCaseCheck = false;
      }


      //special character
      if(inputValue.match(specialRegex)){
        special?.classList.add('success_text');
        this.specialCharacterCheck = true;
      }
      else{
        special?.classList.remove('success_text');
        this.specialCharacterCheck = false;
      }

      // number checks
      if(inputValue.match(numbersRegex)){
        number?.classList.add('success_text');
        this.numberCheck = true;
      }
      else{
        number?.classList.remove('success_text');
        this.numberCheck = false;
      }

      //min length checks
      if(inputValue.length >=8){
        min_length?.classList.add('success_text')
        this.minLengthCheck = true;
      }
      else{
        min_length?.classList.remove('success_text');
        this.minLengthCheck = false
      }
    }
  }

  hide(){
    this.showToolTip = false;
  }

  forgotPassword(){
    this.templateService.openDialog(
      ForgotPasswordComponent,
      'auto',
      '540px',
      '80vw'
    )
  }
}
