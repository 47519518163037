<section
  class="main_section"
  [ngClass]="pageData?.className"
  *ngIf="!loader && !sectionLoading"
  id="contact_us_section"
>
  <div class="left_container">
    <div class="contact_heading">Contact Us</div>
    <div
      class="contact_description"
      [ngStyle]="pageData?.contactDescription.textStyles"
    >
      {{ pageData?.contactDescription.text | titlecase }}
    </div>
  </div>
  <div class="right_container">
    <div class="name_email">
      <div class="name_container">
        <div class="label">Name</div>
        <mat-form-field appearance="outline" class="sfull-width">
          <input matInput placeholder="Enter Name" />
        </mat-form-field>
      </div>
      <div class="email_container">
        <div class="label">Email</div>
        <mat-form-field appearance="outline" class="sfull-width">
          <input matInput placeholder="Enter Email Address" />
        </mat-form-field>
      </div>
    </div>
    <div class="message_container">
      <div class="label">Message</div>
      <textarea
        placeholder="Enter Message"
        [cols]="coloumnsLength"
        rows="4"
      ></textarea>
    </div>
    <div class="send_btn">
      <button [ngStyle]="pageData?.buttonText.textStyles" class="btn_value">
        Send
      </button>
    </div>
  </div>
</section>
<section *ngIf="loader" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</section>
<section *ngIf="sectionLoading" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <div class="text">generating contact us form</div>
</section>
