import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener } from '@angular/core';
import { BusinessService } from 'src/app/master-config-components/masters/business/business.service';
import { PeriodicElement } from 'src/app/master-config-components/masters/business/registration-type/list-registration-type/list-registration-type.component';
import { TemplateService } from 'src/app/services/template.service';
import { BlogServiceService } from '../../blog-service.service';
import { AddAuthorComponent } from '../add-author/add-author.component';
import { DeleteAuthorComponent } from '../delete-author/delete-author.component';
import { ViewAuthorComponent } from '../view-author/view-author.component';

@Component({
  selector: 'app-list-author',
  templateUrl: './list-author.component.html',
  styleUrls: ['./list-author.component.scss', './mq-list-author.component.scss']
})
export class ListAuthorComponent {


  displayedColumns: string[] =
    [
      'position',
      'email',
      'count',
      'date',
      'Action'
    ];
  dataSource: any = [];
  ischecked: boolean = true;


  constructor(
    private templateService: TemplateService,
    private businessSerive: BusinessService,
    public blogService: BlogServiceService
  ) {
    this.getScreenSize();
  }


  ngOnInit() {
    this.getAllListing();
  }


  searchbar: boolean = false;
  search() {
    this.searchbar = !this.searchbar;
  }
  closesearch() {
    this.searchbar = false;
    this.authorName = '';
    this.getAllListing();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  addAuthor() {
    let dialog = this.blogService.openDeleteDialog(
      AddAuthorComponent,
      'auto',
      '400px',
      { edit: false },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      this.getAllListing();
    });
  }

  editAuthor(tag: any) {
    let dialog = this.blogService.openDeleteDialog(
      AddAuthorComponent,
      'auto',
      '400px',
      { edit: true, data: tag },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      this.getAllListing();
    });
  }


  deleteAuthor(authorId: any) {
    let dialog = this.blogService.openDeleteDialog(
      DeleteAuthorComponent,
      '250px',
      '350px',
      { data: authorId },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      if (!data) return
      this.getAllListing();
    });
  }
  authorName: any;
  emptyloader: boolean = false;
  getAllListing() {
    this.emptyloader = true;
    this.blogService.getAllBlogAuthors(JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }


  _searchAuthor() {
    if (this.authorName.length > 2) this.searchAuthor();
    else if (this.authorName === '') this.getAllListing();
  }
  // search 
  searchAuthor() {
    this.emptyloader = true;
    this.blogService.searchBlogsAuthorByBusinessId(this.authorName, JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }

  // checkbox 
  exportenquiry:any = [];
  enquirycheck(item: any, active: any, event: any) {
    event.stopPropagation();
    // if (!active) {
    //   this.exportenquiry.push(item);
    // } else {
    //   this.exportenquiry.forEach((element, i) => {
    //     if (item.leadId === element.leadId) {
    //       this.exportenquiry.splice(i, 1);
    //     }
    //   });
    // }
  }
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }
  selection = new SelectionModel<PeriodicElement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.exportenquiry = [];
      return;
    }

    this.selection.select(...this.dataSource);
    this.exportenquiry = [];
    this.dataSource.forEach((element) => {
      this.exportenquiry.push(element);
    });
  }

  // toggle button 
  isToggleChecked: boolean = false;
  activestatus(element: any, index: any) {
    this.isToggleChecked = true;
    // element.tt = true;
    element.active = !element.active;
  }


  viewTag(authorId: any) {
    let dialog = this.blogService.openDeleteDialog(
      ViewAuthorComponent,
      '100vh',
      '50%',
      { data: authorId },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      if (!data) return;
      this.getAllListing();
    });
  }
}
