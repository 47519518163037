import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private http: HttpClient,
  ) { }

  // Bussiness Industry API

  // for Business Industry listing 
  getBusinessIndustry(page:number,size:number){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry?size=${size}&page=${page}`
    )
  }

  // for Bussiness Industry Search 
  getSearchIndustry(page:number,size:number,searchText:any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry?size=${size}&page=${page}&text=${searchText}`
    )
  }

  //for add Industry
  addBusinessIndustry(data:any){
    return this.http.post(
      environment.baseUrl+`admin/master/business/industry`,data
    )
  }


  // Sub Industry API 
  // for sub industry Listing api 
  getSubIndustries(page:number,size:number){
    return this.http.get(
      environment.baseUrl + `admin/master/business/industry/sub?size=${size}&page=${page}`
    )
  }

   // for Bussiness Industry Search 
   getSearchSubIndustry(page:number,size:number,searchText:any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry/sub?size=${size}&page=${page}&text=${searchText}`
    )
  }

   //for add Sub Industry
   addBusinessSubIndustry(data:any){
    return this.http.post(
      environment.baseUrl+`admin/master/business/industry/sub`,data
    )
  }
  
  getSubIndustryByIndustry(data:any){
    return this.http.put(
      environment.baseUrl+`admin/master/business/industry/sub/list`,data
    )
  }


  // Business Type API 

  getBusinessType(page:number,size:number){
    return this.http.get(
      environment.baseUrl+`admin/master/business/type?size=${size}&page=${page}`
    )
  }

  // for Bussiness type Search 
  getSearchType(page:number,size:number,searchText:any){
    return this.http.get(
      environment.baseUrl+`admin/master/business/type?size=${size}&page=${page}&text=${searchText}`
    )
  }

  // for add bussines type 
  addBusinessType(data:any){
    return this.http.post(
      environment.baseUrl+`admin/master/business/type`,data
    )
  }


  // Registration business API 
  // for listing 

  getRegistrationListing(page:number,size:number){
    return this.http.get(
      environment.baseUrl + `admin/master/business/registration?size=${size}&page=${page}`
    )
  }

  // for search 
  getSearchRegistration(page:number,size:number,searchText:any){
    return this.http.get(
      environment.baseUrl + `admin/master/business/registration?size=${size}&page=${page}&text=${searchText}`
    )
  }

  // for add registration business type 

  addRegistrationType(data:any){
    return this.http.post(
      environment.baseUrl + `admin/master/business/registration`,data
    )
  }

  //merged industries and subindustries api
  mergedIndustrySubIndustry(){
    return this.http.get(
      environment.baseUrl+`admin/master/business/industry/sub?size=1000&page=0`
    )
  }

}
