<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="left-container">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value | titlecase}}</div>
        <div class="description" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        <div class="buttons">
            <!-- <button class="getStartedBtn" [ngStyle]="data[2].style?data[2].style:{}">{{data[2].value | titlecase}}</button> -->
            <button class="learnMoreBtn" [ngStyle]="data[3].style?data[3].style:{}" (click)="redirectUrl()">{{data[3].value | titlecase}}</button>
        </div>
    </div>
    <div class="right-container">
        <img class="imageProperties" [ngStyle]="data[4].style?data[4].style:{}" src="{{data[4].imgUrl}}" alt="picture">
    </div>
</div>