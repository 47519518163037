import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebisiteService } from '../webisite.service';
import { SeoImageUploadService } from './../../../../services/ImageUploadService/seo-image-upload.service';
import { TemplateService } from './../../../../services/template.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss', './mq-seo.component.scss']
})
export class SeoComponent implements OnInit{

  metaTitle = '';
  metaTitlelength = 0;
  metaDescription = '';
  metaDescriptionLength = 0;

  facebookTitle = '';
  facebookTitleLength = 0;
  facebookDescription = '';
  facebookDescriptionLength = 0;

  twitterTitle = '';
  twitterTitleLength = 0;
  twitterDescription = '';
  twitterDescriptionLength = 0;
  domainName:String="";

  constructor(
    private ds : DomSanitizer,
    private templateService : TemplateService,
    private _seoImageUploadService : SeoImageUploadService,
    private _websiteService : WebisiteService
  ){

  }

  businessDetails:any;
  pageLoader : boolean = false;
  gettingData: any;

  ngOnInit(): void {
    let bDetails = window.localStorage.getItem('bDetails') || '';
    if(bDetails != '') {
      this.businessDetails = JSON.parse(bDetails);
      this.getBusinessDetailsByBusinessId();
    }
  }

  getBusinessDetailsByBusinessId(){
    this.pageLoader = true;
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res:any) => {
        //console.log(res.data);
        this.gettingData = res.data;
        this.metaTitle = this.gettingData.website.metaTitle;
        this.metaDescription = this.gettingData.website.metaDesc;
        this.facebookImage = this.gettingData.website.fbImage ? this.gettingData.website.fbImage : undefined;
        this.facebookTitle = this.gettingData.website.fbTitle;
        this.facebookDescription = this.gettingData.website.fbDesc;
        this.twitterImage = this.gettingData.website.twitterImage ? this.gettingData.website.twitterImage : undefined;
        this.twitterTitle = this.gettingData.website.twitterTitle;
        this.twitterDescription = this.gettingData.website.twitterDesc;
        if(this.gettingData.website.domainUrl) {
          this.domainName = this.gettingData.website.domainUrl;
        } else {
          this.domainName = this.gettingData.website.websiteUrl;
        }
        // //console.log(this.websiteStatus)
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  //meta card

  metaTitleFun(){
    this.metaTitlelength = this.metaTitle.length;
  }
  metaDescFun(){
    this.metaDescriptionLength = this.metaDescription.length;
  }

  //facebook card
  facebookTitleFun(){
    this.facebookTitleLength = this.facebookTitle.length;
  }
  facebookDescriptionFun(){
    this.facebookDescriptionLength = this.facebookDescription.length
  }
  browseFcImage(){
    document.getElementById('browse_fc_image')?.click();
  }
  facebookImageLoader : boolean = false;
  facebookImage :any ;
  async updateFcImage(ev:any){
    this.pageLoader = true;
    // ////console.log();
    // let fcImg = URL.createObjectURL(ev.srcElement.files[0])
    // ////console.log(a);
    // this.facebookImage = this.ds.bypassSecurityTrustUrl(fcImg)
    let img:File = ev.srcElement.files[0];
    const file = ev.srcElement.files[0];
    // //console.log(this.businessDetails);
    
    this.facebookImage = await this._seoImageUploadService.uploadSEOimages(file)
    this.facebookImage = this.facebookImage.Location;
    this.pageLoader = false;
  }

  dropHandler(ev:any) {
    // ////console.log('File(s) dropped');
  
    // Prevent default behavior (Prevent file from being opened)
    // ev.preventDefault();
  
    // if (ev.dataTransfer.items) {
    //   // Use DataTransferItemList interface to access the file(s)
    //   [...ev.dataTransfer.items].forEach((item, i) => {
    //     // If dropped items aren't files, reject them
    //     if (item.kind === 'file') {
    //       const file = item.getAsFile();
    //       // ////console.log(`… file[${i}].name = ${file.name}`);
    //     }
    //   });
    // } else {
    //   // Use DataTransfer interface to access the file(s)
    //   [...ev.dataTransfer.files].forEach((file, i) => {
    //     // ////console.log(`… file[${i}].name = ${file.name}`);
    //   });
    // }
    // ////console.log('first working');
    // ev.preventDefault();
    // // ev.dataTransfer.dropEffect = "move";
    // ////console.log(ev.dataTransfer);
    ev.preventDefault();
    // ////console.log(ev.srcElement.files);
    let html = ev.dataTransfer.getData("text/html");
    // ////console.log(html);
    let src = new DOMParser().parseFromString(html,"text/html").querySelector('img')?.src;
    ////console.log(src);
    
    
  }

  kk : any;
  dragOverHandler(ev:any) {
    // ////console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    // ev.preventDefault();
    // // ////console.log('second working');
    // ////console.log(ev);
    ev.preventDefault();
    // Get the id of the target and add the moved element to the target's DOM
    // const data = ev.dataTransfer.getData("text/plain");
    // ev.target.appendChild(document.getElementById(data));
    
    
  }

  //Twitter Card
  browseTwitterImage(){
    document.getElementById('browse_twitter_image')?.click();
  }
  twitterImage :any ;
  async updateTwitterImage(ev:any){
    this.pageLoader = true;
    // ////console.log();
    // let fcImg = URL.createObjectURL(ev.srcElement.files[0])
    // // ////console.log(a);
    // this.twitterImage = this.ds.bypassSecurityTrustUrl(fcImg)
    let img:File = ev.srcElement.files[0];
    const file = ev.srcElement.files[0];
    // //console.log(this.businessDetails);
    
    this.twitterImage = await this._seoImageUploadService.uploadSEOimages(file)
    this.twitterImage = this.twitterImage.Location;
    this.pageLoader = false
  }  
  twitterTitleFun(){
    this.twitterTitleLength = this.twitterTitle.length;
  }
  twitterDescriptionFun(){
    this.twitterDescriptionLength = this.twitterDescription.length;
  }



  updateSeoTags(){
    this.pageLoader = true;
    let data = {
      businessId:this.gettingData.website.businessId,
      metaTitle:this.metaTitle,
      metaDesc:this.metaDescription,
      fbImage:this.facebookImage,
      fbTitle:this.facebookTitle,
      fbDesc:this.facebookDescription,
      twitterImage:this.twitterImage,
      twitterTitle:this.twitterTitle,
      twitterDesc:this.twitterDescription
    }

    // //console.log(data);
    this._websiteService.updateSEOTagsFromSpecificWebsite(data).subscribe(
      (res:any) => {
        //console.log(res.data);
        
        this.templateService.openSnack(
          `SEO Tags Updated Successfully`,
          'Ok'
        );
        this.pageLoader = false
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }
  

}
