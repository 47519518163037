<div class="bgContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="mainContainer">
        <div class="headingContainer">
            <div [ngStyle]="data[0].style?data[0].style:{}" class="heading">
                {{data[0].value | titlecase}}
            </div>
            <!-- <div [ngStyle]="data[1].style?data[1].style:{}" class="description">{{data[1].value}}</div> -->
        </div>
        <div class="rowFlexContainer">
            <div class="imageContainer">
                <img class="section_image" src="{{data[2].imgUrl}}" alt="image" [ngStyle]="data[2].style?data[2].style:{}">
            </div>
            <div class="content">
                <div [ngStyle]="data[3].style?data[3].style:{}" class="numberStyle">{{data[3].value}}</div>
                <div [ngStyle]="data[4].style?data[4].style:{}" class="contentHeading">{{data[4].value}}</div>
                <div [ngStyle]="data[5].style?data[5].style:{}" class="contentDescription">{{data[5].value}}</div>
            </div>
        </div>
        <div class="rowFlexContainer2">
            <div class="content">
                <div [ngStyle]="data[6].style?data[6].style:{}" class="numberStyle">{{data[6].value}}</div>
                <div [ngStyle]="data[7].style?data[7].style:{}" class="contentHeading">{{data[7].value}}</div>
                <div [ngStyle]="data[8].style?data[8].style:{}" class="contentDescription">{{data[8].value}}</div>
            </div>
            <div class="imageContainer">
                <img class="section_image" src="{{data[9].imgUrl}}" alt="image" [ngStyle]="data[9].style?data[9].style:{}">
            </div>
        </div>
        <div class="rowFlexContainer">
            <div class="imageContainer">
                <img class="section_image" src="{{data[10].imgUrl}}" alt="image" [ngStyle]="data[10].style?data[10].style:{}">
            </div>
            <div class="content">
                <div [ngStyle]="data[11].style?data[11].style:{}" class="numberStyle">{{data[11].value}}</div>
                <div [ngStyle]="data[12].style?data[12].style:{}" class="contentHeading">{{data[12].value}}</div>
                <div [ngStyle]="data[13].style?data[13].style:{}" class="contentDescription">{{data[13].value}}</div>
            </div>
        </div>
    </div>
</div>