<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <img src="{{data[0].imgUrl}}" alt="image" [ngStyle]="data[0].style?data[0].style:{}" class="leftContainerImage">
    </div>
    <div class="rightContainer">
        <div [ngStyle]="data[1].style?data[1].style:{}" class="heading">
            {{data[1].value}}
        </div>
        <div class="description">
            <div [ngStyle]="data[2].style?data[2].style:{}" class="numbers">
                {{data[2].value}}
            </div>
            <div [ngStyle]="data[3].style?data[3].style:{}" class="data">
                {{data[3].value}}
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="description">
            <div [ngStyle]="data[4].style?data[4].style:{}" class="numbers">
                {{data[4].value}}
            </div>
            <div [ngStyle]="data[5].style?data[5].style:{}" class="data" >
                {{data[5].value}}
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="description">
            <div [ngStyle]="data[6].style?data[6].style:{}" class="numbers">
                {{data[6].value}}
            </div>
            <div [ngStyle]="data[7].style?data[7].style:{}" class="data">
                {{data[7].value}}
            </div>
        </div>
    </div>
</div>