<div class="bgContainer">
    <div class="rowFlexContainer">
        <div class="aboutContainer">
            <div class="heading" [ngStyle]="aboutBeeos.style?aboutBeeos.style:{}">{{aboutBeeos.value}}</div>
            <div  class="description" [ngStyle]="description.style?description.style:{}">{{description.value}}</div>
            <div class="icons">
                <img [ngStyle]="facebookIcon.style?facebookIcon.style:{}" src="{{facebookIcon.imgUrl}}" alt="">
                <img [ngStyle]="twitterIcon.style?twitterIcon.style:{}" src="{{twitterIcon.imgUrl}}" alt="">
                <img [ngStyle]="linkedInIcon.style?linkedInIcon.style:{}" src="{{linkedInIcon.imgUrl}}" alt="">
            </div>
            <div [ngStyle]="copyRightsText.style?copyRightsText.style:{}">{{copyRightsText.value}}</div>
        </div>
        <div class="findOutContainer">
            <div class="headings" [ngStyle]="findOutText.style?findOutText.style:{}">{{findOutText.value}}</div>
            <ul class="findOutList">
                
                <!-- <li [ngStyle]="portfolioText.style?portfolioText.style:{}">{{portfolioText.value}}</li>
                <li [ngStyle]="caseStudyText.style?caseStudyText.style:{}">{{caseStudyText.value}}</li> -->
                <li [ngStyle]="aboutUsText.style?aboutUsText.style:{}" class="aboutus">{{aboutUsText.value}}</li>
                <!-- <li [ngStyle]="careersText.style?careersText.style:{}">{{careersText.value}}</li> -->
                <li [ngStyle]="blogText.style?blogText.style:{}" class="blog">{{blogText.value}}</li>
                <li [ngStyle]="servicesText.style?servicesText.style:{}" class="service">{{servicesText.value}}</li>
            </ul>
            <!-- <div [ngStyle]="servicesText.style?servicesText.style:{}">{{servicesText.value}}</div>
            <div [ngStyle]="portfolioText.style?portfolioText.style:{}">{{portfolioText.value}}</div>
            <div [ngStyle]="caseStudyText.style?caseStudyText.style:{}">{{caseStudyText.value}}</div>
            <div [ngStyle]="aboutUsText.style?aboutUsText.style:{}">{{aboutUsText.value}}</div>
            <div [ngStyle]="careersText.style?careersText.style:{}">{{careersText.value}}</div>
            <div [ngStyle]="blogText.style?blogText.style:{}">{{blogText.value}}</div> -->
        </div>
        <div class="contactUsContainer">
            <div class=" contact_heading" [ngStyle]="contactUsText.style?contactUsText.style:{}">{{contactUsText.value}}</div>
            <div [ngStyle]="mobileNumber.style?mobileNumber.style:{}" class="mobile">{{mobileNumber.value}}</div>
            <div [ngStyle]="emailAddress.style?emailAddress.style:{}" class="email">{{emailAddress.value}}</div>
            <div [ngStyle]="address.style?address.style:{}" class="address">{{address.value}}</div>
        </div>
    </div>
</div>
