<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value}}</div>
        <div class="description">
            <div class="rowFlex">
                <img class="tickImg" src="./../../../../../../../../assets/images/common/rightTick.jpg" alt="" [ngStyle]="data[6].style?data[6].style:{}">
                <div [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
            </div>
            <div class="rowFlex">
                <img class="tickImg" src="./../../../../../../../../assets/images/common/rightTick.jpg" alt="" [ngStyle]="data[6].style?data[6].style:{}">
                <div [ngStyle]="data[2].style?data[2].style:{}">{{data[2].value}}</div>
            </div>
            <div class="rowFlex">
                <img class="tickImg" src="./../../../../../../../../assets/images/common/rightTick.jpg" alt="" [ngStyle]="data[6].style?data[6].style:{}">
                <div [ngStyle]="data[3].style?data[3].style:{}">{{data[3].value}}</div>
            </div>
        </div>
        <div class="buttons">
            <!-- <button class="learnMoreBtn" [ngStyle]="learnMoreBtn.style?learnMoreBtn.style:{}">{{learnMoreBtn.value}}</button> -->
            <button class="getStartedBtn" [ngStyle]="data[5].style?data[5].style:{}" (click)="redirectTo()">{{data[5].value | titlecase}}</button>
        </div>
    </div>
    <div class="rightContainer">
        <img class="imageProperties" src="{{data[7].imgUrl}}" alt="image" [ngStyle]="data[7].style?data[7].style:{}">
    </div>
</div>