import { Component } from '@angular/core';

@Component({
  selector: 'app-stats-text-wrap',
  templateUrl: './stats-text-wrap.component.html',
  styleUrls: ['./stats-text-wrap.component.scss']
})
export class StatsTextWrapComponent {

  templateData : any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "The Landingfolio Facts",
        type : 'text', field : 'text',
        style : {'color':'#FFFFFF'}
      },
      {
        value : "1M+",
        type : 'text', field : 'text',
        style : {'color':'white'}
      },
      {
        value:"37K+",
        type:"text",
        field:"text",
        style:{'color':'white'}
      },
      {
        value:"99%",
        type:"text",
        field:"text",
        style:{'color':'white'}
      },
      {
        value:"25",
        type:"text",
        field:"text",
        style:{'color':'white'}
      },
      {
        value:"42%",
        type:"text",
        field:"text",
        style:{'color':'white'}
      },
      {
        value : "Tickets Delivered This Month",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value:"Active Customers Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Customer Satisfaction Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Expert Team Members",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Customers Retention Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats5Component",
  }
  data = this.templateData.data;
  heading = this.data[0];
  subheading1 =  this.data[1];
  subheading2 = this.data[2]
  subheading3 = this.data[3]
  subheading4 = this.data[4]
  subheading5 = this.data[5]
  subtext1 = this.data[6];
  subtext2 = this.data[7]
  subtext3 = this.data[8]
  subtext4 = this.data[9]
  subtext5 = this.data[10]
  
}
