<section class="main_section">
    <div class="main-header-section">
        <div class="main-left-side">
            <div class="main-heading" [ngStyle]="data[0].style?data[0].style:{}">
                {{data[0].value}}
            </div>
            <div class="sub-heading" [ngStyle]="data[1].style?data[1].style:{}">
                {{data[1].value}}
            </div>
        </div>
        <div class="main-right-side">
            <div class="right-customer-btn" [ngStyle]="data[2].style?data[2].style:{}">
                {{data[2].value}}
            </div>
            <div class="right-create-btn" [ngStyle]="data[3].style?data[3].style:{}">
                {{data[3].value}}
            </div>
        </div>
    </div>
    <div class="main-middle-section">
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
        <div class="first-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="main-img" />
            <div class="white-box">
                <div class="star-image">
                    <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                    class="stars-img" />
                </div>
                <div class="first-image-name" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
                <div class="first-image-position" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="first-image-destination" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>

            </div>
        </div>
    </div>
</section>