<div class="mainSection" [ngStyle]="{'background-color': bgColor}">
    <div class="headSection">
        <!-- <div class="rowFlex">
            <div>
                <img src="" alt="">
            </div>
            <div [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        </div> -->
        <div class="heading" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
    </div>
    <div class="imageSection">
        <div class="centerImageProperties">
            <img class="imageStyle"  [ngStyle]="data[11].style?data[11].style:{}" src="{{data[11].imgUrl}}" alt="">
        </div>
    </div>
    <div class="belowSection">
        <div class="bottomContent">
            <div class="contentProperties">
                <div class="numbersSize" [ngStyle]="data[3].style?data[3].style:{}">{{data[3].value}}</div>
                <div class="contentText" [ngStyle]="data[4].style?data[4].style:{}">{{data[4].value}}</div>
            </div>
            <div class="contentProperties">
                <div class="numbersSize" [ngStyle]="data[5].style?data[5].style:{}">{{data[5].value}}</div>
                <div class="contentText" [ngStyle]="data[6].style?data[6].style:{}">{{data[6].value}}</div>
            </div>
            <div class="contentProperties">
                <div class="numbersSize" [ngStyle]="data[7].style?data[7].style:{}">{{data[7].value}}</div>
                <div class="contentText" [ngStyle]="data[8].style?data[8].style:{}">{{data[8].value}}</div>
            </div>
        </div>
        <div class="ourMissionContentSize">
            <div class="ourMission" [ngStyle]="data[9].style?data[9].style:{}">{{data[9].value}} <span><mat-icon class="iconColor">keyboard_arrow_right</mat-icon></span></div>
            <div class="ourMissionText"[ngStyle]="data[10].style?data[10].style:{}">{{data[10].value}}</div>
        </div>
    </div>
</div>