import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";
import { TemplateService } from "src/app/services/template.service";
import { WebisiteService } from "../../../../webisite.service";

@Component({
  selector: 'app-single-horizontal-testimonial',
  templateUrl: './single-horizontal-testimonial.component.html',
  styleUrls: ['./single-horizontal-testimonial.component.scss' , './mq-single-horizontal-testimonial.components.scss']
})
export class SingleHorizontalTestimonialComponent implements OnInit{

  @Input() templateData:any;
  @Input() color:any;
  valuesData:any;
  componentData: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        //console.log("value check",value);
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'single_horizontal_testimonial'){
            this.templateData=conCompList;  
            this.dataAssign();
          }
          else{
            
          }
        });
      }
    });
    this.dataAssign(); 
  }
  // templateData:any = {
  //   "componentCategory" : "testimonial",
  //   "ranking" : 0,
  //   "businessCategoryId" : "",
  //   "businessCategoryName" : "All category",
  //   "data" : [
  //     {
  //       "imgUrl" : "./../../../../../../../../assets/images/testimonials/quote-left.svg",
  //       "display" : true,
  //       "type" : "image",
  //       "field" : "image",
  //       "style" : {"width":"35px", "height":"35px","opacity":"0.5"}
  //     },
  //     {
  //       "value" : "People now recognise that having a good performance conversation means that something happens as a result.",
  //       "field" : "text",
  //       "type" : "text",
  //       "style" : {"font-weight":700, "font-size":"22px","color":"#121212","margin-top":"35px"}
  //     },
  //     {
  //       "value" : "With Landingfolio, the design team can now build design which identifies employees career aspirartions and goals and from which we approach managers",
  //       "field" : "text",
  //       "type" : "text",
  //       "style" : { "font-size":"20px","color":"#959393","margin-top":"35px"}
  //     },
  //     {
  //       "value" : "Read Success Story",
  //       "field" : "button",
  //       "type" : "button",
  //       link : "",
  //       "display" : true,
  //       "style" : {"color":"blue","font-size":"16px","font-weight":700,"margin-top":"35px"}
  //     },
  //     {
  //       "imgUrl" : "./../../../../../../../../assets/images/testimonials/profile-img.jpg",
  //       "display" : true,
  //       "type" : "image",
  //       "field" : "image",
  //       "style" : {"width":"100%","height":"345px","border-radius":"25px"}
  //     },
  //     {
  //       "value" : "Albert Flores",
  //       "field" : "text",
  //       "type" : "text",
  //       "style" : {"color":"#ffffff","font-size":"16px","font-weight":600}
  //     },
  //     {
  //       "value" : "Product Manager at Jamanar",
  //       "field" : "text",
  //       "type" : "text",
  //       "style" : {"color":"#DFDFDE","font-size":"14px"}
  //     }
  //   ],
  //   "createdTimeStamp" : "",
  //   "language" : "English",
  //   "componentName" : "Testimonial3Component"
  // }
  // componentName = this.templateData.componentName;
  // data = this.templateData.data;
  data:any;
  // text = this.data[1];
  text:any;
  // subtext = this.data[2];
  subtext : any;
  // link = this.data[3];
  // image = this.data[0];
  // profileImg = this.data[4];
  profileImg : any;
  // person = this.data[5];
  person : any;
  // designation = this.data[6];
  designation : any;
  bgColor : any;

  dataAssign(){
    this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    let bName = this._dbService.getBusinessName();
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.valuesData != null) {
      this.data[1].value = this.valuesData.title.replaceAll("{Company Name}",bName);
      this.data[2].value = this.valuesData.data[0].text.replaceAll("{Company Name}",bName);
      this.data[4].imgUrl = this.valuesData.data[0].reviewerImage;
      this.data[5].value = this.valuesData.data[0].reviewedBy;
      this.data[6].value = this.valuesData.data[0].reviewerDesignation;
      this.data[7].value = this.valuesData.data[0].desc.replaceAll("{Company Name}",bName);
    }


    // this.text = this.valuesData.title;
    // this.subtext = this.valuesData.subTitle;
    // this.profileImg = this.valuesData.data[0].reviewerImage;
    // this.person = this.valuesData.data[0].reviewedBy;
    // this.designation = this.valuesData.data[0].reviewerDesignation;

  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
