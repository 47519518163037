<!-- <div class="process-backgroundImage" id="process_section_Image">
    <img [src]="processSection.processSectionImage">
</div> -->
<!-- <div class="process-text" id="process_section_Titletext" [ngStyle]="navId === 'process_section_Titletext' ? {'border':'2px solid #F79824'}:{}">
    {{processSection.processSectionText}}
</div> -->
<section
  class="main_section"
  *ngIf="!loader && !sectionLoading"
  id="process_section"
>
  <div
    class="project-text1-title"
    id="process_section_Titletext1"
    [ngStyle]="pageData?.processSectionText1.textStyles"
  >
    {{ pageData?.processSectionText1.text | titlecase }}
  </div>
  <div
    class="project-text2-title"
    id="process_section_Titletext2"
    [ngStyle]="pageData?.processSectionText2.textStyles"
  >
    {{ pageData?.processSectionText2.text }}
  </div>
  <div class="yellow-line">
    <div class="line-contaner"></div>
  </div>
  <div class="process-sectioncard">
    <ng-container *ngFor="let process of pageData?.processArray">
      <div class="card-section">
        <div
          class="card-number"
          [id]="process?.serviceTitle?.id"
          [ngStyle]="process?.serviceTitle?.textStyles"
        >
          {{ process.serviceTitle.name }}
        </div>
        <div
          class="sub-text"
          [id]="process?.serviceText?.id"
          [ngStyle]="process?.serviceText?.textStyles"
        >
          {{ process?.serviceText.name }}
        </div>
        <div
          class="description"
          [id]="process?.serviceDesc?.id"
          [ngStyle]="process?.serviceDesc.textStyles"
        >
          {{ process.serviceDesc.name }}
        </div>
      </div>
    </ng-container>
  </div>
</section>
<section *ngIf="loader" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</section>
<section *ngIf="sectionLoading" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <div class="text">Getting Best Processing for you</div>
</section>
