import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-stats-text-partition',
  templateUrl: './stats-text-partition.component.html',
  styleUrls: ['./preview-stats-text-partition.component.scss']
})
export class PreviewStatsTextPartitionComponent {
  templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "The Landingfolio Facts",
        type :'text', field :'text',
        style : {}
      },
      {
        value : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        type : 'text', field : 'text',
        style : {'color':'#959393'}
      },
      {
        value : "1M+",
        type : 'text', field : 'text',
        style : {'font-weight':700}
      },
      {
        value:"53K+",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value:"98.29%",
        type:"text",
        field:"text",
        style:{'font-weight':700}
      },
      {
        value:"Active Customers Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value:"Customer Satisfaction Rate",
        type:"text",
        field:"text",
        style:{'color':'#959393'}
      },
      {
        value :"Tickets Delivered This Month",
        type : 'text', 
        field : 'text',
        style : {'color':'#959393'}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats3Component",
  }
  data = this.templateData.data;
  heading = this.data[0];
  subheading =  this.data[1];
  stats1Heading = this.data[2];
  stats2Heading = this.data[3]
  stats3Heading = this.data[4]
  stats1Text = this.data[5];
  stats2Text = this.data[6]
  stats3Text = this.data[7]

}
