<section class="main_section">
    <div class="main-header-section">
        <div class="header-left-side" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
        <div class="header-right-side">
            <div class="right-side-text" [ngStyle]="data[2].style?data[2].style:{}">
                {{data[2].value}}
            </div>
            <div class="right-side-image">
                <img src="{{data[0].imgUrl}}" alt="" [ngStyle]="data[0].style?data[0].style:{}"
                class="editor-img" />
            </div>
        </div>
    </div>
    <div class="middle-header-section" [ngStyle]="data[3].style?data[3].style:{}">
        {{data[3].value}}
    </div>
    <div class="header-bottom-section" [ngStyle]="data[4].style?data[4].style:{}">
        {{data[4].value}}
    </div>
    <div class="header-button-section">
        <div class="demo-button"[ngStyle]="data[6].style?data[6].style:{}">
                {{data[6].value}}
        </div>
        <div class="contact-us-btn" [ngStyle]="data[7].style?data[7].style:{}">
            {{data[7].value}}
        </div>
    </div>
</section>
