import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-home-hero-banner',
  templateUrl: './home-hero-banner.component.html',
  styleUrls: ['./home-hero-banner.component.scss','./mq-hero-banner.component.scss']
})
export class HomeHeroBannerComponent {
  @Input() pageData:any;
  @Input() templateData:any;
   componentData:any=null;
   constructor(
     private _activatedRoute : ActivatedRoute,
     private templatesService:TemplateService,
     private emitterService : EventEmmiterService
   ){
   }
 
   navId : any;
   services:any;
   serviceText:string="";
   loader : boolean = false;
   sectionLoading : boolean = false;
   ngOnInit(){
     this.templatesService.updateElements.subscribe({
       next:(value:any)=>{
         //console.log("value check",value);
         this.templateData=value;         
       }
     })
     this.dataAssign(); 
   }
 
 
   data:any=[]
 
   check(){
 
   }
 
   value:any=[]
 
   smallText:any;
   bigText:any;
   buttonConfig:any;
   dataAssign(){
     this.templateData.componentContentList.forEach((value:any) => {
       if(value.componentName=='Home-hero-banner'){
         this.componentData=value;
         this.data=value.data;
       }
     });
     //console.log(this.data);
     this.smallText=this.data[1].value;
     this.bigText=this.data[2].value;
     this.buttonConfig=this.data[3];
   }
}
