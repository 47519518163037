<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <img class="imageProperties" src="{{data[8].imgUrl}}" alt="image" [ngStyle]="data[8].style?data[8].style:{}">
    </div>
    <div class="rightContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value}}</div>
        <div class="description" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        
        <div class="rowFlex">
            <div class="userData">
                <div class="users" [ngStyle]="data[4].style?data[4].style:{}">{{data[4].value}}</div>
                <div class="userDownloads" [ngStyle]="data[5].style?data[5].style:{}">{{data[5].value}}</div>
            </div>
            <div class="userData">
                <div class="users" [ngStyle]="data[6].style?data[6].style:{}">{{data[6].value}}</div>
                <div class="userDownloads" [ngStyle]="data[7].style?data[7].style:{}">{{data[7].value}}</div>
            </div>
        </div>
        <div class="inputButton"> 
            <!-- <input type="text" placeholder="{{inputText.value}}" [ngStyle]="inputText.style?inputText.style:{}"> -->
            <button class="getStartedBtn" [ngStyle]="data[3].style?data[3].style:{}" (click)="redirectUrl()">{{data[3].value | titlecase}}</button>
        </div> 
    </div>
</div>