<section class="main-section">
    <!-- header section  -->
    <div class="header-section">
        <div class="header-left-side" *ngIf="!edit">
            Add Tag
        </div>
        <div class="header-left-side" *ngIf="edit">
            Edit Tag
        </div>
        <div class="header-right-side">
            <img src="./../../../../../../assets/images/close.svg" alt="close_svg"  (click)="closeBtn()"/>
        </div>
    </div>
    <!-- header sub text  -->
    <div class="header-sub-text" *ngIf="!edit">Add tag for blogs</div>
    <div class="header-sub-text" *ngIf="edit">Edit tag for blogs</div>
    <!-- fields section  -->
    <div class="business-tag-section">
        <div class="field-heading">
            Tag Name<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Tag Name" [(ngModel)]="enterTag">
          </mat-form-field>
    </div>

    <!-- <div class="business-slug-section">
        <div class="field-heading">
            Slug<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Slug Name" [(ngModel)]="enterSlug">
          </mat-form-field>
    </div> -->
    <div class="business-description-section">
        <div class="field-heading">
            Description<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Description" [(ngModel)]="enterDescription">
          </mat-form-field>
    </div>

    <!-- button section  -->
    <div class="button-section">
        <div class="left-side">
            <div class="cancel-button" (click)="closeBtn()">Cancel</div>
        </div>
        <div class="right-side" *ngIf="!edit;else editTags">
            <button class="apply-button" (click)="createBusiness()" [disabled]="
            !enterTag || !enterDescription">
                Add Tag
            </button>
        </div>
        <ng-template #editTags>
            <div class="right-side">
                <div class="apply-button" (click)="editBusiness()">
                    Edit Tag
                </div>
            </div>
        </ng-template>
        
    </div>
</section>
