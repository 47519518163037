import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-process-count-image',
  templateUrl: './process-count-image.component.html',
  styleUrls: ['./process-count-image.component.scss' , './mq-process-count-image.component.scss']
})
export class ProcessCountImageComponent {

  @Input() templateData:any;
  valuesData : any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'process_count_image'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData: any = {
  //   'componentCategory': "process",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Process-1",
  //   "data" : [
  //     {
  //       "imgUrl":"./../../../../../../../assets/images/helping_hand.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{},
  //       "textType":"image"
  //     },
  //     {
  //       "value":"Ways we can help",
  //       "type":"text",
  //       "field":"text",
  //       "style":{},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"01.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#C3C3C3"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Clarity gives you the blocks & components you need to create a website.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"20px", "width":"60%"},
  //       "textType":'text'
  //     },
  //     {
  //       "value":"02.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#C3C3C3", "font-size":"30px"},
  //       "textType":'text'
  //     },
  //     {
  //       "value":"Clarity gives you the blocks & components you need to create a website.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"20px", "width":"60%"},
  //       "textType":'text'
  //     },
  //     {
  //       "value":"03.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#C3C3C3", "font-size":"30px"},
  //       "textType":'text'
  //     },
  //     {
  //       "value":"Clarity gives you the blocks & components you need to create a website.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-size":"20px", "width":"60%"},
  //       "textType":'text'
  //     }
  //   ]
  // }

  // data = this.templateData.data
  // image = this.data[0]
  // heading = this.data[1]
  // number1 = this.data[2]
  // number1data = this.data[3]
  // number2 = this.data[4]
  // number2data = this.data[5]
  // number3 = this.data[6]
  // number3data = this.data[7]


  data : any;

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].imgUrl = this.valuesData.imageUrl;
      this.data[1].value = this.valuesData.title;
      this.data[3].value = this.valuesData.data[0].desc;
      this.data[5].value = this.valuesData.data[1].desc;
      this.data[7].value = this.valuesData.data[2].desc;

      // this.valuesData = this.templateData.values;
      // this.data[0].value = this.valuesData.title.replaceAll("[Company Name]", bName);
      // this.data[1].value = this.valuesData.features[0];
      // this.data[2].value = this.valuesData.features[1];
      // this.data[3].value = this.valuesData.features[2];
      // this.data[7].imgUrl = this.valuesData.imageUrl;
      // this.data[5].value = this.valuesData.button1Text;
      // // this.data[4].value = this.valuesData.counter1Count;
      // // this.data[5].value = this.valuesData.counter1Text;
      // // this.data[6].value = this.valuesData.counter2Count;
      // // this.data[7].value = this.valuesData.counter2Text;
      // // this.data[8].imgUrl = this.valuesData.imageUrl;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        // elems= this.webisiteService.typeText(elems,this.templateData)
        this.typeText(elems);
      } else if (elems.type === 'button') {
        // elems= this.webisiteService.typeButton(elems,this.templateData)
        this.typeButton(elems);
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        // arrValue= this.webisiteService.typeText(arrValue,this.templateData)
        this.typeText(arrValue)
      } else if (arrValue.type === 'button') {
        // arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    // data.style = {
    //   "color":this.templateData.textColor,
    //   "font-family":this.templateData.fontFamily,
    // }
    // //console.log(this.templateData);
    if(data.textType === 'heading'){
      data.style = {
        "color":this.templateData.colors.txtHeadingColor,
        "font-family":this.templateData.headingFontFamily,
        "font-size":"28px",
        "font-weight":"bold",
        "line-height":"40px",
        "letter-spacing":"0px"
      }
    }
    else if(data.textType === 'description'){
      data.style = {
        "color":this.templateData.colors.txtDescColor,
        "font-family":this.templateData.bodyFontFamily,
        "font-size":"16px",
        "font-weight":"500",
        "line-height":"26px",
        "letter-spacing":"0px"
      }
    }
    else if(data.textType === 'text'){
      data.style = {
        "color":this.templateData.colors.txtNormalColor,
        "font-family":this.templateData.bodyFontFamily,
        "font-size":"18px",
        "font-weight":"700",
        "line-height":"28px",
        "letter-spacing":"0px"
      }
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":"none",
          "padding":"8px 15px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":"none",
          "height":"44px",
          "font-size":"16px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "padding":"8px 15px",
          "height":"44px",
          "font-size":"16px"
          // "border-radius":"none"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "height":"44px",
          "font-size":"16px"
        }
      }
    }
  }
}
