<div class="header-main-section">
  <div class="header-text">
    Blog Settings
  </div>
  <div class="cross-image">
    <img src="./../../../../../../../../assets/images/blogs/close.svg" (click)="closeImage()">
  </div>
</div>
<hr>
<div class="content-feilds">
  <div class="full-width">
    <!-- <ng-container *ngFor="let item of tags;let i=index"> -->
    <!-- <div class="field">
                <div class="lead-heading">Tags <span *ngIf="i>0"><img src="./../../../../../assets/images/common/delete.svg"/></span></div>
                <mat-form-field class="sfull-width" appearance="outline">
                  <input matInput placeholder="Add tags" [(ngModel)]="item.content" autofocus />
                </mat-form-field>
              </div>
              <div class="add-text" *ngIf="tags.length==i+1">
               <span (click)="addtext()">Add +</span> 
            </div> -->
    <div class="field">
      <div class="lead-heading">Post Title</div>
      <mat-form-field class="post-title" appearance="outline">
        <input matInput placeholder="Add Title" [(ngModel)]="postTitle" />
      </mat-form-field>
    </div>
    <div class="field">
      <div class="lead-heading">Post Summary</div>
      <mat-form-field class="post-summary" appearance="outline">
        <input matInput placeholder="Post summary" [(ngModel)]="postSummary" />
      </mat-form-field>
    </div>
    <div class="field-tag">
      <div class="lead-heading">Tags</div>
      <mat-form-field class="example-chip-list" appearance="outline">
        <mat-chip-grid #chipGrid aria-label="Fruit selection">
          <mat-chip-row *ngFor="let keyword of keywords" (removed)="removeKeyword(keyword)">
            {{keyword}}
            <button matChipRemove [attr.aria-label]="'remove ' + keyword">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Add Tag..." #fruitInput [formControl]="fruitCtrl" [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit.tagName">
            {{fruit.tagName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="field-author">
      <div class="lead-heading">Author</div>
      <!-- <mat-form-field class="sfull-width" appearance="outline">
        <input matInput placeholder="Add author" [(ngModel)]="author.content" />
      </mat-form-field> -->
      <mat-form-field class="example-full-width sfull-width" appearance="outline">
        <input type="text"
               placeholder="Pick author"
               aria-label="Number"
               matInput
               [formControl]="authorControl"
               [matAutocomplete]="auto1" [(ngModel)]= "author.content">
        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="selectedAuthor($event.option.value)" >
          <mat-option *ngFor="let option of filteredAuthorOptions | async" [value]="option.authorName">
            {{option.authorName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="meta-card">
      <div class="meta-text">
        <div class="metadata-text">
          Meta Data
        </div>
        <div class="metadislay-arrow">
          <img src="./../../../../../../../../assets/images/common/rightArrow.svg" alt="right-arrow"
            [ngClass]="metaCardSelect?'imagedownDisplay':''" (click)="metacardSelected()">
        </div>
      </div>
      <div class="sub-metacardtext">
        Extra Content for search engines
      </div>
      <div *ngIf="metaCardSelect" class="metadata-section">
        <div class="field">
          <div class="lead-heading">Meta Title</div>
          <mat-form-field class="sfull-width" appearance="outline">
            <input matInput placeholder="Add Title" [(ngModel)]="title" />
          </mat-form-field>
        </div>
        <div class="field">
          <div class="lead-heading">Meta Description</div>
          <mat-form-field class="sfull-width" appearance="outline">
            <input matInput placeholder="Meta description" [(ngModel)]="description.content" />
          </mat-form-field>
        </div>
      </div>
    </div>


  </div>

  <div class="buttons-section"><button type="button" (click)="addTags()">Add</button></div>
</div>