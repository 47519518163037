import {
  Component,
  ComponentFactoryResolver,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HerobannerRatingReviewBgimgImageComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/heroBanner/herobanner-rating-review-bgimg-image/herobanner-rating-review-bgimg-image.component';
import { HerobannerStatImageComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/heroBanner/herobanner-stat-image/herobanner-stat-image.component';
import { SimpleHeroBannerComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/heroBanner/simple-hero-banner/simple-hero-banner.component';
import { SimpleHerobannerButtonImageComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/heroBanner/simple-herobanner-button-image/simple-herobanner-button-image.component';
import { SingleHorizontalTestimonialComponent } from './../../master-config-components/micro-apps/website/edit-website/website-generationcomponent/testimonal/single-horizontal-testimonial/single-horizontal-testimonial.component';
import { SinglePageDoubleTestimomialComponent } from './../../master-config-components/micro-apps/website/edit-website/website-generationcomponent/testimonal/single-page-double-testimomial/single-page-double-testimomial.component';
import { SingleVerticleTestimonialComponent } from './../../master-config-components/micro-apps/website/edit-website/website-generationcomponent/testimonal/single-verticle-testimonial/single-verticle-testimonial.component';
import { TestimonalSlickcarsouelComponent } from './../../master-config-components/micro-apps/website/edit-website/website-generationcomponent/testimonal/testimonal-slickcarsouel/testimonal-slickcarsouel.component';
import { ShareWebsiteComponent } from 'src/app/our-components/share-website/share-website.component';
import { TemplateService } from 'src/app/services/template.service';
import { ServicesectioneditorComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/service-sections/servicesectioneditor/servicesectioneditor.component';
import { VerticleServiceSectionComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/service-sections/verticle-service-section/verticle-service-section.component';
import { DbService } from 'src/app/services/db-service/db.service';
import {
  buttonShape,
  buttonStyle,
  combinationFontFamily,
  fontFamilies,
} from 'src/app/constants/common-constant';
import { FormControl } from '@angular/forms';
import { MapService } from 'src/app/services/map.service';
import { map, Observable, startWith } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-website-generator',
  templateUrl: './website-generator.component.html',
  styleUrls: ['./website-generator.component.scss'],
})
export class WebsiteGeneratorComponent implements OnInit {
  @ViewChild('displayingComponents', { read: ViewContainerRef })
  displayingComponents: ViewContainerRef | any;

  businessCategoryId: any;
  componentNames: any = [];
  queryParams: boolean = false;
  restyleComponent: boolean = false;
  globalRegenerateComponent: boolean = false;
  buttonStyles = buttonStyle;
  fontFamilies = combinationFontFamily;
  bStyle: any = '';
  buttonShape = buttonShape;
  bShape: any = '';
  selectingFontFamily = '';
  globalStyles = {
    primaryColor: '#5599d5',
    buttonColor: '#fbd09a',
    btnBorderRadius: '0px',
    // textFamily:{
    //   'font-family':"'Lexend Deca', sans-serif"
    // }
    headingFontFamily: {
      'font-family': "'Lexend Deca', sans-serif",
    },
    bodyFontFamily: {
      'font-family': "'Lexend Deca', sans-serif",
    },
  };
  componentsStyling: any = {
    bgColor1: String,
    bgColor2: String,
    buttonShape: String,
    buttonStyle: String,
    headingFontFamily: String,
    bodyFontFamily: String,
    txtHeadingColor: String,
    txtDescColor: String,
    txtNormalColor: String,
    btnColor: String,
    btnTextColor: String,
  };
  colors: any;
  templateData: any = {
    // "id": "1edb27b8-b73e-62c3-be4f-8127e4a93850",
    componentContentList: [],
    componentsData: [],
    businessId: null,
    businessName: null,
  };
  listOfComponents: any = [
    {
      name: 'double_stats_hero_banner',
      component: HerobannerStatImageComponent,
    },
    {
      name: 'rating_review_hero_banner',
      component: HerobannerRatingReviewBgimgImageComponent,
    },
    {
      name: 'simple_hero_banner',
      component: SimpleHeroBannerComponent,
    },
    {
      name: 'simple_button_image_hero_banner',
      component: SimpleHerobannerButtonImageComponent,
    },
    {
      name: 'testimonal_slickcarsouel',
      component: TestimonalSlickcarsouelComponent,
    },
    {
      name: 'double_item_testimonial',
      component: SinglePageDoubleTestimomialComponent,
    },
    {
      name: 'single_horizontal_testimonial',
      component: SingleHorizontalTestimonialComponent,
    },
    {
      name: 'single_vertical_testimonial',
      component: SingleVerticleTestimonialComponent,
    },
    {
      name: 'service_section_simple_horizontal',
      component: ServicesectioneditorComponent,
    },
    {
      name: 'service_section_simple_vertical',
      component: VerticleServiceSectionComponent,
    },
  ];
  srcHeight: number = 0;
  srcWidth: number = 0;

  constructor(
    private templatesService: TemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private _dbService: DbService,
    private _mapservice: MapService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {
    this.getAllTypes();
    this.businessCategoryId = this._dbService.getBusinessCategoryId();
    window.localStorage.setItem('prevUrl', this.router.url);
    this.getScreenSize();
    // let componentStructure = JSON.parse(window.localStorage.getItem('tempStructure') || '');
    // componentStructure.forEach((cmStructure:any) => {
    //   this.componentNames.push({cmStructure});
    // });
    // //console.log(this.componentNames);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // // //console.log(this.scrHeight, this.scrWidth);
  }

  ngOnInit(): void {
    this.templatesService.scrollToComponent.subscribe((res: any) => {
      if (res.goIndex) {
        let a = document.getElementById(
          this.templateData.componentContentList[res.goIndex].category.name
        );
        if (!a) return;
        a.scrollIntoView({ behavior: 'smooth' });
      } else {
        let a = document.getElementById('Contact Us');
        if (!a) return;
        a.scrollIntoView({ behavior: 'smooth' });
      }
    });
    this.checkParams();
  }

  blogData: any = '';
  displayBlogs: boolean = false;
  getblogsBysubCategoryId() {
    this.templatesService.getBlogsBySubInstryId(this.subCategoryId).subscribe({
      next: (res: any) => {
        this.blogData = res.data;
        if (this.blogData.length > 0) {
          this.displayBlogs = true;
        } else {
          this.displayBlogs = false;
        }
        //console.log('displayBlogs', this.displayBlogs);
      },
      error: (err: any) => {},
    });
  }

  totalPageLoader: boolean = false;
  subCategoryId = '';
  checkParams() {
    this.totalPageLoader = true;
    this.route.queryParams.subscribe((params: any) => {
      if (params.tempId != undefined) {
        this.loaderDisplay = false;
        this.getTemplateDataByTemplateId(
          params.tempId.split('+')[0].split(' ')[0]
        );
        this.subCategoryId = params.tempId.split('+')[0].split(' ')[1];
        this.queryParams = true;
      } else {
        this.queryParams = false;
        if (this._dbService.getWebsiteId() === null) {
          this.getTemplateStructure();
          this.subCategoryId = this._dbService.getSubCategoryId() || '';
        } else {
          this.loaderDisplay = false;
          this.getTemplateDataByTemplateId(this._dbService.getWebsiteId());
          this.subCategoryId = this._dbService.getSubCategoryId() || '';
        }
      }
    });
  }

  getTemplateStructure() {
    this.queryParams = true;
    this.templatesService
      .getTemplateStructure(this._dbService.getSubCategoryId())
      .subscribe(
        (res: any) => {
          try {
            this.getblogsBysubCategoryId();
          } catch (error) {
            //console.log(error);
          }
          this.buttonStyles.forEach((bs: any) => {
            if (bs.value === res.data.buttonStyle) {
              this.bStyle = bs.value;
            }
          });
          this.buttonShape.forEach((bShapes) => {
            if ((bShapes.value = res.data.buttonShape)) {
              this.bShape = bShapes.value;
            }
          });
          this.fontFamilies.forEach((ff: any) => {
            // if(ff.value === res.data.fontFamily){
            //   this.selectingFontFamily = ff.value;
            // }
            if (ff.value.split(',')[0] === res.data.headingFontFamily) {
              this.selectingFontFamily = ff.value;
            }
          });
          this.componentsStyling.color = res.data.colors;
          this.colors = res.data.colors;
          window.localStorage.setItem(
            'colors',
            JSON.stringify(res.data.colors)
          );
          this.componentsStyling.headingFontFamily = res.data.headingFontFamily;
          this.componentsStyling.bodyFontFamily = res.data.bodyFontFamily;
          this.componentsStyling.buttonStyle = res.data.buttonStyle;
          this.componentsStyling.buttonShape = res.data.buttonShape;
          this.componentsStyling.btnColor = res.data.colors.btnColor;
          this.componentsStyling.btnTextColor = res.data.colors.btnTextColor;
          this.colors = res.data.colors;
          // window.localStorage.setItem('colors',JSON.stringify(res.data.colors));
          this.sendingColor = res.data.colors;
          window.localStorage.setItem(
            'navLinks',
            JSON.stringify(res.data.navLinks)
          );
          window.localStorage.setItem(
            'tempStructure',
            JSON.stringify(res.data.templates.categories)
          );
          res.data.templates.categories.forEach((categories: any) => {
            this.componentNames.push(categories);
          });
          // //console.log('templateStructure',this.componentNames);

          this.getComponentsData();
        },
        (err) => {
          //console.log(err.error.message);
        }
      );
    // //console.log(this.componentsStyling);
  }
  myInteval: any;
  bgColorCountValue = 1;
  componentData: any = [];
  async getComponentsData() {
    let a: number = 0;
    //console.log(this.componentNames);
    await this.componentNames.forEach((compNames: any, i: any) => {
      // //console.log(this.componentNames[i],i);
      if (this.previousComponentIdList.length != 0) {
        setTimeout(() => {
          // //console.log(i,'loader starts');
          this.templatesService
            .getComponentsData(
              this.previousComponentIdList[i].componentId,
              this.componentNames[i].id,
              this._dbService.getSubCategoryId()
            )
            .subscribe(
              (res: any) => {
                this.componentNames.forEach(
                  (cmStructure: any, index: number) => {
                    if (res.data.category.name === cmStructure.name) {
                      res.data.bgColor =
                        this.componentsStyling.color.componentStyles[
                          index
                        ].bgColor;
                      res.data.buttonShape = this.componentsStyling.buttonShape;
                      res.data.buttonStyle = this.componentsStyling.buttonStyle;
                      res.data.headingFontFamily =
                        this.componentsStyling.headingFontFamily;
                      res.data.bodyFontFamily =
                        this.componentsStyling.bodyFontFamily;
                      res.data.colors = {
                        txtHeadingColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtHeadingColor,
                        txtDescColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtDescColor,
                        txtNormalColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtNormalColor,
                        btnColor: this.componentsStyling.btnColor,
                        btnTextColor: this.componentsStyling.btnTextColor,
                      };
                      this.templateData.componentContentList.splice(
                        index,
                        0,
                        res.data
                      );
                      if (index == 0) {
                        this.templateData.componentsData.splice(index, 0, {
                          componentName: res.data.webComponentName,
                          componentCategoryId: res.data.category.id,
                          componentCategoryName: res.data.category.name,
                          componentId: res.data.id,
                          contentId: res.data.contentId,
                          subIndustryId: this._dbService.getSubCategoryId(),
                          loaderDisplay: true,
                        });
                      } else {
                        this.componentData.splice(index, 0, {
                          componentName: res.data.webComponentName,
                          componentCategoryId: res.data.category.id,
                          componentCategoryName: res.data.category.name,
                          componentId: res.data.id,
                          contentId: res.data.contentId,
                          subIndustryId: this._dbService.getSubCategoryId(),
                          loaderDisplay: true,
                        });
                      }

                      //console.log('templateData', this.templateData);
                      this.bgColorCountValue = this.bgColorCountValue + 1;
                    }
                  }
                );
                // //console.log(i,'loader ends',i*1000);
              },
              (err) => {
                // //console.log(err.error.message);
                this.totalPageLoader = false;
                this.templatesService.openSnack(
                  `${err.error.message}`,
                  'Close'
                );
              }
            );
        }, i * 1000);
      } else {
        setTimeout(() => {
          // //console.log(i,'loader starts');
          this.templatesService
            .getComponentsData(
              null,
              this.componentNames[i].id,
              this._dbService.getSubCategoryId()
            )
            .subscribe(
              (res: any) => {
                this.componentNames.forEach(
                  (cmStructure: any, index: number) => {
                    if (res.data.category.name === cmStructure.name) {
                      res.data.bgColor =
                        this.componentsStyling.color.componentStyles[
                          index
                        ].bgColor;
                      res.data.buttonShape = this.componentsStyling.buttonShape;
                      res.data.buttonStyle = this.componentsStyling.buttonStyle;
                      res.data.headingFontFamily =
                        this.componentsStyling.headingFontFamily;
                      res.data.bodyFontFamily =
                        this.componentsStyling.bodyFontFamily;
                      res.data.colors = {
                        txtHeadingColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtHeadingColor,
                        txtDescColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtDescColor,
                        txtNormalColor:
                          this.componentsStyling.color.componentStyles[index]
                            .txtNormalColor,
                        btnColor: this.componentsStyling.btnColor,
                        btnTextColor: this.componentsStyling.btnTextColor,
                      };
                      this.templateData.componentContentList.splice(
                        index,
                        0,
                        res.data
                      );
                      if (index == 0) {
                        this.templateData.componentsData.splice(index, 0, {
                          componentName: res.data.webComponentName,
                          componentCategoryId: res.data.category.id,
                          componentCategoryName: res.data.category.name,
                          componentId: res.data.id,
                          contentId: res.data.contentId,
                          subIndustryId: this._dbService.getSubCategoryId(),
                          loaderDisplay: true,
                        });
                      } else {
                        this.componentData.splice(index, 0, {
                          componentName: res.data.webComponentName,
                          componentCategoryId: res.data.category.id,
                          componentCategoryName: res.data.category.name,
                          componentId: res.data.id,
                          contentId: res.data.contentId,
                          subIndustryId: this._dbService.getSubCategoryId(),
                          loaderDisplay: true,
                        });
                      }

                      //console.log('templateData', this.templateData);
                      this.bgColorCountValue = this.bgColorCountValue + 1;
                    }
                  }
                );
                // //console.log(i,'loader ends',i*1000);
              },
              (err) => {
                // //console.log(err.error.message);
                this.totalPageLoader = false;
                this.templatesService.openSnack(
                  `${err.error.message}`,
                  'Close'
                );
              }
            );
        }, i * 1000);
      }
    });
    this.totalPageLoader = false;
    if (this.scrollCount < this.componentNames.length) {
      this.myInteval = setInterval(() => {
        if (this.scrollCount == this.componentNames.length - 1) {
          this.loaderText = 'Your website is Ready';
          // this.loaderDisplay=false;
          clearInterval(this.myInteval);
          // //console.log("finally done man")
          this.closeloader();
        } else {
          this.callscroll();
          this.templateData.componentsData.push(
            this.componentData[this.scrollCount]
          );
          this.scrollCount = this.scrollCount + 1;
        }
      }, 2000);
    }
    // this.componentLoading();
  }
  scrollCount: number = 0;
  loaderText: string = 'AI Picking Best Color Palette';
  loaderDisplay: boolean = true;
  callscroll() {
    // this.loaderText=`Generating ${this.componentNames[this.scrollCount+1].name} section`
    if (this.componentNames[this.scrollCount + 1].name === 'Hero Banner') {
      this.loaderText = 'AI Generating Beautiful Hero Banner';
    } else if (
      this.componentNames[this.scrollCount + 1].name === 'Service Section'
    ) {
      this.loaderText = 'AI Adding Some Services';
    } else if (
      this.componentNames[this.scrollCount + 1].name === 'Testimonial'
    ) {
      this.loaderText = 'AI Writing Positive Reviews';
    } else if (this.componentNames[this.scrollCount + 1].name === 'About Us') {
      this.loaderText = 'AI Writting About Business';
    } else if (this.componentNames[this.scrollCount + 1].name === 'Process') {
      this.loaderText = 'AI Adding Business Process';
    } else if (this.componentNames[this.scrollCount + 1].name === 'FAQ') {
      this.loaderText = "AI Adding Business FAQ's";
    } else if (this.componentNames[this.scrollCount + 1].name === 'Location') {
      this.loaderText = 'AI Adding Business Location';
    } else if (
      this.componentNames[this.scrollCount + 1].name === 'Contact Us'
    ) {
      this.loaderText = 'AI Adding Lead Capture Form';
    } else if (this.displayBlogs) {
      if (this.componentNames[this.scrollCount + 1].name === 'Blog') {
        this.loaderText = 'AI Adding Business Blogs';
      }
    }
    let a = document.getElementById(this.componentNames[this.scrollCount].name);
    if (!a) return;
    a.scrollIntoView({ behavior: 'smooth' });
    let element = document.querySelector(
      `#${this.componentNames[this.scrollCount + 1].name}`
    );
    // element?.scrollTop = element?.scrollHeight
    if (!element) return;
    element.scrollTop = element.scrollHeight;
  }
  closeloader() {
    // //console.log("template data",this.templateData);
    setTimeout(() => {
      this.loaderDisplay = false;
      this.queryParams = false;
      let a = document.getElementById('Top Nav Bar');
      if (!a) return;
      a.scrollIntoView({ behavior: 'smooth' });
      // a = a+1;
      // if(a === this.componentNames.length){
      // if(this.globalRegenerateClick){
      //   this.updateTemplateData();
      // }
      // else{
      this.initialTemplateData();
      // }
    }, 2000);
    // }
  }
  // pickComponentsDynamically(data:any,index:any){
  //   //console.log('dyanmic data',data,index);
  //   data.componentContentList.forEach((comContentList:any) =>{
  //     this.listOfComponents.forEach((listComponents:any,i:any) => {
  //       if(comContentList.webComponentName === listComponents.name){
  //         const component = this.compFactoryResolver.resolveComponentFactory(listComponents.component);
  //         const k = this.displayingComponents.createComponent(component);
  //         k.instance.templateData = comContentList;
  //         this.listOfComponents.splice(i,1);
  //       }
  //     });
  //   })
  // }

  getTemplateDataByTemplateId(tempId: any) {
    this.queryParams = false;
    this.templatesService.getTemplateDataByTemplateId(tempId).subscribe(
      (res: any) => {
        try {
          this.getblogsBysubCategoryId();
        } catch (error) {
          //console.log('error', error);
        }
        //console.log(res.data);
        this.changeContentPurpose = res.data.components;
        this.sendingColor = res.data.components[0].colors;
        this.componentsStyling.buttonShape = res.data.components[0].buttonShape;
        this.buttonShape.forEach((bshape: any) => {
          if (bshape.value === res.data.components[0].buttonShape) {
            this.bShape = bshape.value;
          }
        });
        this.componentsStyling.buttonStyle = res.data.components[0].buttonStyle;
        this.buttonStyles.forEach((bstyle: any) => {
          if (bstyle.value === res.data.components[0].buttonStyle) {
            this.bStyle = bstyle.value;
          }
        });
        this.componentsStyling.headingFontFamily =
          res.data.components[0].headingFontFamily;
        this.componentsStyling.bodyFontFamily =
          res.data.components[0].bodyFontFamily;
        this.componentsStyling.btnColor =
          res.data.components[0].colors.btnColor;
        this.componentsStyling.btnTextColor =
          res.data.components[0].colors.btnTextColor;
        this.fontFamilies.forEach((ff: any) => {
          if (
            ff.value.split(',')[0] === res.data.components[0].headingFontFamily
          ) {
            this.selectingFontFamily = ff.value;
          }
        });
        //console.log(res.data);
        let a: number = 0;
        res.data.components.forEach((cmpList: any) => {
          res.data.colors = this.sendingColor;
          this.templateData.componentContentList.push(cmpList);
          this.templateData.componentsData.push({
            componentName: cmpList.webComponentName,
            componentCategoryId: cmpList.category.id,
            componentCategoryName: cmpList.category.name,
            componentId: cmpList.id,
            contentId: cmpList.contentId,
            subIndustryId: this._dbService.getSubCategoryId(),
          });
        });
        this.templatesService.updateElements.emit(this.templateData);
        // a = a+1;
        // if(a === this.componentNames.length){
        //   this.initialTemplateData();
        // }
        //global stylings

        this.totalPageLoader = false;
      },
      (err) => {
        this.totalPageLoader = false;
        this.templatesService.openSnack(`${err.error.message}`, 'Close');
      }
    );
    ////console.log(this.templateData);
  }

  // async templateDataAssign(){
  //   let industryId = this._dbService.getBusinessCategoryId()
  // let a : number = 0;
  //   await this.componentNames.forEach((component:any) => {
  //       this.templatesService.getComponentsData(component.cmStructure.id,this._dbService.getSubCategoryId()).subscribe(
  //         {
  //           next:(res:any)=>{
  //             //console.log(res.data);

  //             this.templateData.componentContentList.push(res.data);
  //             this.templateData.componentsData.push({
  //               componentName: res.data.webComponentName,
  //               componentCategoryId: res.data.category.id,
  //               componentCategoryName : res.data.category.name,
  //               componentId : res.data.id,
  //               contentId : res.data.contentId,
  //               subIndustryId:this._dbService.getSubCategoryId()
  //             });
  //             // //console.log(this.templateData);

  // a = a+1;
  // if(a === this.componentNames.length){
  //   this.initialTemplateData();
  // }
  //             this.templatesService.updateElements.emit(this.templateData);
  //           },
  //           error:(err)=>{
  //             this.templatesService.openSnack(`${component.componentCategory} Not Found`,"Close");
  //           },
  //         }
  //       )
  //   });
  // }
  sendingColor: any;
  initialTemplateData() {
    let data = {
      components: this.templateData.componentContentList,
      businessName: this._dbService.getBusinessName(),
      colors: this.sendingColor,
    };
    this.templatesService.intiateTemplateData(data).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.changeContentPurpose = res.data.components;
        // window.localStorage.setItem('templateId',res.data.id);
        // window.localStorage.setItem('colors',JSON.stringify(res.data.colors));
        this._dbService.setWebsiteId(res.data.id);
        this.templatesService.updateElements.emit(this.templateData);
        this.totalPageLoader = false;
      },
      (err) => {
        ////console.log(err.error.message);
        this.totalPageLoader = false;
        this.templatesService.openSnack(`${err.error.message}`, 'Close');
      }
    );
  }

  regenerateComponent(
    componentCategoryId: any,
    componentId: any,
    subIndustryId: any,
    contentId: any,
    i: any
  ) {
    this.templatesService
      .regenerateComponents(
        componentCategoryId,
        subIndustryId,
        componentId,
        contentId
      )
      .subscribe(
        (res: any) => {
          this.templateData.componentContentList.forEach(
            (component: any, i: any) => {
              if (component.category.id === componentCategoryId) {
                res.data.colors = this.sendingColor;
                res.data.bgColor =
                  this.templateData.componentContentList[i].bgColor;
                res.data.colors.txtHeadingColor =
                  this.templateData.componentContentList[
                    i
                  ].colors.txtHeadingColor;
                res.data.colors.txtDescColor =
                  this.templateData.componentContentList[i].colors.txtDescColor;
                res.data.colors.txtNormalColor =
                  this.templateData.componentContentList[
                    i
                  ].colors.txtNormalColor;
                res.data.colors.btnColor =
                  this.templateData.componentContentList[i].colors.btnColor;
                res.data.colors.btnTextColor =
                  this.templateData.componentContentList[i].colors.btnTextColor;
                res.data.buttonShape = this.componentsStyling.buttonShape;
                res.data.buttonStyle = this.componentsStyling.buttonStyle;
                res.data.headingFontFamily =
                  this.componentsStyling.headingFontFamily;
                res.data.bodyFontFamily = this.componentsStyling.bodyFontFamily;
                this.templateData.componentContentList.splice(i, 1);
                this.templateData.componentContentList.splice(i, 0, res.data);
                this.templateData.componentsData.forEach(
                  (element: any, j: any) => {
                    if (element.componentCategoryId == componentCategoryId) {
                      this.templateData.componentsData.splice(j, 1);
                      this.templateData.componentsData.splice(j, 0, {
                        componentName: res.data.webComponentName,
                        componentCategoryId: res.data.category.id,
                        componentCategoryName: res.data.category.name,
                        componentId: res.data.id,
                        contentId: res.data.contentId,
                        subIndustryId: this._dbService.getSubCategoryId(),
                      });
                    }
                    this.bgColorCountValue = this.bgColorCountValue + 1;
                  }
                );
              }
            }
          );
          this.templatesService.updateElements.emit(this.templateData);
          this.updateTemplateData();

          //console.log('regeneratedData', this.templateData);
          // setTimeout(() => {
          //   this.loaderDisplay = false;
          // }, 1000);
        },
        (err) => {
          // //console.log(err.error.message);
          this.templatesService.openSnack(`${err.error.message}`, 'Close');
        }
      );
  }

  //this array using only for change content purpose
  changeContentPurpose: any = [];
  //dont use for any other purpose
  updateTemplateData() {
    setTimeout(() => {
      let data = {
        id: this._dbService.getWebsiteId(),
        components: this.templateData.componentContentList,
        businessName: this._dbService.getBusinessName(),
        color: this.colors,
      };
      this.templatesService.updateTemplateData(data).subscribe(
        (res: any) => {
          //console.log('data updated', res.data);
          this.changeContentPurpose = res.data.components;
          this.totalPageLoader = false;
        },
        (err) => {
          this.totalPageLoader = false;
          this.templatesService.openSnack(`${err.error.message}`, 'Close');
        }
      );
    }, 500);
  }

  changeContent(
    componentCategoryId: any,
    subIndustryId: any,
    contentId: any,
    index: number
  ) {
    // this.templatesService.contentChangeCategory(componentName,businessCategoryId).subscribe(
    //   {
    //     next:(value:any)=>{
    //       this.templateData.componentContentList.forEach((component:any,i:any) => {
    //         if(component.componentName==componentName){
    //           this.templateData.componentContentList.splice(i,1);
    //           this.templateData.componentContentList.splice(i,0,value.data)
    //         }
    //       });
    //       this.templatesService.updateElements.emit(this.templateData);
    //     },
    //     error:(err)=>{
    //       this.templatesService.openSnack("No Data Found","Close");
    //     },
    //   }
    // )
    this.changeContentPurpose.forEach((component) => {
      if (componentCategoryId === component.category.id) {
        this.templatesService
          .changeContent(
            componentCategoryId,
            subIndustryId,
            component.contentId
          )
          .subscribe(
            (res: any) => {
              //console.log('previous contentid', contentId);
              // //console.log(res.data);
              this.templateData.componentContentList.forEach(
                (cmpContList: any, i: any) => {
                  if (cmpContList.category.id === componentCategoryId) {
                    // this.templateData.componentContentList.splice(i,1);
                    // this.templateData.componentContentList.splice(i,0,res.data.data)
                    // cmpContList.contentId = res.data.contentId;
                    // Object.assign(cmpContList.values,res.data.data);
                    delete cmpContList.contentId;
                    delete cmpContList.values;
                    cmpContList.values = res.data.data;
                    cmpContList.contentId = res.data.contentId;
                    // delete this.templateData.componentContentList[i].contentId;
                    // //console.log("removed content Id",this.templateData.componentContentList[i]);
                    // this.templateData.componentContentList[i].values = res.data.data
                    // this.templateData.componentContentList[i].contentId = res.data.contentId
                    // //console.log("added Content Id",this.templateData.componentContentList[i]);
                  }
                }
              );
              this.templatesService.updateElements.emit(this.templateData);
              this.updateTemplateData();
              //console.log('new content id', res.data.contentId);
              // //console.log('new data',this.templateData);
            },
            (err) => {
              //console.log(err.error.message);
            }
          );
      }
    });
  }
  signUp() {
    // this.templateService.openDialog(SigninSignupComponent, '94%', '1238px', {
    //   webId:this.querywebId
    //  });
    if (this._dbService.getWebsiteId() != null) {
      this.router.navigate(['/signup']);
    } else {
      this.templatesService.openSnack(
        'Website not yet generated, Getting error',
        'Ok'
      );
    }
  }

  shareWebsite() {
    // //console.log(this._dbService.getWebsiteId());
    if(this.srcWidth > 475){
      if (this._dbService.getWebsiteId() != null) {
        this.templatesService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
          webId:
            window.location.href +
            `?tempId=${this._dbService.getWebsiteId()}+${this._dbService.getSubCategoryId()}`,
        });
      } else {
        this.templatesService.openSnack('Website Not Yet Generated', 'Ok');
      }
    }
    else{
      if (this._dbService.getWebsiteId() != null) {
        this.templatesService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
          webId:
            window.location.href +
            `?tempId=${this._dbService.getWebsiteId()}+${this._dbService.getSubCategoryId()}`,
        });
      } else {
        this.templatesService.openSnack('Website Not Yet Generated', 'Ok');
      }
    }
  }
  goBack() {
    this.router.navigate(['']);
    window.localStorage.clear();
  }

  //Global Restyle Website

  positionRestyleTop: any = '';
  positionRestyleRight: any = '';
  openRestyleComponent() {
    this.restyleComponent = true;
    let a = document.getElementById('restyle_component');
    let position = a?.getBoundingClientRect();
    this.positionRestyleRight = position?.top;
    this.positionRestyleTop = position?.right;
  }
  cancelRestyleComponent() {
    this.restyleComponent = false;
  }

  changeFontFamily() {
    this.globalStyles.headingFontFamily['font-family'] =
      this.selectingFontFamily.split(',')[0];
    this.globalStyles.bodyFontFamily['font-family'] =
      this.selectingFontFamily.split(',')[1];
  }

  //global regenrate component

  searchLocation: any =
    `${this._dbService.getLocality()}` + ',' + `${this._dbService.getState()}`;
  searchBox = new FormControl();
  locations: any = [];
  searchResults: Observable<string[]> | any;
  selectedLocation: any;
  myControl = new FormControl();
  bType: any =
    this._dbService.getBusinessCategoryName() +
    '-' +
    this._dbService.getSubCategoryName();
  filteredOptions: Observable<string[]> | any;
  allTypes: any = [];

  globalRegenrate() {
    this.globalRegenerateComponent = true;
  }

  //location change
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        res.features.forEach((location: any) => {
          locations.push({
            displayName:
              location?.place_name.split(', ')[0] +
              ',' +
              location?.context[0]?.text +
              ',' +
              location?.context[2]?.text,
            latlng: location?.center,
          });
        });
        this.locations = locations;
        this.searchResults = this.searchBox.valueChanges.pipe(
          startWith(''),
          map((value) => this._searchFilter(value))
        );
        // ////console.log(this.locations);
      },
      (err) => {
        ////console.log(err);
      }
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    this.selectedLocation = option;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    this._dbService.setLocality(splitedLocation[0]);
    this._dbService.setState(splitedLocation[1]);
  }

  //industry type change

  getAllTypes() {
    this.templatesService.mergedIndustrySubIndustry().subscribe(
      (res: any) => {
        // //console.log(res.data);
        this.filteredOptions = this.myControl;
        // this.allTypes = res.data;
        res.data.forEach((data: any) => {
          this.allTypes.push({
            name: data.parent.name + ' - ' + data.name,
            industryId: data.parent.id,
            subIndustryId: data.id,
            industryName: data.parent.name,
            subIndustryName: data.name,
          });
        });
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
      (err) => {
        // ////console.log(err)
        this.snackBar.open(`${err.error.message}`, 'Close');
      }
    );
  }
  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.allTypes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  selectBusinessType(ev: any) {
    // //console.log(ev);
    this._dbService.setBusinessCategoryId(ev.industryId);
    this._dbService.setBusinessCategoryName(ev.industryName);
    this._dbService.setSubCategoryId(ev.subIndustryId);
    this._dbService.setSubCategoryName(ev.subIndustryName);
  }

  //businessName change
  bName: any = this._dbService.getBusinessName();

  cancelRegenerate() {
    this.globalRegenerateComponent = false;
  }

  globalRegenerateClick: boolean = false;
  previousComponentIdList: any = [];
  saveGlobalRegenerate() {
    this.previousComponentIdList = [];
    this.templateData.componentContentList.forEach((ccList) => {
      this.previousComponentIdList.push({
        categoryId: ccList.category.id,
        componentId: ccList.id,
      });
    });
    this._dbService.setBusinessName(this.bName);
    this.globalRegenerateClick = true;
    this.getTemplateStructure();
    this.componentNames = [];
    localStorage.removeItem('templateId');
    this.templateData.componentContentList = [];
    this.templateData.componentsData = [];
    this.scrollCount = 0;
    this.componentData = [];
    this.loaderDisplay = true;
    this.loaderText = 'AI Picking Best Color Palette';
    this.globalRegenerateComponent = false;
  }

  saveRestyle() {
    // //console.log(this.templateData);

    // //console.log(
    //   'primary color:',this.componentsStyling.primaryColor,
    //   'text color:',this.componentsStyling.textColor,
    //   'font-family:',this.selectingFontFamily,
    //   'b shape:', this.bShape,
    //   'b style:',this.bStyle
    // );

    this.templateData.componentContentList.forEach(
      (comContList: any, i: number) => {
        comContList.bgColor = this.templateData.componentContentList[i].bgColor;
        comContList.buttonShape = this.bShape;
        comContList.buttonStyle = this.bStyle;
        comContList.headingFontFamily = this.selectingFontFamily.split(',')[0];
        comContList.bodyFontFamily = this.selectingFontFamily.split(',')[1];
        comContList.colors.btnColor = this.componentsStyling.btnColor;
        comContList.colors.btnTextColor = this.componentsStyling.btnTextColor;
      }
    );
    this.restyleComponent = false;
    this.templatesService.updateElements.emit(this.templateData);
    this.updateTemplateData();
  }

  shapeChange(ev: any) {
    if (ev.value === 'ROUNDED') {
      this.globalStyles.btnBorderRadius = '10px';
    } else {
      this.globalStyles.btnBorderRadius = '0px';
    }
  }
}
