<section class="main_section" *ngIf="!pageLoader">
    <div class="page_title_description">
        <div class="page_title">Search Engine Optimization & Sharing</div>
        <div class="description">
            Set how your site looks like on search engines and when shared.
        </div>
    </div>
    <div class="meta_card">
        <div class="card_title">Meta Card</div>
        <div class="hr_line"></div>
        <div class="card_bottom_layer">
            <div class="card_left_part">
                <!-- meta title -->
                <div class="box_title">Meta Title</div>
                <mat-form-field appearance="outline" class="input_card">
                    <input placeholder="Enter meta title" matInput maxlength="60" (keyup)="metaTitleFun()"
                    [(ngModel)]="metaTitle"/>
                </mat-form-field>
                <div class="recomended_chars">
                    Recommended: 60 characters. You’ve used <span style="color: #36B37E;">{{metaTitlelength}}</span>
                </div>

                <!-- meta description -->
                <div class="box_title">Meta Description</div>
                <textarea id="meta_description" cols="50" rows="4" class="meta_desc"
                placeholder="Enter meta Description" maxlength="140" [(ngModel)]="metaDescription"
                (keyup)="metaDescFun()"></textarea>
                <div class="recomended_chars">
                    Recommended: 140 characters. You’ve used <span style="color: #36B37E;">{{metaDescriptionLength}}</span>
                </div>
            </div>
            <div class="card_right_part">
                <div class="box_title">Search Engine Result Preview</div>
                <div class="search_engine_box">
                    <div class="google_searchbox">
                        <div class="google_img">
                            <img src="./../../../../../assets/images/common/google.svg" alt="google">
                        </div>
                        <mat-form-field class="google_search" appearance="outline">
                            <input matInput [(ngModel)]="metaTitle" disabled/>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="google_result">
                        {{domainName}} › home
                    </div>
                    <div class="meta_title">
                        {{metaTitle ? metaTitle : 'Meta Title'}}
                    </div>
                    <div class="meta_description">
                        {{metaDescription ? metaDescription : 'Meta Description'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="facebook_card">
        <div class="card_title">Facebook Card</div>
        <div class="hr_line"></div>
        <div class="card_bottom_layer">
            <div class="card_left_part">
                <div class="box_title">Facebook Image</div>
                <div class="facebook_image_box" *ngIf="!pageLoader; else uploadFacebookLoader">
                    <div class="upload_img">
                        <img src="./../../../../../assets/images/common/upload_img.svg" alt="upload">
                    </div>
                    <div class="uploading_img"   
                        id="drop_zone"
                        (drop)="dropHandler($event);"
                        (dragover)="dragOverHandler($event);"
                        >
                        <div class="select_img">
                            <input type="button" value="Click to upload" class="fc_browse_btn" id="display_browse_btn" (click)="browseFcImage()">
                            <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/jpeg,image/jpg" (change)="updateFcImage($event)"
                            class="fc-btn" id="browse_fc_image"/>
                        </div>
                        <div class="dropzone">
                            <div class="text-drop">
                                <div class="centered">Or Drop your file here!</div>
                                <img src="" alt="" draggable="true">
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #uploadFacebookLoader>
                    <div class="facebook_image_box">
                        <div class="image_loader">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                </ng-template>
                <div class="box_title">Facebook Title</div>
                <mat-form-field appearance="outline" class="input_card">
                    <input placeholder="Enter meta title" matInput maxlength="60" (keyup)="facebookTitleFun()"
                    [(ngModel)]="facebookTitle"/>
                </mat-form-field>
                <div class="recomended_chars">
                    Recommended: 60 characters. You’ve used <span style="color: #36B37E;">{{facebookTitleLength}}</span>
                </div>


                <div class="box_title">Facebook Description</div>
                <textarea id="meta_description" cols="50" rows="4" class="meta_desc"
                placeholder="Enter meta Description" maxlength="140" [(ngModel)]="facebookDescription"
                (keyup)="facebookDescriptionFun()"></textarea>
                <div class="recomended_chars">
                    Recommended: 140 characters. You’ve used <span style="color: #36B37E;">{{facebookDescriptionLength}}</span>
                </div>
            </div>
            <div class="card_right_part">
                <div class="box_title">Facebook Card Preview</div>
                <div class="search_engine_box">
                    <div class="facebook_imgUrl" *ngIf="facebookImage === undefined; else upImg">
                        Facebook
                    </div>
                    <ng-template #upImg>
                        <div class="fb_imgUrl">
                            <img [src]="facebookImage">
                        </div>
                    </ng-template>
                    <div class="google_result">
                        {{domainName}} › home
                    </div>
                    <div class="meta_title">
                        {{facebookTitle ? facebookTitle : 'Title'}}
                    </div>
                    <div class="meta_description">
                        {{facebookDescription ? facebookDescription : 'Description'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="facebook_card">
        <div class="card_title">Twitter Card</div>
        <div class="hr_line"></div>
        <div class="card_bottom_layer">
            <div class="card_left_part">
                <div class="box_title">Twitter Image</div>
                <div class="facebook_image_box" *ngIf="!pageLoader ; else uploadTwitterLoader">
                    <div class="upload_img">
                        <img src="./../../../../../assets/images/common/upload_img.svg" alt="upload">
                    </div>
                    <div class="uploading_img"   
                        id="drop_zone"
                        (drop)="dropHandler($event);"
                        (dragover)="dragOverHandler($event);"
                        >
                        <div class="select_img">
                            <input type="button" value="Click to upload" class="fc_browse_btn" id="display_browse_btn" (click)="browseTwitterImage()">
                            <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/jpeg,image/jpg" (change)="updateTwitterImage($event)"
                            class="fc-btn" id="browse_twitter_image"/>
                        </div>
                        <div class="dropzone">
                            <div class="text-drop">
                                <div class="centered">Or Drop your file here!</div>
                                <img src="" alt="" draggable="true">
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #uploadTwitterLoader>
                    <div class="facebook_image_box">
                        <div class="image_loader">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                </ng-template>
                <div class="box_title">Twitter Title</div>
                <mat-form-field appearance="outline" class="input_card">
                    <input placeholder="Enter meta title" matInput maxlength="60" (keyup)="twitterTitleFun()"
                    [(ngModel)]="twitterTitle"/>
                </mat-form-field>
                <div class="recomended_chars">
                    Recommended: 60 characters. You’ve used <span style="color: #36B37E;">{{twitterTitleLength}}</span>
                </div>


                <div class="box_title">Twitter Description</div>
                <textarea id="meta_description" cols="50" rows="4" class="meta_desc"
                placeholder="Enter meta Description" maxlength="140" [(ngModel)]="twitterDescription"
                (keyup)="twitterDescriptionFun()"></textarea>
                <div class="recomended_chars">
                    Recommended: 140 characters. You’ve used <span style="color: #36B37E;">{{twitterDescriptionLength}}</span>
                </div>
            </div>
            <div class="card_right_part">
                <div class="box_title">Twitter Card Preview</div>
                <div class="search_engine_box">
                    <div class="twitter_imgUrl" *ngIf="twitterImage === undefined; else twitterImg">
                        Twitter
                    </div>
                    <ng-template #twitterImg>
                        <div class="fb_imgUrl">
                            <img [src]="twitterImage">
                        </div>
                    </ng-template>
                    <div class="google_result">
                        {{domainName}} › home
                    </div>
                    <div class="meta_title">
                        {{twitterTitle ? twitterTitle : 'Title'}}
                    </div>
                    <div class="meta_description">
                        {{twitterDescription ? twitterDescription : 'Description'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="update_btn">
            <button (click)="updateSeoTags()">Update</button>
        </div>
    </div>
</section>
<section class="loader_section" *ngIf="pageLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>