import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-builder-introduction',
  templateUrl: './builder-introduction.component.html',
  styleUrls: ['./builder-introduction.component.scss']
})
export class BuilderIntroductionComponent implements OnInit {

  constructor(
    private router : Router
  ) { }

  bTypeScreen : boolean = false;
  seeSuggestionsScreen : boolean = false;
  businessNameScreen : boolean = false;
  suggestedNamesScreen : boolean = false;
  btype = '';
  bName = '';

  ngOnInit(): void {
  }

  start(){
    // this.bTypeScreen = true;
    // this.seeSuggestionsScreen = false;
    this.router.navigate(['/business-type']);
  }
  seeSuggestions(){
    this.bTypeScreen = false;
    this.seeSuggestionsScreen = true;
  }
  suggestions = [
    "ajay","durga prasad","gaurav"
  ]

  seeMore(){
    this.suggestions = [];
    this.suggestions.push("chaithanya","chanti","sandeep")
  }
  goBack(){
    this.bTypeScreen = true;
    this.seeSuggestionsScreen = false;
  }
  gotoBName(){
    this.bTypeScreen = false;
    this.seeSuggestionsScreen = false;
    this.businessNameScreen = true;
  }
  goToBtypeScreen(){
    this.bTypeScreen = true;
    this.seeSuggestionsScreen = false;
    this.businessNameScreen = false;
  }
  seeSuggestedNames(){
    this.suggestedNamesScreen = true;
    this.bTypeScreen = false;
    this.seeSuggestionsScreen = false;
    this.businessNameScreen = false;
  }
  enterOwnBName(){
    this.suggestedNamesScreen = false;
    this.bTypeScreen = false;
    this.seeSuggestionsScreen = false;
    this.businessNameScreen = true;
  }
  generateWebsite(){
    this.router.navigate(['/generating-website'])
  }

}
