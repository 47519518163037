import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../blog-service.service';

@Component({
  selector: 'app-delete-blog',
  templateUrl: './delete-blog.component.html',
  styleUrls: ['./delete-blog.component.scss']
})
export class DeleteBlogComponent {
  blogId:any="";
  constructor(
    public dialogRef: MatDialogRef<DeleteBlogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService:BlogServiceService,
  ){
    this.blogId=this.data.blogId
  }

  deleteBlog(){
    this.blogService.deleteImage(this.blogId).subscribe(
      (res:any)=>{
        this.blogService.openSnack("Blog deleted successfully","Close");
        this.dialogRef.close({ended:"success"})
      },
      (err)=>{
        //console.log("error orccured");
        
      }
    )
  }

  cancelBlog(){
    this.dialogRef.close({ended:"closed"})
  }
}
