import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss', './mq-aboutus.component.scss'],
})
export class AboutusComponent {
  @Input() templateData: any;
  valuesData: any;
  componentData: any;
  @Input() color: any;
  constructor(
    private templatesService: TemplateService,
    private _dbService: DbService,
    private webisiteService:WebisiteService
  ) {}

  ngOnInit() {
    this.templatesService.updateElements.subscribe((value: any) => {
      value.componentContentList.forEach((conCompList: any) => {
        if (
          conCompList.webComponentName ===
          'simple_about_us_section_background_image'
        ) {
          this.templateData = conCompList;
          this.dataAssign();
        } else {
        }
      });
    });

    this.dataAssign();
  }

  //  data:any=[
  //   {"value":"About-Us","field":'heading',"type":'heading',"style":{'font-weight':300},"textType":"heading"},
  //   {"value":"[Company Name] Construction Company is a construction firm that specializes in delivering high-quality building and construction services to clients. With years of experience in the industry, the company has established a reputation for delivering projects on time and within budget. JK Construction offers a full range of construction services, including design and build, project management, and construction management.","type":'text',"field":'sub-text',"style":{},"textType":"text"},
  //   {"value":"The company is known for its commitment to safety and its use of the latest technology and construction techniques. JK Construction has a team of highly skilled and experienced professionals who work closely with clients to understand their specific needs and deliver tailored solutions. Whether you are looking to build a new home, renovate your existing property, or construct a commercial building, JK Construction Company can help you achieve your goals.","type":'text',"field":'sub-text',"style":{},"textType":"text"},
  //   {"value":'Contact-Us',"type":'button',"link":'',"style":{},"field":"button","display":true,"textType":"button"},
  //   {"imageUrl":'../../../assets/images/editor/aboutus.svg',"style":{},"display":true,"type":'image',"field":'image',"textType":"image"},
  //   {"alignment":false,"style":{}}
  // ]

  // heading=this.data[0];
  // text1=this.data[1];
  // text2=this.data[2];
  // image=this.data[4];

  data: any;

  bgColor: any;

  dataAssign() {
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if (this.templateData.values != null) {
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title;
      this.data[4].imgUrl = this.valuesData.imgUrl;
      this.data[1].value = this.valuesData.desc[0];
      this.data[2].value = this.valuesData.desc[1];
      //Company name showing like [company name]
      this.data[2].value = this.data[2].value.replaceAll(
        '{Company Name}',
        bName
      );
    } else {
    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data: any) {
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if (data.textType === 'heading') {
  //     data.style = {
  //       color: this.templateData.colors.txtHeadingColor,
  //       'font-family': this.templateData.headingFontFamily,
  //     };
  //   } else if (data.textType === 'description') {
  //     data.style = {
  //       color: this.templateData.colors.txtDescColor,
  //       'font-family': this.templateData.bodyFontFamily,
  //     };
  //   } else if (data.textType === 'text') {
  //     data.style = {
  //       color: this.templateData.colors.txtNormalColor,
  //       'font-family': this.templateData.bodyFontFamily,
  //     };
  //   }
  // }
  // typeButton(data: any) {
  //   if (this.templateData.buttonStyle === 'SOLID') {
  //     if (this.templateData.buttonShape === 'SHARP') {
  //       data.style = {
  //         'background-color': this.templateData.colors.btnColor,
  //         color: this.templateData.colors.btnTextColor,
  //         'font-family': this.templateData.bodyFontFamily,
  //         'border-radius': '0px',
  //         padding: '8px 15px',
  //         border: 'none',
  //         height: '44px',
  //         'font-size': '16px',
  //       };
  //     } else {
  //       data.style = {
  //         'background-color': this.templateData.colors.btnColor,
  //         color: this.templateData.colors.btnTextColor,
  //         'font-family': this.templateData.bodyFontFamily,
  //         'border-radius': `10px`,
  //         padding: '8px 15px',
  //         border: 'none',
  //         height: '44px',
  //         'font-size': '16px',
  //       };
  //     }
  //   } else {
  //     if (this.templateData.buttonShape === 'SHARP') {
  //       data.style = {
  //         'background-color': 'transparent',
  //         color: this.templateData.colors.btnTextColor,
  //         'font-family': this.templateData.bodyFontFamily,
  //         border: `2px solid ${this.templateData.colors.btnColor}`,
  //         padding: '8px 15px',
  //         height: '44px',
  //         'font-size': '16px',
  //         // "border-radius":"none"
  //       };
  //     } else {
  //       data.style = {
  //         'background-color': 'transparent',
  //         color: this.templateData.colors.btnTextColor,
  //         'font-family': this.templateData.bodyFontFamily,
  //         'border-radius': `10px`,
  //         padding: '8px 15px',
  //         border: `2px solid ${this.templateData.colors.btnColor}`,
  //         height: '44px',
  //         'font-size': '16px',
  //       };
  //     }
  //   }
  // }
}
