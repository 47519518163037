<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value | titlecase}}
        </div>
        <div class="description" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
        <!-- <div class="inputBtn">
            <input type="text" placeholder="{{inputText.value}}" class="input" [ngStyle]="inputText.style?inputText.style:{}">
            <button class="button" [ngStyle]="getStartedButton.style?getStartedButton.style:{}">{{getStartedButton.value}}
            </button>
        </div> -->
        <div class="contact-btn">
            <button class="button" [ngStyle]="data[3].style?data[3].style:{}" (click)="redirectUrl()">{{data[3].value | titlecase}}</button>
        </div>
    </div>
    <div class="rightContainer">
        <img class="rightImage" src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}" loading="lazy">
    </div>
</div>