import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from './../../../services/template.service';

@Component({
  selector: 'app-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.scss']
})
export class BlogSectionComponent {
  @Input() pageData:any={};
  navId:any;
  constructor(
    private templatesService:TemplateService,
    private router:Router,
    private activatedRoute:ActivatedRoute
    ){
    
  }
  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(
    //   (params) => {
    //     this.navId = params['elem']
    //     // //console.log(params['elem'])
    //   }
    // )
    // this.getAllBlogs();
    // this.templatesService.updateElementsData.subscribe((res:any)=>{
    //   //console.log("updateElementsData",res);
    //   this.pageData=res;
    // })
    //console.log("blog data",this.pageData)
  }

  blogsData:any=[
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // },
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // },
    // {
    //   blogHeading:"The road to success is always under construction",
    //   blogCreatedDate:'April 20, 2015'
    // }
  ]

  blogData:any="";
  getAllBlogs(){
    this.templatesService.getAllBlogs().subscribe({
      next:(value:any)=>{
        //console.log("value",value.data);
        this.blogsData=value.data;
        //console.log("blogsData",this.blogsData);
            
      },
      error:(err)=>{
        //console.log("error",err);
        
      },
    })
  }


  clickCards(cards:any){
    //console.log(cards);
    this.router.navigate(['/view-blog'],{queryParams:{blogId:cards.id}})
    }
}
