import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-contactus-text-inputs',
  templateUrl: './contactus-text-inputs.component.html',
  styleUrls: ['./contactus-text-inputs.component.scss','./mq-contactus-text-inputs.component.scss']
})
export class ContactusTextInputsComponent {

  @Input() templateData:any;
  valuesData:any;
  
  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private _matPopup : MatSnackBar,
    private webisiteService:WebisiteService
  ){}


  // dataSource: any = {
  //   "componentName": "SimpleFooterGetInTouchComponent",
  //   "headerData" : [
  //     {
  //       "value": "{Company Name} Getting in touch with you",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight": 500,"font-size": "36px", "color":"#000000"}
  //     },
  //     {
  //       "value": "We're glad you're interested in reaching out to us.Whether you have a question about our products, want to give us feedback, or just want to say hi,we're here to help.You can get in touch with us filing out this form",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight": 500,"font-size": "18px", "color":"rgb(149, 147, 147)","padding-top": "2%"}
  //     },
  //     // {
  //     //   "value": "Location :",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
  //     // },
  //     // {
  //     //   "value": "Hyderabad,TG 500008",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
  //     // },
  //     // {
  //     //   "value": "Email :",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
  //     // },
  //     // {
  //     //   "value": "contactus@gmail.com",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
  //     // },
  //     // {
  //     //   "value": "Call :",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
  //     // },
  //     // {
  //     //   "value": "+1 (551)318-7369",
  //     //   "type":"Text",
  //     //   "field":"Text",
  //     //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
  //     // },
  //   ]
  // }

  // headerData = this.dataSource.headerData;
  
  // templateData ={
  //   "data" :[
  //     {
  //       "value":"[Company Name] Getting in touch with you",
  //       "type":"text",
  //       "field":"Text",
  //       "style":{"font-weight": 900},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Use this form to get in touch with [Company Name]. Whether you have a question or need a quote, we'll be happy to help.",
  //       "type":"text",
  //       "field":"Text",
  //       "style":{"font-weight": 100},
  //       "textType":"description"
  //     },
  //     {
  //       "value":'Send',
  //       "type":"button",
  //       "field":"button",
  //       "textType":"button",
  //       "style":{"font-weight":300}
  //     }
  //   ]
  // }

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        //console.log(value)
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'simple_contactus_right_heading'){
            this.templateData = conCompList;
            this.dataAssign();
          }
        });
      })
    
    this.dataAssign();
  }

  data : any;
  bgColor : any;
  // redirectionUrl : any;
  dataAssign(){
    this.data = this.templateData.data;
    this.checkingStyles();
    var bName = this._dbService.getBusinessName();
    this.data[0].value = this.data[0].value.replaceAll("{Company Name}", bName);
    this.data[1].value = this.data[1].value.replaceAll("{Company Name}", bName);
    this.bgColor = this.templateData.bgColor;
  }
  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
       elems= this.webisiteService.typeText(elems,this.templateData)
      }
      else if(elems.type === 'button'){
        elems= this.webisiteService.typeButton(elems,this.templateData)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        // this.typeText(arrValue)
        arrValue=  this.webisiteService.typeText(arrValue,this.templateData)
      }
      else if(arrValue.type === 'button'){
        // this.typeButton(arrValue)
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  showMessage(){
    this._matPopup.open(
      'You need to signup to access this feature',
      'Ok'
    )
  }
  redirectionUrl(){
    window.open(`http://maps.google.com/maps?z=11&t=k&q=${this.data[4].value[3].value}N ${this.data[4].value[4].value}E`,'_blank')
  }
  redirectEmail(){
    window.location.href = `mailto:${this.data[8].value}`
  }
  redirectMobile(){
    window.location.href = `tel:${this.data[6].value}`;
  }
}
