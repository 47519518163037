<div
  class="success_stories_section"
  [ngStyle]="{ 'background-image': 'url(' + data[0].imgUrl + ')' }"
  id="testimonial_bg_image_image"
  *ngIf="!loader && !sectionLoading"
>
  <div class="stories">
    <ngx-slick-carousel
      class="carousel carousel_box"
      [config]="slideConfig"
      #slickModal="slick-carousel"
      [ngStyle]="{'background-color': bgColor}"
    >
      <div
        ngxSlickItem
        class="single_success_story"
      >
        <div class="double_quotes">
          <img
            src="./../../../../assets/images/double_quotes.svg"
            alt="double_quotes"
          />
        </div>
        <div
          class="person_short_note"
          [ngStyle]="data[1].value[0].style ? data[1].value[0].style : {}"
        >
          {{ data[1].value[0].value}} <br>
          <span class="person_note"
          [ngStyle]="data[1].value[1].style ? data[1].value[1].style : {}">
          {{data[1].value[1].value}}
          </span>
        </div>
        <div class="reviewer-company">
          <div
            class="person_name"
            [ngStyle]="
            data[1].value[2].style ? data[1].value[2].style : {}
            "
          >
            {{ data[1].value[2].value }}
          </div>
          <div class="person-details">
          <div
            class="person_designation"
            [ngStyle]="data[1].value[3].style ? data[1].value[3].style : {}"
          >
            &nbsp;{{ data[1].value[3].value }}
          </div>
          
          <div class="person-type"
            [ngStyle]="
            data[1].value[4].style ? data[1].value[4].style : {}
            "
          >
            &nbsp; - {{ data[1].value[4].value  }}
          </div>
        </div>
        </div>
      </div>
      <div
        ngxSlickItem
        class="single_success_story"
      >
        <div class="double_quotes">
          <img
            src="./../../../../assets/images/double_quotes.svg"
            alt="double_quotes"
          />
        </div>
        <div
          class="person_short_note"
          [ngStyle]="data[2].value[0].style ? data[2].value[0].style : {}"
        >
          {{ data[2].value[0].value | titlecase}} <br>
          <span class="person_note"
          [ngStyle]="data[2].value[1].style ? data[2].value[1].style : {}">
          {{data[2].value[1].value}}
          </span>
        </div>
        <div class="reviewer-company">
          <div
            class="person_name"
            [ngStyle]="
            data[2].value[2].style ? data[2].value[2].style : {}
            "
          >
          {{ data[2].value[2].value }}
          </div>
          <div class="person-details">
          <div
            class="person_designation"
            [ngStyle]="data[2].value[3].style ? data[2].value[3].style : {}"
          >
            &nbsp;{{ data[2].value[3].value }}
          </div>
          <div class="person-type"
            [ngStyle]="
            data[2].value[4].style ? data[2].value[4].style : {}
            "
          >
            &nbsp; - {{ data[2].value[4].value  }}
          </div>
          </div>
        </div>
      </div>
      <div
      ngxSlickItem
      class="single_success_story"
    >
      <div class="double_quotes">
        <img
          src="./../../../../assets/images/double_quotes.svg"
          alt="double_quotes"
        />
      </div>
      <div
        class="person_short_note"
        [ngStyle]="data[3].value[0].style ? data[3].value[0].style : {}"
      >
        {{ data[3].value[0].value | titlecase}} <br>
        <span class="person_note"
          [ngStyle]="data[3].value[1].style ? data[3].value[1].style : {}">
          {{data[3].value[1].value}}
          </span>
      </div>
      <div class="reviewer-company">
        <div
          class="person_name"
          [ngStyle]="
          data[3].value[2].style ? data[3].value[2].style : {}
          "
        >
        {{ data[3].value[2].value }}
        </div>
        <div class="person-details">
        <div
          class="person_designation"
          [ngStyle]="data[3].value[3].style ? data[3].value[3].style : {}"
        >
          &nbsp;{{ data[3].value[3].value }}
        </div>
        <div class="person-type"
          [ngStyle]="
          data[3].value[4].style ? data[3].value[4].style : {}
          "
        >
          &nbsp; - {{ data[3].value[4].value  }}
        </div>
      </div> 
      </div>
    </div>
    </ngx-slick-carousel>
  </div>
</div>
<section *ngIf="loader" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</section>
<section *ngIf="sectionLoading" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <div class="text">Generating best testimonials</div>
</section>