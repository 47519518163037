import { Component, HostListener, Input } from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-top-navbar1',
  templateUrl: './top-navbar1.component.html',
  styleUrls: ['./top-navbar1.component.scss']
})
export class TopNavbar1Component {
  @Input() templateData:any;
  constructor(
    private eventEmitterService : EventEmmiterService
  ){
    this.getScreenSize();
  }
  businessName:String|null=window.localStorage.getItem('bname');
  header : any = {
    logo :{
      imageText : '[Company Name]',
      textStyles:{
        'font-size':'24px',
        'font-weight':'bold',
        'font-family':"'Lexend Deca', sans-serif",
        'color':'white'
      }
    },
    contactBtn:{
      buttonText:'Contact Now',
      routeId:"CONTACT_SECTION",
      textStyles:{
        'font-size':'18px',
        'font-weight':'normal',
        'font-family':"'Lexend Deca', sans-serif",
        'color':'white',
        'background-color':'#FE7F2D',
        'border':'none',
        'outline':'none',
        'border-radius':'8px',
        'padding':'3px 15px'
      }
    },
    ids:['navbar2','navbar1']
  }

  scrHeight : any;
  scrWidth : any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    //console.log(this.scrHeight, this.scrWidth);
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  x : any;
  ngOnInit(){
    // this.eventEmitterService.globalRegenerate.subscribe(
    //   (res) => {
    //     if(res.globalRegenerate){
    //       // let allIds = document.querySelectorAll('*[id]');
    //       let n = this.header.ids.length;
    //       this.x = this.x % n;
    //       // let  first_x_elements = this.totalData.slice(0,this.x);
    //       let first_x_elements = this.header.ids.slice(0,this.x);
    //       // let remaining_elements = this.totalData.slice(this.x,n);
    //       let remaining_elements = this.header.ids.slice(this.x,n)
    //       this.header.ids = [...remaining_elements,...first_x_elements];
    //       let showNavbar = document.getElementById(`${this.header.ids[0]}`)
    //       if(!showNavbar) return;
    //       showNavbar.style.visibility = 'visible';
    //       //console.log(this.header.ids)
    //     }
        
    //   }
    // )
  }
}
