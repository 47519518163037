<section class="main_section">
    <div class="main-header-section">
    <div class="main-heading" [ngStyle]="data[0].style?data[0].style:{}">
        {{data[0].value}}
    </div>
    <div class="sub-heading" [ngStyle]="data[1].style?data[1].style:{}">
        {{data[1].value}}
    </div>
    </div>

    <div class="middle-faq-section">
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[2].imgUrl}}" alt="" [ngStyle]="data[2].style?data[2].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[3].style?data[3].style:{}">
                {{data[3].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
        </div>
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[6].style?data[6].style:{}">
                {{data[6].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[7].style?data[7].style:{}">
                {{data[7].value}}
            </div>
        </div>
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[8].imgUrl}}" alt="" [ngStyle]="data[8].style?data[8].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[9].style?data[9].style:{}">
                {{data[9].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[10].style?data[10].style:{}">
                {{data[10].value}}
            </div>
        </div>
    </div>

    <div class="middle-faq-second-section">
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[11].imgUrl}}" alt="" [ngStyle]="data[11].style?data[11].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[12].style?data[12].style:{}">
                {{data[12].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[13].style?data[13].style:{}">
                {{data[13].value}}
            </div>
        </div>
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[14].imgUrl}}" alt="" [ngStyle]="data[14].style?data[14].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[15].style?data[15].style:{}">
                {{data[15].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[16].style?data[16].style:{}">
                {{data[16].value}}
            </div>
        </div>
        <div class="first-faq">
            <div class="image-section">
                <img src="{{data[17].imgUrl}}" alt="" [ngStyle]="data[17].style?data[17].style:{}"
                        class="first-img" />
            </div>
            <div class="first-heading" [ngStyle]="data[18].style?data[18].style:{}">
                {{data[18].value}}
            </div>
            <div class="first-faq-text" [ngStyle]="data[19].style?data[19].style:{}">
                {{data[19].value}}
            </div>
        </div>
    </div>


</section>
