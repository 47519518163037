<div class="main-section">
    <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
    <div class="stats-section">
        <div class="stats" >
            <div class="statsHeading" [ngStyle]="subheading1.style?subheading1.style:{}">{{subheading1.value}}</div>
            <div class="statsText" [ngStyle]="subtext1.style?subtext1.style:{}">{{subtext1.value}}</div>
        </div>
        <div class="stats" >
            <div class="statsHeading" [ngStyle]="subheading2.style?subheading2.style:{}">{{subheading2.value}}</div>
            <div class="statsText" [ngStyle]="subtext2.style?subtext2.style:{}">{{subtext2.value}}</div>
        </div>
        <div class="stats" >
            <div class="statsHeading" [ngStyle]="subheading3.style?subheading3.style:{}">{{subheading3.value}}</div>
            <div class="statsText" [ngStyle]="subtext3.style?subtext3.style:{}">{{subtext3.value}}</div>
        </div>
        <div class="stats" >
            <div class="statsHeading" [ngStyle]="subheading4.style?subheading4.style:{}">{{subheading4.value}}</div>
            <div class="statsText" [ngStyle]="subtext4.style?subtext4.style:{}">{{subtext4.value}}</div>
        </div>
        <div class="stats" >
            <div class="statsHeading" [ngStyle]="subheading5.style?subheading5.style:{}">{{subheading5.value}}</div>
            <div class="statsText" [ngStyle]="subtext5.style?subtext5.style:{}">{{subtext5.value}}</div>
        </div>    
    </div>
</div>
