<div class="mainContainer">
    <div class="rowContainer">
        <div class="logoHeading">
            <img src="{{data[11].imgUrl}}" alt="" [ngStyle]="data[11].style?data[11].style:{}" class="logo-img">
            <div [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value |titlecase}}</div>
        </div>
        <!-- <div class="spaceBtw">
            <span [ngStyle]="company.style?company.style:{}">{{company.value}}</span>
            <span [ngStyle]="resource.style?resource.style:{}">{{resource.value}}</span>
            <span [ngStyle]="careers.style?careers.style:{}">{{careers.value}}</span>
            <span [ngStyle]="help.style?help.style:{}">{{help.value}}</span>
            <span [ngStyle]="privacy.style?privacy.style:{}">{{privacy.value}}</span>
        </div> -->
        <div class="right-footer-section">
            <div [ngStyle]="data[6].style?data[6].style:{}" class="copyRightsContent">{{data[6].value | titlecase}}
            </div>
            <div class="icons">
                <a [href]="data[7].redirectionUrl"><img [ngStyle]="data[7].style?data[7].style:{}"
                        src="{{data[7].imgUrl}}" alt="" class="facebook"></a>
                <a [href]="data[7].redirectionUrl"><img [ngStyle]="data[8].style?data[8].style:{}"
                        src="{{data[8].imgUrl}}" alt="" class="insta"></a>
                <a [href]="data[9].redirectionUrl"><img [ngStyle]="data[9].style?data[9].style:{}"
                        src="{{data[9].imgUrl}}" alt="" class="linkin"></a>
                <a [href]="data[9].redirectionUrl"><img [ngStyle]="data[10].style?data[10].style:{}"
                        src="{{data[10].imgUrl}}" alt="" class="twitter"></a>
            </div>
        </div>
    </div>


</div>