<div class="navProperties">
    <nav class="leftNav">
       <img [ngStyle]="data[0].style?data[0].style:{}" src="{{data[0].imgUrl}}" alt="">
    </nav>
    <nav class="rightNav" >
        <!-- <span [ngStyle]="home.style?home.style:{}">{{home.value}}</span>
        <span [ngStyle]="dashboard.style?dashboard.style:{}">{{dashboard.value}}</span>
        <span [ngStyle]="pricing.style?pricing.style:{}">{{pricing.value}}</span> -->
        <span><button [ngStyle]="data[4].style?data[4].style:{}">{{data[4].value}}</button></span>
    </nav>
</div>