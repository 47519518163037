import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmmiterService {

  constructor() { }

  componentLoader = new EventEmitter<any>();
  globalRegenerate = new EventEmitter<any>();
  loadingSections = new EventEmitter<any>();
  activateComponent = new EventEmitter<any>();
}
