<div class="total-section">
    <div class="middle-section">
        <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
    </div>
    <div class="images-container">
        <div class="images-section">
           <div class="image-section-firstpart">
            <div>
            <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
            </div>
        <div>
            <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        </div>
        <div>
            <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
        </div>
        </div>
        <div class="image-section-secondpart">
            <div>
                <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
                </div>
                <div>
                    <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
                </div>
                <div>
                    <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
                </div>
        </div>
        <div class="image-section-lastpart">
            <div>
                <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
                </div>
                <div>
                    <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
                </div>
        </div>
    </div>

</div>
<div class="footer-container">
    <div class="footer-part">
        <div class="footer">
            <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}">
            </div>
            <div class="stars">
                <img src="{{icon2.imgUrl}}" alt="" [ngStyle]="icon2.style?icon2.style:{}">
                <div [ngStyle]="text1.style?text1.style:{}">{{text1.value}}</div>
            </div>
    </div>
</div>
</div>


