import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SeoImageUploadService } from 'src/app/services/ImageUploadService/seo-image-upload.service';

@Component({
  selector: 'app-add-author',
  templateUrl: './add-author.component.html',
  styleUrls: ['./add-author.component.scss']
})
export class AddAuthorComponent {

  constructor(
    public dialogRef: MatDialogRef<AddAuthorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public blogService: BlogServiceService,
    public ds : DomSanitizer,
    private _seoImageUploadService : SeoImageUploadService,
  ) {
  }

  enterAuthor: string = '';
  enterSlug: string = '';
  enterEmail: string = '';
  imgFile: string = '';
  edit: boolean = this.data.edit;
  ngOnInit() {
    if (this.data.edit) {
      this.enterAuthor = this.data.data.authorName;
      this.enterEmail = this.data.data.email;

    }

  }

  browseFcImage() {
    document.getElementById('browse_fc_image')?.click();
  }

  file:any ='';
   async updatePostImage(ev: any) {
    const file = ev.srcElement.files[0];
    this.file = file;
    // this.noImg = window.URL.createObjectURL(file);
    // this.jeweller.jewellerLogo  = window.URL.createObjectURL(file);
    this.jeweller.jewellerLogo = await this._seoImageUploadService.uploadSEOimages(file,'Blogs')
    this.jeweller.jewellerLogo = this.jeweller.jewellerLogo.Location;

  }

  jeweller:any={
    businessName: "",
    legalEntityName: "",
    jewellerWebsite: "",
    address: {
      address: "",
      city: "",
      state: "",
      pinCode: ""
    },
    contactPerson: "",
    contactPhoneNo: "",
    jewellerLogo: "",
    jewellerGenId: "",
    active: true,
    createdTimeStamp: new Date(),
    updatedTimeStamp: new Date(),
    accountDetails: {
      accountName: "",
      accountNumber: 0,
      ifsc_code: "",
      account_holder_name: "",
      tan_number: 0
    }
  }

  delete(){
    this.jeweller.jewellerLogo  = "";
  }

  addAuthor() {
    let data = {
      authorName: this.enterAuthor,
      email: this.enterEmail,
      imgUrl: this.jeweller.jewellerLogo,
      createdTimeStamp: new Date(),
      blogsCreatedCount: 0,
      createdById: null,
      createdByName: "ajay",
      businessId: JSON.parse(window.localStorage.getItem("bDetails") || "").id
    }
    this.blogService.createBlogAuthor(data).subscribe({
      next: (res: any) => {
        this.blogService.openSnack("Author created sucessfully", "Close");
        this.dialogRef.close();
      },
      error: (err: any) => {
        this.blogService.openSnack(err.error.message, "close");
      }
    })
  }

  editAuthor() {
    let data = {
      id: this.data.data.id,
      authorName: this.enterAuthor,
      email: this.enterEmail,
      imgUrl: this.jeweller.jewellerLogo,
      createdTimeStamp: new Date(),
      blogsCreatedCount: this.data.data.blogsCreatedCount,
      createdById: this.data.data.createdById,
      createdByName: this.data.data.createdByName,
      businessId: JSON.parse(window.localStorage.getItem("bDetails") || "").id
    }
    this.blogService.updateBlogAuthor(data).subscribe({
      next: (value: any) => {
        this.blogService.openSnack("Author updated sucessfully", "Close");
        this.dialogRef.close();
      },
      error: (err: any) => {
        this.dialogRef.close();
      },
    })
  }

  closeBtn() {
    this.dialogRef.close();
  }

}

