<div class="main-section">
    <div class="heading">
        <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
    </div>
    <div class="stats-section">
        <div class="stats" *ngFor="let item of statsHeading.value; let i = index" >
            <div [ngStyle]="statsHeading.style?statsHeading.style:{}">{{item}}</div>
            <div [ngStyle]="statsText.style?statsText.style:{}">{{statsText.value[i]}}</div>
        </div>
    </div>
</div>
