import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-footer1',
  templateUrl: './footer1.component.html',
  styleUrls: ['./preview-footer1.component.scss' ,]
})
export class PreviewFooter1Component {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;


  constructor(){}

  ngOnInit(){
    this.dataAssign();
  }
  businessName:any=window.localStorage.getItem('bname');
  // data = [{
  //   "logo" :{
  //     "imageText" : "[Company Name]",
  //     "textStyles":{
  //       "font-size":"24px",
  //       "font-weight":"bold",
  //       "font-family":"Lexend Deca, sans-serif",
  //       "color":"white"
  //     }
  //   },
  //   "shortNote":{
  //     "text":"Constructing world best",
  //     "textStyles":{
  //       "font-size":"16px",
  //       "font-weight":"normal",
  //       "font-family":"Lexend Deca, sans-serif",
  //       "color":"white"
  //     }
  //   },
  //   "className" : "normal"
  // }];
  data : any;


  footer :any;

  dataAssign(){
    // //console.log('footer templateData',this.templateData);
    // this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    this.footer = this.data[0];
    if(this.templateData.values != null){
      this.valuesData = this.templateData.values;
      this.data[0].logo.imageText = this.valuesData.companyName;
      this.data[0].shortNote.text = this.valuesData.companyDesc;
      this.data[0].instagramUrl = this.valuesData.instagramUrl;
      this.data[0].twitterUrl = this.valuesData.twitterUrl;
      this.data[0].facebookUrl = this.valuesData.facebookUrl;
    }
  }
}
