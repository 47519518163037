<section class="main_section">
    <div class="main-header" [ngStyle]="data[0].style?data[0].style:{}">
        {{data[0].value}}
    </div>
    <div class="photos-section">
        <div class="company-image">
            <img src="{{data[1].imgUrl}}" alt="" [ngStyle]="data[1].style?data[1].style:{}"
            class="editor-img" />
        </div>
        <div class="company-image">
            <img src="{{data[2].imgUrl}}" alt="" [ngStyle]="data[2].style?data[2].style:{}"
            class="editor-img" />
        </div>
        <div class="company-image">
            <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}"
            class="editor-img" />
        </div>
        <div class="company-image">
            <img src="{{data[4].imgUrl}}" alt="" [ngStyle]="data[4].style?data[4].style:{}"
            class="editor-img" />
        </div>
        <div class="company-image">
            <img src="{{data[5].imgUrl}}" alt="" [ngStyle]="data[5].style?data[5].style:{}"
            class="editor-img" />
        </div>
        <div class="company-image">
            <img src="{{data[6].imgUrl}}" alt="" [ngStyle]="data[6].style?data[6].style:{}"
            class="editor-img" />
        </div>
    </div>
</section>
