import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from './../../services/template.service';

@Component({
  selector: 'app-business-name',
  templateUrl: './business-name.component.html',
  styleUrls: ['./business-name.component.scss' , './mq-business-name.component.scss']
})
export class BusinessNameComponent implements OnInit {

  bName = '';
  suggestedNamesScreen : boolean = false;
  showSuggestionsHint : boolean = false;
  getSuggestedNames : any = [];

  constructor(
    private router : Router,
    private templateService : TemplateService,
    private _dbService : DbService
  ) { }

  ngOnInit(): void {
    this.templateService.getAllBusinessName(this._dbService.getSubCategoryId()).subscribe(
      (res:any) => {
        // this.getSuggestedNames = res.data;
        res.data.forEach((bnames:any) => {
          this.getSuggestedNames.push({name:bnames})
        });
        if(this.getSuggestedNames.length != 0) {
          this.showSuggestionsHint = true;
        }
      },
      (err) => {
        //console.log(err)
      }
    )
    this.getBusinessCategoryImages();
    this.getScreenSize();
  }

  
  scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?:any) {
    this.scrWidth = window.innerWidth;
  }

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":true,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":false,
    "pauseOnHover":false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  mobileslideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "infinite":false,
    "autoplay":false,
    "autoplaySpeed":3500,
    "dots":false,
    "arrows":true,
    "pauseOnHover":true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  suggestNames : any = [];
  x  = 3;

  seeSuggestedNames(){
    this.suggestNames = [];
    this.suggestedNamesScreen = true;
    let n = this.getSuggestedNames.length;
    this.x = this.x % n;
    let  first_x_elements = this.getSuggestedNames.slice(0,this.x);
    let remaining_elements = this.getSuggestedNames.slice(this.x,n);
    this.getSuggestedNames = [...remaining_elements,...first_x_elements];
    this.getSuggestedNames.forEach((element:any,i:any) => {
      if(i < 3){
        this.suggestNames.push(element)
      }
    });
  }
  enterOwnBName(){
    this.suggestedNamesScreen = false;
    this.suggestNames = [];
  }
  seeMore(){
    this.suggestNames = [];
    let n = this.getSuggestedNames.length;
    this.x = this.x % n;
    let  first_x_elements = this.getSuggestedNames.slice(0,this.x);
    let remaining_elements = this.getSuggestedNames.slice(this.x,n);
    this.getSuggestedNames = [...remaining_elements,...first_x_elements];
    this.getSuggestedNames.forEach((element:any,i:any) => {
      if(i < 3){
        this.suggestNames.push(element)
      }
    });
  }

  backToScreen(){
    this.router.navigate(['business-type'])
  }
  generateWebsite(){
    // this.templateService.getTemplateStructure(window.localStorage.getItem('subCategory')).subscribe(
    //   (res:any) => {
        this.router.navigate(['/generating-website']);
        // window.localStorage.setItem('bname',this.bName);
        this._dbService.setBusinessName(this.bName)
    //     window.localStorage.setItem('tempStructure',JSON.stringify(res.data.categories));
    //   },
    //   (err) => {
    //     console.log(err.error.message);
        
    //   }
    // )
  }

  businessCategoryImages:any[]=[];
  getBusinessCategoryImages(){
    this.templateService.getBusinessCategoryImages(this._dbService.getSubCategoryId()).subscribe(
      {
        next:(value:any)=>{
          this.businessCategoryImages=value.data.imageUrls;
        },
        error:(err)=>{
         //console.log("template image url error",err);
             
        },
      }
    )
  }

  clickedSuggestions(item:any){
    this.bName = item?.name;
    this.suggestedNamesScreen = false;
  }


}
