import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-rating-review-testimonial',
  templateUrl: './rating-review-testimonial.component.html',
  styleUrls: ['./preview-rating-review-testimonial.component.scss']
})
export class PreviewRatingReviewTestimonialComponent {
  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      {
        "value": "Don’t just take our word for it",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "36px","color":"#101828"}
      },
      {
        "value": "Hear from some of our amazing customers who are building faster.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "20px","color":"#475467"}
      },
      {
        "value": "Our customers",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "16px","color":"#344054"}
      },
      {
        "value": "Create account",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "16px","color":"#FFFFFF"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/service/second_image.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Stars.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "auto","margin-bottom":"2%"}
      },
      {
        "value": "Rich Wilson",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "30px","color":"#FFFFFF"}
      },
      {
        "value": "PM, Hourglass",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "18px","color":"#FFFFFF"}
      },
      {
        "value": "Web Development Agency",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "14px","color":"#FFFFFF"}
      },
      
      

    ]
  }

  data = this.templateData.data;
}
