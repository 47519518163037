<div class="mainSection" [ngStyle]="{'background-color': bgColor}">
    <div class="headContainer">
        <div [ngStyle]="data[0].style?data[0].style:{}" class="heading">{{data[0].value | titlecase}}</div>
    </div>
    <div class="rowFlex questionAnswers">
        <div class="questionNumber" [ngStyle]="data[2].style?data[2].style:{}">
            01.
        </div>
        <div class="rightPart">
            <div [ngStyle]="data[1].style?data[1].style:{}" class="question">
                {{data[1].value}}
            </div>
            <div [ngStyle]="data[2].style?data[2].style:{}" class="answer">
                {{data[2].value}}</div>
        </div>
    </div>
    <!-- <div class="questionAnswers">
        <div [ngStyle]="data[1].style?data[1].style:{}" class="question">
            01. {{data[1].value}}
        </div>
        <div [ngStyle]="data[2].style?data[2].style:{}" class="answer">
            {{data[2].value}}</div>
    </div> -->
    <div class="rowFlex questionAnswers">
        <div class="questionNumber" [ngStyle]="data[4].style?data[4].style:{}">
            02.
        </div>
        <div class="rightPart">
            <div [ngStyle]="data[3].style?data[3].style:{}"class="question">
                {{data[3].value}}</div>
            <div [ngStyle]="data[4].style?data[4].style:{}" class="answer">
                {{data[4].value}}</div>
        </div>
    </div>
    <!-- <div class="questionAnswers">
        <div [ngStyle]="data[3].style?data[3].style:{}"class="question">
            02. {{data[3].value}}</div>
        <div [ngStyle]="data[4].style?data[4].style:{}" class="answer">
            {{data[4].value}}</div>
    </div> -->
    <div class="rowFlex questionAnswers">
        <div class="questionNumber" [ngStyle]="data[6].style?data[6].style:{}">
            03.
        </div>
        <div class="rightPart">
            <div [ngStyle]="data[5].style?data[5].style:{}" class="question">
                {{data[5].value}}</div>
            <div [ngStyle]="data[6].style?data[6].style:{}" class="answer">
                {{data[6].value}}
            </div>
        </div>
    </div>
    <!-- <div class="questionAnswers">
        <div [ngStyle]="data[5].style?data[5].style:{}" class="question">
            03. {{data[5].value}}</div>
        <div [ngStyle]="data[6].style?data[6].style:{}" class="answer">
            {{data[6].value}}
        </div>
    </div> -->
    <div class="rowFlex questionAnswers">
        <div class="questionNumber" [ngStyle]="data[8].style?data[8].style:{}">
            04.
        </div>
        <div class="rightPart">
            <div [ngStyle]="data[7].style?data[7].style:{}" class="question">
                {{data[7].value}}
            </div>
            <div [ngStyle]="data[8].style?data[8].style:{}" class="answer">
                {{data[8].value}}
            </div>
        </div>
    </div>
    <!-- <div class="questionAnswers">
        <div [ngStyle]="data[7].style?data[7].style:{}" class="question">
            04. {{data[7].value}}
        </div>
        <div [ngStyle]="data[8].style?data[8].style:{}" class="answer">
            {{data[8].value}}
        </div>
    </div> -->
</div>