import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EditWebsiteComponent } from './master-config-components/micro-apps/website/edit-website/edit-website/edit-website.component';
import { CreateBlogsComponent } from './master-config-components/micro-apps/website/edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { CreateMasterBlogsComponent } from './master-config-components/micro-apps/website/edit-website/website-generationcomponent/blogs/masterBlogs/create-master-blogs/create-master-blogs.component';
import { BuilderIntroductionComponent } from './our-components/builder-introduction/builder-introduction.component';
import { BusinessNameComponent } from './our-components/business-name/business-name.component';
import { BusinessTypeComponent } from './our-components/business-type/business-type.component';
import { GeneratingWebsiteComponent } from './our-components/generating-website/generating-website.component';
import { HomeComponent } from './our-components/home/home.component';
import { PrivacyPolicyComponent } from './our-components/privacy-policy/privacy-policy.component';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { TemplateImagesUploadComponent } from './our-components/template-images-upload/template-images-upload.component';
import { TermsConditionsComponent } from './our-components/terms-conditions/terms-conditions.component';
import { WebsiteEditorComponent } from './our-components/website-editor/website-editor.component';
import { PreviewComponent } from './preview/preview.component';
import { AuthGuard } from './services/auth.guard';
import { WebsiteGeneratorComponent } from './website-generation/website-generator/website-generator.component';
import { WebsiteComponent } from './website/website.component';
import { SuccessComponent } from './our-components/strip/success/success.component';
import { CancelComponent } from './our-components/strip/cancel/cancel.component';
import { PricingComponent } from './our-components/pricing/pricing.component';
import { WithoutSignupEditComponent } from './our-components/without-signup-edit/without-signup-edit.component';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'introduction',
    component: BuilderIntroductionComponent,
  },
  {
    path: 'generating-website',
    component: WebsiteGeneratorComponent,
  },
  {
    path: 'business-type',
    component: BusinessTypeComponent,
  },
  {
    path: 'business-name',
    component: BusinessNameComponent,
  },
  {
    path:'test',
    component:GeneratingWebsiteComponent
  },
  {
    path:'preview',
    component:PreviewComponent
  },
  {
    path: 'master',
    loadChildren: () =>
      import(
        './master-config-components/master-config-mod/master-config-mod.module'
      ).then((module) => module.MasterConfigModModule),
      canActivate:[AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(
        './master-config-components/master-config-mod/master-config-mod.module'
      ).then((module) => module.MasterConfigModModule),
      canActivate:[AuthGuard]
  },
  {
    path:'signup',
    component:SigninSignupComponent
  },
  {
    path:'reset-password',
    component:RestPasswordComponent
  },
  {
    path:'login',
    component:SigninSignupComponent
  },
  {
    path:'upload',
    component:TemplateImagesUploadComponent
  },
  {
    path:'testing',
    component:GeneratingWebsiteComponent
  },
  {
    path:'edit-website',
    component:EditWebsiteComponent
  },
  {
    path:'local-edit',
    component:WithoutSignupEditComponent
  },
  {
    path:'create-blog',
    component:CreateBlogsComponent
  },
  {
    path:'privacy-policy',
    component:PrivacyPolicyComponent
  },
  {
    path:'terms-conditions',
    component:TermsConditionsComponent
  },
  {
    path:"stripe-success",
    component:SuccessComponent
  },
  {
    path:'stripe-cancel',
    component:CancelComponent
  },
  {
    path:'pricing',
    component:PricingComponent
  }
  // {
  //   path:'gen-v1',
  //   component:WebsiteGeneratorComponent
  // }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{
  //   preloadingStrategy: PreloadAllModules
  // })],
  imports:[RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
