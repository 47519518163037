<section class="main_section">
    <div class="main-header-section">
        <div class="main-heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value}}
        </div>
        <div class="main-header-main-heading" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
        <div class="main-header-sub-heading" [ngStyle]="data[2].style?data[2].style:{}">
            {{data[2].value}}
        </div>
    </div>

    <div class="middle-section">
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
        <div class="first-section">
            <div class="first-image">
                <img src="{{data[3].imgUrl}}" alt="" [ngStyle]="data[3].style?data[3].style:{}" class="first-img" />
            </div>
            <div class="first-main-heading" [ngStyle]="data[4].style?data[4].style:{}">
                {{data[4].value}}
            </div>
            <div class="first-sub-heading" [ngStyle]="data[5].style?data[5].style:{}">
                {{data[5].value}}
            </div>
        </div>
    </div>
</section>