<div class="about-us" [ngStyle]="{'background-color': bgColor}">
    <div class="right-aboutUs">
        <div class="aboutUs-heading" [ngStyle]="data[0].style ? data[0].style : {}">
            {{data[0].value}}
        </div>
        <div class="Content-text" [ngStyle]="data[1].style ? data[1].style : {}">
         {{data[1].value | titlecase}}
        </div>
        <div class="content-text2" [ngStyle]="data[2].style ? data[2].style : {}">
            {{data[2].value}}
        </div>
        <div>

        </div>
    </div>
    <div class="left-image">
        <div class="aboutUs-image">
            <img [src]="data[4].imgUrl">
        </div>
    </div>
    </div>

    <!-- <img src="./../../../edit-website/edit-website.component.scss" alt=""> -->