import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddComponentsectionComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/website-generationcomponent/add-componentsection/add-componentsection.component';
import { ImageUploadService } from 'src/app/services/ImageUploadService/image-upload.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-website-editor',
  templateUrl: './website-editor.component.html',
  styleUrls: ['./website-editor.component.scss']
})
export class WebsiteEditorComponent {
  constructor(
    private _activatedRoute : ActivatedRoute,
    private router : Router,
    private templatesService:TemplateService,
    private imageUploadServiceService:ImageUploadService
  )
  {
  }
  
  data:any[]=[];
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        //console.log("value check",value);
        this.templateData=value;       
      }
    })
    // this.templateDataAssign();
   
  }

 check:any={
    "componentCategory": "About-Us",
    "ranking": 2,
    "businessCategoryId": "1eda9215-46e6-6984-a675-130cb2740cf4",
    "businessCategoryName": "CONSTRUCTION",
    'data':[
      {value:"About-Us",field:'heading',type:'heading',style:{'font-weight':300}},
      {value:"JK Construction Company is a construction firm that specializes in delivering high-quality building and construction services to clients. With years of experience in the industry, the company has established a reputation for delivering projects on time and within budget. JK Construction offers a full range of construction services, including design and build, project management, and construction management.",type:'text',field:'sub-text',style:{}},
      {value:"The company is known for its commitment to safety and its use of the latest technology and construction techniques. JK Construction has a team of highly skilled and experienced professionals who work closely with clients to understand their specific needs and deliver tailored solutions. Whether you are looking to build a new home, renovate your existing property, or construct a commercial building, JK Construction Company can help you achieve your goals.",type:'text',field:'sub-text',style:{}},
      {value:'Contact-Us',type:'button',link:'',style:{},field:"button",display:true},
      {imageUrl:'../../../assets/images/editor/aboutus.svg',style:{},display:true,type:'image',field:'Image'},
      {alignment:false,style:{}}
    ],
    "createdTimeStamp": "2023-02-10T09:09:24.773Z",
    "language": "English",
    "componentName": "about-us"
  }


  heading=this.data[0];
  text1=this.data[1];
  text2=this.data[2];
  image=this.data[4];
//   data:any=[
    
//     {value:"We Help Customers Build Their Dreams into Reality",field:"service-heading",type:'heading',style:{}},
//      {
//       value:[
//         {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
//         {value:'Design and Build Services',type:'text',field:'service1'},
//         {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
//        ],
//        type:'array'
//       },
//       {
//         value:[
//          {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
//          {value:'Design and Build Services',type:'text',field:'service1'},
//          {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
//         ],
//         type:'array'
//        },
//        {
//         value:[
//          {imageUrl:'https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg',type:'image',field:"image"},
//          {value:'Design and Build Services',type:'text',field:'service1'},
//          {value:'Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion',type:'text',field:'text'}
//         ],
//         type:'array'
//        },

// ]


  clicktoUpload(){

  }

  ContentChanged(){
    this.templatesService.updateElements.emit(this.data);
  }

  file : any ;
  profileImg : any = '';
  imageUploadingLoader : boolean = false
  async updateBackgroundImage(ev:any){
    // this.imageUploadingLoader = true;
    // const file = ev.srcElement.files[0];
    // this.file = file;
    // this.profileImg = await this.imageUploadServiceService.uploadfoodcourtImages(this.file);
    // this.profileImg = this.profileImg.Location;
    // this.imageUploadingLoader = false;
  }

  addImage(){
    document.getElementById('browse_img')?.click();
  }

  editValue:boolean=true;
  editSection(ev:any){
  this.templateData.componentContentList.forEach((element:any) => {
    if(element.componentName==ev){
      element.editSection=!element.editSection
    this.editParam=element.editSection;
    this.selectedComponent=ev;
    }
    else{
      element.editSection=false;
    }
  });
  // this.templatesService.editComponent.emit();
  }
  editParam:any=false;
  selectedComponent:any;

  templateData:any={
      "id": "1edb27b8-b73e-62c3-be4f-8127e4a93850",
      "componentContentList": [
        // {
        //   "componentCategory": "Hero-Banner",
        //   "ranking": 1,
        //   "businessCategoryId": "1eda9215-46e6-6984-a675-130cb2740cf4",
        //   "businessCategoryName": "CONSTRUCTION",
        //   'data':[
        //     {"imageUrl":"","style":{},"display":true,"type":"image","field":"Background-image"},
        //     {"value":"We are","style":{},"field":"small text","type":"text"},
        //     {"value":"Constructor","style":{},"field":"small test","type":"text"},
        //       {"value":"View Projects","type":"button","link":"/view-projects","style":{},"field":"button","display":true}
        //     ],
        //   "createdTimeStamp": "2023-02-10T09:09:24.773Z",
        //   "language": "English",
        //   "componentName": "home-hero-banner",
        //   'editSection':false
        // },
        // {
        //   "componentCategory": "About-Us",
        //   "ranking": 2,
        //   "businessCategoryId": "1eda9215-46e6-6984-a675-130cb2740cf4",
        //   "businessCategoryName": "CONSTRUCTION",
        //   'data':[
        //     {value:"About-Us",field:'heading',type:'heading',style:{'font-weight':300}},
        //     {value:"JK Construction Company is a construction firm that specializes in delivering high-quality building and construction services to clients. With years of experience in the industry, the company has established a reputation for delivering projects on time and within budget. JK Construction offers a full range of construction services, including design and build, project management, and construction management.",type:'text',field:'sub-text',style:{}},
        //     {value:"The company is known for its commitment to safety and its use of the latest technology and construction techniques. JK Construction has a team of highly skilled and experienced professionals who work closely with clients to understand their specific needs and deliver tailored solutions. Whether you are looking to build a new home, renovate your existing property, or construct a commercial building, JK Construction Company can help you achieve your goals.",type:'text',field:'sub-text',style:{}},
        //     {value:'Contact-Us',type:'button',link:'',style:{},field:"button",display:true},
        //     {imageUrl:'../../../assets/images/editor/aboutus.svg',style:{},display:true,type:'image',field:'Image'},
        //     {alignment:false,style:{}}
        //   ],
        //   "createdTimeStamp": "2023-02-10T09:09:24.773Z",
        //   "language": "English",
        //   "componentName": "About-us",
        //   'editSection':false
        // },
        // {
        //   "componentCategory": "service-section",
        //   "ranking": 4,
        //   "businessCategoryId": "1eda9215-46e6-6984-a675-130cb2740cf4",
        //   "businessCategoryName": "CONSTRUCTION",
        //     "data":[
        //     {"value":"We Help Customers Build Their Dreams into Reality","field":"heading","type":"heading","style":{}},
        //      {
        //       "value":[
        //         {"imageUrl":"https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg","type":"image","field":"image"},
        //         {"value":"Design and Build Services","type":"text","field":"service1"},
        //         {"value":"Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion","type":"text","field":"text"}
        //        ],
        //        "type":"array"
        //       },
        //       {
        //         "value":[
        //          {"imageUrl":"https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg","type":"image","field":"image"},
        //          {"value":"Design and Build Services","type":"text","field":"service1"},
        //          {"value":"Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion","type":"text","field":"text"}
        //         ],
        //         "type":"array"
        //        },
        //        {
        //         "value":[
        //          {"imageUrl":"https://dev-beeos.s3.amazonaws.com/images/372379c1676157821903service%201.jpg","type":"image","field":"image"},
        //          {"value":"Design and Build Services","type":"text","field":"service1"},
        //          {"value":"Offers complete design and construction services, including architectural design, engineering, and construction management. This service allows clients to work with a single entity from concept to completion","type":"text","field":"text"}
        //         ],
        //         "type":"array"
        //        }
        
        // ],
        //   "createdTimeStamp": "2023-02-10T09:09:24.773Z",
        //   "language": "English",
        //   "componentName": "Service-component",
        //   'editSection':false
        // },
      ],
      componentsData:[
        {
          "componentName": "home-hero-banner",
          "componentCategory": "Hero-Banner",
          "ranking": 1,
        },
        {
          "componentName": "About-us",
          "componentCategory": "About-Us",
          "ranking": 2,
        },
        {
          "componentName": "Service-component",
          "componentCategory": "service-section",
          "ranking": 4,
        },
    
      ],
      "businessId": null,
      "businessName": null,
      "primaryColor": null,
      "buttonColor": null,
      "textFamily": null
  }

  componentData:any=null;

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='About-us'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    //console.log(this.data);
    this.heading=this.data[0];
    this.text1=this.data[1];
    this.text2=this.data[2];
    this.image=this.data[4];
  }

  deleteComponent(ev:any){
    this.templateData.componentsData.forEach((element:any,i:any) => {
      if(element.componentName==ev){
        this.templateData.componentsData.splice(i,1);
      }
    });
    //console.log(this.templateData.componentsData);
    
    this.templatesService.updateElements.emit(this.templateData);
  }

  move(input:any, from:any, to:any) {
    let numberOfDeletedElm = 1;
  
    const elm = input.splice(from, numberOfDeletedElm)[0];
  
    numberOfDeletedElm = 0;
  
    input.splice(to, numberOfDeletedElm, elm);
  }


  // regenerateComponent(componentCategory:any,businessCategoryId:any,index?:number){
  //   this.templatesService.getComponentsData(componentCategory,businessCategoryId).subscribe(
  //     {
  //       next:(value:any)=>{
  //         this.templateData.componentContentList.forEach((component:any,i:any) => {
  //           if(component.componentCategory==componentCategory){
  //             this.templateData.componentContentList.splice(i,1);
  //             this.templateData.componentContentList.splice(i,0,value.data)
  //           }
  //         });
  //         this.templatesService.updateElements.emit(this.templateData); 
  //       },
  //       error:(err)=>{
  //         this.templatesService.openSnack("Something went wrong","Close");
  //       },
  //     }
  //   )
  // }


  changeContent(componentName:any,businessCategoryId:any,index:number){
    this.templatesService.contentChangeCategory(componentName,businessCategoryId).subscribe(
      {
        next:(value:any)=>{
          this.templateData.componentContentList.forEach((component:any,i:any) => {
            if(component.componentName==componentName){
              this.templateData.componentContentList.splice(i,1);
              this.templateData.componentContentList.splice(i,0,value.data)
            }
          });
          this.templatesService.updateElements.emit(this.templateData); 
        },
        error:(err)=>{
          this.templatesService.openSnack("No Data Found","Close");
        },
      }
    )
  }

  componentNames:any=[
    {"componentCategory": "Hero-Banner"},
    {"componentCategory": "About-Us"},
    {"componentCategory": "service-section"}
  ]

  // async templateDataAssign(){
  //   this.componentNames.forEach((component:any) => {
  //       this.templatesService.getComponentsData(component.componentCategory,'1eda9215-46e6-6984-a675-130cb2740cf4').subscribe(
  //         {
  //           next:(value:any)=>{
  //       this.templateData.componentContentList.push(value.data);
  //       this.dataAssign();
  //       this.templatesService.updateElements.emit(this.templateData);
  //           },
  //           error:(err)=>{
  //             this.templatesService.openSnack(`${component.componentCategory} Not Found`,"Close");
  //           },
  //         }
  //       )
  //   });
  //   //console.log("template data",this.templateData);
 
  // }

//  async getTemplatesComponent(componentCategory:any,businessId:any){
//     let data=null;
//    await this.templatesService.getComponentsData(componentCategory,businessId).subscribe(
//       {
//         next:(value:any)=>{
//     this.data=value.data
   
//         },
//         error:(err)=>{
//           this.templatesService.openSnack("Something went wrong","Close");
//         },
//       }
//     )
//     //console.log(data);
    
//     return data;
//   }


  collapseSection(ev:any){
    this.editParam=ev;
  }

  addSection(){
    this.templatesService.openDialog(AddComponentsectionComponent, 'auto', '654px', {
      data:this.templateData
     });
  }

}
