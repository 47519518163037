import { Component, Input, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import * as mapboxgl from 'mapbox-gl';
import { DbService } from 'src/app/services/db-service/db.service';
import { environment } from 'src/environments/environment';
import { TemplateService } from 'src/app/services/template.service';


@Component({
  selector: 'app-map-with-right-address',
  templateUrl: './map-with-right-address.component.html',
  styleUrls: ['./map-with-right-address.component.scss']
})
export class MapWithRightAddressComponent {

  @Input() templateData;
  
  map: mapboxgl.Map | any;
  lat: any = 0;
  lng: any = 0;
  style = "mapbox://styles/mapbox/light-v11";

  constructor(
    private _dbService : DbService,
    private templatesService:TemplateService,
  ){
    // this.lat = this._dbService.getLat();
    // this.lng = this._dbService.getLng();
  }
  
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (res:any) => {
        res.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'map_with_right_side_address'){
            this.templateData = conCompList;
            this.dataAssign();
          }
        });
      }
    )
    this.dataAssign();
  }

  // templateData = {
  //   "data":[
  //     {
  //       "value":"Location",
  //       "type":"text",
  //       "field":"heading",
  //       "textType":"heading",
  //       "style":{}
  //     },
  //     {
  //       "value":this._dbService.getLocality(),
  //       "type":"text",
  //       "field":"text",
  //       "textType":"text",
  //       "stye":{}
  //     },
  //     {
  //       "value":this._dbService.getState(),
  //       "type":"text",
  //       "field":"text",
  //       "textType":"text",
  //       "style":{}
  //     }
  //   ]
  // }

  data:any;

  dataAssign(){
    this.data = this.templateData.data;
    if(this.data[1].value === ''){
      this.data[1].value = this._dbService.getLocality();
      this.data[2].value = this._dbService.getState();
      this.data[5].value = this._dbService.getLat();
      this.data[6].value = this._dbService.getLng();
    }
    this.lat = +this.data[5].value;
    this.lng = +this.data[6].value;
    this.map = new mapboxgl.Map({
      container:'map',
      style: this.style,
      zoom: 12,
      center: [this.lng, this.lat],
      accessToken: environment.mapbox.accessToken,
    });
    new mapboxgl.Marker({ color: '#0267c1' })
      .setLngLat([this.lng, this.lat])
      .addTo(this.map);
    this.checkingStyles();
  }
  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    // data.style = {
    //   "color":this.templateData.textColor,
    //   "font-family":this.templateData.fontFamily,
    // }
    // console.log(this.templateData);
    if(data.textType === 'heading'){
      data.style = {
        "color":this.templateData.colors.txtHeadingColor,
        "font-family":this.templateData.headingFontFamily,
      }
    }
    else if(data.textType === 'description'){
      data.style = {
        "color":this.templateData.colors.txtDescColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
    else if(data.textType === 'text'){
      data.style = {
        "color":this.templateData.colors.txtNormalColor,
        "font-family":this.templateData.bodyFontFamily,
      }
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":"none",
          "padding":"8px 15px",
          "border":"none"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.colors.btnColor,
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":"none"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border":`2px solid ${this.templateData.colors.btnColor}`,
          "padding":"8px 15px",
          // "border-radius":"none"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.colors.btnTextColor,
          "font-family":this.templateData.bodyFontFamily,
          "border-radius":`10px`,
          "padding":"8px 15px",
          "border":`2px solid ${this.templateData.colors.btnColor}`
        }
      }
    }
  }
}
