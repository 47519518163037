import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-stats3',
  templateUrl: './stats3.component.html',
  styleUrls: ['./stats3.component.css','./mq-stats3.component.css' ]
})
export class Stats3Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }

  @Input() templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "The Landingfolio Facts",
        type :'heading', field :'heading',
        style : {'font-size':'32px'}
      },
      {
        value : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        type : 'subtext', field : 'subtext',
        style : {'color':'#959393','font-size':'14px'}
      },
      {
        value : ["1M+", "53K+", "98.29%"],
        type : 'heading', field : 'heading',
        style : {'font-size':'32px','font-weight':700}
      },
      {
        value :["Tickets Delivered This Month",
                "Active Customers Rate", "Customer Satisfaction Rate"],
        type : 'text', field : 'text',
        style : {'color':'#959393', 'font-size':'14px'}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats3Component",
  }
  data = this.templateData.data;
  heading = this.data[0];
  subheading =  this.data[1];
  statsHeading = this.data[2];
  statsText = this.data[3];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-stats3'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.subheading =  this.data[1];
    this.statsHeading = this.data[2];
    this.statsText = this.data[3];
  }
}
