import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-slider-faq-components',
  templateUrl: './slider-faq-components.component.html',
  styleUrls: ['./preview-slider-faq-components.component.scss']
})
export class PreviewSliderFaqComponentsComponent {
  
  @Input() templateData:any;
  valuesData : any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'faq_slider'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData:any= {
  //   "componentCategory" : "FAQ",
  //   "ranking" : 0,
  //   "businessCategoryId" : "",
  //   "businessCategoryName" : "All category",
  //   "data" :[
  //     {
  //       "value" : "Frequently asked questions",
  //       "type" : "heading", "field" : "heading",
  //       "style" : {"font-size":"24px","font-weight":800,"margin-bottom":"10px"}
  //     },
  //     {
  //       "value" : "Everything you need to know about the product and billing.",
  //       "type" : "subtext", "field" : "subtext",
  //       "style" : {"font-size":"16px","color":"#959393"}
  //     },
  //     {
  //       "value" : [
  //         {
  //           "value":"Can I change my plan later?",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"heading",
  //           "style":{"font-size":"16px","font-weight":"700"}
  //         },
  //         {
  //           "value":"What is your cancellation policy?",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"heading",
  //           "style":{"font-size":"16px","font-weight":"700"}
  //         },
  //         {
  //           "value":"Can other info be added to an invoice?",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"heading",
  //           "style":{"font-size":"16px","font-weight":"700"}
  //         },
  //         {
  //           "value":"How does billing work?",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"heading",
  //           "style":{"font-size":"16px","font-weight":"700"}
  //         },
  //         {
  //           "value":"How do I change my account email?",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"heading",
  //           "style":{"font-size":"16px","font-weight":"700"}
  //         }
  //       ],
  //       "type" : "array", "field" : "array",
  //       "textType":"array",
  //       "style" : {"font-size":"14px","font-weight":"700"}
  //     },
  //     {
  //       "value" : [
  //         {
  //           "value":"Yes you can try us for 30 days. ",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //         {
  //           "value":"personalized 30-minute onboarding call to get you up and running as soon as possible" ,
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //         {
  //           "value":"If you want, we'll provide you with a free",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //         {
  //           "value":"a",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //         {
  //           "value":"b",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //         {
  //           "value":"c",
  //           "type":"text",
  //           "field":"text",
  //           "textType":"text",
  //           "style":{"font-size":"14px","font-weight":"400"}
  //         },
  //       ],
  //       "type" : "array", "field" : "array",
  //       "textType":"array",
  //       "style" : {"font-size":"12px","color":"#959393"}
  //     },
  //     {
  //       "imgUrl" : "./../../../../../../../../assets/images/faq/show.png",
  //       "type" : "image", "field" : "image",
  //       "display" : true, "status" : true,
  //       "style" : {"cursor":"pointer","height":"24px","width":"24px"}
  //     },
  //     {
  //       "imgUrl" :  "./../../../../../../../../assets/images/faq/hide.png",
  //       "type" : "image", "field" : "image",
  //       "display" : true, "status" : false,
  //       "style" : {"cursor":"pointer","height":"24px","width":"24px"}
  //     },
  //     {
  //       "value" : "Still have questions?",
  //       "type" : "text", "field" : "text",
  //       "style" : {"font-size":"16px", "font-weight":700 ,"margin-bottom": "10px"}
  //     },
  //     {
  //       "value" : "Can't find the answer you're looking for? Please chat to our friendly team.",
  //       "type" : "subtext", "field" : "subtext",
  //       "style" : {"font-size":"14px","color":"#959393" ,"margin-bottom": "10px"}
  //     },
  //     {
  //       "value" : "Get in touch",
  //       "type" : "button", "field" : "button", "link" : "", 
  //       "style" : {"margin":"auto","margin-top":"15px","background-color":"#A084DC","width":"120px","height":"auto",
  //                 "border-radius":"5px","padding":"10px","color":"#ffffff","font-size":"16px","font-weight":700}
  //     },
  //     {
  //       "imgUrl" : "./../../../../../../../../assets/images/faq/person.jpg",
  //       "type" : "image", "field" : "image",
  //       "display" : true, 
  //       "style" : {"width":"50px","height":"50px", "border-radius":"50px","border":"2px solid #ffffff","margin-bottom":"10px"}
  //     }
      
  //   ],
  //   "createdTimeStamp" : "",
  //   "language" : "English",
  //   "componentName" : "Faq1Component",
  // }
  // data = this.templateData.data;
  data : any;
  bgColor : any;

  // heading = this.data[0];
  // subheading = this.data[1];
  // questions = this.data[2];
  // answer =  this.data[3];
  // closeImg = this.data[4];
  // showImg = this.data[5];
  // heading2 = this.data[6];
  // subheading2 = this.data[7];
  // button = this.data[8];
  // personImg = this.data[9];
  
  expandedIndex = 0;


  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title;
      this.data[1].value = this.valuesData.desc;
      this.data[2].value = [];
      this.data[3].value = [];
      this.valuesData.data.forEach((arrData:any) => {
        this.data[2].value.push({
          "value":arrData.question,
          "type":"text",
          "field":"text",
          "textType":"heading",
          "style":{"font-size":"16px","font-weight":"700"}
        });
        this.data[3].value.push(        {
          "value":arrData.answer,
          "type":"text",
          "field":"text",
          "textType":"text",
          "style":{"font-size":"14px","font-weight":"400"}
        });
      });
      // this.data[2].value = this.valuesData.data[0].answer;
      // this.data[3].value = this.valuesData.data[1].question;
      // this.data[4].value = this.valuesData.data[1].answer;
      // this.data[5].value = this.valuesData.data[2].question;
      // this.data[6].value = this.valuesData.data[2].answer;
      // this.data[7].value = this.valuesData.data[3].question;
      // this.data[8].value = this.valuesData.data[3].answer;


    }
    else{

    }
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
