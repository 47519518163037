import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-delete-tags',
  templateUrl: './delete-tags.component.html',
  styleUrls: ['./delete-tags.component.scss']
})
export class DeleteTagsComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService : BlogServiceService
  ){

  }

  tagId : any ;
  ngOnInit(){
    this.tagId = this.data.data;
  }

  closeBtn(){
    this.dialogRef.close();
  }

  deleteTag(){
    this.blogService.deleteBlogTag(this.tagId).subscribe({
      next:(res:any)=>{
          this.blogService.openSnack("Tag Deleted sucessfully","Close");
          this.dialogRef.close('success');
      },
      error:(err)=>{
        this.blogService.openSnack(err.error.message,"close");
      }
    })
  }
}
