<section class="main-section">
  <!-- header-section  -->
  <div class="main-header-section" *ngIf="screenWidth > 475">
    <!-- header-left-section  -->
    <div class="header-left-section">
      <div class="header-left-main-heading">
        Blogs <span class="header-span">{{jsonData.length}} Blogs</span>
      </div>
      <div class="header-left-sub-heading">
        create and manage your blogs
      </div>
    </div>
    <!-- header-right-section  -->
    <div class="header-right-section" *ngIf="screenWidth > 475">
      <div class="add-business-section" (click)="createBlog()">
        + Create New Post
      </div>
    </div>
  </div>

  <!-- dropdown-section  -->
  <div class="dropdown-section" *ngIf="screenWidth > 475">
    <ng-container *ngFor="let blog of Blogs">
      <div class="blog-list" (click)="getBlogList(blog.value)" [ngClass]="{ 'blog-active': blog.status }">
        {{blog.name}}
      </div>
    </ng-container>
  </div>

  <!-- search and filter section  -->
  <div class="search-filter-main-section" *ngIf="screenWidth > 475">
    <div class="search-bar">
      <mat-form-field class="sfull-width" appearance="outline">
        <input matInput placeholder="Search" [(ngModel)]="searchText" (click)="searchBlog()" (keyup)="searchBlog()" />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="filter-section">
      <div class="filter-icon">
        <img src="../../../../../../assets/images/master/filter_icon.svg" alt="filter_Svg" />
      </div>
      <div>Filters</div>
    </div>
  </div>


  <!-- Blog Listing  -->
  <ng-container *ngIf="!emptyloader; else editLoader">
    <ng-container *ngIf="screenWidth > 475">
      <ng-container *ngIf="jsonData.length > 0; else emptyscreen">
        <div class="blog-section">
          <div class="blog-listing-main-section" *ngFor="let Blog of jsonData" (click)="clickCards(Blog.id)">
            <div class="blog-left-side">
              <div class="blog-main-heading">
                {{Blog.postTitle}}
              </div>
              <div class="author-tag-section">
                <div class="author-side">
                  <div class="left-side">
                    Author :
                  </div>
                  <div class="right-side">
                    {{(Blog.author ? (Blog.author.content | titlecase) : 'N/A')}}
                  </div>
                </div>
                <div class="tag-side">
                  <div class="left-side">
                    Tag :
                  </div>
                  <div class="right-side">
                    <div class="author-name">
                      <ng-container *ngIf="Blog?.keyWords?.content?.split(',').length>0">
                          <ng-container *ngFor="let item of Blog.keyWords.content.split(',');let i =index">
                                  {{item}} <span *ngIf="Blog.keyWords.content.split(',').length!==i+1">,</span>&nbsp;
                          </ng-container>
                      </ng-container>
                  </div>
                  </div>
                </div>
              </div>
              <div class="blog-position-section">
                <div class="position-section" *ngIf="Blog.blogStatus === 'PUBLISH' || Blog.blogStatus === 'ALL'">
                  Published On {{Blog.blogScheduledDate | date : 'mediumDate'}}
                </div>
                <div class="position-section-draft" *ngIf="Blog.blogStatus === 'SAVE'">
                  Draft
                </div>
                <div class="position-section-scheduled" *ngIf="Blog.blogStatus === 'SCHEDULED'">
                  Scheduled On {{Blog.blogScheduledDate | date: 'mediumDate'}}
                </div>
                <div class="words-section">
                  {{Blog.wordCount}} words
                </div>
                <div class="read-section">
                  {{Blog.readTime?Blog.readTime:'0'}} mins read
                </div>
                <div class="edited-section">
                  Edited &nbsp;{{Blog.updatedTimeStamp | date : 'mediumDate'}}
                </div>
              </div>

            </div>
            <div class="blog-right-side">
              <div class="image-section" *ngIf="Blog.blogStatus !== 'PUBLISH'">
                <img src="./../../../../../../../../assets/images/master/edit_icon.svg" alt="edit_svg"
                  (click)="editBlog(Blog)" />
              </div>
              <div class="blog-visitor-section" *ngIf="Blog.blogStatus === 'PUBLISH' ||  Blog.blogStatus === 'ALL'">
                <div class="visitor-left-side">
                  <div class="visitor-heading">
                    Visitor
                  </div>
                  <div class="visitor-text">
                    3.2K
                  </div>
                </div>
                <div class="visitor-right-side">
                  <div class="visitor-heading">
                    Session Duration
                  </div>
                  <div class="visitor-text">
                    5m 1s
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="screenWidth < 475">
      <div class="search-filter-resp">
        <div class="search-filter-left-resp">
          <div class="search-img-resp" (click)="search()">
            <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon" class="search-img">
          </div>
          <div class="search_input" *ngIf="searchbar">
            <mat-form-field class="sfull-width" appearance="outline">
              <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                class="search-icon">
              <input matInput placeholder="Search" />
              <img src="../../../../../../../../assets/images/close.svg" alt="close" (click)="closesearch()">
            </mat-form-field>
          </div>
          <div class="filter-section-resp">
            <img src="../../../../../../../../assets/images/master/filter_icon.svg" alt="" class="filter-img">
          </div>
        </div>
        <div class="search-filter-right-resp">
          <div class="add-business-section" (click)="createBlog()">
            + Create New Post
          </div>
        </div>
      </div>

      <div class="dropdown-section" *ngIf="screenWidth < 475">
        <ng-container *ngFor="let blog of Blogs">
          <div class="blog-list" (click)="getBlogList(blog.value)" [ngClass]="{ 'blog-active': blog.status }">
            {{blog.name}}
          </div>
        </ng-container>
      </div>

      <div class="main-header-section" *ngIf="screenWidth < 475">
        <!-- header-left-section  -->
        <div class="header-left-section">
          <div class="header-left-main-heading">
            Blogs <span class="header-span">{{jsonData.length}} Blogs</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="jsonData.length > 0; else emptyscreen">
        <div class="blog-section">
          <div class="blog-mobile-section" *ngFor="let Blog of jsonData" (click)="clickCards(Blog.id)">
            <div class="blog-main-heading">
              {{Blog.postTitle}}
            </div>
            <div class="author-date-section">
              <div class="author-side">
                <div class="left-side">
                  Author :
                </div>
                <div class="right-side">
                  {{ (Blog.author ? (Blog.author.name | titlecase) : 'N/A') }}
                </div>
              </div>
              <div class="blog-position-section">
                <div class="position-section">
                  Published On {{(Blog.blogScheduledDate | date : 'mediumDate') ? Blog.blogScheduledDate : 'N/A'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #editLoader>
    <section class="loader_section">
      <!-- <div class="loader_section"> -->
          <div class="api_loader">
              <div class="dots-container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
              </div>
          </div>
      <!-- </div> -->
  </section>
  </ng-template>
  <ng-template #emptyscreen>
    <div class="empty-part">
      No Any Blog Yet!!
    </div>
  </ng-template>
</section>