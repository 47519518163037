import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-servicesectioneditor',
  templateUrl: './servicesectioneditor.component.html',
  styleUrls: ['./servicesectioneditor.component.scss','./mq-servicesection.component.scss']
})
export class ServicesectioneditorComponent {
  @Input() pageData:any;
  @Input() templateData:any;
  componentData:any=null;
  constructor(
    private _activatedRoute : ActivatedRoute,
    private templatesService:TemplateService,
    private emitterService : EventEmmiterService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){
  }

  navId : any;
  services:any;
  serviceText:string="";
  loader : boolean = false;
  sectionLoading : boolean = false;
  bgColor : any
  ngOnInit(){
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        // //console.log("value check",value);
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'service_section_simple_horizontal'){
            this.templateData=conCompList;
            this.dataAssign();
          }
          else{}
        });
        // this.dataAssign();     
      }
    })
    this.dataAssign();
    // //console.log("service section",this.templateData);
    
  }


  data:any=[]

  check(){

  }

  value:any=[];
  heading : any;
  valuesData:any;
  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null) {
      this.valuesData = this.templateData.values;
      var bName = this._dbService.getBusinessName();
      this.data[0].value = this.valuesData.title.replaceAll("{Company Name}", bName);

      this.data[1].value[0].imageUrl = this.valuesData.data[0].img;
      this.data[1].value[1].value = this.valuesData.data[0].text;
      this.data[1].value[2].value = this.valuesData.data[0].desc;

      this.data[2].value[0].imageUrl = this.valuesData.data[1].img;
      this.data[2].value[1].value = this.valuesData.data[1].text;
      this.data[2].value[2].value = this.valuesData.data[1].desc;

      this.data[3].value[0].imageUrl = this.valuesData.data[2].img;
      this.data[3].value[1].value = this.valuesData.data[2].text;
      this.data[3].value[2].value = this.valuesData.data[2].desc;
    }
   


    // this.heading = this.valuesData.title
    // this.value=[
    //   {
    //  serviceImages:{
    //   img:this.valuesData.data[0].img
    //  },
    //  serviceTitle:{
    //   value:this.valuesData.data[0].text
    //  },
    //  serviceDes:{
    //   value:this.valuesData.data[0].desc
    //  }
    //   },
    //   {
    //     serviceImages:{
    //       img:this.valuesData.data[1].img
    //      },
    //      serviceTitle:{
    //       value:this.valuesData.data[1].text
    //      },
    //      serviceDes:{
    //       value:this.valuesData.data[1].desc
    //      }
    //   },
    //   {
    //     serviceImages:{
    //       img:this.valuesData.data[2].img
    //      },
    //      serviceTitle:{
    //       value:this.valuesData.data[2].text
    //      },
    //      serviceDes:{
    //       value:this.valuesData.data[2].desc
    //      }
    //   },
    //   ]
  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
