<section class="main-banner" id="banner_bg_image_image">
    <div class="left-checks">
        <div class="small-heading">
            {{smallText}}
        </div>
        <div class="big-heading">
            {{bigText}}
        </div>
        <div class="banner-button" *ngIf="buttonConfig.display">
            <button>
                {{buttonConfig.value}}
            </button>
        </div>
    </div>
</section>