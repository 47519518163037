import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-opened-faq',
  templateUrl: './opened-faq.component.html',
  styleUrls: ['./preview-opened-faq.component.scss']
})
export class PreviewOpenedFaqComponent {

  @Input() templateData:any;
  valuesData : any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'opened_faq'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }


  // templateData:any = {
  //   "componentCategory" : "FAQ",
  //   "ranking" : 0,
  //   "businessCategoryId" : "",
  //   "businessCategoryName" : "All category",
  //   "data" : [
  //     {
  //       "value":"Frequently asked questions",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight":700,"padding-bottom":"5%"},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"01. How this UI kit is different from others in market?",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight":500},
  //       "textType":"description"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7c7c7c", "font-size":"14px", "padding-top":"10px"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"02. Do I need any experience to work with Rareblocks?",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight":500,"font-size":"18px", "padding-bottom":"5px"},
  //       "textType":"description"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco ",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7c7c7c", "font-size":"14px", "padding-top":"10px"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"03. Do you provide any support for this kit?",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight":500,"font-size":"18px", "padding-bottom":"5px"},
  //       "textType":"description"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco ",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7c7c7c", "font-size":"14px", "padding-top":"10px"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"04. Will I get my money back if I am not satisfied?",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight":500,"font-size":"18px", "padding-bottom":"5px"},
  //       "textType":"description"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco ",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7c7c7c", "font-size":"14px", "padding-top":"10px"},
  //       "textType":"text"
  //     }
  //   ],
  //   "createdTimeStamp" : "",
  //   "language" : "English",
  //   "componentName" : "Faq3Component"
  // }
  // data = this.templateData.data
  // heading = this.data[0]
  // question1 = this.data[1]
  // answer1 = this.data[2]
  // question2 = this.data[3]
  // answer2 = this.data[4]
  // question3 = this.data[5]
  // answer3 = this.data[6]
  // question4 = this.data[7]
  // answer4 = this.data[8]

  data : any;

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title;
      this.data[1].value = this.valuesData.data[0].question;
      this.data[2].value = this.valuesData.data[0].answer;
      this.data[3].value = this.valuesData.data[1].question;
      this.data[4].value = this.valuesData.data[1].answer;
      this.data[5].value = this.valuesData.data[2].question;
      this.data[6].value = this.valuesData.data[2].answer;
      this.data[7].value = this.valuesData.data[3].question;
      this.data[8].value = this.valuesData.data[3].answer;

    }
    else{

    }
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
