<div class="main-section">
    <div class="heading-section">
        <div class="image">
            <img src="{{image.imgUrl}}" alt="">
        </div>
        <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="description" [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
    </div>
    <div class="stats-section">
        <div class="stats">
            <div class="statsHeading" [ngStyle]="stats1Heading.style?stats1Heading.style:{}">{{stats1Heading.value}}</div>
            <div class="statsText" [ngStyle]="stats1Text.style?stats1Text.style:{}">{{stats1Text.value}}</div>
        </div>
        <div class="stats">
            <div class="statsHeading" [ngStyle]="stats2Heading.style?stats2Heading.style:{}">{{stats2Heading.value}}</div>
            <div class="statsText" [ngStyle]="stats2Text.style?stats2Text.style:{}">{{stats2Text.value}}</div>
        </div>
        <div class="stats">
            <div class="statsHeading" [ngStyle]="stats3Heading.style?stats3Heading.style:{}">{{stats3Heading.value}}</div>
            <div class="statsText" [ngStyle]="stats3Text.style?stats3Text.style:{}">{{stats3Text.value}}</div>
        </div>
    </div>
</div>
