<section class="main_section">
    <div class="top_section">
        <div class="top_title">
            Contact Us
        </div>
        <div class="cross_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>

    <div class="fields business-field-section">
        <div class="single_field">Name</div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. Joseph Nair" matInput/>
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Mobile Number</div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. 98xxxxx12" matInput/>
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Email</div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. abc@gmail.com" matInput/>
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Message</div>
        <mat-form-field class="sfull-width_1" appearance="outline">
            <input placeholder="Comment" matInput/>
        </mat-form-field>
    </div>
    <div class="submit_btn">
        <button>Submit</button>
    </div>
</section>