<div class="project-text1-title" id="blog_section_Title1" [ngStyle]="navId === 'blog_section_Title1' ? {'border':'2px solid #F79824'}:{}">
    {{pageData.blogSectionTitle1}}
</div>
<div class="project-text2-title" id="blog_section_Title2" [ngStyle]="navId === 'blog_section_Title2' ? {'border':'2px solid #F79824'}:{}">
    {{pageData.blogSectionTitle2}}
</div>
<div class="yellow-line">
    <div class="line-contaner">
    </div>
</div>
<!-- <div class="blog-section">
    <ng-container *ngFor="let blog of blogsData">
        
                <div class="blog-cards">
                    <ng-container *ngFor="let block of blog.blocks;let i =index">
                        <ng-container *ngIf="i<3">
                    <div class="blog-heading" *ngIf="block.type=='header'">
                        {{block.data.text}}
                    </div>
                </ng-container>
                </ng-container>
                    <div class="created-date">
                      {{blog.createdTimeStamp}}
                    </div>
                </div>
        </ng-container>
</div> -->
<!-- <div class="blog-section">
<ng-container *ngFor="let blog of blogsData">
<div class="blog-cards" (click)="clickCards(blog)">
<ng-container *ngFor="let block of blog.blocks;let i =index">
<ng-container *ngIf="i<3">
    <div class="blog-heading" *ngIf="block.type=='header'">
        {{block.data.text}}
    </div>
</ng-container>
</ng-container>
<div class="created-date">
    {{blog.createdTimeStamp | date : 'mediumDate'}}
  </div>
</div>
</ng-container>

</div> -->