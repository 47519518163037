import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-multiple-features',
  templateUrl: './multiple-features.component.html',
  styleUrls: ['./preview-multiple-features.component.scss']
})
export class PreviewMultipleFeaturesComponent {
  templateData: any = {
    "componentName": "PreviewMultipleFeaturesComponent",
    "data": [
      {
        "value": "Features",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "16px", "color": "#6941C6" }
      },
      {
        "value": "Analytics that feels like it’s from the future",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "36px", "color": "#101828" }
      },
      {
        "value": "Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 400, "font-size": "20px", "color": "#475467" }
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/features/main-Image.svg",
        "type": "Image",
        "field": "Image",
        "style": { "width": "48px" }
      },
      {
        "value": "Share team inboxes",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 600, "font-size": "20px", "color": "#101828" }
      },
      {
        "value": "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.",
        "type": "Text",
        "field": "Text",
        "style": { "font-weight": 400, "font-size": "16px", "color": "#475467" }
      },





    ]
  }

  data = this.templateData.data;
}
