<section class="main-section">
    <!-- header-section  -->
    <div class="header-main-section">
        <div class="header-left-side">
            Tag Detail
        </div>
        <div class="header-right-side">
            <div class="left-side-btn">
                <div class="delete-btn"><img src="../../../../../../../assets/images/master/delete_icon.svg"
                        alt="delete_icon" (click)="deleteTag(dataSource.id)" /></div>
                <div class="vertical-line"></div>
                <div class="edit-btn"><img src="../../../../../../../assets/images/master/edit_icon.svg" alt="edit_icon"
                        (click)="editTag(dataSource)" /></div>

            </div>
            <div class="close-btn">
                <img src="../../../../../../../assets/images/close.svg" alt="close_svg" (click)="closeBtn()" />
            </div>
        </div>
    </div>

    <!-- header middle heading  -->
    <div class="header-middle-section">
        <div class="header-sub-heading">
            {{dataSource.tagName?dataSource.tagName:'N/A' | titlecase}}
        </div>
        <div class="header-sub-text">
           {{dataSource.description?dataSource.description:'N/A'}}
        </div>
    </div>

    <!-- blog section  -->
    <div class="blog-main-section">
        <div class="blog-section">
            <div class="blog-left-section">
                Blog Created :
            </div>
            <div class="blog-right-section">
                {{dataSource.blogsCreatedCount}}
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-left-section">
                Created On :
            </div>
            <div class="blog-right-section">
                {{dataSource.createdTimeStamp | date : 'mediumDate'}}
            </div>
        </div>
        <!-- <div class="blog-section">
            <div class="blog-left-section">
                Slug :
            </div>
            <div class="blog-right-section">
                {{dataSource.description}}
            </div>
        </div> -->
    </div>

    <hr>

    <!-- blog-bottom-section  -->
    <ng-container *ngIf="dataSource.blogsCreatedCount.length > 0 ; else emptyScreen">
    <div class="blog-bottom-section">
        <div class="blog-bottom-heading">
            Blogs
        </div>
        <div class="blog-bottom-text">
            All blogs created with Residentail construction tag
        </div>
        <div class="blog-bottom-form" *ngFor="let blogs of blogListing">
            <img src="../../../../../../../../../assets/images/blogs/blog_image.svg" alt="blog_svg" />
            <div class="blog-form-heading">
                {{blogs.name}}
            </div>
            <div class="form-author-section">
                <div class="form-left">
                    <div class="form-author-left">
                        Author :
                    </div>
                    <div class="form-author-right">
                        {{blogs.value}}
                    </div>
                </div>
                <div class="form-right">
                    <div class="form-right-left">
                        <img src="../../../../../../../../../assets/images/blogs/Vector.svg" alt="vector_svg" />
                    </div>
                    <div class="form-right-right">
                        {{blogs.status}}
                    </div>
                </div>
            </div>
            <div class="blog-position-section">
                <div class="position-section">
                    Published On 9 Jan 2023
                </div>
                <div class="words-section">
                    329 words
                </div>
                <div class="read-section">
                    5 mins read
                </div>
                <div class="edited-section">
                    Edited 2 days ago
                </div>
            </div>
        </div>
    </div>
    </ng-container>
    <ng-template #emptyScreen>
        <div class="empty-screen-section">
            No Any Blog Yet !!
        </div>
    </ng-template>
</section>