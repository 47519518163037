import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener } from '@angular/core';
import { BusinessService } from 'src/app/master-config-components/masters/business/business.service';
import { PeriodicElement } from 'src/app/master-config-components/masters/business/registration-type/list-registration-type/list-registration-type.component';
import { TemplateService } from 'src/app/services/template.service';
import { BlogServiceService } from '../../blog-service.service';
import { AddTagsComponent } from '../add-tags/add-tags.component';
import { DeleteTagsComponent } from '../delete-tags/delete-tags.component';
import { ViewTagsComponent } from '../view-tags/view-tags.component';

@Component({
  selector: 'app-list-tags',
  templateUrl: './list-tags.component.html',
  styleUrls: ['./list-tags.component.scss','./mq-list-tags.component.scss']
})
export class ListTagsComponent {

  displayedColumns: string[] =
    [
      'position',
      'name',
      'date',
      'Action'
    ];
  dataSource:any = [];
  ischecked: boolean = true;


  constructor(
    private templateService: TemplateService,
    private businessSerive: BusinessService,
    public blogService: BlogServiceService
  ) {
    this.getScreenSize();
  }


  ngOnInit() {
    this.getAllListing();
  }

  searchbar: boolean = false;
  search() {
    this.searchbar = !this.searchbar;
  }
  closesearch() {
    this.searchbar = false;
    this.tagName = '';
    this.getAllListing();
  }


  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  addTag() {
    let dialog = this.blogService.openDeleteDialog(
      AddTagsComponent,
      'auto',
      '400px',
      { edit: false },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      this.getAllListing();
    });
  }

  editTag(tag: any) {
    let dialog = this.blogService.openDeleteDialog(
      AddTagsComponent,
      'auto',
      '400px',
      { edit: true, data: tag },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      this.getAllListing();
    });
  }


  deleteTag(tagId: any) {
    let dialog = this.blogService.openDeleteDialog(
      DeleteTagsComponent,
      '250px',
      '350px',
      { data: tagId },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      if (!data) return;
      this.getAllListing();
    });
  }
  page = 0;
  size = 1000000;
  tagName: any;
  emptyloader: boolean = false;
  getAllListing() {
    this.emptyloader = true;
    this.blogService.getBlogsTagsByBusinessId(JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }


  _searchIndustry() {
    if (this.tagName.length > 2) this.searchIndustry();
    else if (this.tagName === '') this.getAllListing();
  }
  // search 
  searchIndustry() {
    this.emptyloader = true;
    this.blogService.searchBlogsTagByBusinessId(this.tagName, JSON.parse(window.localStorage.getItem('bDetails') || "").id).subscribe
      ({
        next: (res: any) => {
          this.emptyloader = false;
          this.dataSource = res.data;
        },
        error: (err: any) => {
          this.emptyloader = false;
          this.blogService.openSnack(err.error.message, "close");
          this.dataSource = [];
        }
      })
  }

  // checkbox 
  exportenquiry: any[] = [];
  enquirycheck(item: any, active: any, event: any) {
    event.stopPropagation();
    // if (!active) {
    //   this.exportenquiry.push(item);
    // } else {
    //   this.exportenquiry.forEach((element, i) => {
    //     if (item.leadId === element.leadId) {
    //       this.exportenquiry.splice(i, 1);
    //     }
    //   });
    // }
  }
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }
  selection = new SelectionModel<PeriodicElement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.exportenquiry = [];
      return;
    }

    this.selection.select(...this.dataSource);
    this.exportenquiry = [];
    this.dataSource.forEach((element) => {
      this.exportenquiry.push(element);
    });
  }

  // toggle button 
  isToggleChecked: boolean = false;
  activestatus(element: any, index: any) {
    this.isToggleChecked = true;
    // element.tt = true;
    element.active = !element.active;
  }


  viewTag(tagId: any) {
    let dialog = this.blogService.openDeleteDialog(
      ViewTagsComponent,
      '100vh',
      '50%',
      { data: tagId },
      '80vw'
    );
    dialog.afterClosed().subscribe((data: any) => {
      if (!data) return;
      this.getAllListing();
    });
  }
}

