import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from "./../../../../../../../services/template.service";
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-simple-herobanner-button-image',
  templateUrl: './simple-herobanner-button-image.component.html',
  styleUrls: ['./preview-simple-herobanner-button-image.component.scss']
})
export class PreviewSimpleHerobannerButtonImageComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {

    
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'simple_button_image_hero_banner'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    this.dataAssign();
  }
  
  // templateData: any = {
  //   "componentCategory": "hero banner",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner7Component",
  //   "data" : [
  //     {
  //       "value":"The design system is the greatest.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"It is a long established fact that a reader will be distracted by the readable content of a page when looking at this layout.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Get Started",
  //       "type":"button",
  //       "field":"button",
  //       "style":{"background-color": "#033E4E", "color": "white"}
  //     },
  //     {
  //       "value":"Learn More",
  //       "type":"button",
  //       "field":"button",
  //       "style":{"background-color": "#A88722", "color": "white"}
  //     },
  //     {
  //       "imgUrl":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAZlBMVEX///8AAACpqamampo3Nzfr6+u3t7dmZmaKiorS0tL6+vq+vr6CgoJ0dHTW1tY6Ojrc3NwrKyuQkJDExMRFRUXi4uKgoKAhISHx8fFdXV0vLy/o6OgPDw8WFhaDg4NlZWVBQUFLS0sHh4AZAAADEUlEQVR4nO3d2XaiQBSF4RAUHAAZYsQx8f1fstOLA0KU1rJPpdhkf3ewNJ5fRs2FLy9EREREREREv0+yDPAtk768NPbGIk5v9JXj6fsrLq824NH1TMqO3zZj6nogCzqJpetprGjvqKHrYawIWxeJZuVnNEMXfTY1l8tGfRp9W9+5XmJYv0lP3KySFWeHU+k6S1G9PJflvcuhVO2laC7LgeyjTofSJftpIIuv1WLudCZdeZX0KotS6DudSZfPQngsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxMdCfCzEx0J8LMTHQnwsxHe78N3pTLrebxZuVpOxWG1uFo4QC/GxEN+vK8zL6ViU/M0ufCzEx0J8LMTHQnwsxMdCfL+1cOZ0Jl2nbqH8LvfE6Uy6JlVS/bvcSbXopU6H0pRKUVKvkOVs4XIqRYtMipo1kazYFGNoXBTynb4XNevqjfq1Gbchum3W1LQOu8gbo6i9YXeup7Fg1znkpgfX86g7TLuH53TleiJlk2+BX3LXM6nKb10VSn8s23Hll32Xkn0yV5GG1Utlj/y9RE7vYarz4sn+39dLHXJfmD/0YDk+oO6Lze50r+4iAczkeHjs0Qs5/oE+vpmOXL8hOHfFprsd3sc341OH0YlpCDamA8tbsrE4kyrzcyPabipb5GDwlAPWbno0H1felKO1mVQ9s8thXfTl6pYZPSlD2k3l1G/2Fbo//HvTIKqcYtnhwlP0uJN8GPHi+lnB/Zf8WR+ettB1UlegHnj5Sn4Y4vsDG4tdR3WwkIUwhX6x/H+FP+DCucofmw+4sFD5Y4XDwkWPS2HfIwxcCnsfYUsxlP9Q7XT2lSvJ/Zf+MXY+Zenfej7vw0rh2XVWy9lKoY2r+rPsnGf3rrNaLP17ab11HSa2azuBREREREREvf4AM8FXIciTKYkAAAAASUVORK5CYII=",
  //       "type":"image",
  //       "field":"image",
  //       "link":"",
  //       "style":{}
  //     }
  //   ]
  // }

  // data = this.templateData.data
  data:any;
  // heading = this.data[0]
  heading : any;
  // description = this.data[1]
  description : any;
  // getStartedBtn = this.data[2]
  getStartedBtn : any;
  // learnMoreBtn = this.data[3]
  // desktopImage = this.data[4]
  desktopImage : any;

  bgColor:any;

  dataAssign(){
    this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.valuesData != null) {
      var bName = this._dbService.getBusinessName();
      this.data[0].value = this.valuesData.title.replace("{Company Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replace("{Company Name}", bName);
      this.data[2].value = this.valuesData.button1Text;
      this.data[4].imgUrl = this.valuesData.imageUrl;
    }

    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.getStartedBtn = this.valuesData.button1Text;
    // this.desktopImage = this.valuesData.imageUrl
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
  redirectUrl(){
    window.open(`${this.data[3].link}`,'_blank');
  }
}
