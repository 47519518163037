import { Component , Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent {


  constructor(
     public dialogRef: MatDialogRef<AddTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    public blogService:BlogServiceService
  ){
  }

  enterTag: string ='';
  enterSlug: string = '';
  enterDescription: string ='';
  imgFile: string ='';
  edit:boolean=this.data.edit;
  ngOnInit() {
    if(this.data.edit){
      this.enterTag=this.data.data.tagName;
      this.enterDescription=this.data.data.description;

    }
    
  }

  createBusiness(){
   let data= {
      tagName: this.enterTag,
      description: this.enterDescription,
      createdTimeStamp: new Date(),
      blogsCreatedCount: 0,
      createdById: null,
      createdByName: "ajay",
      businessId: JSON.parse(window.localStorage.getItem("bDetails")||"").id
    }
    this.blogService.createBlogTags(data).subscribe({
      next:(res:any)=>{
          this.blogService.openSnack("Tag created sucessfully","Close");
          this.dialogRef.close();
      },
      error:(err)=>{
        this.blogService.openSnack(err.error.message,"close");
      }
    })
  }

  editBusiness(){
    let data= {
      id:this.data.data.id,
      tagName: this.enterTag,
      description: this.enterDescription,
      createdTimeStamp: new Date(),
      blogsCreatedCount: this.data.data.blogsCreatedCount,
      createdById: this.data.data.createdById,
      createdByName: this.data.data.createdByName,
      businessId: JSON.parse(window.localStorage.getItem("bDetails")||"").id
    }
    this.blogService.updateBlogTag(data).subscribe({
      next:(value:any)=>{
        this.blogService.openSnack("Tag updated sucessfully","Close");
        this.dialogRef.close();
      },
      error:(err)=>{
        this.dialogRef.close();
      },
    })
  }

  closeBtn(){
    this.dialogRef.close();
  }

}
