import { Component, HostListener , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';
import { AddAuthorComponent } from '../add-author/add-author.component';
import { DeleteAuthorComponent } from '../delete-author/delete-author.component';

@Component({
  selector: 'app-view-author',
  templateUrl: './view-author.component.html',
  styleUrls: ['./view-author.component.scss']
})
export class ViewAuthorComponent {

  constructor(
    public dialogRef: MatDialogRef<ViewAuthorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService : BlogServiceService){

  }
  authorId : any ;
  ngOnInit(): void {
      this.dialogRef.updatePosition({ top: '0px', right: `0px` });
      this.authorId = this.data.data;
      this.getAuthorView();
  }

  scrWidth: number | undefined;
  scrHeight: number | undefined;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }


  closeBtn(){
    this.dialogRef.close();
  }

  editTag(data:any){
    let dialog = this.blogService.openDeleteDialog(
      AddAuthorComponent,
      'auto',
      '400px',
      {edit:true,data:data},
      '80vw'
    );
    dialog.afterClosed().subscribe((data:any) => {
      this.dialogRef.close('true');
    });
  }


  deleteTag(authorId:any){
    let dialog = this.blogService.openDeleteDialog(
      DeleteAuthorComponent,
      '250px',
      '350px',
      {data:authorId},
      '80vw'
    );
    dialog.afterClosed().subscribe((data:any) => {
      this.dialogRef.close('success');
    });
  }

  dataSource : any = [];
  emptyloader : boolean = false;
  getAuthorView() {
    this.emptyloader = true;
    this.blogService.getAuthorByAuthorId(this.authorId).subscribe((res: any) => {
      this.emptyloader = false;
      this.dataSource = res.data;
    })
  }

  blogListing = [
    { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' },
    { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' }
  ]


}
