<div class="about-us">
<div class="right-aboutUs">
    <div class="aboutUs-heading">
        {{data.heading.text}}
    </div>
    <div class="Content-text">
        {{data.text1.text}}
    </div>
    <div class="content-text2">
        {{data.text2.text}}
    </div>
</div>
<div class="left-image">
    <div class="aboutUs-image">
        <img [src]="data.image.imageUrl">
    </div>
</div>
</div>
