import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-teams-cards-profiles',
  templateUrl: './teams-cards-profiles.component.html',
  styleUrls: ['./preview-teams-cards-profiles.component.scss']
})
export class PreviewTeamsCardsProfilesComponent {
  templateData: any={
    "componentCategory": "Team",
    "ranking": 0,
    "businessCategoryId": "",
    "businessCategoryName": "All category",
    "data":
    [
      {
          "value": "Our exclusive members",
          "field": "heading",
          "type": "heading",
          "style": { 'font-size': '25px', 'font-weight': 600, 'color': 'black'},
          "textType":"heading"
      },
      {
        "value": "Clarity gives you the blocks & components you need to create a truly professional website, landing page or admin panel for your Saas.",
        "field": "subtext",
        "type": "subtext",
        "style": { 'font-size': '14px', 'font-weight': 500, 'color': '#959393' },
        "textType":"subtext"
      },
      {
        value:
          [
            {
              "imgUrl": "../../../../../../../../assets/images/testimonials/person2.jpg",
              "display": true,
              "type": 'image',
              "field": 'image',
              "style": {  'margin': '12px 0px', 'border-radius':'20px' },
              "textType":"image"
            },
            {
              "value": "Besssie Cooper",
              "type": "heading",
              "field": "Name",
              "style": { 'font-size': '15px', 'font-weight': 600,  'color': 'white' },
              "textType":"name"
            },
            {
              "value": "Marketing Specialist",
              "type": "heading",
              "field": "Designation",
              "style": { 'font-size': '12px', 'font-weight': 500, 'color': 'white', 'margin-bottom': '5px' },
              "textType":"designation"
            },
          ],
        type: 'array',
        field: '',
        style: {}
      },
      {
        value:
          [
            {
              "imgUrl": "../../../../../../../../assets/images/testimonials/person2.jpg",
              "display": true,
              "type": 'image',
              "field": 'image',
              "style": {'margin': '12px 0px','border-radius':'20px' },
              "textType":"image"
            },
            {
              "value": "Annette Black",
              "type": "heading",
              "field": "Name",
              "style": { 'font-size': '15px', 'font-weight': 600,  'color': 'white' },
              "textType":"name"
            },
            {
              "value": "Marketing Manager",
              "type": "heading",
              "field": "Designation",
              "style": { 'font-size': '12px', 'font-weight': 500, 'color': 'white', 'margin-bottom': '5px' },
              "textType":"designation"

            },
          ],
        type: 'array',
        field: '',
        style: {}
      },
    ],
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "team3component"
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subtext = this.data[1];
  icon1 = this.data[2];
  icon2 = this.data[3];
}
