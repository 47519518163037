import { EventEmitter, Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) { }


  //subscription apis

  getAllCountries(){
    return this.http.get(
      environment.baseUrl+`admin/master/location/country?size=1000&page=0`
    )
  }

  getAllPlans(countryId){
    return this.http.get(
      environment.baseUrl+`business/subscription?countryId=${countryId}`
    )
  }

  getPlansBasedOnBusinessId(businessId){
    return this.http.get(
      environment.baseUrl+`business/subscription/plan/business?businessId=${businessId}`
    )
  }

  subscribePlan(data:any){
    return this.http.post(
      environment.baseUrl+`business/payment/subscription?businessId=${data.businessId}&subscriptionId=${data.subscriptionId}&isMonthly=${data.isMonthly}`,data
    )
  }

  subscribedOrNot(businessId,payment){
    return this.http.get(
      environment.baseUrl+`business/subscription/business?businessId=${businessId}&payments=${payment}`
    )
  }

  sendPaymentId(data){
    return this.http.put(
      environment.baseUrl+`business/subscription/business?paymentId=${data.paymentId}&status=${data.status}`,data
    )
  }

  paymentAPI(data:any){
    return this.http.post(
      environment.baseUrl+`business/payment/session`,data
    )
  }

  downloadPaymentInvoice(data){
    return this.http.put(
      environment.baseUrl+`business/invoice/download?invoiceId=${data.invoiceId}`,data
    )
  }


  //open popup

  openDialog(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw'
    });
  }
}
