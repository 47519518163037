import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-stats5',
  templateUrl: './stats5.component.html',
  styleUrls: ['./stats5.component.css']
})
export class Stats5Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }

  @Input() templateData : any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "The Landingfolio Facts",
        type : 'heading', field : 'heading',
        style : {'font-size':'20px','color':'#FFFFFF'}
      },
      {
        value : ["1M+","37K+","99%","25","42%"],
        type : 'heading', field : 'heading',
        style : {'font-size':'32px','color':'white'}
      },
      {
        value : ["Tickets Delivered This Month",
        "Active Customers Rate", "Customer Satisfaction Rate",
        "Expert Team Members","Customers Retention Rate"],
        type : 'subtext', field : 'subtext',
        style : {'color':'#959393','font-size':'14px'}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats5Component",
  }
  data = this.templateData.data;
  heading = this.data[0];
  subheading =  this.data[1];
  subtext = this.data[2];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-stats5'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.subheading =  this.data[1];
    this.subtext = this.data[2];
  }
}
