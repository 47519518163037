import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";

@Component({
  selector: 'app-preview-simple-verticle-footer',
  templateUrl: './simple-verticle-footer.component.html',
  styleUrls: ['./preview-simple-verticle-footer.component.scss']
})
export class PreviewSimpleVerticleFooterComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private _dbService : DbService
  ){}

  ngOnInit(): void {
    this.dataAssign();
  }

  
  // templateData: any = {
  //   "componentCategory": "navbar",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Footer2Component",
  //   "data" : [
  //     {
  //       "imgUrl": "https://www.designhill.com/resize_img.php?atyp=page_file&pth=ft_ca_ct||117||contestfile_1&flp=1554116576-13511971185ca1efe0bcd5a0-26602492.jpg",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{}
  //     },
  //     {
  //       "value": "ClarityUI",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight": 600}
  //     },
  //     {
  //       "value": "Company",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Resource",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Career",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Help",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Privacy",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value": "Copyright 2022 Landingfolio. All Rights Reserved.",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"padding-top": "30px", "font-size": "18px", "color":"#868687"}
  //     },
  //   ]
  // }

  // data = this.templateData.data
  data : any;
  // logoImage = this.data[0]
  logoImage : any;
  // heading = this.data[1]
  heading : any;
  // company = this.data[2]
  // resource = this.data[3]
  // career = this.data[4]
  // help = this.data[5]
  // privacy  = this.data[6]
  // copyRights = this.data[7];
  copyRights : any;
  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    // this.logoImage = this.valuesData.companyLogo;
    // this.heading = this.valuesData.companyName;
    // this.copyRights = this.valuesData.copyRightText;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].imgUrl = this.valuesData.companyLogo;
      this.data[1].value = this.valuesData.companyName ;
      this.data[7].value = this.valuesData.copyRightText.replace("{Company Name}", bName);
    }
    else{

    }
  }

  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }

  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    data.style = {
      "color":this.templateData.textColor,
      "font-family":this.templateData.fontFamily,
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
  }

}
