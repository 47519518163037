<div class="main-section">
    <div class="heading">
        <div class="mainHeading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="description" [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
    </div>
    <div class="stats-section">
        <div class="stats">
            <div class="statsNumber" [ngStyle]="stats1Heading.style?stats1Heading.style:{}">{{stats1Heading.value}}</div>
            <div class="statsDesc" [ngStyle]="stats1Text.style?stats1Text.style:{}">{{stats1Text.value}}</div>
        </div>
        <div class="stats">
            <div class="statsNumber" [ngStyle]="stats2Heading.style?stats2Heading.style:{}">{{stats2Heading.value}}</div>
            <div class="statsDesc" [ngStyle]="stats2Text.style?stats2Text.style:{}">{{stats2Text.value}}</div>
        </div>
        <div class="stats">
            <div class="statsNumber" [ngStyle]="stats3Heading.style?stats3Heading.style:{}">{{stats3Heading.value}}</div>
            <div class="statsDesc" [ngStyle]="stats3Text.style?stats3Text.style:{}">{{stats3Text.value}}</div>
        </div>
    </div>
</div>
