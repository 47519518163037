import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-stats2',
  templateUrl: './stats2.component.html',
  styleUrls: ['./stats2.component.css']
})
export class Stats2Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }

  @Input() templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Unleash the full power of data",
        type : 'heading', field: 'heading',
        style : {'font-size':'24px','font-weight':900}
      },
      {
        value : "Everything you need to convert, engage, and retain more users",
        type : 'subtext', field : 'subtext',
        style : {'color':'#959393','font-size':'16px'}
      },
      {
        value : ["40+", "600%", "4k+"],
        type : 'heading', field : 'heading',
        style : {'color':'#A084DC','font-size':'44px','font-weight':700}
      },
      {
        value : ["Integrations", "Return on Investment", "Global customers"],
        type : 'text', field : 'text',
        style : {'font-size':'12px','font-weight':700}
      },
      {
        imgUrl :"./../../../../../../../../assets/images/stats/bolt-icon.png",
        display : true, type :'image', field :'image',
        style : {}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats2Component",
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subheading = this.data[1];
  statsHeading = this.data[2];
  statsText = this.data[3];
  image = this.data[4];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-stats2'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.subheading = this.data[1];
    this.statsHeading = this.data[2];
    this.statsText = this.data[3];
    this.image = this.data[4];
  }
}
