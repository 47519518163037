<section class="main_section">
    <div class="left_part">
        <img src="./../../../assets/images/left_image.jpg" alt="">
    </div>
    <div class="line"></div>
    <div class="right_part">
        <div class="logo">
            <img src="./../../../assets/images/named-logo.svg" alt="logo">
        </div>
        <div class="bottom_section">
            <div class="title">Build a website in 30 seconds using artificial intelligence</div>
            <div class="get-started">
                <button (click)="start()">Get Started</button>
            </div>
        </div>
        <div class="bottom_section" *ngIf="bTypeScreen && !seeSuggestionsScreen && !businessNameScreen && !suggestedNamesScreen">
            <div class="title">What type of business are you building?</div>
            <div class="taking_input">
                <input placeholder="Enter a business type" [(ngModel)]="btype"/>
                <button [disabled]="btype === ''" (click)="gotoBName()">next</button>
            </div>
            <div class="suggest_businessType">
                Not Sure? <span style="color: blue;cursor: pointer;" (click)="seeSuggestions()"> See more suggestions</span>
            </div>
        </div>
        <div class="bottom_section" *ngIf="seeSuggestionsScreen">
            <div class="title">What type of business are you building?</div>
            <div style="margin-top: 8px;">Here are some suggestions</div>
            <div class="suggestions" style="margin-top: 10px;text-align: left;width: 30%;display: flex;justify-content: space-between;flex-wrap: nowrap;">
                <ng-container *ngFor="let item of suggestions">
                    <button style="font-size: 16px; width: auto;background-color: blue;color: white;width: 30%;">{{item}}</button>
                </ng-container>
            </div>
            <div style="margin-top: 10px;text-align: left;width: 30%;">
                <span style="color: blue;cursor: pointer;font-size: 15px" (click)="goBack()">Pick my own</span>
                <span style="color: blue;cursor: pointer;margin-left: 10px;font-size: 15px;" (click)="seeMore()">See more</span>
            </div>
        </div>
        <div class="bottom_section" *ngIf="businessNameScreen">
            <div class="back" style="color: blue;cursor: pointer;" (click)="goToBtypeScreen()">Back</div>
            <div class="title" style="margin-top: 10px;">What’s the name of your business?</div>
            <div class="taking_input">
                <input placeholder="Enter a business type" [(ngModel)]="bName"/>
                <button [disabled]="bName === ''" (click)="generateWebsite()">next</button>
            </div>
            <div class="suggest_businessType">
                Not Sure? <span style="color: blue;cursor: pointer;" (click)="seeSuggestedNames()"> See more suggestions</span>
            </div>
        </div>
        <div class="bottom_section" *ngIf="suggestedNamesScreen">
            <div class="title">What’s the name of your business?</div>
            <div style="margin-top: 8px;">Here are some suggestions</div>
            <div class="suggestions" style="margin-top: 10px;text-align: left;width: 30%;display: flex;justify-content: space-between;flex-wrap: nowrap;">
                <ng-container *ngFor="let item of suggestions">
                    <button style="font-size: 16px; width: auto;background-color: blue;color: white;width: 30%;">{{item}}</button>
                </ng-container>
            </div>
            <div style="margin-top: 10px;text-align: left;width: 30%;">
                <span style="color: blue;cursor: pointer;font-size: 15px" (click)="enterOwnBName()">Enter my own</span>
                <span style="color: blue;cursor: pointer;margin-left: 10px;font-size: 15px;" (click)="seeMore()">See more</span>
            </div>
        </div>
    </div>
</section>