import { FacebookAuthProvider } from 'firebase/auth';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Topnavbar1Component } from './global-components/topnavbar/topnavbar1/topnavbar1.component';
import { ConstantMaterialModule } from './material_module/constant-material.module';
import { BuilderIntroductionComponent } from './our-components/builder-introduction/builder-introduction.component';
import { BusinessNameComponent } from './our-components/business-name/business-name.component';
import { BusinessTypeComponent } from './our-components/business-type/business-type.component';
import { GeneratingWebsiteComponent } from './our-components/generating-website/generating-website.component';
import { HomeComponent } from './our-components/home/home.component';
import { WebsiteComponent } from './website/website.component';
import { ServiceSectionComponent } from './global-components/others/service-section/service-section.component';
import { BlogSectionComponent } from './global-components/others/blog-section/blog-section.component';
import { HeroBannerComponent } from './global-components/others/hero-banner/hero-banner.component';
import { Topnavbar2Component } from './global-components/topnavbar/topnavbar2/topnavbar2.component';
import { AboutUsComponent } from './global-components/others/about-us/about-us.component';
import { ProcessSectionComponent } from './global-components/others/process-section/process-section.component';
import { ContactUsComponent } from './global-components/others/contact-us/contact-us.component';
import { ConstructionFooterComponent } from './global-components/footer/construction-footer/construction-footer.component';
import { TestimonialComponent } from './global-components/others/testimonial/testimonial.component';
import { ShareWebsiteComponent } from './our-components/share-website/share-website.component';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { WebsiteEditorComponent } from './our-components/website-editor/website-editor.component';
import { HomeNavBarComponent } from './our-components/home-nav-bar/home-nav-bar.component';
import { MainLandingBusinessComponent } from './master-config-components/mas-component/main-landing-business/main-landing-business.component';
import { SidnavMasterComponent } from './master-config-components/mas-component/sidnav-master/sidnav-master.component';
import { IndustryComponent } from './master-config-components/mas-component/business/industry/industry.component';
import { RegistrationTypeComponent } from './master-config-components/mas-component/business/registration-type/registration-type.component';
import { PreviewComponent } from './preview/preview.component';
import { SidenavAdminComponent } from './master-config-components/mas-component/sidenav-admin/sidenav-admin.component';
import { SecondSidenavAdminComponent } from './master-config-components/mas-component/second-sidenav-admin/second-sidenav-admin.component';
import { TemplateImagesUploadComponent } from './our-components/template-images-upload/template-images-upload.component';
import { WebsiteGeneratorComponent } from './website-generation/website-generator/website-generator.component';
import { WebsiteModule } from './master-config-components/micro-apps/website/website.module';
import { PrivacyPolicyComponent } from './our-components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './our-components/terms-conditions/terms-conditions.component';
import { SuccessComponent } from './our-components/strip/success/success.component';
import { CancelComponent } from './our-components/strip/cancel/cancel.component';
import { PricingComponent } from './our-components/pricing/pricing.component';
import { WithoutSignupEditComponent } from './our-components/without-signup-edit/without-signup-edit.component';
import { ForgotPasswordComponent } from './our-components/signin-signup/forgot-password/forgot-password.component';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';
import { FeaturesComponent } from './our-components/components/features/features.component';
import { PreviousWorkComponent } from './our-components/components/previous-work/previous-work.component';
import { UpcomingFeaturesComponent } from './our-components/components/upcoming-features/upcoming-features.component';
import { ResponsivenessComponent } from './our-components/components/responsiveness/responsiveness.component';
import { MultiTestimonialComponent } from './our-components/components/multi-testimonial/multi-testimonial.component';
import { CtaActionComponent } from './our-components/components/cta-action/cta-action.component';
import { OurHeroBannerComponent } from './our-components/components/our-hero-banner/our-hero-banner.component';
import { OurTestimonialComponent } from './our-components/components/our-testimonial/our-testimonial.component';
import { OurFooterComponent } from './our-components/components/our-footer/our-footer.component';
import { OurBlogsComponent } from './our-components/components/our-blogs/our-blogs.component';
import { OurContactUsComponent } from './our-components/components/our-contact-us/our-contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GeneratingWebsiteComponent,
    BusinessTypeComponent,
    BusinessNameComponent,
    BuilderIntroductionComponent,
    Topnavbar1Component,
    WebsiteComponent,
    ServiceSectionComponent,
    BlogSectionComponent,
    HeroBannerComponent,
    Topnavbar2Component,
    AboutUsComponent,
    ProcessSectionComponent,
    ContactUsComponent,
    ConstructionFooterComponent,
    TestimonialComponent,
    ShareWebsiteComponent,
    SigninSignupComponent,
    WebsiteEditorComponent,
    HomeNavBarComponent,
    PreviewComponent,
    TemplateImagesUploadComponent,
    WebsiteGeneratorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    SuccessComponent,
    CancelComponent,
    PricingComponent,
    WithoutSignupEditComponent,
    ForgotPasswordComponent,
    RestPasswordComponent,
    FeaturesComponent,
    PreviousWorkComponent,
    UpcomingFeaturesComponent,
    ResponsivenessComponent,
    MultiTestimonialComponent,
    CtaActionComponent,
    OurHeroBannerComponent,
    OurTestimonialComponent,
    OurFooterComponent,
    OurBlogsComponent,
    OurContactUsComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    ConstantMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
      libraries: ['places', 'geometry'],
    }),
    WebsiteModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [Topnavbar2Component, HeroBannerComponent],
})
export class AppModule {}
