<div class="share section">
  <div class="header-website">
    <div class="header-text">Share Website</div>
    <div class="close-image" (click)="closeDilog()">
      <img src="./../../../assets/images/close.svg" alt="close-img" />
    </div>
  </div>
  <hr />
  <div class="social_url">
    <div class="twitter" (click)="share('twitter')">
      <img src="./../../../assets/images/social/twitter.svg" alt="twitter" />
    </div>
    <div class="facebook" (click)="share('facebook')">
      <img src="./../../../assets/images/social/facebook.svg" alt="facebook" />
    </div>
    <!-- <div class="instagram" (click)="share('instagram')">
            <img src="./../../../assets/images/social/instagram.svg" alt="instagram">
        </div>
        <div class="git_hub" (click)="share('git_hub')" *ngIf="screenWidth > 475">
            <img src="./../../../assets/images/social/git_hub.svg" alt="git_hub">
        </div> -->
  </div>
  <div class="url-container">
    <div class="url-display">
      {{ webSiteUrl }}
    </div>
    <div class="clipboard" (click)="clipboardCopy()" *ngIf="!clipboard">
      <mat-icon>file_copy</mat-icon>
    </div>
    <div class="clipboard" *ngIf="clipboard">
      <img src="./../../../assets/images/common/copied_link.svg" alt="" />
    </div>
  </div>
  <!-- <ng-container *ngIf="screenWidth < 475">
    <div class="email-section">
      <div class="field-heading">Email Someone</div>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Enter email address" required />
      </mat-form-field>
    </div>
    <div class="description">
      <textarea>
    Hey there,
    I just launched my new business 
    website! I’d love to hear what you think.
    Check it out!

    http://smartsolutions.simpo.ai
                </textarea
      >
    </div>
    <div class="button-section">
      <div class="close-btn">
        <button (click)="closeDilog()">Close</button>
      </div>
      <div class="send-btn">
        <button>Send</button>
      </div>
    </div>
  </ng-container> -->

  <hr *ngIf="screenWidth > 475" />
  <div class="bottom-section" *ngIf="screenWidth > 475">
    <div class="close-button">
      <button (click)="closeDilog()">Close</button>
    </div>
  </div>
</div>
