<!-- <div class="navProperties">
    <nav class="nav1">{{leftNav.value}}</nav>
    <nav class="nav2" >
        <span>{{features.value}}</span>
        <span>{{pricing.value}}</span>
        <span>{{support.value}}</span>
    </nav>
    <nav class="nav3">
        <span><button>{{navbarButton.value}}</button></span>
    </nav>
</div> -->
<div class="mainFlex" [ngStyle]="{'background-color': bgColor}">
    <div class="leftContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value | titlecase}}
        </div>
        <div class="subtext" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value | titlecase}}
        </div> 
        <!-- <button class="btn" [ngStyle]="freeEditorButton.style?freeEditorButton.style:{}">{{freeEditorButton.value}}</button> -->
        <!-- <div class="stars">
            <span class="material-icons"> star_rate </span>
            <span class="material-icons"> star_rate </span>
            <span class="material-icons"> star_rate </span>
            <span class="material-icons"> star_rate </span>
            <span class="material-icons"> star_rate </span>
        </div>  -->
        <div class="buttondiv">
            <button class="buttonProperties" [ngStyle]="data[5].style ? data[5].style : {}" (click)="redirectUrl()">{{data[5].value}}</button>
        </div>
        <!-- <div class="feedbackHead" [ngStyle]="data[2].style?data[2].style:{}">{{data[2].value | titlecase}}</div>
        <div class="feedbackContent" [ngStyle]="data[3].style?data[3].style:{}">{{data[3].value | titlecase}}</div>
        <div class="personProfile">
            <img class="profilePic" src="{{data[6].imgUrl}}" alt="pic" [ngStyle]="data[6].style?data[6].style:{}">
            <div class="personName" [ngStyle]="data[4].style?data[4].style:{}">{{data[4].value | titlecase}}</div>
        </div> -->
    </div>
    <div class="rightContainer">
        <img class="rightImage" [ngStyle]="data[10].style?data[10].style:{}" src="{{data[10].imgUrl}}" alt="code">
    </div>
</div>