import { Component } from '@angular/core';

@Component({
  selector: 'app-multiple-stats-with-image',
  templateUrl: './multiple-stats-with-image.component.html',
  styleUrls: ['./multiple-stats-with-image.component.scss','./mq-multiple-stats-with-image.component.scss']
})
export class MultipleStatsWithImageComponent {
  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      // {
      //   "imgUrl": "./../../../../../../../../assets/images/hero_banner/editor_icon.svg",
      //   "type":"Image",
      //   "field":"Image",
      //   "style":{"width": "36px"}
      // },
      {
        "value": "Launch faster",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "16px", "color":"#6941C6"}
      },
      {
        "value": "Build something great",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "36px", "color":"#101828"}
      },
      {
        "value": "We’ve done all the heavy lifting so you don’t have to — get all the data you need to launch and grow your business faster.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "20px", "color":"#475467"}
      },
      {
        "value": "400+",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "60px", "color":"#7F56D9"}
      },
      {
        "value": "Projects completed",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "18px", "color":"#101828"}
      },
      {
        "value": "We’ve helped build over 400 amazing projects.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "14px", "color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/stats/first_image.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      


      

    ]
  }

  data = this.templateData.data;
}
