<div class="mainContainer" [ngStyle]="{'background-color': bgColor}">
    <div class="headContainer">
        <div class="heading" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value | titlecase}}
        </div>
        <!-- <div [ngStyle]="data[1].style?data[1].style:{}" class="description">
            {{data[1].value}}
        </div> -->
    </div>
    <div class="bottomContainer">
        <div class="signUpStepsContainer">
            <div [ngStyle]="data[2].style?data[2].style:{}" class="steps">
                {{data[2].value}}
            </div>
            <div [ngStyle]="data[3].style?data[3].style:{}" class="stepsDescription">
                {{data[3].value}}
            </div>
        </div>
        <div class="signUpStepsContainer">
            <div [ngStyle]="data[4].style?data[4].style:{}" class="steps">
                {{data[4].value}}
            </div>
            <div [ngStyle]="data[5].style?data[5].style:{}" class="stepsDescription">
                {{data[5].value}}
            </div>
        </div>
        <div class="signUpStepsContainer">
            <div [ngStyle]="data[6].style?data[6].style:{}" class="steps">
                {{data[6].value}}
            </div>
            <div [ngStyle]="data[7].style?data[7].style:{}" class="stepsDescription">
                {{data[7].value}}
            </div>
        </div>
    </div>
</div>