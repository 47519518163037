
import { Component, HostListener, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-simple-navbar-pagelinks',
  templateUrl: './preview-simple-navbar-pagelinks.component.html',
  styleUrls: ['./preview-simple-navbar-pagelinks.component.scss']
})
export class PreviewSimpleNavbarPagelinksComponent {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){

  }


  ngOnInit(): void {
    this.getScreenSize();
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'nav_bar_simple_page_links'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    this.dataAssign();
  }

  // templateData: any = {
  //   "componentCategory": "navbar",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Navbar1Component",
  //   "data" : [
  //     {
  //       "value":"Untitled UI",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"cursor": "pointer"}
  //     },
  //     {
  //       "value":"Home",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"cursor": "pointer"}
  //     },
  //     {
  //       "value":"Products",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"cursor": "pointer"}
  //     },
  //     {
  //       "value":"Resourcing",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"cursor": "pointer"}
  //     },
  //     {
  //       "value":"Pricing",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"cursor": "pointer"}
  //     },
  //     {
  //       "imgUrl":"https://png.pngtree.com/png-clipart/20190604/original/pngtree-creative-company-logo-png-image_1197025.jpg",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "16px", "padding-right": "5px"}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/profilepic.jpg",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "30px", "border-radius":"4px", "cursor": "pointer"}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/menu.png",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "22px"}
  //     }
  //   ]
  // }
  // data = this.templateData.data
  data : any ;
  // untitledUI = this.data[0]
  untitledUI : any;
  home :any ;
  products : any;
  resourcing:any;
  pricing:any;
  // products = this.data[2]
  // resourcing = this.data[3]
  // pricing = this.data[4]
  // icon = this.data[5]
  icon:any;
  // profilePic = this.data[6]
  // menuIcon = this.data[7]


  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }


  links : any = [];
  bgColor : any;
  borderBottom : any;
  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.borderBottom = `2.5px solid ${this.templateData.colors.txtHeadingColor}`
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      // this.valuesData = this.templateData.values;
      // this.data[5].imgUrl = this.valuesData.companyLogo;
      this.data[0].value = bName;

      //navbar links
      let navLinks = JSON.parse(window.localStorage.getItem('navLinks') || '');
      this.data[3].value = navLinks.length > 0 ? navLinks[0].name : "";
      this.data[3].redirectingUrl = navLinks.length > 0 ? navLinks[0].index : "";
      this.data[4].value = navLinks.length > 1 ? navLinks[1].name : "";
      this.data[4].redirectingUrl = navLinks.length > 1 ? navLinks[1].index : "";
      this.data[5].value = navLinks.length > 2 ? navLinks[2].name : "";
      this.data[5].redirectingUrl = navLinks.length > 2 ? navLinks[2].index : "";
      this.data[6].value = navLinks.length > 3 ? navLinks[3].name : "";
      this.data[6].redirectingUrl = navLinks.length > 3 ? navLinks[3].index : "";
    }
  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }

  openDownPopupUp : boolean = false;
  openPopup(){
    this.openDownPopupUp = !this.openDownPopupUp
  }

  goTo(item:any){
    this.templatesService.scrollToComponent.emit({goIndex:item});
    this.openDownPopupUp = false
  }
  contactClicked(){
    this.templatesService.scrollToComponent.emit({click:'contact'})
  }
}
