import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../blog-service.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';


@Component({
  selector: 'app-meta-tags-popup',
  templateUrl: './meta-tags-popup.component.html',
  styleUrls: ['./meta-tags-popup.component.scss']
})
export class MetaTagsPopupComponent {
  scrHeight: number=0;
  scrWidth: number=0;
  blogData:any|object;
  postSummary:any;
  postTitle:any;
  readingTime:any;
  constructor(
    public dialogRef: MatDialogRef<MetaTagsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService: BlogServiceService,
  ) {  
    this.getScreenSize();
    if(this.data.data?.blogType=='master'){
      this.getAllMasterTags();
    }
    else{
      this.getAllListing();
    }
    if(this.data.data?.blogType=='master'){
      this.getAllMasterBlogAuthors();
    }
    else{
      this.getAllBlogsByBusinessId();
    }
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.metaTagDetails.slice())),
    );

    
  }

  ngOnInit() {
    if (this.scrWidth > 475) {
      this.dialogRef.updatePosition({ top: '154px', right: '98px' });
    } else {
      this.dialogRef.updatePosition({ top: '120px', right: '50px' });
    }
    this.blogData=this.data.data.blogData;
    console.log("blogData",this.blogData);
    
    if(this.blogData){
      if(this.blogData?.author?.content!=null){
        this.author=this.blogData.author;
      }
      if(this.blogData.blogMeta?.metaDescription!=null){
        this.description=this.blogData.blogMeta.metaDescription;
      }
      if(this.blogData.keyWords?.content!=null){
        this.keywords=this.blogData.keyWords.content.split(",");
      }
      if(this.blogData.blogMeta?.metaTitle!=null){
      this.title=this.blogData.blogMeta.metaTitle;
      }
      if(this.blogData?.postSummary!=null){
        this.postSummary=this.blogData.postSummary;
      }
      if(this.blogData?.postTitle!=null){
        this.postTitle=this.blogData.postTitle
      }
      if(this.blogData?.readTime!=null){
        this.readingTime=this.blogData.readTime;
      }
    }
    console.log("data",this.data);
    
  
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  closeImage(){
    this.dialogRef.close();
  }
  author={
    name:'author',
    content:''
  }

  description={
    name:'description',
    content:''
  }

  title:String="";

  addTags(){
    let data:any={}
  //console.log("keyword:" ,this.keywords.join(','));
    if(this.keywords.length>0){
     data ={ name:'keywords',content:this.keywords.join(',')}
    }

    let checkData={
      keyWords:data,
      author:this.author,
      postTitle: this.postTitle,
      blogMeta: {
        metaTitle: this.title,
        metaDescription: this.description
      },
      postSummary: this.postSummary,
      readTime:this.readingTime
    }
    console.log(checkData,);
    
    if(this.data.data.typeRequest=="api"){
      let returnedTarget=Object.assign(this.blogData,checkData);
      console.log("return Target",returnedTarget);  
      this.blogService.updateBlog(returnedTarget).subscribe({
              next: (res: any) => {
                this.blogService.openSnack(
                  'Tags added sucessfully',
                  'Close'
                );
              },
              error: (err: any) => {
                //console.log('error', err);
              },
            });
    }
    if(this.data.data.typeRequest=="masterMetaTags"){
      let returnedTarget=Object.assign(this.blogData,checkData);
      //console.log(returnedTarget);  
      this.blogService.UpdateMasterBlog(returnedTarget).subscribe({
              next: (res: any) => {
                this.blogService.openSnack(
                  'Tags added sucessfully',
                  'Close'
                );
              },
              error: (err: any) => {
                //console.log('error', err);
              },
            });
    }

   
    this.dialogRef.close(
      {
        blogSetting: {
          keyWords:data,
          author:this.author,
          postTitle: this.postTitle,
          blogMeta: {
            metaTitle: this.title,
            metaDescription: this.description
          },
          postSummary: this.postSummary,
          readTime:this.readingTime
        }
      } 
      );
  }
  keywords:any[] = [];
  formControl = new FormControl(['angular']);

  removeKeyword(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.keywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  edit(keyword: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
      return;
    }

    // Edit existing fruit
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords[index] = value;
    }
  }


  metaTagDetails:any=[];
  getAllListing() {
    this.blogService.getBlogsTagsByBusinessId(window.localStorage.getItem('bDetails')?JSON.parse(window.localStorage.getItem('bDetails')||"").id:"").subscribe((res: any) => {
      this.metaTagDetails = res.data;
    })
  }


  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<any>;
  fruits: string[] = ['Lemon'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement> | any;



  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event){
    console.log("event",event);
    
    this.keywords.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  console.log("keywords",this.keywords);
  
    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.metaTagDetails.filter((fruit:any) => fruit.tagName.toLowerCase().includes(filterValue));
  }

  metaCardSelect:boolean=false;
  metacardSelected(){
  this.metaCardSelect=!this.metaCardSelect;
  }

  authorControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredAuthorOptions: Observable<any> | undefined;

  private _authorFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.authorDetails.filter((option:any) => option.authorName.toLowerCase().includes(filterValue));
  }

  authorDetails:any=[]

  getAllBlogsByBusinessId(){
    this.blogService.getAllBlogAuthors(window.localStorage.getItem('businessId')||null).subscribe(
      {
        next:(value:any)=>{
          this.authorDetails=value.data;
          this.filteredAuthorOptions = this.authorControl.valueChanges.pipe(
            startWith(''),
            map(value => this._authorFilter(value || '')),
          );
        },
        error:(err)=>{
          this.blogService.openSnack("No Data Found","Close");
        },
      }
    )
  }

  getAllMasterBlogAuthors(){
    this.blogService.getAllAuthors().subscribe(
      {
        next:(value:any)=>{
          this.authorDetails=value.data;
          this.filteredAuthorOptions = this.authorControl.valueChanges.pipe(
            startWith(''),
            map(value => this._authorFilter(value || '')),
          );
        },
        error:(err)=>{
          this.blogService.openSnack("No Data Found","Close");
        },
      }
    )
  }

  getAllMasterTags(){
    this.blogService.getBlogsTagsByBusinessId(window.localStorage.getItem('bDetails')?JSON.parse(window.localStorage.getItem('bDetails')||"").id:"").subscribe((res: any) => {
      this.metaTagDetails = res.data;
    })
  }

  selectedAuthor(ev:any){
    console.log(ev);
    this.author.content=ev;
  }
}
