<div class="main" [ngStyle]="{'background-color': bgColor}">
    <div [ngStyle]="data[0].style?data[0].style:{}" class="first-text">{{data[1].value | titlecase}}</div>
    <div [ngStyle]="data[1].style?data[1].style:{}" class="second-text">{{data[0].value | titlecase}}</div>
    <div class="testimonial-section">
     <div class="test-content">
         <img src="{{data[2].value[0].imageUrl}}" alt="" class="text-content-img"  [ngStyle]="data[2].style?data[2].style:{}" *ngIf="data[2].value[0].imageUrl != 'string' || data[2].value[0].imageUrl != ''">
         <div class="details-container">
             <div class="text" [ngStyle]="data[3].value[0].style?data[3].value[0].style:{}">{{data[3].value[0].value | titlecase}}</div>
             <div class="person_shot_note" [ngStyle]="data[6].value[0].style ? data[6].value[0].style : {}">
                {{data[6].value[0].value}}
             </div>
             <div class="person-details" >
                 <span [ngStyle]="data[4].value[0].style?data[4].value[0].style:{}" class="person-name">{{data[4].value[0].value | titlecase}}</span>
                 <span [ngStyle]="data[5].value[0].style?data[5].value[0].style:{}" class="person-type">{{data[5].value[0].value | titlecase}}</span>
             </div>
         </div>
     </div>
     <div class="test-content">
         <img src="{{data[2].value[1].imageUrl}}" alt="" class="text-content-img" [ngStyle]="data[2].style?data[2].style:{}" *ngIf="data[2].value[1].imageUrl != 'string' || data[2].value[1].imageUrl != ''">
         <div class="details-container">
             <div class="text" [ngStyle]="data[3].value[1].style?data[3].value[1].style:{}">{{data[3].value[1].value}}</div>
             <div class="person_shot_note" [ngStyle]="data[6].value[1].style ? data[6].value[1].style : {}">
                {{data[6].value[1].value}}
             </div>
             <div class="person-details">
                 <span [ngStyle]="data[4].value[1].style?data[4].value[1].style:{}" class="person-name">{{data[4].value[1].value}}</span>
                 <span [ngStyle]="data[5].value[1].style?data[5].value[1].style:{}" class="person-type">{{data[5].value[1].value}}</span>
             </div>
         </div>
     </div>
    </div> 
 </div>