import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-navbar-contact-btn',
  templateUrl: './navbar-contact-btn.component.html',
  styleUrls: ['./navbar-contact-btn.component.scss']
})
export class NavbarContactBtnComponent {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  
  constructor(
    private templatesService:TemplateService,
  ){}

  ngOnInit(){
    console.log(this.templateData);
    
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'nav_bar_simple_contact'){
            this.templateData = conCompList;
            this.dataAssign();
            console.log(this.templateData);
            
          }
          else{}
        });
      })
    this.dataAssign();
  }

  // templateData: any = {
  //   "componentCategory": "navbar",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "LogoipsumHeaderComponent",
  //   "data" : [
  //     {
  //       "imgUrl":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPtfg1dHS79cFBmaRilYwh6HTQA_JyHJ5a4Q&usqp=CAU",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "50%","border-radius":"50%"}
  //     },
  //     {
  //       "value":"Home",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Dashboard",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Pricing",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Contact",
  //       "type":"Button",
  //       "field":"Button",
  //       "style":{"color": "white","background-color": "blue","padding": "5px 15px","border-radius": "4px","border": "none"}
  //     }
  //   ]
  // }

  // data = this.templateData.data
  data : any;
  // logo = this.data[0]
  logo : any;
  // home = this.data[1]
  // dashboard = this.data[2]
  // pricing = this.data[3]
  // actionBtn = this.data[4]
  actionBtn : any

  dataAssign(){
    console.log(this.templateData);
    this.data = this.templateData.data;
    this.checkingStyles();
    if(this.templateData.values != null){
      this.valuesData = this.templateData.values;
      this.data[0].imgUrl = this.valuesData.companyLogo;
      this.data[4].value = 'contact'
    }
    else{

    }
  }

  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }

  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    data.style = {
      "color":this.templateData.textColor,
      "font-family":this.templateData.fontFamily,
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
  }
}
