<div class="main-section">
    <div class="heading">
        <div [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
        <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div [ngStyle]="subtext.style?subtext.style:{}">{{subtext.value}}</div>
    </div>
    <div class="content">
        <div class="stat-section">
            <div class="stats" *ngFor="let item of statsHeading.value; let i = index">
                <div [ngStyle]="statsHeading.style?statsHeading.style:{}">{{item}}</div>
                <div [ngStyle]="statsSubheading.style?statsSubheading.style:{}">{{statsSubheading.value[i]}}</div>
                <div [ngStyle]="statsText.style?statsText.style:{}">{{statsText.value[i]}}</div>
            </div>
        </div>
        <div class = "image-section">
            <img [ngStyle]="image.style?image.style:{}" src="{{image.imgUrl}}" alt="">
        </div>
    </div>
</div>
