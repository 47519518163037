<section class="main_section">
    <div class="left_part">
        <div class="logo-left" *ngIf="scrWidth < 475px">
            <img src="./../../../assets/images/named-logo.svg" alt="logo">
        </div>
        <!-- <img src="./../../../assets/images/left_image.jpg" alt=""> -->
        <!-- <agm-map [latitude]="latitude" [longitude]="longitude" id="map"
        [zoom]="zoom">
            <agm-marker [latitude]="latitude" [longitude]="longitude" ></agm-marker>
        </agm-map> -->
        <div class="map map_section" id="map"></div>
    </div>
    <div class="right_part">
        <div class="logo" *ngIf="scrWidth > 475px">
            <img src="./../../../assets/images/named-logo.svg" alt="logo">
        </div>
        <div class="bottom_section" *ngIf="!seeSuggestionsScreen">
            <div *ngIf="showAddress != ''" class="location_rel">It looks like you’re in <span class="location" (click)="changeLocation()">&nbsp;{{showAddress}}</span>
            <span class="pin"><img src="./../../../assets/images/map/black_map_pin.svg" alt="map_pin"></span></div>
            <div *ngIf="showAddress === ''" class="location_rel">Please allow location<span class="pin"><img src="./../../../assets/images/map/black_map_pin.svg" alt="map_pin"></span></div>
            <div class="title">What type of business are you building?</div>
            <div class="taking_input">
                <mat-form-field class="sfull-width domain-input" appearance="outline">
                    <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                    <input type="text" placeholder="Enter business type" matInput [formControl]="myControl" [matAutocomplete]="auto1"
                    (ngModelChange)="getVal($event)"/>
                    <mat-autocomplete #auto1="matAutocomplete">
                      <mat-option *ngFor="
                          let option of filteredOptions | async;
                          index as i
                        " [value]="option.name"
                        (click)="selectBusinessType(option)"
                        (keyup.enter)="selectBusinessType(option)"
                        id="searchValue"
                        >
                        {{ option.name}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- <mat-form-field class="sfull-width" appearance="outline">
                    <mat-select [(ngModel)]="subCategory">
                        <mat-option value="">--Select--</mat-option>
                        <mat-option [value]="item.id" *ngFor="let item of allSubTypes">{{item?.name | titlecase}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <div class="next_btn">
                    <button [disabled]="bType === ''" (click)="goTobName()">Next</button>
                </div>
            </div>
            <!-- <div class="suggest_businessType">
                Not Sure? <span style="color: blue;cursor: pointer;" (click)="seeSuggestions()"> See more suggestions</span>
            </div> -->
        </div>
        <div class="bottom_section" *ngIf="seeSuggestionsScreen">
            <div class="title">What type of business are you building?</div>
            <div style="margin-top: 8px;">Here are some suggestions</div>
            <div class="suggestions" style="margin-top: 10px;text-align: left;display: flex;justify-content: space-between;flex-wrap: nowrap;">
                <ng-container *ngFor="let item of suggestions">
                    <button >{{item.businessCategory}}</button>
                </ng-container>
            </div>
            <div style="margin-top: 10px;text-align: left;width: 30%;">
                <span style="color: blue;cursor: pointer;font-size: 15px" (click)="goBack()">Pick my own</span>
                <span style="color: blue;cursor: pointer;margin-left: 10px;font-size: 15px;" (click)="seeMore(3)">See more</span>
            </div>
        </div>
        <!-- <div class="bottom_section" *ngIf="businessNameScreen">
            <div class="back" style="color: blue;cursor: pointer;" (click)="goToBtypeScreen()">Back</div>
            <div class="title" style="margin-top: 10px;">What’s the name of your business?</div>
            <div class="taking_input">
                <input placeholder="Enter a business type" [(ngModel)]="bName"/>
                <button [disabled]="bName === ''" (click)="generateWebsite()">next</button>
            </div>
            <div class="suggest_businessType">
                Not Sure? <span style="color: blue;cursor: pointer;" (click)="seeSuggestedNames()"> See more suggestions</span>
            </div>
        </div>
        <div class="bottom_section" *ngIf="suggestedNamesScreen"> -->
            <!-- <div class="title">What’s the name of your business?</div>
            <div style="margin-top: 8px;">Here are some suggestions</div>
            <div class="suggestions" style="margin-top: 10px;text-align: left;width: 30%;display: flex;justify-content: space-between;flex-wrap: nowrap;">
                <ng-container *ngFor="let item of suggestions">
                    <button style="font-size: 16px; width: auto;background-color: blue;color: white;width: 30%;">{{item}}</button>
                </ng-container>
            </div>
            <div style="margin-top: 10px;text-align: left;width: 30%;">
                <span style="color: blue;cursor: pointer;font-size: 15px" (click)="enterOwnBName()">Enter my own</span>
                <span style="color: blue;cursor: pointer;margin-left: 10px;font-size: 15px;" (click)="seeMore()">See more</span>
            </div> -->
        <!-- </div> -->
    </div>
</section>
<section class="location_box" *ngIf="changeLocationbox">
    <div class="change_location">
        <div class="location_title_cross">
            <div class="location_title">Where are you?</div>
            <div class="cross" (click)="close()"><mat-icon>close</mat-icon></div>
        </div>
        <div class="hr_line"></div>
        <div class="location_input">
            <mat-form-field class="sfull_width" appearance="outline">
                <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                <input type="text" placeholder="Search for location" matInput [formControl]="searchBox" [matAutocomplete]="auto2" 
                [(ngModel)]="searchLocation" (keyup)="search()"/>
                <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="
                        let option of searchResults | async;
                        index as i
                    " [value]="option.displayName"
                    (click)="selectedLocations(option)"
                    >
                    {{ option.displayName | titlecase }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="hr_line"></div>
        <div class="save_btn">
            <button (click)="saveLocation()">Save</button>
        </div>
    </div>
</section>