<div class="main-section">
    <div class="headingContainer">
        <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="description" [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
    </div>
    <div class="rightContainer">
        <div class="stats-section">
            <div class="stats">
                <div class="statsHeading" [ngStyle]="statsHeading1.style?statsHeading1.style:{}">{{statsHeading1.value}}</div>
                <div class="statsText" [ngStyle]="statsText1.style?statsText1.style:{}">{{statsText1.value}}</div>
            </div>
            <div class="stats">
                <div class="statsHeading" [ngStyle]="statsHeading2.style?statsHeading2.style:{}">{{statsHeading2.value}}</div>
                <div class="statsText" [ngStyle]="statsText2.style?statsText2.style:{}">{{statsText2.value}}</div>
            </div>
            <div class="stats borderNone">
                <div class="statsHeading" [ngStyle]="statsHeading3.style?statsHeading3.style:{}">{{statsHeading3.value}}</div>
                <div class="statsText" [ngStyle]="statsText3.style?statsText3.style:{}">{{statsText3.value}}</div>
            </div>
        </div>
    </div>
</div>

