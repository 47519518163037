import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from "./../../../../../../../services/template.service";
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-herobanner-stat-image',
  templateUrl: './herobanner-stat-image.component.html',
  styleUrls: ['./herobanner-stat-image.component.scss', './mq-herobanner-stat-image.component.scss']
})
export class HerobannerStatImageComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color:any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}
  
  ngOnInit(): void {
    // //console.log('double stats hero banner',this.templateData);

    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'double_stats_hero_banner'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  
  // templateData: any = {
  //   "componentCategory": "hero banner",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "BannerComponent",
  //   "data" : [
  //     {
  //       "value":"A special credit card made for Developers",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"font-weight": 900, "line-height": "normal"}
  //     },
  //     {
  //       "value":"Lorem ipsum dolor sit amet, consectatur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color": "#959393"}
  //     },
  //     {
  //       "value":"Rareblocks",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Sign in",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Get Free Card",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"2943",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"$1M+",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"https://freepngimg.com/thumb/debit_card/4-2-debit-card-png-hd.png",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     },   {
  //       "value":"Solutions",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Industries",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Fees",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"About Rareblocks",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Create free account",
  //       "type":"button",
  //       "field":"button",
  //       "style":{}
  //     },
  //     {
  //       "value":"Cards Delivered",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Transactions Completed",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     }
  //   ]
    
  // }

  // data = this.templateData.data
  data : any;
  // heading = this.data[0]
  heading : any;
  // description = this.data[1]
  description : any;
  // rareBlocks = this.data[2]
  // signIn = this.data[3]
  // getFreeCard = this.data[4]
  // cards = this.data[5]
  cards:any
  // transactions = this.data[6]
  transactions:any;
  // image = this.data[7]
  image:any;
  // solutions = this.data[8]
  // industries = this.data[9]
  // fees = this.data[10]
  // about = this.data[11]
  // createFreeAccount = this.data[12]
  // cardsDelivered = this.data[13]
  cardsDelivered : any;
  // transactionsCompleted = this.data[14];
  transactionsCompleted : any;

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      let cityName = this._dbService.getLocality();
      this.valuesData = this.templateData.values;
      // this.data[0].value = this.valuesData.title.replaceAll("[Company Name]", bName);
      // this.data[1].value = this.valuesData.subTitle.replaceAll("[Company Name]", bName);
      this.data[0].value = this.valuesData.title.replaceAll("{Company Name}", bName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{Company Name}", bName);
      this.data[0].value = this.valuesData.title.replaceAll("{City Name}", cityName);
      this.data[1].value = this.valuesData.subTitle.replaceAll("{City Name}", cityName);
      this.data[5].value = this.valuesData.counter1Count;
      this.data[6].value = this.valuesData.counter1Text;
      this.data[7].value = this.valuesData.counter2Count;
      this.data[8].value = this.valuesData.counter2Text;
      this.data[9].imgUrl = this.valuesData.imageUrl;
      this.data[14].value = this.valuesData.button1Text;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // //console.log(this.templateData);
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData)
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }

  redirectUrl(){
    window.open(`${this.data[14].link}`,'_blank');
  }
}
