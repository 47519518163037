import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";

@Component({
  selector: 'app-simple-footer-social-links',
  templateUrl: './simple-footer-social-links.component.html',
  styleUrls: ['./simple-footer-social-links.component.scss','./mq-simple-footer-social-links.component.scss']
})
export class SimpleFooterSocialLinksComponent implements OnInit{

  @Input() templateData:any;
  valuesData:any;
  componentData: any;

  constructor(
    private _dbService : DbService
  ){}

  ngOnInit(): void {
    this.dataAssign()
  }

    
  
  // templateData: any = {
  //   "componentCategory": "navbar",
  //   "ranking": 0,
  //   "businessCategoryId": "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "Footer1Component",
  //   "data" : [
  //     {
  //       "value":"ClarityUI",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight": 600,"font-size": "23px","padding": "8px"}
  //     },
  //     {
  //       "value":"Company",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Resource",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Careers",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Help",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Privacy",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Copyright 2022 Landingfolio. All Rights Reserved",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"display": "flex","justify-content": "center", "color":"#868687", "font-weight": 500}
  //     },
  //     {
  //       "imgUrl":"https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-facebook-social-media-icon-png-image_6315968.png",
  //       "type":"Image",
  //       "field":"Image",
  //       "redirectionUrl":"",
  //       "style":{"width": "22px"}
  //     },
  //     {
  //       "imgUrl":"https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg?w=2000",
  //       "type":"Image",
  //       "field":"Image",
  //       "redirectionUrl":"",
  //       "style":{"width": "22px"}
  //     },
  //     {
  //       "imgUrl":"https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/LinkedIn_icon_circle.svg/800px-LinkedIn_icon_circle.svg.png",
  //       "type":"Image",
  //       "field":"Image",
  //       "redirectionUrl":"",
  //       "style":{"width": "22px"}
  //     },
  //     {
  //       "imgUrl":"https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
  //       "type":"Image",
  //       "field":"Image",
  //       "redirectionUrl":"",
  //       "style":{"width": "22px"}
  //     },
  //     {
  //       "imgUrl":"https://img.freepik.com/free-vector/abstract-logo-flame-shape_1043-44.jpg?w=2000",
  //       "type":"Image",
  //       "field":"Image",
  //       "style":{"width": "30px","padding-right": "10px"}
  //     }
  //   ]
  // }

  // data = this.templateData.data
  data:any;
  // clarityUI = this.data[0]
  clarityUI : any;
  // company = this.data[1]
  // resource = this.data[2]
  // careers = this.data[3]
  // help = this.data[4]
  // privacy = this.data[5]
  // copyRights = this.data[6]
  copyRights : any;
  // facebookIcon = this.data[7]
  facebookIcon = 'https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-facebook-social-media-icon-png-image_6315968.png'
  fbUrl : any;
  // instagramIcon = this.data[8]
  instagramIcon = 'https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg?w=2000'
  instUrl : any;
  // linkedInIcon = this.data[9]
  linkedInIcon = 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/LinkedIn_icon_circle.svg/800px-LinkedIn_icon_circle.svg.png'
  linkedInUrl:any;
  // twitterIcon = this.data[10]
  twitterIcon = 'https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png'
  twitterIconUrl : any;
  // mainLogo = this.data[11]
  mainLogo : any

  dataAssign(){
    this.data = this.templateData.data;
    // this.mainLogo = this.valuesData.companyLogo;
    // this.clarityUI = this.valuesData.companyName;
    // this.copyRights = this.valuesData.copyRightText;
    // this.fbUrl = this.valuesData.facebookUrl;
    // this.instUrl = this.valuesData.instagramUrl;
    // this.linkedInUrl = '';
    // this.twitterIconUrl = this.valuesData.twitterUrl

    this.checkingStyles();
    
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[11].imgUrl = this.valuesData.companyLogo;
      this.data[0].value = this.valuesData.companyName;
      this.data[6].value = this.valuesData.copyRightText.replaceAll("{Company Name}", bName);
      this.data[6].value = this.valuesData.copyRightText.replaceAll("[Company Name]", bName);
      this.data[7].redirectionUrl = this.valuesData.facebookUrl;
      this.data[8].redirectionUrl = this.valuesData.instagramUrl;
      this.data[9].redirectionUrl = this.valuesData.linkedInUrl;
      this.data[10].redirectionUrl = this.valuesData.twitterUrl
    }
    else{

    }

  }

  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        this.typeText(elems)
      }
      else if(elems.type === 'button'){
        this.typeButton(elems)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        this.typeText(arrValue)
      }
      else if(arrValue.type === 'button'){
        this.typeButton(arrValue)
      }
    });
  }

  typeText(data:any){
    data.style = {
      "color":this.templateData.textColor,
      "font-family":this.templateData.fontFamily,
    }
  }
  typeButton(data:any){
    if(this.templateData.buttonStyle === "SOLID"){
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":this.templateData.primaryColor,
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
    else{
      if(this.templateData.buttonShape === "SHARP"){
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":"none",
          "padding":"8px 15px"
        }
      }
      else{
        data.style = {
          "background-color":"transparent",
          "color":this.templateData.textColor,
          "font-family":this.templateData.fontFamily,
          "border-radius":`1px solid ${this.templateData.primaryColor}`,
          "padding":"8px 15px"
        }
      }
    }
  }

}
