<section class="main_section">
    <section class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </section>
    <section class="body_section">
        <div class="left_container" *ngIf="!pageLoader ; else loadPage">
            <div class="browse_image">
                <input type="button" value="Browse Image" class="fc_browse_btn" id="display_browse_btn" (click)="browseImage()">
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/jpeg,image/jpg" (change)="updateImage($event)" 
                class="fc-btn" id="browse_fc_image"/>
            </div>
            <div class="copy_img_url">
                <div class="copy_heading">Image Url</div>
                <div class="url_copy_btn" *ngIf="imgUrl != ''">
                    <div class="url">
                        {{imgUrl}}
                    </div>
                    <div class="copy_btn" (click)="clipBoardCopy()">
                        <mat-icon>file_copy</mat-icon>
                    </div>
                </div>
                <div class="url_copy_btn" *ngIf="imgUrl === ''">
                    <div class="url">No Url Found</div>
                </div>
            </div>
        </div>
        <ng-template #loadPage>
            <div class="left_container">
                <div class="loader">
                    <img src="./../../../assets/images/loader/beeos_loader.gif" alt="loader">
                </div>
            </div>
        </ng-template>
        <div class="right_container">
            <div class="container_heading">Image Preview</div>
            <div class="img_container">
                <div class="no_img" *ngIf="imgUrl === ''">No Image Found</div>
                <img [src]="imgUrl" alt="" *ngIf="imgUrl != ''">
            </div>
        </div>
    </section>
</section>