import {
  ChangeDetectorRef,
  Compiler,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  buttonShape,
  buttonStyle,
  fontFamilies,
} from '../constants/common-constant';
import { ShareWebsiteComponent } from '../our-components/share-website/share-website.component';
import { SigninSignupComponent } from '../our-components/signin-signup/signin-signup/signin-signup.component';
import { EventEmmiterService } from '../services/event-emmiter.service';
import { TemplateService } from '../services/template.service';
import { BlogSectionComponent } from './../global-components/others/blog-section/blog-section.component';
import { HeroBannerComponent } from './../global-components/others/hero-banner/hero-banner.component';
import { ServiceSectionComponent } from './../global-components/others/service-section/service-section.component';
import { Topnavbar2Component } from './../global-components/topnavbar/topnavbar2/topnavbar2.component';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
})
export class WebsiteComponent {
  @ViewChild('abc', { read: ViewContainerRef }) abc:
    | ViewContainerRef
    | undefined;

  constructor(
    private templateService: TemplateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private emitterService: EventEmmiterService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // window.onbeforeunload = function() {
    //   return "Dude, are you sure you want to refresh? Think of the kittens!";
    // }
    this.getScreenSize();
  }
  // createComponent(){
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(Topnavbar2Component);
  //   if(!this.abc) return;
  //   const componentRef = this.abc.createComponent(componentFactory);
  //   if(!componentRef) return;
  //   componentRef.instance.index = 1
  // }
  scrHeight: any;
  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    //console.log(this.scrHeight, this.scrWidth);
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  totalPageLoader: boolean = false;
  indexValues: any = [];

  serviceComponent: boolean = false;
  service_ComponentData: any;
  blogComponent: boolean = false;
  blog_ComponentData: any;
  heroBannerComponent: boolean = false;
  heroBanner_ComponentData: any;
  aboutUs_ComponentData: any = 'about Us';
  aboutUsComponent: boolean = false;
  testimonial_component: boolean = false;
  testimonial_componentData: any;
  contact_component: boolean = false;
  contact_componentData: any;
  process_component: boolean = false;
  process_componentData: any;

  totalData: any;
  loader: boolean = false;
  loadingTime = 3000;
  queyWebCheck: boolean = false;
  restyleComponent: boolean = false;
  buttonStyles = buttonStyle;
  fontFamilies = fontFamilies;
  bStyle: any = '';
  buttonShape = buttonShape;
  bShape: any = '';

  globalStyles = {
    primaryColor: '#5599d5',
    buttonColor: '#fbd09a',
    textFamily: {
      'font-family': "'Lexend Deca', sans-serif",
    },
  };
  selectingFontFamily = '';
  ngOnInit() {
    this.bStyle = this.buttonStyles[0].value;
    this.bShape = this.buttonShape[0].value;
    this.selectingFontFamily = this.fontFamilies[0].value;
    this.queryParamSubscribe();
    this.totalPageLoader = true;
    if (window.localStorage.getItem('webId') === null) {
      this.queyWebCheck = false;
      this.templateService
        .getTemplateDataByBCId(window.localStorage.getItem('bcid'))
        .subscribe(
          (res: any) => {
            //console.log(res.data);
            this.totalData = res.data;
            this.totalPageLoader = false;
            // window.localStorage.setItem('webId',res.data.id)
            res.data.componentContentList.forEach((element: any) => {
              if (element.componentName === 'service') {
                // this.serviceComponent = true;
                // this.service_ComponentData = element.data[0];
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'service') {
                    this.serviceComponent = true;
                    this.service_ComponentData = element.data[0];
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'heroBanner',
                      });
                    }, 500);
                  }
                });
              } else if (element.componentName === 'blog') {
                this.blogComponent = true;
                this.blog_ComponentData = element.data[0];
              } else if (element.componentName === 'heroBanner') {
                this.heroBannerComponent = true;
                this.heroBanner_ComponentData = element.data[0];
              } else if (element.componentName === 'Home_Testomnial') {
                // this.testimonial_component = true;
                // this.testimonial_componentData = element.data[0];
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Home_Testomnial') {
                    this.testimonial_component = true;
                    this.testimonial_componentData = element.data[0];
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'Home-process-component',
                      });
                    }, 500);
                  }
                });
              } else if (element.componentName === 'Contactus-section') {
                // this.contact_component = true;
                // this.contact_componentData = element.data[0];
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Contactus-section') {
                    this.contact_component = true;
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'Home_Testomnial',
                      });
                      this.contact_componentData = element.data[0];
                    }, 500);
                  }
                });
              } else if (element.componentName === 'Home-process-component') {
                // this.process_component = true;
                // this.process_componentData = element.data[0];
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Home-process-component') {
                    this.process_component = true;
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'service',
                      });
                      this.process_componentData = element.data[0];
                    }, 500);
                  }
                });
              }
            });
          },
          (err) => {
            //console.log(err);
            this.totalPageLoader = false;
          }
        );
    } else if (this.querywebId !== undefined) {
      this.queyWebCheck = true;
      this.templateService
        .getTemplateDataByTemplateId(this.querywebId)
        .subscribe(
          (res: any) => {
            //console.log(res.data)
            this.totalData = res.data[0];
            this.totalPageLoader = false;
            res.data[0].componentContentList.forEach((element: any) => {
              if (element.componentName === 'service') {
                this.serviceComponent = true;
                this.service_ComponentData = element.data[0];
              } else if (element.componentName === 'blog') {
                this.blogComponent = true;
                this.blog_ComponentData = element.data[0];
              } else if (element.componentName === 'heroBanner') {
                this.heroBannerComponent = true;
                this.heroBanner_ComponentData = element.data[0];
              } else if (element.componentName === 'Home_Testomnial') {
                this.testimonial_component = true;
                this.testimonial_componentData = element.data[0];
              } else if (element.componentName === 'Contactus-section') {
                this.contact_component = true;
                this.contact_componentData = element.data[0];
              } else if (element.componentName === 'Home-process-component') {
                this.process_component = true;
                this.process_componentData = element.data[0];
              }
            });
          },
          (err) => {
            //console.log(err);
            this.totalPageLoader = false;
          }
        );
    } else {
      this.queyWebCheck = false;
      this.templateService
        .getTemplateDataByTemplateId(window.localStorage.getItem('webId'))
        .subscribe(
          (res: any) => {
            //console.log(res.data)
            this.totalData = res.data[0];
            this.totalPageLoader = false;
            res.data[0].componentContentList.forEach((element: any) => {
              if (element.componentName === 'service') {
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'service') {
                    this.serviceComponent = true;
                    this.service_ComponentData = element.data[0];
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'heroBanner',
                      });
                      this.loader = true;
                    }, 500);
                  }
                });
              } else if (element.componentName === 'blog') {
                // this.blogComponent = true;
                this.blog_ComponentData = element.data[0];
              } else if (element.componentName === 'heroBanner') {
                this.heroBannerComponent = true;
                this.heroBanner_ComponentData = element.data[0];
                this.loader = true;
              } else if (element.componentName === 'Home_Testomnial') {
                // this.testimonial_component = true;
                // this.testimonial_componentData = element.data[0];
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Home_Testomnial') {
                    this.testimonial_component = true;
                    this.loader = true;
                    this.testimonial_componentData = element.data[0];
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'Home-process-component',
                      });
                    }, 500);
                  }
                });
              } else if (element.componentName === 'Contactus-section') {
                // this.contact_component = true;
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Contactus-section') {
                    this.contact_component = true;
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'Home_Testomnial',
                      });
                      this.contact_componentData = element.data[0];
                      this.loader = false;
                    }, 500);
                  }
                });
              } else if (element.componentName === 'Home-process-component') {
                this.emitterService.activateComponent.subscribe((res) => {
                  if (res.nextComponent === 'Home-process-component') {
                    this.process_component = true;
                    this.loader = true;
                    setTimeout(() => {
                      this.emitterService.loadingSections.emit({
                        lastComponent: 'service',
                      });
                      this.process_componentData = element.data[0];
                    }, 500);
                  }
                });
              }
            });
          },
          (err) => {
            //console.log(err);
            this.totalPageLoader = false;
          }
        );
    }
  }

  querywebId: any = null;

  queryParamSubscribe() {
    this.activatedRoute.queryParams.subscribe({
      next: (value: any) => {
        this.querywebId = value.webId;
      },
    });
  }
  regenrate(ev: any) {
    // //console.log(ev);
    this.totalData.componentContentList.forEach((element: any) => {
      if (element.componentName === ev) {
        let x = 1;
        let n = element.data.length;
        x = x % n;
        // let  first_x_elements = this.totalData.slice(0,this.x);
        let first_x_elements = element.data.slice(0, x);
        // let remaining_elements = this.totalData.slice(this.x,n);
        let remaining_elements = element.data.slice(x, n);
        element.data = [...remaining_elements, ...first_x_elements];
        // //console.log(element.data);
      }
    });
    this.loader = true;
    this.emitterService.componentLoader.emit({ loading: true, name: ev });
    if (window.localStorage.getItem('webId') === null) {
      let data = {
        componentContentList: this.totalData.componentContentList,
      };
      this.templateService.intiateTemplateData(data).subscribe(
        (res: any) => {
          this.totalData = res.data;
          window.localStorage.setItem('webId', res.data.id);
          res.data.componentContentList.forEach((element: any) => {
            if (element.componentName === 'service') {
              this.serviceComponent = true;
              this.service_ComponentData = element.data[0];
            } else if (element.componentName === 'blog') {
              this.blogComponent = true;
              this.blog_ComponentData = element.data[0];
            } else if (element.componentName === 'heroBanner') {
              this.heroBannerComponent = true;
              this.heroBanner_ComponentData = element.data[0];
            } else if (element.componentName === 'Home_Testomnial') {
              this.testimonial_component = true;
              this.testimonial_componentData = element.data[0];
            } else if (element.componentName === 'Contactus-section') {
              this.contact_component = true;
              this.contact_componentData = element.data[0];
            } else if (element.componentName === 'Home-process-component') {
              this.process_component = true;
              this.process_componentData = element.data[0];
            }
          });
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        },
        (err) => {
          //console.log(err)
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        }
      );
    } else {
      let data = {
        templateId: window.localStorage.getItem('webId'),
        id: this.totalData.id,
        componentContentList: this.totalData.componentContentList,
      };
      this.templateService.updateTemplateData(data).subscribe(
        (res: any) => {
          this.totalData = res.data;
          window.localStorage.setItem('webId', res.data.id);
          res.data.componentContentList.forEach((element: any) => {
            if (element.componentName === 'service') {
              this.serviceComponent = true;
              this.service_ComponentData = element.data[0];
            } else if (element.componentName === 'blog') {
              this.blogComponent = true;
              this.blog_ComponentData = element.data[0];
            } else if (element.componentName === 'heroBanner') {
              this.heroBannerComponent = true;
              this.heroBanner_ComponentData = element.data[0];
            } else if (element.componentName === 'Home_Testomnial') {
              this.testimonial_component = true;
              this.testimonial_componentData = element.data[0];
            } else if (element.componentName === 'Contactus-section') {
              this.contact_component = true;
              this.contact_componentData = element.data[0];
            } else if (element.componentName === 'Home-process-component') {
              this.process_component = true;
              this.process_componentData = element.data[0];
            }
          });
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        },
        (err) => {
          //console.log(err)
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        }
      );
    }
  }
  //go back button
  goBackHome() {
    this.router.navigate([``]);
    window.localStorage.clear();
  }

  //global Regeneration
  globalRegeneration() {
    this.emitterService.globalRegenerate.emit({ globalRegenerate: true });
    this.totalData.componentContentList.forEach((element: any) => {
      // if(element.componentName){
      let gx = 1;
      let n = element.data.length;
      gx = gx % n;
      // let  first_x_elements = this.totalData.slice(0,this.x);
      let first_x_elements = element.data.slice(0, gx);
      // let remaining_elements = this.totalData.slice(this.x,n);
      let remaining_elements = element.data.slice(gx, n);
      element.data = [...remaining_elements, ...first_x_elements];
      //console.log(n,gx,first_x_elements,remaining_elements);
      // }
    });
    if (window.localStorage.getItem('webId') === null) {
      let data = {
        componentContentList: this.totalData.componentContentList,
      };
      this.templateService.intiateTemplateData(data).subscribe(
        (res: any) => {
          this.totalData = res.data;
          window.localStorage.setItem('webId', res.data.id);
          res.data.componentContentList.forEach((element: any) => {
            if (element.componentName === 'service') {
              this.serviceComponent = true;
              this.service_ComponentData = element.data[0];
            } else if (element.componentName === 'blog') {
              this.blogComponent = true;
              this.blog_ComponentData = element.data[0];
            } else if (element.componentName === 'heroBanner') {
              this.heroBannerComponent = true;
              this.heroBanner_ComponentData = element.data[0];
            } else if (element.componentName === 'Home_Testomnial') {
              this.testimonial_component = true;
              this.testimonial_componentData = element.data[0];
            } else if (element.componentName === 'Contactus-section') {
              this.contact_component = true;
              this.contact_componentData = element.data[0];
            } else if (element.componentName === 'Home-process-component') {
              this.process_component = true;
              this.process_componentData = element.data[0];
            }
          });
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        },
        (err) => {
          //console.log(err)
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        }
      );
      this.emitterService.globalRegenerate.emit({ globalRegenerate: false });
    } else {
      let data = {
        templateId: window.localStorage.getItem('webId'),
        id: this.totalData.id,
        componentContentList: this.totalData.componentContentList,
      };
      this.templateService.updateTemplateData(data).subscribe(
        (res: any) => {
          this.totalData = res.data;
          window.localStorage.setItem('webId', res.data.id);
          res.data.componentContentList.forEach((element: any) => {
            if (element.componentName === 'service') {
              this.serviceComponent = true;
              this.service_ComponentData = element.data[0];
            } else if (element.componentName === 'blog') {
              this.blogComponent = true;
              this.blog_ComponentData = element.data[0];
            } else if (element.componentName === 'heroBanner') {
              // this.loader = true;
              // this.emitterService.componentLoader.emit({loading:true,name:'hearoBanner'})
              this.heroBannerComponent = true;
              this.heroBanner_ComponentData = element.data[0];
              // this.loader = false;
              // this.emitterService.componentLoader.emit({loading:false,name:'hearoBanner'})
            } else if (element.componentName === 'Home_Testomnial') {
              this.testimonial_component = true;
              this.testimonial_componentData = element.data[0];
            } else if (element.componentName === 'Contactus-section') {
              this.contact_component = true;
              this.contact_componentData = element.data[0];
            } else if (element.componentName === 'Home-process-component') {
              this.process_component = true;
              this.process_componentData = element.data[0];
            }
          });
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        },
        (err) => {
          //console.log(err)
          this.loader = false;
          this.emitterService.componentLoader.emit({ loading: false });
        }
      );
      this.emitterService.globalRegenerate.emit({ globalRegenerate: false });
    }
  }
  updateGlobalColor() {
    this.totalData.componentContentList.forEach((element: any) => {
      element.data.forEach((data: any) => {
        // //console.log(data);
        Object.keys(data).forEach((o1) => {
          if (o1 === 'textStyles') {
            data[o1] = 'red';
          } else {
            this.getAllKeyValues(o1, data[o1]);
          }
        });
      });
    });
  }
  getAllKeyValues(key: any, values: any) {
    if (Array.isArray(values)) {
      this.checkingForArray(values);
    } else {
      if (typeof values === 'object') {
        Object.keys(values).forEach((o2) => {
          if (o2 === 'textStyles') {
            Object.keys(values[o2]).forEach((ts) => {
              if (ts === 'background-color') {
                values[o2][ts] = 'red';
              }
            });
          } else {
            this.getAllKeyValues(o2, values[o2]);
          }
        });
      }
    }
  }
  checkingForArray(array: any) {
    array.forEach((a1: any) => {
      Object.keys(a1).forEach((o3: any) => {
        if (o3 === 'textStyles') {
          Object.keys(a1[o3]).forEach((ts) => {
            if (ts === 'background-color') {
              a1[o3][ts] = 'red';
            }
          });
        } else {
          this.getAllKeyValues(o3, a1[o3]);
        }
      });
    });
    //console.log(this.totalData);
  }

  shareWebsite() {
    this.templateService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
      webId: this.querywebId,
    });
  }

  createYourOwn() {
    this.router.navigate(['/introduction']);
  }
  signUp() {
    // this.templateService.openDialog(SigninSignupComponent, '94%', '1238px', {
    //   webId:this.querywebId
    //  });
    this.router.navigate(['/signup']);
  }
  openRestyleComponent() {
    this.restyleComponent = true;
  }
  changeFontFamily() {
    this.globalStyles.textFamily['font-family'] = this.selectingFontFamily;
  }
}
