import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-our-contact-us',
  templateUrl: './our-contact-us.component.html',
  styleUrls: ['./our-contact-us.component.scss']
})
export class OurContactUsComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<OurContactUsComponent>,
  ){}

  ngOnInit(){
  }

  close(){
    this.dialogRef.close();
  }
}
