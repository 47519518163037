<div class="termsandconditons">
    <div class="top-navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </div>
    <div class="terms-main-section">
        <div class="page-heading">Privacy Policy</div>
        <hr class="hr-line">
        <div class="index-terms">
            <div class="index-sec">
                <div class="index-values">
                    <div class="index-sec-heading">Section</div>
                    <ul class="index-ul">
                        <li *ngFor="let item of indexSection" class={{item?.value}} class="index-li" (click)="scrollTo(item.value)">
                            <a class="index-name">{{item?.indexName}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="vert-line"></div>
            <div class="terms-sec">
                <section id="INFORMATION">
                    <div class="terms-conditions">
                        <div class="terms-sec-heading">How we use the website information</div>
                        <div class="terms-sec-description">We use the Website Information to provide, improve, maintain and evaluate the Service, including as follows:</div>
                        <div class="terms-sec-description">
                            To understand or analyze how you use the Service and how we might improve or change them.
                        </div>
                        <div class="terms-sec-description">
                            To verify your identity and establish your account.
                        </div>
                        <div class="terms-sec-description">
                            To send you updates, emails, newsletters and other information that may be of interest to you.
                        </div>
                        <div class="terms-sec-description">
                            To send ads to you when you are on the Service or visit other websites (or other media channels and applications); create, tailor, deliver and study the effectiveness of advertising and promotional material both on and off of the Service; and to perform other analytics
                        </div>
                    </div>
                </section>
                <section id="PROFILES">
                    <div class="terms-conditions">
                        <div class="terms-sec-heading">Information in business profiles collected</div>
                        <div class="terms-sec-description">
                            Acquire Lead collects from Business Profiles: Name, Email Addresses, Phones, Social profiles (id, url, name, job title, company, location, skills …).
                        </div>
                        <div class="terms-sec-description">
                            We collect information from the following third-party sources:
                        </div>
                        <div class="terms-sec-description">
                            third parties who license, sell or otherwise provide data they have collected; or
                        </div>
                        <div class="terms-sec-description">
                            (ii) information from publicly available sources, such as via the Internet and social networks, including through public. For example, we use open web services and APIs to complete and update the information we have about Contacts and enrich it (e.g. by adding links to Contacts' verified social network profiles), 
                            in order to allow our Users and vendors to find relevant Business Profiles.
                        </div>
                    </div>
                </section>
                <section id="SHARING">
                    <div class="terms-conditions">
                        <div class="terms-sec-heading">How we share your Information</div>
                        <div class="terms-sec-description">
                            We share Business Profiles with our users, trusted vendors, and business partners. Specifically, 
                            we may disclose to them (or permit their access to) Business Profiles retained in the Acquire Lead B2B Database for the purposes of ensuring that their prospective or existing Business Profiles are authentic, current, and up to date.    
                        </div>
                        <div class="terms-sec-description">
                            At any time, you may decide to opt-out from enabling us to disclose or allow access to your Business Profile 
                            information stored on the Acquire Lead B2B Database by filling in your relevant details here.
                        </div>
                    </div>
                </section>
                <section id="PRIVACY">
                    <div class="terms-conditions">
                        <div class="terms-sec-heading">Children’s privacy</div>
                        <div class="terms-sec-description">
                            Acquire Lead does not knowingly collect information from children under the age of 16. If you are under the age of 16, please do not submit any personal data through the Platform. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy 
                            Policy by instructing their children never to provide personal data on our Platform without their permission.
                        </div>
                        <div class="terms-sec-description">
                            If you have reason to believe that a child under the age of 16 has provided personal data to Acquire Lead through the Platform, 
                            please contact privacy@acquirelead.com and we will endeavor to delete that information from our databases.
                        </div>
                    </div>
                </section>
                <section id="PAYMENT">
                    <div class="terms-sec-heading">Payment Processing</div>
                    <div class="terms-sec-description">
                        We collect payments from Customers only and do not directly collect or store payment information. 
                        We use third-party, PCI-compliant, payment processors, which collect payment information on our behalf in order to 
                        complete transactions with our Customers. While our administrators are able to view and track actual transactions via the client portals of the payment processors, we do not have access to, or process, credit card information.
                    </div>
                </section>
                <section id="SOCIAL">
                    <div class="terms-sec-heading">Social Media</div>
                    <div class="terms-sec-description">
                        We use social media plug-ins (e.g., Facebook, Twitter). These features may collect your IP address and/or other information as set forth in each social platform's privacy policy, which page you are visiting on our Site, and may set a cookie to enable the feature to function properly. Acquire Lead also has a presence on some social media platforms, including Twitter and LinkedIn. Through our accounts on social medial platforms, we may in some instances collect personal information when you interact with the Acquire Lead accounts or otherwise communicate with us through those accounts. Any information that you post on social media is governed by each social platform's privacy notices, and any personal 
                        information that we collect via our social media accounts will be processed in accordance with this Privacy Policy.
                    </div>
                </section>
                <section id="SECURITY">
                    <div class="terms-sec-heading">Data security and Integrity</div>
                    <div class="terms-sec-description">
                        We take steps that are reasonably necessary to securely provide our Platform. We have put in place reasonably appropriate security measures designed to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. We limit access to personal data only to those employees, 
                        agents, contractors and the third parties who have a business need-to-know.
                    </div>
                    <div class="terms-sec-description">
                        We also have procedures in place to deal with any suspected data security breach. If required, we will notify you and any applicable regulator of a suspected data security breach. 
                        We also require those parties to whom we transfer your personal information to provide acceptable standards of security.
                    </div>
                    <div class="terms-sec-description">
                        Not with standing, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Platform may not be secure. 
                        Therefore, take special care in deciding what information you send to us via email.
                    </div>
                </section>
                <section id="RESTRICTION">
                    <div class="terms-sec-heading">Additional Restrictions </div>
                    <div class="terms-sec-description">
                        Notwithstanding anything else in this Privacy Policy, if you provide Acquire Lead access to your email account, for the purpose of using our email composing features, the following types of your Google data or Microsoft data will be subject to these additional restrictions:
                    </div>
                    <div class="terms-sec-description">
                        Acquire Lead will only use access to read, write, modify, or control email message bodies, metadata, headers, and settings to enable End Users to compose and process emails and will not transfer this data to others unless doing so is 
                        necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets. We will not use this data for serving advertisements, selling data, or any other purpose except as set hereinabove or as otherwise permitted in line with Customer-/End User-specific consent.
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>