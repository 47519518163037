<section class="main-banner" id="banner_bg_image_image" [ngStyle]="{'background-image': data.bgImg === '' ? 'url(./../../../../assets/images/hero_banner/hero-banner-card.svg)' : 'url( '+ data.bgImg + ')'}"
*ngIf="!loader && !sectionLoading">
    <div class="left-checks">
        <div class="small-heading" [id]="data.smallText.id" [ngStyle]="data.smallText.textStyles">
            {{data.smallText.text | titlecase}}
        </div>
        <div class="big-heading" [id]="data.bigText.id" [ngStyle]="data.bigText.textStyles">
            {{data.bigText.text.replace('[Company Name]', 'Lets Mobility') | titlecase}}
        </div>
        <div class="banner-button" [id]="data.buttonText.id">
            <button [ngStyle]="data.buttonText.textStyles">
                {{data.buttonText.text | titlecase}}
            </button>
        </div>
    </div>
</section>
<section *ngIf="loader" class="loader">
    <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div>
</section>
<section *ngIf="sectionLoading" class="loader">
    <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
    </div>
    <div class="text">
        Getting beautiful hero banner for you
    </div>
</section>