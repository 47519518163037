import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit{

  current :any = 'ACCOUNT'

  constructor() { }
  ngAfterViewInit(): void {
    const sections = document.querySelectorAll('section');
    const leftSec = document.querySelectorAll('.index-sec ul li');
    leftSec[0].classList.add('active');

    // //console.log(leftSec);
    window.addEventListener('scroll', () => {
      // let current = 'ACCOUNT';
      
      sections.forEach(element => {
        const sectionTop = element.offsetTop;
        const sectionHeight = element.clientHeight;
        if(window.pageYOffset >= (sectionTop - sectionHeight / 3)){
          this.current = element.getAttribute('id');
        }
      });
      leftSec.forEach(li => {
        li.classList.remove('active');
        if(li.classList.contains(this.current)){
          li.classList.add('active')
        }
      });
      if((window.innerHeight + window.scrollY) >= document.body.scrollHeight){
        let lastIndex = leftSec.length-1;
        let lastSecondIndex = leftSec.length-2;
        leftSec[lastIndex].classList.add('active');
        leftSec[lastSecondIndex].classList.remove('active');
      }
    })
  }

  ngOnInit(): void {
      this.current = 'ACCOUNT';
  }

  indexSection = [
    {
      indexName:'How We Use the Website Information',
      value:'INFORMATION'
    },
    {
      indexName:'Information in Business Profiles collected',
      value:'PROFILES'
    },
    {
      indexName:'How we share your information',
      value:'SHARING'
    },
    {
      indexName:'Children’s Privacy',
      value:'PRIVACY'
    },
    {
      indexName:'Payment Processing',
      value:'PAYMENT'
    },
    {
      indexName:'Social Media',
      value:'SOCIAL'
    },
    {
      indexName:'Data Security and Integrity',
      value:'SECURITY'
    },
    {
      indexName:'Additional Restrictions',
      value:'RESTRICTIONS'
    }
  ];
  scrollTo(item:any){
    let el = document.getElementById(item);
    if(!el) return;
    el.scrollIntoView()
  }

}
