import { Component } from '@angular/core';

@Component({
  selector: 'app-footer4',
  templateUrl: './footer4.component.html',
  styleUrls: ['./footer4.component.scss' , './mq-footer4.component.scss']
})
export class Footer4Component {

  templateData: any = {
    'componentCategory': "footer",
    'ranking': 0,
    'businessCategoryId': "",
    "businessCategoryName": "All category",
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "Footer4Component",
    "data" : [
      {
        "value":"About Beeos",
        "type":"text",
        "field":"text",
        "style":{"font-size":"22px", "font-weight":400},
        "textType":"heading"
      },
      {
        "value":"We provide end to end product development services. We help you shape the perfect strategies, satisfying your goals with innovative ideas and thus empowering your business with best tech solutions. We work with clients to maximize the effectiveness of their business through digital technology.",
        "type":"text",
        "field":"text",
        "style":{"font-size":"14px","padding": "3% 0px"},
        "textType":"description"
      },
      {
        "imgUrl":"",
        "type":"image",
        "field":"image",
        "style":{},
        "textType":"image"
      },
      {
        "imgUrl":"",
        "type":"image",
        "field":"image",
        "style":{},
        "textType":"image"
      },
      {
        "imgUrl":"",
        "type":"image",
        "field":"image",
        "style":{},
        "textType":"image"
      },
      {
        "imgUrl":"",
        "type":"image",
        "field":"image",
        "style":{},
        "textType":"image"
      },
      {
        "value":"© Beeos. All Rights Reserved.",
        "type":"text",
        "field":"text",
        "style":{"padding": "3% 0px"},
        "textType":"text"
      },
      {
        "value":"Find Out",
        "type":"text",
        "field":"text",
        "style":{"font-size":"22px", "font-weight":400, "padding-left": "18px"},
        "textType":"heading"
      },
      {
        "value":"Services",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px" , "font-weight": "400"},
        "textType":"text"
      },
      {
        "value":"Portfolio",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px"},
        "textType":"text"
      },
      {
        "value":"Casestudy",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px"},
        "textType":"text"
      },
      {
        "value":"About Us",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px" , "font-weight": "400" },
        "textType":"text"
      },
      {
        "value":"Careers",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px"},
        "textType":"text"
      },
      {
        "value":"Blog",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px" ,"font-weight": "400"},
        "textType":"text"
      },
      {
        "value":"Contact Us",
        "type":"text",
        "field":"text",
        "style":{"font-size":"22px", "font-weight":400},
        "textType":"heading"
      },
      {
        "value":"(+91)1234567890",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px","padding": "3% 0px 2%" , "font-weight": "400"},
        "textType":"number"
      },
      {
        "value":"abc@abc.com",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px", "padding": "2% 0px" , "font-weight": "400"},
        "textType":"text"

      },
      {
        "value":"Plot No. 3-A, Savitri Ashirwad Building, Beside Times House, Navodaya CO-OP, Rd No 3, Banjara Hills Hyderabad 500034",
        "type":"text",
        "field":"text",
        "style":{"font-size":"16px", "padding": "2% 0px" , "font-weight": "400"},
        "textType":"text"
      }
    ]
  }

  
  data = this.templateData.data
  aboutBeeos =  this.data[0]
  description = this.data[1]
  facebookIcon = this.data[2]
  twitterIcon = this.data[3]
  linkedInIcon = this.data[4]
  copyRightsIcon = this.data[5]
  copyRightsText = this.data[6]
  findOutText = this.data[7]
  servicesText = this.data[8]
  portfolioText = this.data[9]
  caseStudyText = this.data[10]
  aboutUsText = this.data[11]
  careersText = this.data[12]
  blogText = this.data[13]
  contactUsText = this.data[14]
  mobileNumber = this.data[15]
  emailAddress = this.data[16]
  address = this.data[17]

}
