import { Component, HostListener, Input } from '@angular/core';
import { EventEmmiterService } from './../../../services/event-emmiter.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  @Input() pageData:any;
  contactUs : any

  constructor(
    private emitterService : EventEmmiterService
  ){}

  loader : boolean = false;
  sectionLoading : boolean = false;

  scrHeight : any;
  scrWidth : any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // //console.log(this.scrHeight, this.scrWidth);
  }
  coloumnsLength : any;

  ngOnInit(){
    if(this.scrWidth > 475){
      this.coloumnsLength = 101
    }
    else{
      this.coloumnsLength = 40
    }
    this.contactUs = this.pageData

    this.emitterService.loadingSections.subscribe(
      (res) =>{
        if(res.lastComponent === 'Home_Testomnial'){
          let a = document.getElementById('contact_us_section');
          a?.scrollIntoView({behavior:'smooth'})
          this.sectionLoading = true;
          setTimeout(() => {
            this.sectionLoading = false;
            // this.emitterService.activateComponent.emit({nextComponent:'Contactus-section',loading:false})
          }, 5000);
        }
        // //console.log(res)
      }
    )

    this.emitterService.componentLoader.subscribe(
      (res)=>{
        // this.loader = res.loading;
        if(res.name === 'Contactus-section'){
          this.loader = res.loading;
        }
        else{
          this.loader = false;
        }
      }
    )
  }

  // contactUs ={
  //   contactDescription:{
  //     text:'We are construction business offering a wide range of services. contact us today to your project needs',
  //     textStyles:{
  //       'font-size':'14px',
  //       'font-weight':'500',
  //       'color':'#434443',
  //       'line-height':'26px',
  //       'font-style':'normal',
  //       'font-family':"'Lexend Deca', sans-serif"
  //     }
  //   },
  //   className : 'normal',
  //   buttonText:{
  //     text:'send',
  //     textStyles:{
  //       'font-size':'14px',
  //       'font-weight':'500',
  //       'color':'white',
  //       'line-height':'26px',
  //       'font-style':'normal',
  //       'font-family':"'Lexend Deca', sans-serif",
  //       'background-color':'#faba6c'
  //     }
  //   }
  // }

}
