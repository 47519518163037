import { Component, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../webisite.service';
import { ConnectExistingDomainComponent } from '../popups/connect-existing-domain/connect-existing-domain.component';
import { ListSearchedDomainsComponent } from '../popups/list-searched-domains/list-searched-domains.component';
import { environment } from 'src/environments/environment';
import { UpgradePlanComponent } from '../popups/upgrade-plan/upgrade-plan.component';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', './mq-settings.component.scss']
})
export class SettingsComponent implements OnInit{

  color : any = '#0099FF';
  websiteStatus : any = '';
  searchIndex : any = 'ENABLE';
  domainUrl : any = '';
  businessDetails : any;
  pageLoader : boolean = false;

  constructor(
    private templateService : TemplateService,
    private _websiteService : WebisiteService,
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _snackBar : MatSnackBar
  ){}

  ngOnInit(): void {
    this.templateService.scrollToComponent.subscribe(
      (res:any)=>{
        //console.log(res.goTo);
        let a = document.getElementById(`${res.goTo}`);
        if(!a) return;
        a.scrollIntoView({behavior:'smooth'});
      }
    )
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.getBusinessDetailsByBusinessId();
    }
  }

  webStatus = [
    {
      name:'public',
      message:'Anyone can see the website',
      status:true,
      value:'PUBLISHED'
    },
    {
      name:'Private',
      message:'Only you can see the website',
      status:false,
      value:'PRIVATE'
    },
    {
      name:'Disabled',
      message:'The website and its features are disabled',
      status:false,
      value:'DISABLED'
    }
  ]
  searchIndexing = [
    {
      name:'Enable',
      message:'All search tools can index your website',
      status:true,
      value:'ENABLE'
    },
    {
      name:'Diable',
      message:'No search tools can index your website',
      status:false,
      value:'DISABLE'
    }
  ];

  getBusinessDetailsByBusinessId(){
    this.pageLoader = true;
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res:any) => {
        // //console.log(res.data);
        this.webStatus.forEach((status:any) => {
          if(status.value === res.data.website.status){
            this.websiteStatus = status.value;
          }
        });
        // //console.log(this.websiteStatus)
        this.domainUrl = res.data.website.websiteUrl.split('//')[1].split('.')[0];
        if(this.linkingDomain != null){
          this.linkingDomain = res.data.website.domainUrl.split('//')[1];
        }
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  changeWebsiteStatus(ev:any){
    // //console.log(ev.value)
    this.pageLoader = true;
    let data = {
      bId:this.businessDetails.id,
      status : ev.value
    }
    this._websiteService.updateWebsiteStatus(data).subscribe(
      (res:any) =>{
        this.webStatus.forEach((status:any) => {
          if(status.value === res.data.status){
            this.websiteStatus = status.value;
          }
        });
        // //console.log(this.websiteStatus)
        this.domainUrl = res.data.websiteUrl.split('//')[1].split('.')[0];
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  updateWebsiteUrl(){
    this.pageLoader = true;
    let data = {
      businessId : this.businessDetails.id,
      websiteUrl : 'https://'+this.domainUrl+`.${environment.urlEndingPoint}`
    }
    this._websiteService.updateWebsiteUrl(data).subscribe(
      (res:any) => {
        //console.log(res.data)
        this.templateService.openSnack('Website Url Updated','Ok')
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }


  //search custom domain

  domainName = '';

  searchDomain(){
    this.templateService.openDialog(
      ListSearchedDomainsComponent,
      '530px',
      '530px',
      this.domainName
    );
  }

  //link existing domain
  linkingDomain:any;
  loader : boolean = false;
  linkDomain(){
    this.loader = true;
    this._paymentService.subscribedOrNot(this._dbService.getBusinessId(),false).subscribe(
      (res:any) => {
        // console.log(res.data);
        if(res.data.subscriptions === null || !res.data.subscriptions.active){
          let popup = this.templateService.openDialog(
            UpgradePlanComponent,
            'auto',
            '630px',
            '80vw'
          )
        }
        else{
          let popup = this.templateService.openDialog(
            ConnectExistingDomainComponent,
            'auto',
            '540px',
            '80vw'
          )
        }
        this.loader = false;
      },
      (err) => {
        this._snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
        this.loader = false;
      }
    )
    // let data = {
    //   businessId : this.businessDetails.id,
    //   domainUrl : 'https://'+this.linkingDomain
    // }
    // this._websiteService.linkExistingDomain(data).subscribe(
    //   (res:any) => {
    //     //console.log(res.data);
    //   },
    //   (err) => {
    //     //console.log(err.error);
    //   }
    // )
  }

  searchDomainthroughGodaddy(){
    // window.location.href = `https://www.secureserver.net/?prog_id=589411`;
    let url = 'https://www.secureserver.net/?prog_id=589411';
    let win = window.open(url,'_blank');
    win?.focus();
  }
}
