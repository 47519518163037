import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from './../../../services/template.service';

@Component({
  selector: 'app-topnavbar1',
  templateUrl: './topnavbar1.component.html',
  styleUrls: ['./topnavbar1.component.scss'],
})
export class Topnavbar1Component {
  @Input() data: any = '';
  navId: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private templatesService: TemplateService,
    private router: Router
  ) {}

  ngOnInit() {
    try {
      this.data.header.logo.textStyles = { border: '2px solid #F79824' };
      this._activatedRoute.queryParams.subscribe((params: any) => {
        //console.log(params);
        this.navId = params.elem;
      });
      //console.log('data', this.data);
      this.templatesService.updateElementsData.subscribe((res: any) => {
        //console.log('updateElementsData', res);
        this.data = res;
      });
    } catch (err) {
      //console.log('Something went wrong', err);
    }
  }
  headerBtnEvent(btnDetails: any) {
    //console.log('btn details', btnDetails);
    this.router.navigate(['/' + btnDetails.route]);
  }
}
