import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-hover-horizontal-service-section',
  templateUrl: './hover-horizontal-service-section.component.html',
  styleUrls: ['./hover-horizontal-service-section.component.scss' , './mq-hover-horizontal-service-section.component.scss']
})
export class HoverHorizontalServiceSectionComponent implements OnInit{

  @Input() pageData:any;
  @Input() templateData:any;
  componentData:any=null;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        // //console.log("value check",value);
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'hover_horizontal_service_section'){
            this.templateData=conCompList;
            this.dataAssign();
          }
          else{}
        });
        // this.dataAssign();     
      }
    })
    this.dataAssign();
  }


  // templateData: any = {
  //   'componentCategory': "service",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "ServiceComponent",
  //   "data" : [
  //     {
  //       "value":"Our Industry Experience",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"color": "rgba(32,57,110,1)", "font-weight": 700}
  //     },
  //     {
  //       "value":"We provide industry-specific solutions to help enterprises and fast-growing organizations succeed in today's digital-first world.",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"color": "rgba(41,44,48,1)"}
  //     },
  //     {
  //       "value":"Banking",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "value":"Explore More >>",
  //       "type":"Text",
  //       "field":"Text",
  //       "display": false,
  //       "style":{"text-align":"left","color": "rgb(237, 229, 67)"}
  //     },
  //     {
  //       "value":"Insurance",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "value":"Financial Service",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "value":"Health Care",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "value":"Ed-Tech",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "value":"Others",
  //       "type":"Text",
  //       "field":"Text",
  //       "style":{"font-weight":500}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/banking.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/insurance.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/financial-service.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     }
  //   ]
  // }

  // data = this.templateData.data
  data:any;
  // heading = this.data[0]
  // description = this.data[1]
  // banking = this.data[2]
  // exploreMore = this.data[3]
  // insurance = this.data[4]
  // financialService = this.data[5]
  // healthCare = this.data[6]
  // edTech = this.data[7]
  // others = this.data[8]

  valuesData:any;
  bgColor:any;
  dataAssign(){
    this.valuesData = this.templateData.values;
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.valuesData != null) {
      var bName = this._dbService.getBusinessName();
      this.data[0].value = this.valuesData.title.replace("{Company Name}", bName);

      this.data[9].imgUrl = this.valuesData.data[0].img;
      this.data[2].value = this.valuesData.data[0].text;
      this.data[3].value = this.valuesData.data[0].desc;

      this.data[10].imgUrl = this.valuesData.data[1].img;
      this.data[4].value = this.valuesData.data[1].text;
      this.data[12].value = this.valuesData.data[1].desc;

      this.data[11].imgUrl = this.valuesData.data[2].img;
      this.data[5].value = this.valuesData.data[2].text;
      this.data[13].value = this.valuesData.data[2].desc;

      // this.data[1].value[0].imageUrl = this.valuesData.data[0].img;
      // this.data[1].value[1].value = this.valuesData.data[0].text;
      // this.data[1].value[2].value = this.valuesData.data[0].desc;

      // this.data[2].value[0].imageUrl = this.valuesData.data[1].img;
      // this.data[2].value[1].value = this.valuesData.data[1].text;
      // this.data[2].value[2].value = this.valuesData.data[1].desc;

      // this.data[3].value[0].imageUrl = this.valuesData.data[2].img;
      // this.data[3].value[1].value = this.valuesData.data[2].text;
      // this.data[3].value[2].value = this.valuesData.data[2].desc;
    }
   


    // this.heading = this.valuesData.title
    // this.value=[
    //   {
    //  serviceImages:{
    //   img:this.valuesData.data[0].img
    //  },
    //  serviceTitle:{
    //   value:this.valuesData.data[0].text
    //  },
    //  serviceDes:{
    //   value:this.valuesData.data[0].desc
    //  }
    //   },
    //   {
    //     serviceImages:{
    //       img:this.valuesData.data[1].img
    //      },
    //      serviceTitle:{
    //       value:this.valuesData.data[1].text
    //      },
    //      serviceDes:{
    //       value:this.valuesData.data[1].desc
    //      }
    //   },
    //   {
    //     serviceImages:{
    //       img:this.valuesData.data[2].img
    //      },
    //      serviceTitle:{
    //       value:this.valuesData.data[2].text
    //      },
    //      serviceDes:{
    //       value:this.valuesData.data[2].desc
    //      }
    //   },
    //   ]
  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
