import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-simple-footer-get-in-touch',
  templateUrl: './simple-footer-get-in-touch.component.html',
  styleUrls: ['./preview-simple-footer-get-in-touch.component.scss']
})
export class PreviewSimpleFooterGetInTouchComponent {

    templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      {
        "imgUrl": "./../../../../../../../../assets/images/footer/simle_footer_touch.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "36px"}
      },
      {
        "value": "Algofusion",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "20px"}
      },
      {
        "value": "Overview",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Features",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Pricing",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Careers",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Help",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Privacy",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#475467"}
      },
      {
        "value": "Get in touch",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#101828","font-size":"14px","font-weight":600}
      },
      {
        "value": "Get in touch",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#fff","font-size":"16px","font-weight":600,"background-color":"#7F56D9"}
      },
      {
        "value":"© 2023 Algofusion. All rights reserved.",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#667085","font-size":"16px","font-weight":400}
      },
      {
        "value":"Terms",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#667085","font-size":"16px","font-weight":400}
      },
      {
        "value":"Privacy",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#667085","font-size":"16px","font-weight":400}
      },
      {
        "value":"Cookies",
        "type":"Text",
        "field":"Text",
        "style":{"color":"#667085","font-size":"16px","font-weight":400}
      }

    ]
  }

  data = this.templateData.data;
}
