<div
  class="success_stories_section"
  [ngStyle]="{ 'background-image': 'url(' + testimonials?.bgImg + ')' }"
  id="testimonial_bg_image_image"
  *ngIf="!loader && !sectionLoading"
>
  <div class="stories">
    <ngx-slick-carousel
      class="carousel carousel_box"
      [config]="slideConfig"
      #slickModal="slick-carousel"
    >
      <div
        ngxSlickItem
        class="single_success_story"
        *ngFor="let item of testimonials?.dataArray"
      >
        <div class="double_quotes">
          <img
            src="./../../../../assets/images/double_quotes.svg"
            alt="double_quotes"
          />
        </div>
        <div
          class="person_short_note"
          [id]="item.review.id"
          [ngStyle]="item.review.textStyles ? item.review.textStyles : {}"
        >
          {{ item?.review?.text.replace("[Company Name]", "Lets Mobility") }}
        </div>
        <div class="reviewer-company">
          <div
            class="person_name"
            [id]="item.reviewedby.id"
            [ngStyle]="
              item.reviewedby.textStyles ? item.reviewedby.textStyles : {}
            "
          >
            {{ item?.reviewedby?.text }}
          </div>
          <div
            class="person_designation"
            [id]="item.position.id"
            [ngStyle]="item.position.textStyles ? item.position.textStyles : {}"
          >
            &nbsp;{{ item?.position?.text }}
          </div>
          <div
            [id]="item.companyName.id"
            [ngStyle]="
              item.companyName.textStyles ? item.companyName.textStyles : {}
            "
          >
            &nbsp; - {{ item?.companyName?.text }}
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
<section *ngIf="loader" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</section>
<section *ngIf="sectionLoading" class="loader">
  <div class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
  <div class="text">Generating best testimonials</div>
</section>
