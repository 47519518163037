import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-preview-our-success-story',
  templateUrl: './our-success-story.component.html',
  styleUrls: ['./preview-our-success-story.component.scss']
})
export class PreviewOurSuccessStoryComponent {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    // this.getScreenWidth();
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        //console.log("value check",value);
        this.templateData=value;       
      }
    })
    this.dataAssign(); 
  }
  @Input() templateData:any;
 data=[
    {
      "imageUrl": "",
      "style": {},
      "display": true,
      "type": "image",
      "field": "Background-image"
    },
    {
      "value": "our",
      "style": {},
      "field": "first Title",
      "type": "text"
    },
    {
      "value": "Success",
      "style": {},
      "field": "second Title",
      "type": "text"
    },
    {
      "value": "Here are some of our company accomplishment. Aliquam lorem ante, dapibus in viverra quis, feugiat atellus. Phasellus viverra nulla ut metus varius lallus.",
      "style": {},
      "field": "subText",
      "type": "text"
    },
    {
      "value": [
        {
          "imageUrl": "./../../../../../../../../assets/images/components/progress_bar_1.svg",
          "type": "image",
          "field": "image"
        },
        {
          "value": "5987",
          "type": "text",
          "field": "count"
        },
        {
          "value": "projects Done",
          "type": "text",
          "field": "text"
        }
      ],
      "type": "array"
    },
    {
      "value": [
        {
          "imageUrl": "./../../../../../../../../assets/images/components/progress_bar_2.svg",
          "type": "image",
          "field": "image"
        },
        {
          "value": "9866",
          "type": "text",
          "field": "count"
        },
        {
          "value": "qualified staff",
          "type": "text",
          "field": "text"
        }
      ],
      "type": "array"
    },
    {
      "value": [
        {
          "imageUrl": "./../../../../../../../../assets/images/components/progress_bar_3.svg",
          "type": "image",
          "field": "image"
        },
        {
          "value": "7644",
          "type": "text",
          "field": "count"
        },
        {
          "value": "machineries",
          "type": "text",
          "field": "text"
        }
      ],
      "type": "array"
    }
  ]


  bgImage:any;
  firstTitle:any;
  secondTitle:any;
  subText:any;
  card1:any;
  card2:any;
  card3:any;

  dataAssign(){
    // this.templateData.componentContentList.forEach((value:any) => {
    //   if(value.componentName=='Our-success-story'){
    //     this.componentData=value;
    //     this.data=value.data;
    //   }
    // });
    //console.log(this.data);
    this.bgImage=this.data[0];
    this.firstTitle=this.data[1];
    this.secondTitle=this.data[2];
    this.subText=this.data[3];
    this.card1=this.data[4].value;
    this.card2=this.data[5].value;
    this.card3=this.data[6].value;
  }

}
