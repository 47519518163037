import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/services/db-service/db.service";
import { TemplateService } from "src/app/services/template.service";
import { WebisiteService } from "../../../../webisite.service";

@Component({
  selector: 'app-single-page-double-testimomial',
  templateUrl: './single-page-double-testimomial.component.html',
  styleUrls: ['./single-page-double-testimomial.component.scss' , './mq-single-page-double-testimomial.component.scss']
})
export class SinglePageDoubleTestimomialComponent implements OnInit{

  @Input() templateData:any;
  @Input() color:any;
  valuesData:any;
  componentData: any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit() :void {
    // //console.log(this.data,this.data.length);
    // //console.log(this.componentName);
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        //console.log("value check",value);
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'double_item_testimonial'){
            this.templateData=conCompList;  
            this.dataAssign();
          }
          else{
            
          }
        });
      }
    });
    this.dataAssign(); 
  }

  // templateData:any = {
  //   "componentCategory" : "testimonial",
  //   "ranking" : 0,
  //   "businessCategoryId" : "",
  //   "businessCategoryName" : "All category",
    // "data" : [
    //   {
    //     "value" : "3940+ Happy Landingfolio Users",
    //     "field" : "sub-heading",
    //     "type" : "sub-heading",
    //     "style" : {"font-size":"16px", "color":"blue","font-weight":600, "margin-bottom":"25px"},
    //     "textType" : "description"
    //   },
    //   {
    //     "value" : "Don't just take our words",
    //     "field" : "heading",
    //     "type" : "heading",
    //     "style" : {"font-size":"42px", "font-weight":900, "margin-bottom":"80px"},
    //     "textType" : "heading"
    //   },
    //   {
    //     "imgUrl" : [
    //     {
    //       "imgUrl" : "./../../../../../../../../assets/images/testimonials/profile-img.jpg",
    //       "display" : true,
    //       "type" : "image",
    //       "field" : "image",
    //       "style" : {},
    //       "textType" : "image"
    //     },
    //     {
    //       "imgUrl" : "./../../../../../../../../assets/images/testimonials/person2.jpg",
    //       "display" : true,
    //       "type" : "image",
    //       "field" : "image",
    //       "style" : {},
    //       "textType" : "image"
    //     }
    //     ],
    //     "display" : true,
    //     "type" : "array",
    //     "field" : "image",
    //     "style" : { "width":"200px","height":"200px","border-radius":"25px"}
    //   },
    //   {
    //     "value":[
    //       {
    //         "value" : "'We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want'",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {"font-size":"18px","color":"#000000", "text-align":"justify"},
    //         "textType" : "text"
    //       },
    //       {
    //         "value" : "'We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want'",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {"font-size":"18px","color":"#000000", "text-align":"justify"},
    //         "textType" : "text"
    //       }
    //     ],
    //       "type":"array",
    //       "field" : "text",
    //       "style" :{"font-size":"14px", "font-weight":600,"margin-right":"10px"}
    //   },
    //   {
    //     "value" : [
    //       {
    //         "value": "Jenny Wilson",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {},
    //         "textType" : "text"
    //       },
    //       {
    //         "value": "Devan Lane",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {},
    //         "textType" : "text"
    //       }
    //     ],
    //       "type":"array",
    //       "field" : "names",
    //       "style" :{"font-size":"14px", "font-weight":600,"margin-right":"10px"}
    //   },
    //   {
    //     "value" : [
    //       {
    //         "value": "Grower.io",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {},
    //         "textType" : "text"
    //       },
    //       {
    //         "value": "DLDesign.co",
    //         "field" : "text",
    //         "type" : "text",
    //         "style" : {},
    //         "textType" : "text"
    //       }
    //     ],
    //     "field" : "array",
    //     "type" : "designation",
    //     "style" : {"font-size":"14px", "color":"#959393"}
    //   }
    // ]
  //   "createdTimeStamp" : "",
  //   "language" : "English",
  //   "componentName" : "Testimonial1Component"
  // }

  componentName : any;
  data : any 
  heading:any
  subtext:any;
  firstReview : any;
  secondReview : any;
  // personImg : any;
  // person :any;
  // text:any;
  // personDesignation:any;
  bgColor: any ;

  dataAssign(){
    // //console.log(this.valuesData);
    // this.componentName= this.templateData.data;
    this.data = this.templateData.data;
    let bName = this._dbService.getBusinessName();
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    
    // this.heading =this.valuesData.title;
    // this.subtext = this.valuesData.subTitle;
    // this.firstReview = this.valuesData.data[0];
    // this.secondReview = this.valuesData.data[1];
    // this.personImg =this.data[2];
    // this.person= this.data[4];
    // this.text = this.data[3];
    // this.personDesignation = this.data[5];
    if(this.templateData.values != null){
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.subTitle;
      this.data[1].value = this.valuesData.title.replaceAll("{Company Name}",bName);
      this.data[2].value[0].imageUrl = this.valuesData.data[0].reviewerImage;
      this.data[3].value[0].value = this.valuesData.data[0].text.replaceAll("{Company Name}",bName);
      this.data[4].value[0].value = this.valuesData.data[0].reviewedBy;
      this.data[5].value[0].value = this.valuesData.data[0].reviewerDesignation;
      this.data[2].value[1].imageUrl = this.valuesData.data[1].reviewerImage;
      this.data[3].value[1].value = this.valuesData.data[1].text.replaceAll("{Company Name}",bName);
      this.data[4].value[1].value = this.valuesData.data[1].reviewedBy;
      this.data[5].value[1].value = this.valuesData.data[1].reviewerDesignation;
      // this.data[6].value[0].value = this.valuesData.data[0].desc.replaceAll("{Company Name}",bName);;
      this.data[6].value[0].value = this.valuesData.data[0].desc.replaceAll("{Company Name}",bName);
      this.data[6].value[1].value = this.valuesData.data[1].desc.replaceAll("{Company Name}",bName);
    }
    else{}
  }

  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
