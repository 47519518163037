<section class="norma_type">
    <section class="main_section normal">
        <div class="left_container">
            <div class="business_name" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value | titlecase}}</div>
            <div class="short_note" [ngStyle]="data[1].style?data[1].style:{}">{{data[1].value}}</div>
        </div>
        <div class="right_container">
            <!-- <div class="social_icons"> -->
                <ul class="social_icons">
                    <li class="nav-item"><a [href]="data[3].value"  class="nav-link" *ngIf="data[2].status === true || data[2].status === 'true'">
                        <img [src]="data[2].imgUrl" alt="instagram" class="social-icons-img">
                    </a></li>
                    <li class="nav-item"><a  class="nav-link" [href]="data[5].value" *ngIf="data[4].status === true || data[4].status === 'true'">
                        <img [src]="data[4].imgUrl" alt="twitter" class="social-icons-img">
                    </a></li>
                    <li class="nav-item"><a  class="nav-link" [href]="data[7].value" *ngIf="data[6].status === true || data[6].status === 'true'">
                        <img [src]="data[6].imgUrl" alt="facebook" class="social-icons-img">
                    </a></li>
                </ul>
            <!-- </div> -->
        </div>
    </section>
    <div style="display:flex">
        <div class="copy_rights" [ngStyle]="data[0].style?data[0].style:{}" style="width:80%">
            {{data[8].value}}
        </div>
        <div class="powered_by">
            Powered by &nbsp;<a href="https://simpo.ai" style="color:white">simpo.ai</a>
        </div>
    </div>
</section>