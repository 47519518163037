<div class="main-section">
    <div  class="top-nav-bar-section">
        <app-home-nav-bar></app-home-nav-bar>
    </div>
    <div class="middle-section">
        <div class="heading-section">
            Our Pricing
        </div>
        <div class="sub-heading">
            Start building your dream site today. Try any plan with a 14-day money-back guarantee or start with a free plan!
        </div>
        <div class="button-section">
            <!-- <button class="savings-button">Annally</button>
            <button class="monthly-button">Monthly</button> -->
                <button *ngFor="let item of twoButtons" [ngClass]="{'activate': item.status}"
                (click)="planChange(item.value)">{{item.name | titlecase}}
                    <span class="offer_tag" *ngIf="item.value === 'ANNUALLY'">
                        <img src="./../../../assets/images/common/offer_tag.svg" alt="offer_tag"> Get 2 months free
                    </span>
                </button>
        </div>
        <div class="dropdown business-field-section">
            <mat-form-field appearance="outline" class="sfull_width">
                <mat-select [(ngModel)]="selectedCountry" (selectionChange)="changeCountry($event)">
                  <mat-option *ngFor="let item of allCountries" [value]="item">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="total-box">
            <ng-container *ngFor="let item of allPlans">
                <div class="box-container-starter">
                    <div class="stater-section">
                        <div class="starter-plan">
                            <div class="colored-plan" *ngIf="item.prices.yearlyPrice != 0">{{item.plan.name | titlecase}}</div>
                            <div class="normal-plan" *ngIf="item.prices.yearlyPrice === 0">{{item.plan.name | titlecase}}</div>
                        </div>
                        <div class="monthly-section">
                            <!-- <div class="month" *ngIf="isAnually">&#8377;{{item.yearlyPrice}} / Anually</div> -->
                            <div class="month-starter" *ngIf="item.prices.monthlyPrice != 0 && isAnually">
                                <div [innerHTML]="item.prices.currencySymbol"></div>
                                <div>{{item.prices.yearlyPrice}}<span style="font-size:16px !important;color:#939393;font-weight: normal;margin-left: 3px;">Per Anually</span></div>
                            </div>
                            <div class="month-starter" *ngIf="item.prices.monthlyPrice != 0 && !isAnually">
                                <div [innerHTML]="item.prices.currencySymbol"></div>
                                <div>{{item.prices.monthlyPrice}}<span style="font-size:16px !important;color:#939393;font-weight: normal;margin-left: 3px;">Per Month</span></div>
                            </div>
                            <div class="month-starter" *ngIf="item.prices.monthlyPrice === 0"><div>
                                <div [innerHTML]="item.prices.currencySymbol"></div>
                                </div><div>0</div>
                            </div>
                            <!-- <div class="savings"> 
                                <span class="save">SAVE 20% &centerdot;</span>
                                <div class="bills">Billed annually</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="text-starter">{{item.plan.desc}}</div>
                    <div class="hr-line"></div>
                    <div class="list-container-starter">
                        <ng-container *ngFor="let list of item.plan.features">
                            <div class="listing">
                                <div class="image-container" *ngIf="item.prices.yearlyPrice != 0">
                                    <img src="../../../assets/images/colored_check_image.svg">
                                </div>
                                <div class="image-container1" *ngIf="item.prices.yearlyPrice === 0">
                                    <img src="./../../../assets/images/normal_check_image.svg">
                                </div>
                                <div class="list-name-starter">{{ list.name}}</div>
                            </div>
    
                        </ng-container>
                    </div>
                    <button [ngClass]="item.prices.monthlyPrice === 0 ? 'starter-btn' : 'get-start'" (click)="selectedPlan(item.plan.id,item.prices.monthlyPrice)">Get started</button>
                </div>
            </ng-container>
        </div>

    </div>
    <div class="testimonial_section">
        <div class="testimonial_heading">
            Startups & businesses love simpo.ai
        </div>
        <div class="sub_text">
            Join 1,00,000+ startups, entrepreneurs, freelancers, & influencers just like you.
        </div>
        <div class="list_testimonial">
            <ng-container *ngFor="let item of testimonials">
                <div class="single_testimonial">
                    <div class="image_container">
                        <img [src]="item.testimonialImage" alt="Image">
                    </div>
                    <div class="content">
                        {{item.review}}
                    </div>
                    <div class="image_reviewer_name">
                        <div class="reviewer_image">
                            <img [src]="item.reviewerImage" alt="reviewerImage">
                        </div>
                        <div class="reviewer_name">
                            {{item.reviwerName | titlecase}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="faq-section">
        <div class="faq-heading">
            Pricinig FAQ
        </div>
        <div class="faq-sub-text">
            Everything you need to know about the product and billing.
        </div>
        <cdk-accordion class="example-accordion">
            <cdk-accordion-item
              *ngFor="let item of faqQuestions; let index = index;"
              #accordionItem="cdkAccordionItem"
              class="example-accordion-item"
              role="button"
              tabindex="0"
              [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body-' + index">
              <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                {{ item.question}}
                <span class="example-accordion-item-description">
                    <img src="{{ accordionItem.expanded ? './../../../../assets/images/pricing/minus.svg' : './../../../../assets/images/pricing/plus.svg'}}" alt="">
                </span>
              </div>
              <div
                class="example-accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">
                {{item.answer}}
              </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>
    <div class="extra_section">
        <div class="heading_text">
            Still have questions?
        </div>
        <div class="sub_title_text">
            Can’t find the answer you’re looking for?  Our friendly team will help you.
        </div>
        <div class="contact_btn">
            <button (click)="contactUs()">Contact us</button>
        </div>
    </div>
    <div class="generate_website">
        <div class="inside_section">
            <div class="box_heading">
                Start generating your website today with Simpo.ai.
            </div>
            <div class="box_description">
                A fully functional website within just 3 clicks with AI. No credit card required.
            </div>
            <div class="generate-btn">
                <button (click)="generateWebsite()">Generate Website</button>
            </div>
        </div>
    </div>
    <div class="footer_section">
        <app-our-footer></app-our-footer>
    </div>
</div>
