import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-footer',
  templateUrl: './our-footer.component.html',
  styleUrls: ['./our-footer.component.scss','./mq-our-footer.component.scss']
})
export class OurFooterComponent implements OnInit{

  constructor(
    private _router : Router
  ){}

  ngOnInit(): void {
  }

  termsConditions(){
    this._router.navigate(['/terms-conditions'])
  }

  privacyPolicy(){
    this._router.navigate(['/privacy-policy'])
  }

  pricing(){
    this._router.navigate(['/pricing'])
  }

  socialLinks(ev){
    if(ev === 'INSTA'){
      window.open('https://www.instagram.com/simpo.ai/','_blank')
    }
    else if(ev === 'TWITTER'){
      window.open('https://twitter.com/simpowebbuilder','_blank')
    }
    else if(ev === 'FACEBOOK'){
      window.open('https://www.facebook.com/profile.php?id=100091262862601','_blank')
    }
  }

}
