import { Component } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-partner2',
  templateUrl: './partner2.component.html',
  styleUrls: ['./partner2.component.css']
})
export class Partner2Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }



  templateData:any={
    "componentCategory": "Partner",
    "ranking": 0,
    "businessCategoryId": "",
    "businessCategoryName": "All category",
    "data":
    [
      {
        value:"Join 4,000+ companies already  growing",
        field:"heading",
        type : "heading",
        style : {'font-size':'13px', 'font-weight':300, 'color':'gray'}
      },
      {
        value:"Layers",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300 , 'margin-top': '7px'}
      },
      {
        value:"Sisphus",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300,  'margin-top': '7px'}
      },
      {
        value:"Circools",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300,  'margin-top': '7px'}
      },
      {
        value:"Catalog",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300,  'margin-top': '7px'}
      },
      {
        value:"Quotient",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300, 'margin-top': '7px'}
      },
      {
        value:"Hourglass",
        field:"text",
        type : "text",
        style : {'font-size':'13px', 'font-weight':300, 'margin-top': '7px'}
      },
      {
        imgUrl :"./../../../../../../../../assets/images/partner/layers-img.png",
        display : true,
        type : 'image',
        field :'image',
        style :{'width':'30px','height':'30px', 'margin-right':'5px'}
      },
    ],
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "partner2component"
  }
  componentName = this.templateData.componentName;
  data =this.templateData.data;
  heading = this.data[0];
  text1 = this.data[1];
  text2 = this.data[2];
  text3 =this.data[3];
  text4 = this.data[4];
  text5 = this.data[5];
  text6 = this.data[6];
  icon1 = this.data[7];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-partner2'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.text1 = this.data[1];
    this.text2 = this.data[2];
    this.text3 =this.data[3];
    this.text4 = this.data[4];
    this.text5 = this.data[5];
    this.text6 = this.data[6];
    this.icon1 = this.data[7];
  }

}
