import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-stats1',
  templateUrl: './stats1.component.html',
  styleUrls: ['./stats1.component.css','./mq-stats1.component.css']
})
export class Stats1Component {

  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }
  
  @Input() templateData:any = {
    "componentCategory" : "STATS",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Launch faster",
        type : 'subtext', field : 'subtext',
        style : {'color':'#A084DC','font-size':'14px','font-weight':700}
      },
      {
        value : "Build something great",
        type : 'heading', field : 'heading',
        style : {'font-size':'20px','font-weight':'900'}
      },
      {
        value : "We've done all the heavy lifting so you don't have to - get all the data you need to launch and grow your business faster.",
        type : 'text', field : 'text',
        style : {'font-size':'16px','color':'#959393'}
      },
      {
        value : ["4,000+","600%","10k","200+"],
        type : 'heading', field : 'heading',
        style : {'color':'#A084DC','font-size':'44px','font-weight':700}
      },
      {
        value : ["Global customers", "Return on investment", "Global downloads", "5-star reviews"],
        type : 'subtext', field : 'subtext',
        style : {'font-size':'14px','font-weight':700}
      },
      {
        value : [
          "We've helped over 4,000 amazing global companies.",
          "Our customers have reported an average of -600% ROI.",
          "Our app has been downloaded over 10k times",
          "Were proud of our 5-star rating with over 200 reviews"
        ],
        type : 'text', field : 'text',
        style : {'color':'#959393','font-size':'12px'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/stats/stats-image.jpg",
        display : true, type : 'image', field : 'image',
        style : {'width':'100%','height':'350px','object-fit':'cover'}
      }
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "Stats1Component",
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  subheading = this.data[0];
  heading = this.data[1];
  subtext = this.data[2];
  statsHeading = this.data[3];
  statsSubheading = this.data[4];
  statsText = this.data[5];
  image = this.data[6];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-stats1'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.componentName = this.templateData.componentName;
    this.data = this.templateData.data;
    this.subheading = this.data[0];
    this.heading = this.data[1];
    this.subtext = this.data[2];
    this.statsHeading = this.data[3];
    this.statsSubheading = this.data[4];
    this.statsText = this.data[5];
    this.image = this.data[6];
  }
}
