<div class="mainSection">
    <div class="title">
        Connect existing domain
        <div><mat-icon (click)="close()">close</mat-icon></div>
    </div>
    <div class="hr-line"></div>
    <div class="registerDomain">
        Registered Domain
        <!-- <input class="inputBox" type="text"> -->
    </div>
    <div class="form_field business-field-section">
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="" matInput/>
        </mat-form-field>
    </div>
    <div class="belowStepsText">
        Follow below steps to map your existing domain
    </div>
    <div class="steps">
        <span class="fontBold">1. Access domain settings:</span>
        <span>Login to your domain registrar account and locate to the DNS settings for your domain. 
            Look for an option to add or edit DNS records.
        </span>
    </div>
    <div class="steps">
        <span class="fontBold">2. Add CNAME record: </span>
        <span>In this website, create a CNAME record by entering following information.</span>
    </div>
    <div class="cname">
        <span>CNAME: </span>
        <span>dfgh4567hj.business.beeos.com</span>
        <span class="copyStyle">Copy</span>
    </div>
    <div class="steps">
        <span class="fontBold">
            3. Submit and Verify:
        </span>
        <span>
            Once you have created a CNAME record, click on submit and verify button below.
        </span>
    </div>
    <div class="btnRightAlign">
        <button class="buttonStyle" (click)="submitVerify()">Submit & Verify</button>
    </div>
</div>