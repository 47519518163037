<!-- <div class="navProperties">
    <nav class="nav2" >
        <span>{{rareBlocks.value}}</span>
        <span>{{solutions.value}}</span>
        <span>{{industries.value}}</span>
        <span>{{fees.value}}</span>
        <span>{{about.value}}</span>
    </nav>
    <nav class="nav3">
        <span>{{signIn.value}}</span>
        <span><button>{{createFreeAccount.value}}</button></span>
    </nav>
</div> -->
<div class="rowFlex" [ngStyle]="{'background-color': bgColor}">
    <div class="left-container"> 
        <div class="mainContent" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value | titlecase}}
        </div>
        <div class="descriptionProperties" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
       
        <div class="row_Flex1">
            <div class="rowFlex1 cardsCountSize">
                <div class="cardsCount" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value}}
                </div>
                <div class="description_Properties" [ngStyle]="data[6].style?data[6].style:{}">
                    {{data[6].value}}
                </div>
            </div>
            <div class="rowFlex1 cardsCountSize">
                <div class="cardsCount" [ngStyle]="data[7].style?data[7].style:{}">
                    {{data[7].value}}
                </div>
                <div class="description_Properties" [ngStyle]="data[8].style?data[8].style:{}">
                    {{data[8].value}}
                </div>
            </div>
             <!-- <div class="inputHolder">
             <input type="text" placeholder="Enter email address">
            <button class="getStartedBtn" [ngStyle]="data[15].style?data[15].style:{}">{{data[15].value | titlecase}}</button>
        </div>  -->
        </div>
        <div class="btn">
            <button class="getStartedBtn" [ngStyle]="data[14].style?data[14].style:{}" (click)="redirectUrl()">{{data[14].value | titlecase}}</button>
        </div>
    </div>
    <div class="right-container">
        <img class="imgProperties" src="{{data[9].imgUrl}}" alt="image">
    </div>
</div>