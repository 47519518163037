import { Component } from '@angular/core';

@Component({
  selector: 'app-team-profile-circle-images',
  templateUrl: './team-profile-circle-images.component.html',
  styleUrls: ['./team-profile-circle-images.component.scss']
})
export class TeamProfileCircleImagesComponent {
  templateData: any={
    "componentCategory": "Team",
    "ranking": 0,
    "businessCategoryId": "",
    "businessCategoryName": "All category",
    "data":
    [
      {
        "value": "We're hiring!",
        "field": "heading",
        "type": "heading",
        "style": { 'font-size': '13px', 'font-weight': 600, 'color': 'rgb(160, 132, 220)' },
        "textType":"heading"
      },
      {
        "value": "Meet our team",
        "field": "subtext",
        "type": "subtext",
        "style": { 'font-size': '22px', 'font-weight': 600, },
        "textType":"subtext"
      },
      {
        "value": "Our philosophy is simple -- hire a team of diverse, passionate people and  foster a culture that empowers you to do best work",
        "field": "text",
        "type": "text",
        "style": { 'font-size': '15px', 'font-weight': 500, 'color': '#959393' },
        "textType":"text"
      },
      {
        value:
          [
            {
              "imgUrl": "../../../../../../../../assets/images/testimonials/person2.jpg",
              "display": true,
              "type": 'image',
              "field": 'image',
              "style": { 'border-radius': '70px',  },
              "textType":"image"
            },
            {
              "value": "Olivia Rhye",
              "type": "heading",
              "field": "Name",
              "style": { 'font-size': '16px', 'font-weight': 600, 'color': 'black' },
              "textType":"name"
            },

            {
              "value": "Founder & CEO",
              "type": "heading",
              "field": "Designation",
              "style": { 'font-size': '14px', 'font-weight': 500, 'margin-bottom': '5px' },
              "textType":"designation"
            },
          ],
        type: 'array',
        field: '',
        style: {}
      },
      {
        value:
          [
            {
              "imgUrl": "../../../../../../../../assets/images/testimonials/person2.jpg",
              "display": true,
              "type": 'image',
              "field": 'image',
              "style": {   'border-radius': '70px', },
              "textType":"image",
            },
            {
              "value": "Phoenix Baker",
              "type": "heading",
              "field": "Name",
              "style": { 'font-size': '15px', 'font-weight': 600,  'color': 'black' },
              "textType":"name"
            },

            {
              "value": "Engineering Manager",
              "type": "heading",
              "field": "Designation",
              "style": { 'font-size': '14px', 'font-weight': 500,  'margin-bottom': '5px' },
              "textType":"designation"
            },
          ],
        type: 'array',
        field: '',
        style: {}
      },
    ],
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "team5component"
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subtext = this.data[1];
  text1 = this.data[2];
  text2 = this.data[3];
  text3 = this.data[4];

}
