import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss','./mq-about-us.component.scss']
})
export class AboutUsComponent {

data={
heading:{
  text:"About Us",
  id:"aboutUs_heading_text",
  textStyles : {
    "font-size":"20px",
    "font-weight":"normal",
    "font-style":"normal",
    "color":"#FFFFFF"
  }
},
text1:{
  text:"JK Construction Company is a construction firm that specializes in delivering high-quality building and construction services to clients. With years of experience in the industry, the company has established a reputation for delivering projects on time and within budget. JK Construction offers a full range of construction services, including design and build, project management, and construction management.",
  id:"aboutUs_heading_text",
  textStyles : {
    "font-size":"20px",
    "font-weight":"normal",
    "font-style":"normal",
    "color":"#FFFFFF"
  },
},
text2:{
  text:"The company is known for its commitment to safety and its use of the latest technology and /n construction techniques. JK Construction has a team of highly skilled and experienced professionals who work closely with clients to understand their specific needs and deliver tailored solutions. Whether you are looking to build a new home, renovate your existing property, or construct a commercial building, JK Construction Company can help you achieve your goals. ",
  id:"aboutUs_heading_text",
  textStyles : {
    "font-size":"20px",
    "font-weight":"normal",
    "font-style":"normal",
    "color":"#FFFFFF"
  },
},
image:{
  imageUrl:"./../../../../assets/images/aboutUs_Image.svg",
  id:"aboutUs_image_image",
  textStyles : {
    "font-size":"20px",
    "font-weight":"normal",
    "font-style":"normal",
    "color":"#FFFFFF"
  },
}
}
}
