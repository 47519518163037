import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-herobanner-feature-rightside-image',
  templateUrl: './herobanner-feature-rightside-image.component.html',
  styleUrls: ['./preview-herobanner-feature-rightside-image.component.scss']
})
export class PreviewHerobannerFeatureRightsideImageComponent {

  @Input() templateData:any;
  valuesData:any;
  componentData: any;
  @Input() color:any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(){
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'hero_banner_features_rightside_image'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData: any = {
  //   'componentCategory': "hero banner",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "HeroBanner11Component",
  //   "data" : [
  //     {
  //       "value":"Join 4,000+ startups growing with Untitled",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"30-day free trail",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //      "value":"Personalized onboarding",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Accessed to all features",
  //       "type":"text",
  //       "field":"text",
  //       "style":{}
  //     },
  //     {
  //       "value":"Learn More",
  //       "type":"button",
  //       "field":"button",
  //       "style":{"background-color": "white"}
  //     },
  //     {
  //       "value":"Get Started",
  //       "type":"button",
  //       "field":"button",
  //       "style":{"background-color": "#982CCB", "color": "white"}
  //     },
  //     {
  //       "imgUrl":"./../../assets/images/rightTick.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     },
  //     {
  //       "imgUrl": "./../../assets/images/desktop.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{}
  //     }
  //   ]
  // }
  // data = this.templateData.data
  data : any;

  // heading = this.data[0]
  // content1 = this.data[1]
  // content2 = this.data[2]
  // content3 = this.data[3]
  // learnMoreBtn = this.data[4]
  // getStartedBtn = this.data[5]
  // tickImage = this.data[6]
  // rightContainerImage = this.data[7]

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title.replaceAll("[Company Name]", bName);
      this.data[1].value = this.valuesData.features[0];
      this.data[2].value = this.valuesData.features[1];
      this.data[3].value = this.valuesData.features[2];
      this.data[7].imgUrl = this.valuesData.imageUrl;
      this.data[5].value = this.valuesData.button1Text;
      // this.data[4].value = this.valuesData.counter1Count;
      // this.data[5].value = this.valuesData.counter1Text;
      // this.data[6].value = this.valuesData.counter2Count;
      // this.data[7].value = this.valuesData.counter2Text;
      // this.data[8].imgUrl = this.valuesData.imageUrl;

    }
    else{

    }
    // this.heading = this.valuesData.title;
    // this.description = this.valuesData.subTitle[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }
  redirectTo(){
    window.open(`${this.data[5].link}`,'_blank')
  }
}
