<div class="total-section-container">
    <div class="header-section">
        <div class="header-part" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="subtext-part"[ngStyle]="subtext.style?subtext.style:{}">{{subtext.value}}</div>
    </div>
    <div class="middle-section-container">
        <div class="middle-section">
            <div class="team-section">
                <img src="{{ icon1.value[0].imgUrl}}" alt="" [ngStyle]=" icon1.value[0].style? icon1.value[0].style:{}" class="team-img">
                <div class="details">
                <div  class="heading" [ngStyle]=" icon1.value[1].style? icon1.value[1].style:{}">{{ icon1.value[1].value}}</div>
                <div  class="designation"  [ngStyle]=" icon1.value[2].style? icon1.value[2].style:{}">{{ icon1.value[2].value}}</div>
            </div>
            </div>
            <div class="team-section">
                <img src="{{ icon2.value[0].imgUrl}}" alt="" [ngStyle]=" icon2.value[0].style? icon2.value[0].style:{}" class="team-img">
                <div class="details">
                <div  class="heading" [ngStyle]=" icon2.value[1].style? icon2.value[1].style:{}">{{ icon2.value[1].value}}</div>
                <div  class="designation"  [ngStyle]=" icon2.value[2].style? icon2.value[2].style:{}">{{ icon2.value[2].value}}</div>
            </div>
            </div>
            <div class="team-section">
                <img src="{{ icon1.value[0].imgUrl}}" alt="" [ngStyle]=" icon1.value[0].style? icon1.value[0].style:{}" class="team-img">
                <div class="details">
                    <div  class="heading" [ngStyle]=" icon1.value[1].style? icon1.value[1].style:{}">{{ icon1.value[1].value}}</div>
                    <div  class="designation"  [ngStyle]=" icon1.value[2].style? icon1.value[2].style:{}">{{ icon1.value[2].value}}</div>
                </div>
            </div>
            <div class="team-section">
                <img src="{{ icon2.value[0].imgUrl}}" alt="" [ngStyle]=" icon2.value[0].style? icon2.value[0].style:{}" class="team-img">
                <div class="details">
                    <div  class="heading" [ngStyle]=" icon2.value[1].style? icon2.value[1].style:{}">{{ icon2.value[1].value}}</div>
                    <div  class="designation"  [ngStyle]=" icon2.value[2].style? icon2.value[2].style:{}">{{ icon2.value[2].value}}</div>
                </div>
            </div>
            <div class="team-section">
                <img src="{{ icon1.value[0].imgUrl}}" alt="" [ngStyle]=" icon1.value[0].style? icon1.value[0].style:{}" class="team-img">
                <div class="details">
                    <div  class="heading" [ngStyle]=" icon1.value[1].style? icon1.value[1].style:{}">{{ icon1.value[1].value}}</div>
                    <div  class="designation"  [ngStyle]=" icon1.value[2].style? icon1.value[2].style:{}">{{ icon1.value[2].value}}</div>
                </div>
            </div>
            <div class="team-section">
                <img src="{{ icon2.value[0].imgUrl}}" alt="" [ngStyle]=" icon2.value[0].style? icon2.value[0].style:{}" class="team-img">
                <div class="details">
                    <div  class="heading" [ngStyle]=" icon2.value[1].style? icon2.value[1].style:{}">{{ icon2.value[1].value}}</div>
                    <div  class="designation"  [ngStyle]=" icon2.value[2].style? icon2.value[2].style:{}">{{ icon2.value[2].value}}</div>
                </div>
            </div>
        </div>
    </div>

</div>
