<div class="main-section" [ngStyle]="{'background-color': bgColor}">
    <div class="heading">
        <div class="title" [ngStyle]="data[0].style?data[0].style:{}">{{data[0].value | titlecase}}</div> 
        <div class="description" [ngStyle]="data[1].style? data[1].style:{}">{{data[1].value}}</div>
    </div>
    <div class="faq-section">
        <cdk-accordion class="example-accordion">
            <cdk-accordion-item
              *ngFor="let item of data[2].value; let index = index;"
              #accordionItem="cdkAccordionItem"
              class="example-accordion-item"
              role="button"
              tabindex="0"
              [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body-' + index">
              <div class="example-accordion-item-header" (click)="accordionItem.toggle()" [ngStyle]="item.style? item.style:{}" >
                {{ item.value}}
                <span class="example-accordion-item-description">
                    <img src="{{ accordionItem.expanded ? data[5].imgUrl : data[4].imgUrl}}" alt="">
                </span>
              </div>
              <div
                [ngStyle]="data[3].value[index].style? data[3].value[index].style:{}"
                class="example-accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">
                {{data[3].value[index].value}}
              </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>
<!--     
    <div class="ask-faq-section">
        <div>
            <img src="{{personImg.imgUrl}}" alt="" [ngStyle]="personImg.style?personImg.style:{}">
        </div>
        <div [ngStyle]="heading2.style?heading2.style:{}">{{heading2.value}}</div>
        <div [ngStyle]="subheading2.style?subheading2.style:{}">{{subheading2.value}}</div>
        <div [ngStyle]="button.style?button.style:{}">{{button.value}}</div>
    </div>   -->
</div>

  