import { Component, HostListener , Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../../blog-service.service';
import { AddTagsComponent } from '../add-tags/add-tags.component';
import { DeleteTagsComponent } from '../delete-tags/delete-tags.component';

@Component({
  selector: 'app-view-tags',
  templateUrl: './view-tags.component.html',
  styleUrls: ['./view-tags.component.scss']
})
export class ViewTagsComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService : BlogServiceService){

  }
  tagId : any ;
  ngOnInit(): void {
      this.dialogRef.updatePosition({ top: '0px', right: `0px` });
      this.tagId = this.data.data;
      this.getViewTag();
  }

  scrWidth: number | undefined;
  scrHeight: number | undefined;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }


  closeBtn(){
    this.dialogRef.close();
  }

  editTag(data:any){
    let dialog = this.blogService.openDeleteDialog(
      AddTagsComponent,
      'auto',
      '400px',
      {edit:true,data:data},
      '80vw'
    );
    dialog.afterClosed().subscribe((data:any) => {
      this.dialogRef.close('true');
    });
  }


  deleteTag(tagId:any){
    let dialog = this.blogService.openDeleteDialog(
      DeleteTagsComponent,
      '250px',
      '350px',
      {data:tagId},
      '80vw'
    );
    dialog.afterClosed().subscribe((data:any) => {
      this.dialogRef.close('success');
    });
  }

  dataSource : any = [];
  getViewTag(){
    this.blogService.getViewByTagId(this.tagId).subscribe((res:any)=>{
      this.dataSource = res.data;
    })
  }



  blogs = [
    { name : 'Blogs Created :', value : '03' },
    { name : 'Created On:', value : '21 Dec 2022' },
    { name : 'Slug :', value : 'grmconstruction.beeos.com/tag/news/' },
  ]

  blogListing = [
    { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' },
    { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' }
  ]


}
