export const buttonStyle =[
    {
        name:'Outline',
        value:'OUT_LINE'
    },
    {
        name:'Solid',
        value:'SOLID'
    }
];

export const buttonShape = [
    {
        name:'Rounded',
        value:'ROUNDED'
    },
    {
        name:'Sharp',
        value:'SHARP'
    }
]
export const fontFamilies = [
    {
        name:'Source Serif Pro',
        value:"sourceserifpro_semibold600",
        type:'heading'
    },
    {
        name:'Merri Weather',
        value:"merriweather_bold700",
        type:"heading"
    },
    {
        name:'Open Sans Regular',
        value:"opensans_reg400",
        type:"body"
    },
    {
        name:'Opens Sans Semibold',
        value:"opensans_semibold600",
        type:"heading"
    },
    {
        name:'Roboto Regular',
        value:"roboto_regular400",
        type:"body"
    },
    {
        name:'Nunito Sans',
        value:"nunito_sans_bold700",
        type:"heading"
    },
    {
        name:'Nunito',
        value:"nunito_reg400",
        type:"body"
    },
    {
        name:'Bitter',
        value:"bitter_semibold600",
        type:"heading"
    },
    {
        name:'Fira Sans',
        value:"firasans_reg400",
        type:"body"
    },
    {
        name:'Lato',
        value:"lato_regular400",
        type:"body"
    }
]

export const combinationFontFamily=[
    {
        name:"Merri Weather + Open Sans Regular",
        value:"merriweather_bold700,opensans_reg400"
    },
    {
        name:"Source Serif Pro + Lato",
        value:"sourceserifpro_semibold600,lato_regular400"
    },
    {
        name:"Opens Sans Semibold + Roboto Regular",
        value:"opensans_semibold600,roboto_regular400"
    },
    {
        name:"Nunito Sans + Nunito",
        value:"nunito_sans_bold700,nunito_reg400"
    },
    {
        name:"Bitter + Fira Sans",
        value:"bitter_semibold600,firasans_reg400"
    }
]