import { Component, Input } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css']
})
export class ImageGalleryComponent {
  
  constructor( private templatesService:TemplateService){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }

  @Input() templateData : any = {
    "componentCategory" : "image-gallery",
    "ranking":0,
    "businessCategoryId":"",
    "businessCategoryName":"All category",
    "data":[
      {
        value : "Vibes",
        type :'text', field :'heading',
        style : {'font-size':'24px','font-weight':700}
      },
      {
        value : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
        type : 'text', field : 'text',
        style : {'color':'#959393', 'font-size':'16px'}
      },
      {
        value : "Hands Opx",
        type : 'text', field :'image-heading',
        style : {'font-size':'14px','font-weight':600}
      },
      {
        value : [
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery1.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery2.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery1.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery2.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery1.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
          {
            imgUrl : "./../../../../../../../../assets/images/image_gallery/gallery2.jpg",
            type : 'image', field :'image',style :{}, display:true
          },
        ],
        type :'array', field : 'image', style :{}, display:true
      }
    ],
    "createdTimeStamp":"",
    "language":"English",
    "componentName":"ImageGalleryComponent",
  }
  data = this.templateData.data;
  heading = this.data[0];
  text = this.data[1];
  imageHeading =this.data[2];
  images= this.data[3];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-image-gallery'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.text = this.data[1];
    this.imageHeading =this.data[2];
    this.images = this.data[3];
  }
}
