import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { buttonShape, buttonStyle, combinationFontFamily } from 'src/app/constants/common-constant';
import { ShareWebsiteComponent } from 'src/app/our-components/share-website/share-website.component';
import { DbService } from 'src/app/services/db-service/db.service';
import { ImageUploadService } from 'src/app/services/ImageUploadService/image-upload.service';
import { TemplateService } from 'src/app/services/template.service';
import { AddComponentsectionComponent } from '../website-generationcomponent/add-componentsection/add-componentsection.component';
import { MqRightsidepanelComponent } from '../website-generationcomponent/rightsidepanel/mq-rightsidepanel.component';

@Component({
  selector: 'app-edit-website',
  templateUrl: './edit-website.component.html',
  styleUrls: ['./edit-website.component.scss', './mq-edit-website.component.scss']
})
export class EditWebsiteComponent {
  // businessId: any = "1edb27b8-b73e-62c3-be4f-8127e4a93850";
  // templateId: any = "1edb829e-fb01-6cf3-962c-7befc6f116c8";
  businessId: any = "";
  templateId: any = "";
  file: any;
  profileImg: any = '';
  imageUploadingLoader: boolean = false
  editParam: any = true;
  selectedComponent: any = "About-us";
  editValue: boolean = true;
  componentData: any = null;
  loaderDisplay: Boolean = false;
  componentNames: any = [];
  domainName: String = "";
  templateData: any = {
    // "id": "1edb27b8-b73e-62c3-be4f-8127e4a93850",
    "componentContentList": [],
    componentsData: [],
    "businessId": null,
    "businessName": null,
    "primaryColor": null,
    "buttonColor": null,
    "textFamily": null
  }
  data: any[] = [];
  businessCategoryId: any = "";
  subIndustryId: any = '';
  pageLoader: boolean = false;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private templatesService: TemplateService,
    private imageUploadServiceService: ImageUploadService,
    private _dbService: DbService
  ) {
    this.getScreenSize();
  }

  componentsStyling: any = {
    bgColor1: String,
    bgColor2: String,
    buttonShape: String,
    buttonStyle: String,
    fontFamily: String,
    txtHeadingColor: String,
    txtDescColor: String,
    txtNormalColor: String,
    btnColor: String,
    btnTextColor: String,
  }
  businessName:any="";
  ngOnInit(): void {
    if (window.localStorage.getItem('bDetails') === null) {
      this.templateId = this._dbService.getWebsiteId()?.split("+")[0];
      this.getTemplateDatabyBusinessId();
    }
    else {
      let businessDetails = JSON.parse(window.localStorage.getItem('bDetails') || '');
      this.businessId = businessDetails.id;
      this.templateId = businessDetails.templateId;
      this.subIndustryId = businessDetails.subIndustryId;
      if (businessDetails.domainUrl) {
        this.domainName = businessDetails.domainUrl;
      } else {
        this.domainName = businessDetails.websiteUrl;
      }

      this.getTemplateDatabyBusinessId();
      this.getblogsByBusinessId();
      this.templatesService.editorSave.subscribe((res)=>{
        //console.log("response",res);
        let dataCheck:any=res;
        res=dataCheck.templateData;
        this.templateId=dataCheck.templateId;
        this.businessName=dataCheck.businessName;
        this.templateData=res;
  
        this.sendingColor = res.componentContentList[0].colors;
        this.componentsStyling.buttonShape = res.componentContentList[0].buttonShape;
        this.buttonShape.forEach((bshape: any) => {
          if (bshape.value === res.componentContentList[0].buttonShape) {
            this.bShape = bshape.value;
          }
        });
        this.componentsStyling.buttonStyle = res.componentContentList[0].buttonStyle;
        this.buttonStyles.forEach((bstyle: any) => {
          if (bstyle.value === res.componentContentList[0].buttonStyle) {
            this.bStyle = bstyle.value;
          }
        });
        this.componentsStyling.headingFontFamily =
        res.componentContentList[0].headingFontFamily;
        this.componentsStyling.bodyFontFamily =
        res.componentContentList[0].bodyFontFamily;
        this.componentsStyling.btnColor =
        res.componentContentList[0].colors.btnColor;
        this.componentsStyling.btnTextColor =
        res.componentContentList[0].colors.btnTextColor;
          this.fontFamilies.forEach((ff: any) => {
            if (
              ff.value.split(',')[0] === res.componentContentList[0].headingFontFamily
            ) {
              this.selectingFontFamily = ff.value;
            }
          });
      }
      )
    }
    // this.businessCategoryId=this._dbService.getBusinessCategoryId()||"";
    // this.templatesService.updateElements.subscribe({
    //   next: (value: any) => {
    //     this.templateData = value;
    //   }
    // })
    // if (this.templateId == "" || this.templateId == null) {
    //   this.templateDataAssign();
    // }
    // else {
    // }
    // //console.log(JSON.parse(window.localStorage.getItem('bDetails')||"").id );

  }


 

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    //console.log(this.screenWidth);
  }

  clicktoUpload() {

  }

  ContentChanged() {
    this.templatesService.updateElements.emit(this.data);
  }


  async updateBackgroundImage(ev: any) {
    // this.imageUploadingLoader = true;
    // const file = ev.srcElement.files[0];
    // this.file = file;
    // this.profileImg = await this.imageUploadServiceService.uploadfoodcourtImages(this.file);
    // this.profileImg = this.profileImg.Location;
    // this.imageUploadingLoader = false;
  }

  addImage() {
    document.getElementById('browse_img')?.click();
  }


  smallScreenEditParam : boolean = false;
  editSection(ev: any) {
    //console.log(ev);
    if(this.screenWidth > 475){
      this.smallScreenEditParam = false;
      this.templateData.componentContentList.forEach((element: any) => {
        if (element.webComponentName == ev) {
          // element.editSection = !element.editSection
          // this.editParam = element.editSection;
          this.selectedComponent = ev;
          this.templatesService.editComponent.emit({ selectedComponent: this.selectedComponent });
        }
        else {
          element.editSection = false;
        }
      });
      //console.log(this.templateData.componentContentList);;
    }
    else{
      this.templateData.componentContentList.forEach((cmpList) => {
        if(cmpList.webComponentName === ev){
          this.selectedComponent = ev;
          this.templatesService.openDialogForEditorSmallScreen(
            MqRightsidepanelComponent,
            '100vh',
            '100%',
            {selectedComponent:this.selectedComponent,templateData:this.templateData}
          )
        }
      });
    }

  }
  styleSheetSelected(){
    if(this.screenWidth > 475){
      this.templatesService.editComponent.emit({ selectedComponent: "StyleSheet", pageSelected: this.pageSeoslected,style:{
        sendingColor:this.sendingColor,
        buttonShape:this.componentsStyling.buttonShape,
        bShape:this.bShape,
        bStyle:this.bStyle,
        headingFontFamily:this.componentsStyling.headingFontFamily,
        bodyFontFamily:this.componentsStyling.bodyFontFamily,
        btnColor:this.componentsStyling.btnColor,
        btnTextColor:this.componentsStyling.btnTextColor,
        selectingFontFamily:this.selectingFontFamily
      } })
    }
    else{
      this.templatesService.openDialogForEditorSmallScreen(
        MqRightsidepanelComponent,
        '100vh',
        '100%',
        {
          selectedComponent : "StyleSheet",
          pageSelected: this.pageSeoslected,style:{
            sendingColor:this.sendingColor,
            buttonShape:this.componentsStyling.buttonShape,
            bShape:this.bShape,
            bStyle:this.bStyle,
            headingFontFamily:this.componentsStyling.headingFontFamily,
            bodyFontFamily:this.componentsStyling.bodyFontFamily,
            btnColor:this.componentsStyling.btnColor,
            btnTextColor:this.componentsStyling.btnTextColor,
            selectingFontFamily:this.selectingFontFamily
          } 
        }
      )
    }
  }

  pageSelected() {
    this.templatesService.editComponent.emit({ selectedComponent: "seo", pageSelected: this.pageSeoslected });
  }

  deleteComponent(ev: any) {
    this.templateData.componentsData.forEach((element: any, i: any) => {
      if (element.componentName == ev) {
        this.templateData.componentsData.splice(i, 1);
      }
    });
    this.templateData.componentContentList.forEach((cmpContList,j) => {
      if(cmpContList.webComponentName === ev){
        this.templateData.componentContentList.splice(j,1);
      }
    });
    this.templatesService.updateElements.emit(this.templateData);
    //console.log(this.templateData);
  }

  move(componentContentList:any,input: any, from: any, to: any) {
    if(from==1&&to==0){
  return ;
    }
    if(from==componentContentList.length-2&&to==componentContentList.length-1){
      return ;
    }
    let numberOfDeletedElm = 1;
    let numberOfDeletedComponents = 1;

    const elm = input.splice(from, numberOfDeletedElm)[0];
    const comp = componentContentList.splice(from,numberOfDeletedComponents)[0];

    numberOfDeletedElm = 0;
    numberOfDeletedComponents = 0;

    input.splice(to, numberOfDeletedElm, elm);
    componentContentList.splice(to, numberOfDeletedComponents,comp);
    //console.log(this.templateData);
  }

  // regenerateComponent(componentCategory: any, businessCategoryId: any, index?: number,componentId?:any,componentName?:any) {
  //   this.templatesService.regenerateComponentCategoryWithComponentId(componentCategory, businessCategoryId,componentId,componentName).subscribe(
  //     {
  //       next: async (value: any) => {
  //      await  this.templateData.componentContentList.forEach((component: any, i: any) => {
  //           if (component.componentCategory == componentCategory) {
  //             this.templateData.componentContentList.splice(i, 1);
  //             this.templateData.componentContentList.splice(i, 0, value.data);
  //             this.templateData.componentsData.forEach((element:any,j:any) => {
  //               if(element.componentCategory==componentCategory){
  //                 this.templateData.componentsData.splice(j, 1);
  //                 this.templateData.componentsData.splice(j, 0, {
  //                   componentName: value.data.componentName,
  //                   componentCategory:value.data.componentCategory,
  //                   ranking: value.data.ranking,
  //                   componentId:value.data.id
  //                 });
  //                 this.templateData.componentsData = this.templateData.componentsData?.sort((a:any, b:any) =>
  //                 a.ranking < b.ranking ? -1 : 1
  //               );
  //               }
  //              });
  //           }
  //         });
  //         this.templatesService.updateElements.emit(this.templateData);
  //       },
  //       error: (err) => {
  //         this.templatesService.openSnack("Something went wrong", "Close");
  //       },
  //     }
  //   )
  // }
  regenerateComponent(componentCategoryId: any, componentId: any, subIndustryId: any, contentId: any, i: any) {
    this.templatesService.regenerateComponents(componentCategoryId,this.subIndustryId,componentId,contentId).subscribe(
      (res:any) => {
        this.templateData.componentContentList.forEach((component: any, i: any) => {
          if (component.category.id === componentCategoryId) {
            res.data.colors = this.sendingColor;
            res.data.bgColor = this.templateData.componentContentList[i].bgColor;
            res.data.colors.txtHeadingColor = this.templateData.componentContentList[i].colors.txtHeadingColor;
            res.data.colors.txtDescColor = this.templateData.componentContentList[i].colors.txtDescColor;
            res.data.colors.txtNormalColor = this.templateData.componentContentList[i].colors.txtNormalColor;
            res.data.colors.btnColor = this.templateData.componentContentList[i].colors.btnColor;
            res.data.colors.btnTextColor = this.templateData.componentContentList[i].colors.btnTextColor;
            res.data.buttonShape = this.componentsStyling.buttonShape;
            res.data.buttonStyle = this.componentsStyling.buttonStyle;
            res.data.headingFontFamily = this.componentsStyling.headingFontFamily;
            res.data.bodyFontFamily = this.componentsStyling.bodyFontFamily;
            this.templateData.componentContentList.splice(i, 1);
            this.templateData.componentContentList.splice(i, 0, res.data);
            this.templateData.componentsData.forEach((element:any,j:any) => {
              if(element.componentCategoryId == componentCategoryId){
                this.templateData.componentsData.splice(j, 1);
                this.templateData.componentsData.splice(j, 0, {
                  componentName: res.data.webComponentName,
                  componentCategoryId: res.data.category.id,
                  componentCategoryName : res.data.category.name,
                  componentId : res.data.id,
                  contentId : res.data.contentId,
                  subIndustryId:this._dbService.getSubCategoryId()
                });
              }
              this.bgColorCountValue = this.bgColorCountValue+1;
             });
          }
        });
        this.templatesService.updateElements.emit(this.templateData);
        // setTimeout(() => {
        //   this.loaderDisplay = false;
        // }, 1000);
      },
      (err) => {
        // //console.log(err.error.message);
        this.templatesService.openSnack(`${err.error.message}`,'Close');
      }
    )
  }

  // changeContent(componentName: any, businessCategoryId: any, index: number,componentId?:any) {
  //   this.templatesService.contentContentChangeCategory(componentName, businessCategoryId,componentId).subscribe(
  //     {
  //       next: (value: any) => {
  //         this.templateData.componentContentList.forEach((component: any, i: any) => {
  //           if (component.componentName == componentName) {
  //             this.templateData.componentContentList.splice(i, 1);
  //             this.templateData.componentContentList.splice(i, 0, value.data);
  //             this.templateData.componentsData.forEach((element:any,j:any) => {
  //               if(element.componentName == componentName){
  //                 this.templateData.componentsData.splice(j, 1);
  //                 this.templateData.componentsData.splice(j, 0, {
  //                   componentName: value.data.componentName,
  //                   componentCategory:value.data.componentCategory,
  //                   ranking: value.data.ranking,
  //                   componentId:value.data.id
  //                 });
  //                 this.templateData.componentsData = this.templateData.componentsData?.sort((a:any, b:any) =>
  //                 a.ranking < b.ranking ? -1 : 1
  //               );
  //               }
  //              });
  //           }
  //         });
  //         this.templatesService.updateElements.emit(this.templateData);
  //       },
  //       error: (err) => {
  //         this.templatesService.openSnack("No Data Found", "Close");
  //       },
  //     }
  //   )
  // }
  changeContent(componentCategoryId: any, subIndustryId: any, contentId: any, index: number) {
    // this.templatesService.contentChangeCategory(componentName,businessCategoryId).subscribe(
    //   {
    //     next:(value:any)=>{
    //       this.templateData.componentContentList.forEach((component:any,i:any) => {
    //         if(component.componentName==componentName){
    //           this.templateData.componentContentList.splice(i,1);
    //           this.templateData.componentContentList.splice(i,0,value.data)
    //         }
    //       });
    //       this.templatesService.updateElements.emit(this.templateData); 
    //     },
    //     error:(err)=>{
    //       this.templatesService.openSnack("No Data Found","Close");
    //     },
    //   }
    // )
    this.changeContentPurpose.forEach((component) => {
      if(componentCategoryId === component.category.id){
        this.templatesService.changeContent(componentCategoryId,this.subIndustryId,component.contentId).subscribe(
          (res:any) => {
            // //console.log(res.data);
            this.templateData.componentContentList.forEach((cmpContList:any,i:any) => {
              if(cmpContList.category.id === componentCategoryId){
                  // this.templateData.componentContentList.splice(i,1);
                  // this.templateData.componentContentList.splice(i,0,res.data.data)
                // cmpContList.contentId = res.data.contentId;
                // Object.assign(cmpContList.values,res.data.data);
                delete cmpContList.contentId;
                delete cmpContList.values;
                cmpContList.values = res.data.data;
                cmpContList.contentId = res.data.contentId;
                // delete this.templateData.componentContentList[i].contentId;
                // //console.log("removed content Id",this.templateData.componentContentList[i]);
                // this.templateData.componentContentList[i].values = res.data.data
                // this.templateData.componentContentList[i].contentId = res.data.contentId
                // //console.log("added Content Id",this.templateData.componentContentList[i]);
                
              }
            });
            this.templatesService.updateElements.emit(this.templateData);
            // //console.log('new data',this.templateData);
          },
          (err) => {
            //console.log(err.error.message);
          }
        )
      }
    });
  }

  // templateDataAssign() {
  //   this.loaderDisplay = true;
  //   this.componentNames.forEach((component: any) => {
  //     this.templatesService.getComponentsData(component.componentCategory, '1eda9215-46e6-6984-a675-130cb2740cf4').subscribe(
  //       {
  //         next: (value: any) => {
  //           this.templateData.componentContentList.push(value.data);
  //           this.templateData.componentsData.push({
  //             componentName: value.data.componentName,
  //             componentCategory: value.data.componentCategory,
  //             ranking: value.data.ranking,
  //             componentId: value.data.id
  //           })
  //           this.templateData.componentsData = this.templateData.componentsData?.sort((a: any, b: any) =>
  //             a.ranking < b.ranking ? -1 : 1
  //           );
  //           this.templatesService.updateElements.emit(this.templateData);
  //           //console.log(this.templateData);

  //         },
  //         error: (err) => {
  //           this.templatesService.openSnack(`${component.componentCategory} Not Found`, "Close");
  //         },
  //         complete: () => {
  //           this.loaderDisplay = false;
  //         }
  //       }
  //     )
  //   });
  //   ////console.log("template data", this.templateData);

  // }

  // async getTemplatesComponent(componentCategory: any, businessId: any) {
  //   let data = null;
  //   await this.templatesService.getComponentsData(componentCategory, businessId).subscribe(
  //     {
  //       next: (value: any) => {
  //         this.data = value.data

  //       },
  //       error: (err) => {
  //         this.templatesService.openSnack("Something went wrong", "Close");
  //       },
  //     }
  //   )
  //   ////console.log(data);

  //   return data;
  // }

  collapseSection(ev: any) {
    this.editParam = ev;
  }

  addSection() {
    this.templatesService.openDialog(AddComponentsectionComponent, 'auto', '654px', {
      data: this.templateData
    });
  }

  websiteEditoeDone() {
    this.router.navigate(['/admin/website/preview']);
    // //console.log(this.templateData)
  }

  shareWebsite() {
    if (this.screenWidth > 475) {
      this.templatesService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
        webId: this.domainName
      });
    }
    else if (this.screenWidth < 475) {
      this.templatesService.openDialog(ShareWebsiteComponent, '85vh', '100%', {
        webId: this.domainName
      });
    }
  }

  sendingColor: any;
  bgColorCountValue = 1;
  changeContentPurpose :any = [];
  buttonStyles = buttonStyle;
  fontFamilies = combinationFontFamily;
  bStyle: any = '';
  buttonShape = buttonShape;
  bShape: any = '';
  selectingFontFamily = '';
  globalStyles = {
    primaryColor: '#5599d5',
    buttonColor: '#fbd09a',
    btnBorderRadius: '0px',
    // textFamily:{
    //   'font-family':"'Lexend Deca', sans-serif"
    // }
    headingFontFamily: {
      'font-family': "'Lexend Deca', sans-serif",
    },
    bodyFontFamily: {
      'font-family': "'Lexend Deca', sans-serif",
    },
  };
  gettingData: any;
  getTemplateDatabyBusinessId() {
    this.pageLoader = true;
    this.templatesService.getTemplateDataByTemplateId(this.templateId).subscribe(
      (res: any) => {
        this.changeContentPurpose = res.data.components;
        this.sendingColor = res.data.components[0].colors;
        this.componentsStyling.buttonShape = res.data.components[0].buttonShape;
        this.componentsStyling.buttonStyle = res.data.components[0].buttonStyle;
        this.componentsStyling.fontFamily = res.data.components[0].fontFamily;
        // //console.log(res.data)
        let a: number = 0;
        res.data.components.forEach((cmpList: any) => {
          res.data.colors = this.sendingColor;
          this.templateData.componentContentList.push(cmpList);
          this.templateData.componentsData.push({
            componentName: cmpList.webComponentName,
            componentCategoryId: cmpList.category.id,
            componentCategoryName: cmpList.category.name,
            componentId: cmpList.id,
            contentId: cmpList.contentId,
            subIndustryId: this._dbService.getSubCategoryId()
          });
        });
        this.templatesService.updateElements.emit(this.templateData);
        // a = a+1;
        // if(a === this.componentNames.length){
        //   this.initialTemplateData();
        // }
        //global stylings
        let data = this.templateData.componentContentList[1];
        // this.componentsStyling.bgColor = data.bgColor;
        if (this.bgColorCountValue % 2) {
          res.data.bgColor = this.componentsStyling.bgColor1;
        } else {
          res.data.bgColor = this.componentsStyling.bgColor2;
        }
        res.data.buttonShape = this.componentsStyling.buttonShape;
        res.data.buttonStyle = this.componentsStyling.buttonStyle;
        res.data.fontFamily = this.componentsStyling.fontFamily;
        res.data.txtHeadingColor = this.componentsStyling.txtHeadingColor;
        res.data.txtDescColor = this.componentsStyling.txtDescColor;
        res.data.txtNormalColor = this.componentsStyling.txtNormalColor;
        res.data.btnColor = this.componentsStyling.btnColor;
        res.data.btnTextColor = this.componentsStyling.btnTextColor;
        this.pageLoader = false;
        this.sendingColor = res.data.components[0].colors;
        this.componentsStyling.buttonShape = res.data.components[0].buttonShape;
        this.buttonShape.forEach((bshape: any) => {
          if (bshape.value === res.data.components[0].buttonShape) {
            this.bShape = bshape.value;
          }
        });
        this.componentsStyling.buttonStyle = res.data.components[0].buttonStyle;
        this.buttonStyles.forEach((bstyle: any) => {
          if (bstyle.value === res.data.components[0].buttonStyle) {
            this.bStyle = bstyle.value;
          }
        });
        this.componentsStyling.headingFontFamily =
          res.data.components[0].headingFontFamily;
        this.componentsStyling.bodyFontFamily =
          res.data.components[0].bodyFontFamily;
        this.componentsStyling.btnColor =
          res.data.components[0].colors.btnColor;
        this.componentsStyling.btnTextColor =
          res.data.components[0].colors.btnTextColor;
          this.fontFamilies.forEach((ff: any) => {
            if (
              ff.value.split(',')[0] === res.data.components[0].headingFontFamily
            ) {
              this.selectingFontFamily = ff.value;
            }
          });
      },
      (err) => {
        this.pageLoader = false;
        this.templatesService.openSnack(`${err.error.message}`, 'Close')
      }
    );
  }
  templatePages: any[] = [
    { name: "Home" }
  ]
  pageSeoslected: any = this.templatePages[0].name;

  blogData: any = "";
  displayBlogs: boolean = false;
  getblogsByBusinessId() {
    this.templatesService.getBlogsByBuisnessId(this.businessId, "ALL").subscribe
      ({
        next: (res: any) => {
          this.blogData = res.data;
          if (this.blogData.length > 0) {
            this.displayBlogs = true;
          }
          else {
            this.displayBlogs = false;
          }
          //console.log("displayBlogs", this.displayBlogs);

        },
        error: (err: any) => {
        }
      })

  }

  savechangesLoader:boolean=false;
  saveChanges(){
    this.savechangesLoader=true;
    let data:any;
     if(window.localStorage.getItem('bDetails') === null){
    data = {
      id:this.templateId,
      components : this.templateData.componentContentList,
      businessName:null
    }
  }
  else{
    data = {
      id:this.templateId,
      components : this.templateData.componentContentList,
      businessName:this.businessName
    }
  }
  this.templatesService.updateTemplateData(data).subscribe(
    {
      next:(value)=>{
        this.savechangesLoader=false;
        this.templateData.componentContentList.forEach((value:any) => {
         value.editSection=false;
        });
        // this.editCollapse.emit(false);
        this.selectedComponent="";
        // this.pageSeoData=true;
        
        this.pageLoader = false;
      },
      error:(err)=>{
        this.savechangesLoader=false;
        ////console.log(err); 
        this.pageLoader = false;
      },
    }
  )
  }

  previewWebsite(){
    let a = window.localStorage.getItem('bDetails') || '';
    let b = JSON.parse(a);
    let websiteUrl = b.websiteUrl;
    window.open(`${websiteUrl}`,'_blank')
  }
}
