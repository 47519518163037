<div class="main-section">
    <div class="heading-section">
        <div class="image">
            <img src="{{image.imgUrl}}" alt="">
        </div>
        <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div [ngStyle]="subheading.style?subheading.style:{}">{{subheading.value}}</div>
    </div>
    <div class="stats-section">
        <div class="stats" *ngFor="let item of statsHeading.value;let i = index" [ngClass] ="{box2 : i === 1}">
            <div [ngStyle]="statsHeading.style?statsHeading.style:{}">{{item}}</div>
            <div [ngStyle]="statsText.style?statsText.style:{}">{{statsText.value[i]}}</div>
        </div>
    </div>
</div>
