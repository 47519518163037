<div class="total-section" [ngStyle]="{'background-color': bgColor}">
    <div class="mainContainer">
        <div class="logo-details">
            <span><img src="{{data[0].imgUrl}}" alt="" [ngStyle]="data[0].style?data[0].style:{}" class="logoImage"></span>
            <span [ngStyle]="data[1].style?data[1].style:{}" class="clarityUIHeading">{{data[1].value | titlecase}}</span>
        </div>
        <div class="middleSection">
            <ul>
                <li style="margin-right:35px">
                    <a [href]="data[6].value"
                    [ngStyle]="data[5].style?data[5].style:{}">{{data[5].value}}</a>
                </li>
                <li>
                    <a [href]="data[8].value" [ngStyle]="data[7].style?data[7].style:{}">
                        {{data[7].value}}
                    </a>
                </li>
            </ul>
            <!-- <span [ngStyle]="logoImage.style?logoImage.style:{}">{{career.value}}</span>
            <span [ngStyle]="logoImage.style?logoImage.style:{}">{{help.value}}</span>
            <span [ngStyle]="logoImage.style?logoImage.style:{}">{{privacy.value}}</span> -->
        </div>
    </div>
    <div style="display:flex" class="last-section">
        <div [ngStyle]="data[9].style?data[9].style:{}" class="copyRights">
            {{data[9].value | titlecase}}
        </div>
        <div class="powered_by">
            Powered by <a href="https://simpo.ai">simpo.ai</a>
        </div>
    </div>
</div>