<section class="main_section">
    <div class="main-header-section">
        <div class="main-heading-text" [ngStyle]="data[0].style?data[0].style:{}">
            {{data[0].value}}
        </div>
        <div class="main-heading-heading" [ngStyle]="data[1].style?data[1].style:{}">
            {{data[1].value}}
        </div>
        <div class="main-heading-sub-heading" [ngStyle]="data[2].style?data[2].style:{}">
            {{data[2].value}}
        </div>
    </div>
    <div class="middle-section">
        <div class="middle-left-section">
            <div class="first-container">
                <div class="first-main-heading" [ngStyle]="data[3].style?data[3].style:{}">
                    {{data[3].value}}
                </div>
                <div class="first-middle-heading" [ngStyle]="data[4].style?data[4].style:{}">
                    {{data[4].value}}
                </div>
                <div class="first-bottom-heading" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value}}
                </div>
            </div>
            <div class="first-container">
                <div class="first-main-heading" [ngStyle]="data[3].style?data[3].style:{}">
                    {{data[3].value}}
                </div>
                <div class="first-middle-heading" [ngStyle]="data[4].style?data[4].style:{}">
                    {{data[4].value}}
                </div>
                <div class="first-bottom-heading" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value}}
                </div>
            </div>
            <div class="first-container">
                <div class="first-main-heading" [ngStyle]="data[3].style?data[3].style:{}">
                    {{data[3].value}}
                </div>
                <div class="first-middle-heading" [ngStyle]="data[4].style?data[4].style:{}">
                    {{data[4].value}}
                </div>
                <div class="first-bottom-heading" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value}}
                </div>
            </div>
            <div class="first-container">
                <div class="first-main-heading" [ngStyle]="data[3].style?data[3].style:{}">
                    {{data[3].value}}
                </div>
                <div class="first-middle-heading" [ngStyle]="data[4].style?data[4].style:{}">
                    {{data[4].value}}
                </div>
                <div class="first-bottom-heading" [ngStyle]="data[5].style?data[5].style:{}">
                    {{data[5].value}}
                </div>
            </div>
        </div>

        <div class="middle-right-section">
            <img src="{{data[6].imgUrl}}" alt="" [ngStyle]="data[6].style?data[6].style:{}"
                class="main-img" />
        </div>
    </div>
</section>