import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-faq-with-more-question',
  templateUrl: './simple-faq-with-more-question.component.html',
  styleUrls: ['./simple-faq-with-more-question.component.scss','./mq-simple-faq-with-more-question.component.scss']
})
export class SimpleFaqWithMoreQuestionComponent {

  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      {
        "value": "Frequently asked questions",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "36px"}
      },
      {
        "value": "Everything you need to know about the product and billing.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "20px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured icon.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "Is there a free trial available?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value": "Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured_image_1.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "Can I change my plan later?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value": "Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured_image_2.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "What is your cancellation policy?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value": "We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured_image_3.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "Can other info be added to an invoice?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value": "At the moment, the only way to add additional information to invoices is to add the information to the workspace's name.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured_image_4.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "How does billing work?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value":"Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/faq/Featured_image_5.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "48px","margin-bottom":"2%"}
      },
      {
        "value": "How does billing work?",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 600,"font-size": "20px","color":"#101828"}
      },
      {
        "value": "Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 400,"font-size": "16px","color":"#475467"}
      },
      

    ]
  }

  data = this.templateData.data;
}
