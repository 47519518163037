<div class="main">
    <div>
    <div class="heading">
        <div [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div [ngStyle]="text.style?text.style:{}">{{text.value}}</div>
    </div>
    <div>
        <div> < </div>
        <div> > </div>
    </div>
    </div>
    <div class="gallery">
        <div class="images" *ngFor="let image of images.value">
            <div class="image-container">
                <img src="{{image.imgUrl}}" alt="" [ngStyle]="images.style?images.style:{}">
            </div>
            <div [ngStyle]="imageHeading.style?imageHeading.style:{}">{{imageHeading.value}}</div>
        </div>
    </div>
</div>
