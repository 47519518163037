import { Component, Input } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';

@Component({
  selector: 'app-preview-process-count-image-side',
  templateUrl: './process-count-image-side.component.html',
  styleUrls: ['./preview-process-count-image-side.component.scss']
})
export class PreviewProcessCountImageSideComponent {

  
  @Input() templateData:any;
  valuesData : any;

  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private webisiteService:WebisiteService
  ){}

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'process_count_image_side'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  // templateData: any = {
  //   'componentCategory': "process",
  //   'ranking': 0,
  //   'businessCategoryId': "",
  //   "businessCategoryName": "All category",
  //   "createdTimeStamp": "",
  //   "language": "English",
  //   "componentName": "process-3",
  //   "data" : [
  //     {
  //       "value":"Let's see how it works",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"line-height": "normal"},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7B7A7A", "padding-top":"10px"},
  //       "textType":"description"
  //     },
  //     {
  //       "imgUrl":"./../../../../../../../assets/images/helping_hand.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{},
  //       "textType":"image"
  //     },
  //     {
  //       "value":"1",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color": "#090114", "border-color": "aqua"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Find a Product",
  //       "type":"text",
  //       "field":"text",
  //       "style":{},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7B7A7A"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"2",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color": "#090114", "border-color": "aqua"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Customize as you like",
  //       "type":"text",
  //       "field":"text",
  //       "style":{},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7B7A7A"},
  //       "textType":"text"
  //     },
  //     {
  //       "imgUrl":"./../../../../../../../assets/images/helping_hand.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{},
  //       "textType":"image"
  //     },
  //     {
  //       "imgUrl":"./../../../../../../../assets/images/helping_hand.jpg",
  //       "type":"image",
  //       "field":"image",
  //       "style":{},
  //       "textType":"image"
  //     },
  //     {
  //       "value":"3",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"background-color": "#090114", "border-color": "aqua"},
  //       "textType":"text"
  //     },
  //     {
  //       "value":"Release in 10 minutes",
  //       "type":"text",
  //       "field":"text",
  //       "style":{},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco Amet minim mollit non deserunt ullamco",
  //       "type":"text",
  //       "field":"text",
  //       "style":{"color":"#7B7A7A"},
  //       "textType":"text"
  //     }
  //   ]
  // }

  // data = this.templateData.data
  // heading = this.data[0]
  // description = this.data[1]
  // image1 = this.data[2]
  // number1 = this.data[3]
  // findAProductText = this.data[4]
  // findAProductDescription = this.data[5]
  // number2 = this.data[6]
  // customizeText = this.data[7]
  // customizeDescription = this.data[8]
  // image2 = this.data[9]
  // image3 = this.data[10]
  // number3 = this.data[11]
  // releaseText = this.data[12]
  // releaseDescription = this.data[13]

  data : any;

  bgColor : any;

  dataAssign(){
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    this.checkingStyles();
    if(this.templateData.values != null){
      var bName = this._dbService.getBusinessName();
      this.valuesData = this.templateData.values;
      this.data[0].value = this.valuesData.title;
      this.data[1].value = this.valuesData.desc;
      this.data[2].imgUrl = this.valuesData.data[0].img;
      this.data[4].value = this.valuesData.data[0].text;
      this.data[5].value = this.valuesData.data[0].desc;
      this.data[7].value = this.valuesData.data[1].text;
      this.data[8].value = this.valuesData.data[1].desc;
      this.data[9].imgUrl = this.valuesData.data[1].img;
      this.data[10].imgUrl = this.valuesData.data[2].img;
      this.data[12].value = this.valuesData.data[2].text;
      this.data[13].value = this.valuesData.data[2].desc;
      // this.data[3].value = this.valuesData.data[0].desc;
      // this.data[4].imgUrl = this.valuesData.data[1].img;
      // this.data[5].value = this.valuesData.data[1].desc;
      // this.data[6].imgUrl = this.valuesData.data[2].img;
      // this.data[7].value = this.valuesData.data[2].desc;
      // this.valuesData = this.templateData.values;
      // this.data[0].value = this.valuesData.text.replaceAll("[Company Name]", bName);
      // this.data[1].value = this.valuesData.features[0];
      // this.data[2].value = this.valuesData.features[1];
      // this.data[3].value = this.valuesData.features[2];
      // this.data[7].imgUrl = this.valuesData.imageUrl;
      // this.data[5].value = this.valuesData.button1Text;
      // // this.data[4].value = this.valuesData.counter1Count;
      // // this.data[5].value = this.valuesData.counter1Text;
      // // this.data[6].value = this.valuesData.counter2Count;
      // // this.data[7].value = this.valuesData.counter2Text;
      // // this.data[8].imgUrl = this.valuesData.imageUrl;

    }
    else{

    }
    // this.heading = this.valuesData.text;
    // this.description = this.valuesData.subtext[0];
    // this.cards = this.valuesData.counter1Count;
    // this.cardsDelivered = this.valuesData.counter1Text;
    // this.transactions = this.valuesData.counter2Count;
    // this.transactionsCompleted= this.valuesData.counter2Text;
    // this.image = this.valuesData.imageUrl;
  }
  checkingStyles() {
    this.data.forEach((elems: any) => {
      //text
      if (elems.type === 'text') {
        elems= this.webisiteService.typeText(elems,this.templateData)
      } else if (elems.type === 'button') {
        elems= this.webisiteService.typeButton(elems,this.templateData)
      } else if (elems.type === 'array') {
        this.typeArray(elems);
      }
    });
  }
  typeArray(data: any) {
    data.value.forEach((arrValue: any) => {
      if (arrValue.type === 'text') {
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      } else if (arrValue.type === 'button') {
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }
}
