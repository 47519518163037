<ng-container *ngIf="screenWidth > 475">
    <div class="header-sectiontop">
        <div class="left-headertop">
            <div class="done-editortext" (click)="websiteEditoeDone()">
                <img src="./../../../../../../assets/images/editor/backeditorChnages.svg" alt="backeditorchnages">
            </div>
        </div>
        <div class="right-headertop">
            <div class="page-dropdown whitebackgroundInput">
                <mat-form-field appearance="outline" class="days_dropdown">
                    <mat-select [(ngModel)]="pageSeoslected">
                        <mat-option [value]="item.name" *ngFor="let item of templatePages"
                            (click)="pageSelected()">{{item.name}}</mat-option>
                        <!-- <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
              <mat-option value="option3">Option 3</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="style-sheet">
                <img src="./../../../../../../assets/images/editor/styleshetIcon.svg"  alt="style-sheet" (click)="styleSheetSelected()">
            </div>
            <div class="share-icon">
                <img src="./../../../../../../assets/images/editor/shareicon.svg" alt="shareIcon"
                    (click)="shareWebsite()">
            </div>
            <div class="preview-icon">
                <img src="./../../../../../../assets/images/editor/previewicon.svg" alt="preview-icon" />
            </div>
            <div class="save-chnages-btn">
                <button (click)="saveChanges()">
                    <span *ngIf="!savechangesLoader">
                        Save Changes
                    </span>
                    <span *ngIf="savechangesLoader">
                        <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="screenWidth < 475">
    <div class="header-sectiontop">
        <div class="left-headertop">
            <div class="done-editortext" (click)="websiteEditoeDone()">
                <img src="./../../../../../../assets/images/common/left_arrow.svg" alt="">
            </div>
        </div>
        <div class="right-headertop">
            <div class="page-dropdown">
                <mat-form-field appearance="outline" class="days_dropdown">
                    <mat-select [(ngModel)]="pageSeoslected">
                        <mat-option [value]="item.name" *ngFor="let item of templatePages"
                            (click)="pageSelected()">{{item.name}}</mat-option>
                        <!-- <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                      <mat-option value="option3">Option 3</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="reload-icon">
                <img src="./../../../../../../assets/images/editor/reload_icon.svg" alt="reloadIcon" />
            </div> -->
            <!-- <div class="files-icon">
                <img src="./../../../../../../assets/images/editor/files_icon.svg" alt="files-icon" (click)="styleSheetSelected()"/>
            </div> -->
            <div class="share-icon">
                <img src="./../../../../../../assets/images/editor/shareicon.svg" alt="shareIcon"
                    (click)="shareWebsite()">
            </div>
            <div class="preview-icon" (click)="previewWebsite()">
                <img src="./../../../../../../assets/images/common/eye_icon.svg" alt="preview-icon" />
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!pageLoader">
    <div class="total-aboutsection">

        <div class="componentSectionData">
            <div class="header-editor">
                <div class="rightheader-section">
                    <img src="./../../../../../../assets/images/editor/3dots.svg" alt="3dots">
                </div>
                <div class="leftheader-section">
                    <div class="url-text">
                        {{domainName}}
                    </div>
                    <!-- <div class="customedomins-text">
                        Connect Custom Domain
                    </div> -->
                </div>
            </div>
            <hr class="editor-hr"/>
            <div class="editor-containersection">
                <ng-container *ngFor="let item of templateData.componentsData;let i = index">
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Top Nav Bar'">
                        <div *ngIf="item.componentName === 'nav_bar_simple_page_links'">
                            <app-simple-navbar-pagelinks
                                [templateData]="templateData.componentContentList[i]"></app-simple-navbar-pagelinks>
                        </div>
                        <div *ngIf="item.componentName === 'nav_bar_simple_contact'">
                            <app-navbar-contact-btn
                                [templateData]="templateData.componentContentList[i]"></app-navbar-contact-btn>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                               Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit-navbarsHeader">
                                    <div class="edit-sectionheader" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName=='Hero Banner'">
                        <div *ngIf="item.componentName === 'double_stats_hero_banner'">
                            <app-herobanner-stat-image
                                [templateData]="templateData.componentContentList[i]"></app-herobanner-stat-image>
                        </div>
                        <div *ngIf="item.componentName === 'rating_review_hero_banner'">
                            <app-herobanner-rating-review-bgimg-image
                                [templateData]="templateData.componentContentList[i]"></app-herobanner-rating-review-bgimg-image>
                        </div>
                        <div *ngIf="item.componentName === 'simple_hero_banner'">
                            <app-simple-hero-banner
                                [templateData]="templateData.componentContentList[i]"></app-simple-hero-banner>
                        </div>
                        <div *ngIf="item.componentName === 'simple_button_image_hero_banner'">
                            <app-simple-herobanner-button-image
                                [templateData]="templateData.componentContentList[i]"></app-simple-herobanner-button-image>
                        </div>
                        <div *ngIf="item.componentName === 'double_stats_hero_banner_reverse'">
                            <app-herobanner-stat-image-reverse
                                [templateData]="templateData.componentContentList[i]"></app-herobanner-stat-image-reverse>
                        </div>
                        <div *ngIf="item.componentName === 'simple_hero_banner_rightside_background'">
                            <app-siple-herobaner-rightside-background
                                [templateData]="templateData.componentContentList[i]"></app-siple-herobaner-rightside-background>
                        </div>
                        <div *ngIf="item.componentName === 'hero_banner_features_rightside_image'">
                            <app-herobanner-feature-rightside-image
                                [templateData]="templateData.componentContentList[i]"></app-herobanner-feature-rightside-image>
                        </div>
                        <div *ngIf="item.componentName === 'hero_banner_with_review'">
                            <app-herobanner-with-review
                                [templateData]="templateData.componentContentList[i]"></app-herobanner-with-review>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                               Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Location'" id="Location">
                        <div *ngIf="item.componentName === 'map_with_left_side_address'">
                            <app-map-left-address
                                [templateData]="templateData.componentContentList[i]"></app-map-left-address>
                        </div>
                        <div *ngIf="item.componentName === 'map_with_right_side_address'">
                            <app-map-with-right-address
                                [templateData]="templateData.componentContentList[i]"></app-map-with-right-address>
                        </div>
                        <div class="show_btns">
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <!-- <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage"> -->
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Contact Us'" id="Contact Us">
                        <div *ngIf="item.componentName === 'simple_contactus_right_heading'">
                            <app-contactus-text-inputs
                                [templateData]="templateData.componentContentList[i]"></app-contactus-text-inputs>
                        </div>
                        <div *ngIf="item.componentName === 'simple_contact_form'">
                            <app-contact-us1 [templateData]="templateData.componentContentList[i]"></app-contact-us1>
                        </div>
                        <div class="show_btns">
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="displayBlogs">
                        <div class="single_website" *ngIf="item.componentCategoryName === 'Blog'" id="Blog">
                            <div *ngIf="item.componentName === 'Blog_Cards_Horizontal_Align'">
                                <app-blog-cards-horizontal-align
                                    [blogData]="blogData"></app-blog-cards-horizontal-align>
                            </div>
                            <div *ngIf="item.componentName === 'Blog_Cards_Vertical_Align'">
                                <app-blog-cards-vertical-align [blogData]="blogData"></app-blog-cards-vertical-align>
                            </div>
                            <div class="show_btns">
                                <!-- <div class="add-section-top" (click)="addSection()">
                                   Add Section +
                                </div> -->
                                <div class="containerelment">
                                    <div class="feild-edit-last-section">
                                        <div class="edit-section2">
                                            <div class="ImageInnerSection">
                                                <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                    class="action-sectionImage">
                                                <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                    class="action-sectionImage"
                                                    (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                    <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                    class="action-sectionImage"
                                                    (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                                <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                    class="action-sectionImage"
                                                    (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-2">
                                                    <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                    class="action-sectionImage"
                                                    (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-2">
                                                <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                    class="action-sectionImage"
                                                    (click)="deleteComponent(item.componentName)">
                                            </div>
                                        </div>
                                        <div class="edit-section"
                                            (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                            <div class="edit image">
                                                <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                    alt="regenrate">
                                            </div>
                                            <div class="regenerate-text">
                                                Regenerate
                                            </div>
                                        </div>
                                        <div class="edit-section"
                                            (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                            <div class="edit image">
                                                <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                    alt="regenrate">
                                            </div>
                                            <div class="regenerate-text">
                                                Change Content
                                            </div>
                                            s
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="single_website" *ngIf="item.componentCategoryName=='Service Section'">
                        <div *ngIf="item.componentName === 'service_section_simple_horizontal'">
                            <app-servicesectioneditor
                                [templateData]="templateData.componentContentList[i]"></app-servicesectioneditor>
                        </div>
                        <div *ngIf="item.componentName === 'service_section_simple_vertical'">
                            <app-verticle-service-section
                                [templateData]="templateData.componentContentList[i]"></app-verticle-service-section>
                        </div>
                        <div *ngIf="item.componentName === 'hover_horizontal_service_section'">
                            <app-hover-horizontal-service-section
                                [templateData]="templateData.componentContentList[i]"></app-hover-horizontal-service-section>
                        </div>
                        <div class="show_btns">
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="add-section-bottom" (click)="addSection()">
                                Add Section +
                             </div> -->
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName === 'About Us'">
                        <div *ngIf="item.componentName === 'simple_about_us_section'">
                            <app-aboutus [templateData]="templateData.componentContentList[i]"></app-aboutus>
                        </div>
                        <div *ngIf="item.componentName === 'simple_about_us_section_background_image'">
                            <app-aboutus-with-background-image
                                [templateData]="templateData.componentContentList[i]"></app-aboutus-with-background-image>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                                Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="add-section-bottom" (click)="addSection()">
                                Add Section +
                            </div> -->
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Process'">
                        <div *ngIf="item.componentName === 'process_section_spark_image_side'">
                            <app-process-spark-image-side
                                [templateData]="templateData.componentContentList[i]"></app-process-spark-image-side>
                        </div>
                        <div *ngIf="item.componentName === 'process_section_number_text'">
                            <app-process-number-text
                                [templateData]="templateData.componentContentList[i]"></app-process-number-text>
                        </div>
                        <div *ngIf="item.componentName === 'process_count_image_side'">
                            <app-process-count-image-side
                                [templateData]="templateData.componentContentList[i]"></app-process-count-image-side>
                        </div>
                        <div *ngIf="item.componentName === 'process_count_image'">
                            <app-process-count-image
                                [templateData]="templateData.componentContentList[i]"></app-process-count-image>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                                Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="add-section-bottom" (click)="addSection()">
                                Add Section +
                            </div> -->
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentCategoryName === 'FAQ'">
                        <div *ngIf="item.componentName === 'horizontal_faq'">
                            <app-horizontal-faq
                                [templateData]="templateData.componentContentList[i]"></app-horizontal-faq>
                        </div>
                        <div *ngIf="item.componentName === 'opened_faq'">
                            <app-opened-faq [templateData]="templateData.componentContentList[i]"></app-opened-faq>
                        </div>
                        <div *ngIf="item.componentName === 'faq_slider'">
                            <app-slider-faq-components
                                [templateData]="templateData.componentContentList[i]"></app-slider-faq-components>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                                Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="add-section-bottom" (click)="addSection()">
                                Add Section +
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="single_website" *ngIf="item.componentName=='Our-success-story'">
                        <app-our-success-story [templateData]="templateData"></app-our-success-story>
                        <div class="show_btns" >
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate 
                                        </div>
                                    </div>
                                    <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg" alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="single_website" *ngIf="item.componentCategoryName=='Testimonial'">
                        <div *ngIf="item.componentName === 'testimonal_slickcarsouel'">
                            <app-testimonal-slickcarsouel
                                [templateData]="templateData.componentContentList[i]"></app-testimonal-slickcarsouel>
                        </div>
                        <div *ngIf="item.componentName === 'double_item_testimonial'">
                            <app-single-page-double-testimomial
                                [templateData]="templateData.componentContentList[i]"></app-single-page-double-testimomial>
                        </div>
                        <div *ngIf="item.componentName === 'single_horizontal_testimonial'">
                            <app-single-horizontal-testimonial
                                [templateData]="templateData.componentContentList[i]"></app-single-horizontal-testimonial>
                        </div>
                        <div *ngIf="item.componentName === 'single_vertical_testimonial'">
                            <app-single-verticle-testimonial
                                [templateData]="templateData.componentContentList[i]"></app-single-verticle-testimonial>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                               Add Section +
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section2">
                                        <div class="ImageInnerSection">
                                            <img src="./../../../assets/images/editor/copy_black.svg" alt="copyBlack"
                                                class="action-sectionImage">
                                            <img src="./../../../assets/images/editor/arrow-right.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i==1">
                                                <img src="./../../../../../../assets/images/editor/upArrowEnable.svg" alt="upArrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i-1)" *ngIf="i!==1">
                                            <img src="./../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i!=templateData.componentContentList.length-1">
                                                <img src="./../../../../../../assets/images/editor/down-arrow-disabled.svg" alt="downarrow"
                                                class="action-sectionImage"
                                                (click)="move(templateData.componentContentList,templateData.componentsData,i,i+1)" *ngIf="i==templateData.componentContentList.length-1">
                                            <img src="./../../../assets/images/editor/delete_icon.svg" alt="deleteIcon"
                                                class="action-sectionImage"
                                                (click)="deleteComponent(item.componentName)">
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Change Content
                                        </div>
                                        s
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="add-section-bottom" (click)="addSection()">
                                Add Section +
                             </div> -->
                        </div>
                    </div>
                    <!-- <div class="single_website" *ngIf="item.componentName=='contact-Us1'">
                        <app-contact-us1 [templateData]="templateData"></app-contact-us1>
                        <div class="show_btns" >
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="regenerate-text">
                                            Regenerate 
                                        </div>
                                    </div>
                                    <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="regenerate-text">
                                            Change Content 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single_website" *ngIf="item.componentName=='contact-Us2'">
                        <app-contact-us2 [templateData]="templateData"></app-contact-us2>
                        <div class="show_btns" >
                            <div class="containerelment">
                                <div class="feild-edit">
                                    <div class="edit-section" (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="regenerate-text">
                                            Regenerate 
                                        </div>
                                    </div>
                                    <div class="edit-section" (click)="changeContent(item.componentCategoryId,item.subIndustryId,item.contentId,i)">
                                        <div class="regenerate-text">
                                            Change Content 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="single_website" *ngIf="item.componentCategoryName === 'Footer'">
                        <div *ngIf="item.componentName === 'footer_simple_social'">
                            <app-footer1 [templateData]="templateData.componentContentList[i]"></app-footer1>
                        </div>
                        <div *ngIf="item.componentName === 'footer_simple_social_pages'">
                            <app-simple-footer-social-links
                                [templateData]="templateData.componentContentList[i]"></app-simple-footer-social-links>
                        </div>
                        <div *ngIf="item.componentName === 'footer_simple_vertical'">
                            <app-simple-verticle-footer
                                [templateData]="templateData.componentContentList[i]"></app-simple-verticle-footer>
                        </div>
                        <div *ngIf="item.componentName === 'footer_simple_horizontal'">
                            <app-simple-footer-copyrights
                                [templateData]="templateData.componentContentList[i]"></app-simple-footer-copyrights>
                        </div>
                        <div class="show_btns">
                            <!-- <div class="add-section-top" (click)="addSection()">
                               Add Section +
                            </div> -->
                            <!-- <div class="containerelment">
                                <div class="feild-edit-navbarsHeader">
                                    <div class="edit-sectionheader" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="containerelment">
                                <div class="feild-edit-footer">
                                    <div class="edit-section" (click)="editSection(item.componentName)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                                        </div>
                                        <div class="editsection-text">
                                            Edit Section
                                        </div>
                                    </div>
                                    <div class="edit-section"
                                        (click)="regenerateComponent(item.componentCategoryId,item.componentId,item.subIndustryId,item.contentId,i)">
                                        <div class="edit image">
                                            <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                                                alt="regenrate">
                                        </div>
                                        <div class="regenerate-text">
                                            Regenerate
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

        </div>

        <div *ngIf="screenWidth > 475" class="right-edit-section">
            <div *ngIf="editParam" class="editor-tabsection">
                <app-rightsidepanel [templateData]="templateData"
                    (editCollapse)="collapseSection($event)"></app-rightsidepanel>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="pageLoader">
    <div class="loader_section">
        <div class="api_loader">
            <div class="dots-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <!-- <img src="./../../../../../assets/images/loader/beeos_loader.gif" alt="loader_loading"> -->
            <!-- <div class="loader_text">
                Fetching Details
            </div> -->
        </div>
    </div>
</ng-container>