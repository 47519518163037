import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-generating-website',
  templateUrl: './generating-website.component.html',
  styleUrls: ['./generating-website.component.scss']
})
export class GeneratingWebsiteComponent implements OnInit {

  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef | any;

  constructor(
    private _templateService : TemplateService,
    private _dbService : DbService,
    private r: ComponentFactoryResolver,
    private _payments : PaymentsService
  ) { 
    // this.getTemplateStructure();
    this.getblogsBysubCategoryId();
  }

  data : any;

  majorStructure:any = [
    {id: "1edc0986-f58b-63c1-a029-21768a32ab8c", name: "Top Nav Bar"},
    {id: "1edbd77a-4998-6c0b-b88c-6d0efb2d612a", name: "Hero Banner"},
    {id: "1edbccbe-5e71-6136-9f89-b719b955f796", name: "Testimonial"},
    {id: "1edbf3aa-33be-6415-8f52-5102a9d9775d", name: "Service Section"},
    {id: "1edbfd8e-68e3-66bd-9dd6-53f322fc934b", name: "Footer"},
    {name:"Process Section"}
  ];

  allcomponents : any = [
    {
      name:'double_stats_hero_banner',
      // component:HerobannerStatImageComponent
    },
    {
      name:'rating_review_hero_banner',
      // component:HerobannerRatingReviewBgimgImageComponent
    },
    {
      name:'simple_hero_banner',
      // component:SimpleHeroBannerComponent
    },
    {
      name:'simple_button_image_hero_banner',
      // component:SimpleHerobannerButtonImageComponent
    },
    {
      name:'testimonal_slickcarsouel',
      // component:TestimonalSlickcarsouelComponent
    },
    {
      name:'double_item_testimonial',
      // component:SinglePageDoubleTestimomialComponent
    },
    {
      name:'single_horizontal_testimonial',
      // component:SingleHorizontalTestimonialComponent
    },
    {
      name:'single_vertical_testimonial',
      // component:SingleVerticleTestimonialComponent
    },
    {
      name:'service_section_simple_horizontal',
      // component:ServicesectioneditorComponent
    },
    {
      name:'service_section_simple_vertical',
      // component:VerticleServiceSectionComponent
    }
  ]

  subCategoryId = '1edc342d-1eb4-69b4-bd04-bb2add92e776';
  componentNames: any = [];
  templateData:any={
    // "id": "1edb27b8-b73e-62c3-be4f-8127e4a93850",
    "componentContentList": [],
    componentsData:[],
    "businessId": null,
    "businessName": null,
    "primaryColor": null,
    "buttonColor": null,
    "textFamily": null
  }

  ngOnInit(): void {
  }
  // getTemplateStructure(){
  //   this._templateService.getComponentsData('1edbd77a-4998-6c0b-b88c-6d0efb2d612a',this.subCategoryId).subscribe(
  //     (res:any) => {
  //       //console.log(res.data);
  //       this.data = res.data
  //     },
  //     (err) => {
  //       //console.log(err.error.message);
  //     }
  //   )
  // }

  blogData:any="";
  getblogsBysubCategoryId() {
    this._templateService.getBlogsBySubInstryId(this._dbService.getSubCategoryId()).subscribe
      ({
        next: (res: any) => {
          this.blogData = res.data;
          
        },
        error: (err: any) => {
        }
      })

  }

  // getTemplateStructure(){
  //   this._templateService.getTemplateStructure(this.subCategoryId).subscribe(
  //     (res:any) => {
  //       //console.log("template Structure",res.data.categories);
  //       res.data.categories.forEach((categories:any)=>{
  //         this.componentNames.push(categories);
  //       });
  //       this.templateDataAssign();
  //     },
  //     (err) => {
  //       //console.log(err);
  //     }
  //   )
  // }
  // async templateDataAssign(){
  //   await this.componentNames.forEach((compNames:any,i:any) => {
  //     this._templateService.getComponentsData(compNames.id,this.subCategoryId).subscribe(
  //       (res:any) => {
  //         // //console.log(res.data);
  //         this.templateData.componentContentList.push(res.data);
  //         this.pickDynamicTemplate(this.templateData,i);
  //       },
  //       (err)=> {
  //         //console.log(err.error.message);
  //       }
  //     )
  //   });
  // }
  // pickDynamicTemplate(data:any,index:any){
  //   //console.log(data,index);
  //   data.componentContentList.forEach((conCompList:any) => {
  //     this.listOfComponents.forEach((listComponents:any,i:any) => {
  //       if(conCompList.webComponentName === listComponents.name){
  //         const abc = this.r.resolveComponentFactory(listComponents.component);
  //         const k = this.vc.createComponent(abc);
  //         k.instance.tempData = conCompList;
  //         // this._templateService.updateElements.emit(conCompList);
  //         this.listOfComponents.splice(i,1);
  //       }
  //     });
  //   });
  //   //console.log(this.listOfComponents);
  // }


  // regenerateComponent(ev:any){
  //   //console.log('clicked on regenerate');
  //   //console.log(ev.value);
  // }
  // changeContent(){
  //   //console.log('clicked on change content')
  // }


  // stripe api call
  stripeChecking(){
    let data = {
    }
    this._payments.paymentAPI(data).subscribe(
      (res:any) => {
        //console.log(res)
        window.location.href = `${res.data}`
      },
      (err) => {
        //console.log(err)
      }
    )
    
  }



}
