import { Component } from '@angular/core';

@Component({
  selector: 'app-listed-companies-horizontal',
  templateUrl: './listed-companies-horizontal.component.html',
  styleUrls: ['./listed-companies-horizontal.component.scss' , './mq-listed-companies-horizontal.component.scss' ]
})
export class ListedCompaniesHorizontalComponent {
  templateData: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "data" : [
      {
        "value": "Join 4,000+ companies already growing",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "16px", "color":"#475467"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_1.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_2.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_3.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_4.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_5.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
      {
        "imgUrl": "./../../../../../../../../assets/images/client/company_image_6.svg",
        "type":"Image",
        "field":"Image",
        "style":{"width": "100%"}
      },
    ]
  }

  data = this.templateData.data;
}
